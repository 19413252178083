import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, CircularProgress } from '@material-ui/core';
import { AppTheme } from '../../styles/AppTheme';
import { Helper } from '../../common/helpers/Helper';
import { CourseMgr } from '../../common/mgrs/CourseMgr';

export function CourseTileButton(props) {
  const icon = (props.status === CourseMgr.CourseStatus.COMPLETED) ? 'document_download_icon' : 'arrow_right_icon'
  const buttonText = (props.status === CourseMgr.CourseStatus.COMPLETED) ? Helper.getString('downloadCertificate') : Helper.getString('viewCourse')
  return (
    <Button fullWidth={props.fullWidth}
      onClick={props.onClick}
      disabled={props.inProcess}
      className={css(Styles.button, props.buttonStyle)}>
      {!props.inProcess &&
        <div className={css(Styles.buttonTextContainer)}>
          <p className={css(Styles.buttonText, props.buttonTextStyle)}>{buttonText}</p>
          <img
            alt={Helper.getString('arrowRightImgAlt')}
            width='18px' height='18px'
            src={require(`../../assets/images/${icon}.svg`)}
            className={css(Styles.imgContent)} />
        </div>
      }
      {props.inProcess &&
        <CircularProgress size={20} className={css(Styles.progressIcon)} />
      }
    </Button>
  )
}

const Styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    backgroundColor: AppTheme.primaryColor,
    // minWidth: 150,
    padding: 6,
    // minHeight: 64,
    '@media (max-width: 767px)': {
      padding: '7px 8px',
    },
  },
  buttonTextContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonText: {
    fontSize: 12,
    margin: 0,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg,
    '@media (max-width: 767px)': {
      fontSize: 10
    },
  },
  progressIcon: {
    color: AppTheme.secondaryBg
  },
  imgContent: {
    width: 18,
    height: 18,
    marginLeft: 4,
    '@media (max-width: 767px)': {
      width: 14,
      height: 14,
    },
  },
})