import React, { } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Course } from '../../../screens/Course';
import { Controller } from '../../../common/Controller';

export function CoursePreview(props) {
  const courseSlug = props.match.params.slug
  // const orgId = (Controller.get().roleHelper().isProductUser() ? (props.match.params.orgId ? props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
  const orgId = props.match.params.orgId ? props.match.params.orgId : Controller.get().roleHelper().getOrgId()
  const location = props.location && props.location
  return (
    <Course courseSlug={courseSlug} preview={true} orgId={orgId} location={location} />
  )
}

const Styles = StyleSheet.create({
})