import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { AppStyles } from '../../styles/AppTheme';
import { Helper } from '../helpers/Helper';
import { UiHelper } from '../helpers/UiHelper';
import { AppConfig } from '../../AppConfig';
import { CustomProductsStyles } from '../../styles/CustomStyles';
import { Controller } from '../../common/Controller';

const queryString = require('query-string');

export class OrgTeams extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.query = queryString.parse(props.location.search);
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.pageTitle = Helper.getString('groups')
    this.addNewLabel = 'Add'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    if (Controller.get().userMgr().isProductSU() ||
      Controller.get().userMgr().hasOrgRole(this.orgId, 'SUPERUSER') ||
      Controller.get().userMgr().hasOrgRole(this.orgId, 'MANAGER') ||
      Controller.get().userMgr().hasOrgRole(this.orgId, 'CREATOR')) {
      this.toFetch = [
        { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs', this.orgId]) },
        { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs'], { parentOrgId: this.orgId, pb: 'fieldDefSubOrg' }) },
      ]
    } else {
      this.toFetch = []
    }
    this.tableHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span><br />
            <span className={css(Styles.spanValueSmall)}>{current.id}</span>
          </div>
        )
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      parentOrgId: this.orgId
    }
    this.pageKey = 'department'
    this.noAdd = (Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) ? false : true
  }

  onFetchSuccess(results) {
    let items = []
    if (Controller.get().userMgr().isProductSU() || Controller.get().userMgr().hasOrgRole(this.orgId, 'SUPERUSER') || Controller.get().userMgr().hasOrgRole(this.orgId, 'CREATOR')) {
      items = results[1].items
    } else if (Controller.get().userMgr().hasOrgRole(this.orgId, 'MANAGER')) {
      const subOrgIds = Controller.get().roleHelper().getSubOrgIds() || []
      const subOrgIdsDict = {}
      for (const s of subOrgIds) {
        subOrgIdsDict[s] = s
      }
      for (const i of results[1].items) {
        if (subOrgIdsDict[i.orgId]) {
          items.push(i)
        }
      }
    }

    this.formDefinition = results[1] && results[1].fieldDefSubOrg

    const userData = this.userData && this.userData.orgs.filter(i => i.orgId === this.orgId)

    this.pageTitle = results[0].title
    this.setState({
      items: items.sort(
        function (a, b) { return a.title.toLowerCase() >= b.title.toLowerCase() ? 1 : -1 }),
      orgDetails: results[0]
    })
  }

  onTableValue(current, index) {
    switch (index) {
    case 0:
      return current.id
    case 1:
      return current.title
    default:
      return ''
    }
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['orgs']),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', current.id]),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', current.id]),
    });
  }

  onActions(current) {
    const actions = []
    return actions
  }

  onMenuItems(current) {
    const actions = []
    if (!current.parentOrgId) {
      return actions
    }

    let menuItems = []
    if (Controller.get().userMgr().isProductSU() ||
      Controller.get().userMgr().hasOrgRole(this.orgId, 'SUPERUSER')) {
      menuItems = [
        {
          current: current,
          key: current.id + 'editTT',
          action: () => this.setState({ current: current }, () => this.openModalForEdit(current)),
          title: Helper.getString('edit'),
          img: require('../../assets/images/edit-icon.svg')
        },
        {
          current: current,
          key: current.id + 'deleteTT',
          action: () => this.setState({ current: current }, () => this.openModalForDelete(current)),
          title: Helper.getString('delete'),
          img: require('../../assets/images/trash-icon.svg')
        },
        {
          current: current,
          key: current.id + '-users',
          link: this.baseRoute() + '/teams/' + current.id + '/users',
          title: 'Members',
          icon: 'how_to_reg'
        },
        {
          current: current,
          key: current.id + '-courses',
          link: this.baseRoute() + '/teams/' + current.id + '/courses',
          title: 'Courses',
          icon: 'task_alt',
          state: current
        }
      ]
    } else if (Controller.get().userMgr().hasOrgRole(this.orgId, 'MANAGER') || Controller.get().userMgr().hasOrgRole(this.orgId, 'CREATOR')) {
      menuItems = [
        {
          current: current,
          key: current.id + '-users',
          link: this.baseRoute() + '/teams/' + current.id + '/users',
          title: 'Members',
          icon: 'how_to_reg'
        },
        {
          current: current,
          key: current.id + '-courses',
          link: this.baseRoute() + '/teams/' + current.id + '/courses',
          title: 'Courses',
          icon: 'task_alt',
          state: current
        }
      ]
    }
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions
  }

  customSubmit(options) {
    const { formType, formData } = options
    if (formType === 'add') {
      ApiHelper.call({
        method: 'POST', endPoint: ApiHelper.makeUrlPath(['orgs']),
        jsonBody: { ...formData, zoneinfo: this.state.orgDetails.zoneinfo }
      },
      (err, data) => {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err)
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return
        } else {
          this.setState({ modalOpen: false })
          this.fetchItems()
        }
      })
    }
    if (formType === 'edit') {
      ApiHelper.call({
        method: 'PUT', endPoint: ApiHelper.makeUrlPath(['orgs', this.state.current.id]),
        jsonBody: { ...formData }
      },
      (err, data) => {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err)
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return
        } else {
          this.setState({ modalOpen: false })
          this.fetchItems()
        }
      })
    }
    if (formType === 'delete') {
      ApiHelper.call({
        method: 'DELETE', endPoint: ApiHelper.makeUrlPath(['orgs', this.state.current.id]),
      },
      (err, data) => {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err)
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return
        } else {
          this.setState({ modalOpen: false })
          this.fetchItems()
        }
      })
    }
  }

}

const Styles = AppConfig.CUSTOM_STYLE ? CustomProductsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 16
  },
  spanValueSmall: {
    fontSize: 14
  }
})