import React, { useState, Fragment, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AppCommonTheme } from '../styles/Styles';
import FormControl from '@material-ui/core/FormControl';

export function RadioComponent(props) {
  const [state, setState] = useState({
    value: props.value ? props.value : '0'
  })

  const change = (e) => {
    // if (e.target.value === state.value) {
    //   setState({
    //     ...state,
    //     value: ''
    //   })
    //   props.handleState(e.target.name, '')
    // } else {
    setState({
      ...state,
      value: e.target.value
    })
    props.handleState(e.target.name, e.target.value)
    // }
  }

  return (
    <RadioGroup name={props.item.id} value={state.value} className={css(Styles.radioContainer)} onClick={change}>
      {props.option.map((option, index) => {
        return (
          <FormControlLabel
            key={index}
            name={props.item.id}
            value={option.value}
            control={<Radio className={css(Styles.radio)} color='primary' />}
            label={option.label}
            labelPlacement='top'
            classes={{
              label: css(Styles.formLabel)
            }}
            className={css(Styles.formControlLabel)}
          />
        )
      })}
    </RadioGroup>
  )
}

const Styles = StyleSheet.create({
  radioContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'unset',
    flexDirection: 'row'
  },
  radio: {
    padding: 4
  },
  formLabel: {
    fontSize: 14,
    color: AppCommonTheme.appFontColor
  },
  formControlLabel: {
    margin: '0px 30px',
    '@media (max-width: 767px)': {
      margin: 0,
      flex: 1
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      margin: '0px 10px',
    }
  }
})