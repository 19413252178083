import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';
import { CategoryTile } from './CategoryTile';
import { ViewMoreButton } from '../ViewMoreButton';
import { CommonStyles } from '../../styles/Styles';
import { Controller } from '../../common/Controller';

export function Category(props) {

  const tileDisplay = (item, index) => {
    return (
      <Grid key={item.id} lg={4} xs={12} sm={6} md={4} item >
        <CategoryTile
          key={index}
          item={item}
          categories={props.categories}
          backButton={props.backButton} />
      </Grid>
    )
  }

  const data = props.data
  const orgId = Controller.get().roleHelper().getOrgId()
  return (
    <div className={css(props.borderContainer)}>
      <div className={css(CommonStyles.titleOuterContainer)}>
        {props.showTitle &&
          <p className={css(CommonStyles.titleContainer)}>{props.title}</p>
        }
        {data.viewMoreButtonTop &&
          <div className={css(CommonStyles.viewMoreButtonContainer)}>
            <ViewMoreButton
              buttonTextStyle={CommonStyles.buttonTextStyle}
              onClick={() => Controller.get().courseMgr().pushRoute('/learner/orgs/' + orgId + '/categories')} />
          </div>
        }
      </div>

      {data && data.items.length > 0 &&
        <Grid container spacing={2}>
          {data.items.map((item, index) => {
            return (
              data.categorieItemLimit ?
                index < data.categorieItemLimit &&
                tileDisplay(item, index)
                :
                tileDisplay(item, index)
            )
          })}
        </Grid>
      }
    </div>

  )
}

const Styles = StyleSheet.create({
})