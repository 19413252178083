import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';
import { AppTheme } from '../styles/AppTheme';
import { AppButton } from './AppButton';
import { AppHelper } from '../helpers/AppHelper';
import { Document, Page } from 'react-pdf';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export function DocumentFile(props) {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [showMaximize, setShowMaximize] = useState(false)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setShowMaximize(false)
      } else {
        setShowMaximize(true)
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [state, setState] = useState({
    numPages: null,
    pageNumber: 1
  })

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState({
      ...state,
      numPages: numPages
    })
  }

  const customNavigation = () => {
    return (
      <div className={css(Styles.pageControler)}>
        <div className={css(Styles.leftButtonContainer)} >
          {state.pageNumber !== 1 &&
            <div
              className={css(Styles.iconButton)}
              onClick={() => setState({ ...state, pageNumber: state.pageNumber - 1 })}>
              <img
                src={require('../assets/images/arrow_left.svg')}
                className={css(Styles.imgContent)} width='24px' height='24px'
                alt={Helper.getString('documentArrowImgAlt')} />
            </div>
          }
        </div>
        <div className={css(Styles.rightButtonContainer)}>
          {state.pageNumber < state.numPages &&
            <div className={css(Styles.iconButton, Styles.rightIconButton)}
              onClick={() => setState({ ...state, pageNumber: state.pageNumber + 1 })}>
              <img
                src={require('../assets/images/arrow_right.svg')}
                className={css(Styles.imgContent)} width='24px' height='24px'
                alt={Helper.getString('documentArrowImgAlt')} />
            </div>
          }
        </div>
      </div>
    )
  }

  const screenSizeHelper = () => {
    setIsFullScreen(!isFullScreen)
  }

  const customNavigation2 = () => {
    return (
      <div className={css(Styles.pageControler2)}>
        <div className={css([Styles.leftButtonContainer2, Styles.fullscreenIcon])} >
          {state && (isFullScreen ? <FullscreenExitIcon style={{ color: '#4B8BE9' }} onClick={screenSizeHelper} /> : <FullscreenIcon style={{ color: '#4B8BE9' }} onClick={screenSizeHelper} />)}
        </div>
        <div className={css(Styles.leftButtonContainer2)} >
          {state.pageNumber !== 1 &&
            <div
              className={css(Styles.iconButton2)}
              onClick={() => setState({ ...state, pageNumber: state.pageNumber - 1 })}>
              <img
                src={require('../assets/images/arrow_left.svg')}
                className={css(Styles.imgContent2)} width='24px' height='24px'
                alt={Helper.getString('documentArrowImgAlt')} />
            </div>
          }
        </div>
        <div className={css(Styles.rightButtonContainer2)}>
          {state.pageNumber < state.numPages &&
            <div className={css(Styles.iconButton2, Styles.rightIconButton2)}
              onClick={() => setState({ ...state, pageNumber: state.pageNumber + 1 })}>
              <img
                src={require('../assets/images/arrow_right.svg')}
                className={css(Styles.imgContent2)} width='24px' height='24px'
                alt={Helper.getString('documentArrowImgAlt')} />
            </div>
          }
        </div>
      </div>
    )
  }

  const downloadPdf = async (e) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    setState({
      ...state,
      downloadProgress: true,
    })

    const fName = AppHelper.getFileName(props.data)
    if(navigator && navigator.userAgent.startsWith('Azsincx')) {
      AppHelper.downloadFileForMobileApp(props.data.contentLink,fName)
    } else {
      AppHelper.downloadFile(props.data.contentLink)
    }
  }

  const height = props.height ? props.height : 650
  return (
    <div className={css(Styles.outerContainer, isFullScreen && Styles.outerContainerFullScreen)}>
      {customNavigation2()}
      <div className={css(Styles.innerContainer)} style={{ maxHeight: !isFullScreen ? height : 'unset', minHeight: !isFullScreen ? height : 'unset' }}>
        <div className={css(Styles.documentOuterContainer)}>
          {props.data.contentLink &&
            <Document
              file={props.data.contentLink}
              onLoadError={(error) => console.log('Inside Error', error)}
              onLoadSuccess={onDocumentLoadSuccess}
              className={css(Styles.documentContainer)}>
              <Page pageNumber={state.pageNumber} />
            </Document>
          }
        </div>
      </div>
      {props.data.showDownloadButton &&
        <div className={css(Styles.buttonOuterContainer)}>
          <AppButton
            onClick={(e) => downloadPdf(e)}
            buttonText={Helper.getString('download')}
            img={'download_icon'}
            imgAlt={Helper.getString('downloadImgAlt')}
            buttonTextContainer={Styles.buttonTextContainer}
            imgContent={Styles.imgContentIcon}
            buttonContainer={Styles.buttonContainer}
            buttonTextStyle={Styles.buttonTextStyle}
          />
        </div>
      }
    </div>
  )
}

const Styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
    border: '1px solid #DCDBE0',
    borderRadius: AppCommonTheme.borderRadius,
    flexDirection: 'column'
  },
  // outerContainerFullScreen: {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   width: '100%',
  //   zIndex: 1201,
  //   background: '#fff',
  //   height: '99dvh'
  // },
  outerContainerFullScreen: {
    position: 'absolute',
    top: 10,
    left: 10,
    bottom: 10,
    right: 10,
    width: '100vm',
    zIndex: 1201,
    background: '#fff',
    justifyContent: 'flex-start'
    // height: '100vh'
  },
  innerContainer: {
    borderRadius: AppCommonTheme.borderRadius,
    display: 'flex',
    overflowY: 'auto',
    width: '100%',
    '@media (max-width: 767px)': {
      maxHeight: 460,
      minHeight: 460,
      overflowY: 'unset', //TODO check this
    },
  },
  documentContainer: {
    borderRadius: AppCommonTheme.borderRadius,
  },

  pageControler: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  pageControler2: {
    display: 'flex',
    alignSelf: 'flex-end',
    gap: 15,
    cursor: 'pointer',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20
  },
  leftButtonContainer: {
    display: 'flex',
  },
  leftButtonContainer2: {
  },
  rightButtonContainer: {
    display: 'flex',
  },
  rightButtonContainer2: {
  },
  iconButton2: {
  },
  iconButton: {
    width: 50,
    height: 80,
    backgroundColor: AppTheme.secondaryBg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '0px 10px 10px 0px',
    '@media (max-width: 767px)': {
      width: 40,
      height: 64,
    },
  },
  rightIconButton: {
    borderRadius: '10px 0px 0px 10px'
  },
  rightIconButton2: {
  },
  imgContent: {
    width: 24,
    height: 24,
    '@media (max-width: 767px)': {
      width: 18,
      height: 18,
    },
  },
  imgContent2: {
    width: 20,
    height: 20,
    border: '1px solid #4B8BE9',
    borderRadius: '50%',
    padding: 3,
    '@media (max-width: 767px)': {
      width: 16,
      height: 16,
      padding: 2
    },
  },
  documentOuterContainer: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    borderRadius: AppCommonTheme.borderRadius,
    '@media (max-width: 767px)': {
      overflow: 'hidden',
    },
  },

  buttonOuterContainer: {
    position: 'absolute',
    top: 12,
    left: 10
  },
  buttonTextContainer: {
    flexDirection: 'row-reverse'
  },
  imgContentIcon: {
    marginLeft: 0,
    marginRight: 8
  },
  buttonContainer: {
    backgroundColor: '#ffffff4D',
    border: '1px solid' + AppTheme.primaryColor,
    padding: '4px 13px'
  },
  buttonTextStyle: {
    color: AppTheme.primaryColor
  },
  // fullscreenIcon: {
  //   '@media (max-width: 767px)': {
  //     display: 'none',
  //   },
  // }
})