import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { ApiHelper } from '../helpers/ApiHelper';
import { BaseEntityPage } from './BaseEntityPage';
import { AppStyles } from '../../styles/AppTheme';
import { UiHelper } from '../helpers/UiHelper';
import { Helper } from '../helpers/Helper';
import { Controller } from '../Controller';


export class Orgs extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.orgId = this.props.match.params.orgId
    this.pageTitle = Helper.getString('orgs')
    this.pageKey = 'client'
    this.breadCrumbs = [ ]
    this.toFetch = [
      {
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs'],
          { pb: 'fieldDef', parentOrgId: 'ROOT' })
      },
    ]
    this.tableHeaders = [
      'Id',
      'Name'
    ]
    this.apiData = {}
    this.headerActions = [{
      label: 'Add',
      onClick: () => this.openModalForAdd()
    }]
  }

  onFetchSuccess(results) {
    this.fieldDef = results[0].fieldDef
    this.formDefinition = this.fieldDef
    this.setState({
      items: results[0].items.sort(
        function (a, b) { return a.title && a.title.toLowerCase() >= b.title && b.title.toLowerCase() ? 1 : -1 })
    })
  }

  onTableValue(current, index) {
    switch (index) {
    case 0:
      return current.id
    case 1:
      return current.title
    default:
      return ''
    }
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['orgs']),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', current.id]),
    });
  }

  onActions(current) {
    const actions = []
    return actions
  }

  onMenuItems(current) {
    const actions = []
    const menuItems = [
      {
        current: current,
        key:  current.id + 'editTT',
        action: () => this.openModalForEdit(current),
        title: Helper.getString('edit'),
        img: require('../../assets/images/edit-icon.svg')
      },
      {
        current: current,
        key: current.id + '-org',
        link: '/admin/orgs/' + current.id,
        title: 'Explore',
        icon: 'subdirectory_arrow_right'
      }
    ]
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions

  }

}