import React, { Component } from 'react';
import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';
import { StyleSheet, css } from 'aphrodite';

import Dialog from '@material-ui/core/Dialog';

import { DynamicForm } from './DynamicForm';
import { AppStyles, AppTheme } from '../../styles/AppTheme';
import { CustomDynamicFormDialogStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';

export class DynamicFormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.setState({
      screenMessage: ''
    });
    this.props.onClosed();
  }

  fullWidthCondition(fullWidthDialog) {
    if (fullWidthDialog) {
      return true
    }
    if (window.innerWidth && window.innerWidth < 480) {
      return true
    }
    return false
  }

  render() {
    let dialogHeading = this.props.formDefinition.title
    const title = this.props.formDefinition.steps[0].fields.find(x => x.key === 'title')
    let deleteTitle = 'Delete'
    if (title && title.value) {
      deleteTitle = title.value
    }
    if (!dialogHeading) {
      if (this.props.formDefinition.formType === 'add') {
        dialogHeading = 'Add'
      } else if (this.props.formDefinition.formType === 'edit') {
        dialogHeading = 'Edit'
      } else if (this.props.formDefinition.formType === 'del' || this.props.formDefinition.formType === 'delete') {
        dialogHeading = deleteTitle
      } else {
        dialogHeading = 'Add/Edit/Delete'
      }
    }

    const fullWidthDialog = (this.props.fullWidthDialog && window.innerWidth > 480)
    return (
      <Dialog
        open={this.props.isOpen}
        aria-labelledby={this.props.formDefinition.steps[0].title}
        fullWidth={true}
        fullScreen={this.fullWidthCondition(fullWidthDialog)}
        classes={{
          root: css(Styles.customRoot),
          paper: css(Styles.customPaper),
        }}
        BackdropProps={fullWidthDialog && { style: { backgroundColor: 'unset' } }}

        maxWidth='md'
      >
        <div className={css([Styles.modalHeader, AppStyles.modalHeader])}>
          {/* <div className={css(Styles.modalTitle)}>{this.props.formDefinition.steps[0].title}</div> */}
          <div className={css(Styles.modalTitle)}>{dialogHeading}</div>
          <button className={css(Styles.modalCloseButton)} onClick={this.onCloseModal} disabled={this.state.inProcess}>
            <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        </div>
        <div className={css(Styles.modalContent)}>
          {(this.props.formDefinition.formType === 'del' || this.props.formDefinition.formType === 'delete') &&
            <p className={css(Styles.deleteMsg)}>{Helper.getString('deleteConfirmMsg')}</p>
          }
          {this.props.isOpen ? <DynamicForm {...this.props} /> : ''}
        </div>
      </Dialog>
    );
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomDynamicFormDialogStyles : StyleSheet.create({
  modalHeader: {
    padding: 25,
    paddingBottom: 10
  },
  modalTitle: {
    color: 'black',
    fontSize: 26,
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: 28,
    '@media (max-width: 767px)': {
      marginRight: 43,
    }
  },
  modalContent: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  modalCloseButton: {
    position: 'absolute',
    top: 33,
    right: 30,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    width: 18,
    height: 18
  },

  customRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
    zIndex: 13000
  },
  customPaper: {
    borderRadius: 40,
    // minWidth: 600,
    backgroundColor: AppTheme.secondaryBg,
    '::-webkit-scrollbar': {
      display: 'none'
    },
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
  }
})
