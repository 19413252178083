import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  convertToRaw,
  convertFromRaw
} from 'draft-js';
import { UiHelper } from '../../helpers/UiHelper';
import { ApiHelper } from '../../helpers/ApiHelper';
import './Styles.css'

export class RichtextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.processValue(props.value),
      fetchState: ApiHelper.State.LOADING
    }
    this.defaultContent = null
  }

  componentDidMount() {
    this.setState({
      fetchState: ApiHelper.State.READY
    })
  }

  processValue = (value) => {
    if (value) {
      try {
        this.defaultContent = JSON.parse(value).__azformatted;
      } catch (e) {
        console.log('Json parse error', e)
        return EditorState.createEmpty()
      }
    }
    if (this.defaultContent) {
      return EditorState.createWithContent(convertFromRaw(this.defaultContent))
    } else {
      return EditorState.createEmpty()
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    const data = convertToRaw(editorState.getCurrentContent())
    this.props.handleRichtextInputChange(JSON.stringify({ __azformatted: data }), this.props.current.key)
  };

  /*uploadImage = (file) => {
    const error = null
    return new Promise((resolve, reject) => {
      if (error) reject(error);
      else resolve({ data: { link: 'TODO:link to image' } });
    });
  };

  processValueV2 = () => {
    const c = localStorage.getItem('contentData')
    if (c) {
      this.defaultContent = JSON.parse(c)
    }
    if (this.defaultContent) {
      return EditorState.createWithContent(convertFromRaw(this.defaultContent))
    } else {
      return EditorState.createEmpty()
    }
  }

  onEditorStateChangeV2 = (editorState) => {
    this.setState({
      editorState,
    });
    const data = convertToRaw(editorState.getCurrentContent())
    localStorage.setItem('contentData', JSON.stringify(data))
  };*/

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.loadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView() {
    const toolbar = this.props.fullEditor ? undefined : {
      options: ['inline', 'list', 'link'],
      inline: {
        options: ['bold', 'italic'],
      },
      list: {
        options: ['unordered'],
      },
    }
    return (
      <div className={css(Styles.container)}>
        <Editor
          editorState={this.state.editorState}
          wrapperClassName='wrapper'
          editorClassName='editor'
          toolbarClassName='toolbar'
          onEditorStateChange={this.onEditorStateChange}
          toolbar={toolbar}
          stripPastedStyles={true}
        />
      </div>
    );
  }
}

const Styles = StyleSheet.create({
})
