import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Controller } from '../common/Controller';
import { FeedbackQuestions } from '../components/FeedbackQuestions';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Rating } from '../components/Rating';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { Helper } from '../common/helpers/Helper';
import { MultiTextFiled } from '../components/MultiTextFiled';
import { TitleComponent } from '../components/TitleComponent';
import { AppButton } from '../components/AppButton';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AppContext } from '../common/components/contexts/AppContext';
import { ErrorMsg } from '../components/ErrorMsg';
import { AppHelper } from '../helpers/AppHelper';

export function Feedback(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const cssBreakpoint = useMediaQuery('(max-width: 767px)');
  const [state, setState] = useState({
    result: {},
    rating: 0,
    comment: '',
    feedbackQuesAsw: {}
  })
  const orgId = Controller.get().roleHelper().getOrgId()
  const courseSlug = props.match.params.slug

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getFeedback({ orgId, courseSlug })
      console.log('response', response )
      let feedbackAlreadyGiven = false
      if (response && response.courseprogs) {
        for (const item in  response.courseprogs.items) {
          if (item.startsWith('feedbackquestion-') && response.courseprogs.items[item].state === 'SUBMITTED') {
            feedbackAlreadyGiven = true
          }
        }
        const feedbackValues = Object.keys(response.courseprogs.items).filter(i => i.startsWith('feedbackquestion'))
        setState({
          ...state,
          fetchState: ApiHelper.State.READY,
          result: response,
          rating: response.courseprogs.rating && response.courseprogs.rating,
          comment: response.courseprogs.comment && response.courseprogs.comment,
          feedbackQuesPresent: (feedbackValues && feedbackValues.length > 0) ? true : false,
          feedbackAlreadyGiven: feedbackAlreadyGiven
        })
      }
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const selectRate = (value) => {
    setState({ ...state, rating: value + 1 })
  }

  const handleState = (id, value) => {
    setState({
      ...state,
      feedbackQuesAsw: {
        ...state.feedbackQuesAsw,
        [id]: value
      }
    })
  }

  const onInputChange = (e) => {
    setState({
      ...state,
      comment: e.target.value
    })
  }

  const submitFeedback = async () => {
    if (state.rating === 0) {
      setState({
        ...state,
        openError: true,
        errorMsg: Helper.getString('pleaseRateThisCourse')
      })
      return
    }
    if ((!state.feedbackQuesPresent) && Object.keys(state.feedbackQuesAsw).length < Object.keys(state.result.feedbackQuestions).length) {
      setState({
        ...state,
        openError: true,
        errorMsg: Helper.getString('pleaseRateYourThougths')
      })
      return
    }
    setState({
      ...state,
      inProcess: true
    })
    try {
      for (const item in state.feedbackQuesAsw) {
        if (item !== 'undefined') {
          const response = await Controller.get().courseMgr().submitFeedback(item, state.feedbackQuesAsw[item], state.result.courseprogs)
        }
      }
      const response = await Controller.get().courseMgr().submitRating(state.rating, state.comment, state.result.courseprogs)
      if (state.result.certification === 'YES' && !state.feedbackAlreadyGiven) {
        const aid = AppHelper.getAssignmentId(state.result)
        Controller.get().courseMgr().navToCertificate(state.result.slug, { course: true }, aid)
      } else {
        Controller.get().courseMgr().navToCourse(state.result.slug)
      }
      setState({
        ...state,
        inProcess: false,
        openError: false,
      })
    }
    catch (e) {
      setState({ ...state, openError: true, errorMsg: Helper.getErrorMsg(e), inProcess: false })
    }
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }


  const backButton = {
    title: Helper.getString('back'),
    link: '/learner/orgs/' + orgId + '/course/' + courseSlug
  }

  const rows = cssBreakpoint ? 5 : 8
  return (
    <ScreenWrapper
      subTitle={Helper.getString('courseFeedback')}
      state={state}
      backButton={backButton}>

      {state.result &&
        <Fragment>
          <Rating
            rating={state.rating}
            selectRate={selectRate} />

          {state.result.feedbackQuestions && Object.keys(state.result.feedbackQuestions).length > 0 &&
            <div className={css(Styles.outerContainer)}>
              <TitleComponent
                title={Helper.getString('letUsKnowYourThougths')} />
              <FeedbackQuestions
                items={state.result.feedbackQuestions}
                handleState={handleState}
                result={state.result} />
            </div>
          }
          <div className={css(Styles.textOuterContainer)}>
            <TitleComponent
              title={Helper.getString('yourComments')} />
            <MultiTextFiled
              placeholder={Helper.getString('yourComments')}
              value={state.comment}
              onInputChange={onInputChange}
              rows={rows} />
          </div>
          <div className={css(Styles.buttonOuterContainer)}>
            <AppButton
              onClick={() => submitFeedback()}
              buttonContainer={Styles.buttonContainer}
              buttonText={Helper.getString('submitFeedback')}
              inProcess={state.inProcess}
            />
          </div>
          <ErrorMsg
            open={state.openError}
            errMsg={state.errorMsg}
            closeError={closeError}
          />

        </Fragment>
      }
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  outerContainer: {
    marginTop: 60
  },
  textOuterContainer: {
    marginTop: 20
  },
  buttonOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40
  },
  buttonContainer: {
    padding: '8px 33px',
    minWidth: 200,
    '@media (max-width: 767px)': {
      padding: '8px 31px',
    },
  },
})