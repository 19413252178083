import React, { Fragment } from 'react'
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { SearchBar } from './SearchBar';

export const Search = (props) => {
  const cssBreakpoint = useMediaQuery('(max-width:1025px)');

  return (
    <Fragment>
      {/* {cssBreakpoint &&
        <img
          alt={Helper.getString('searchImageAlt')}
          src={require('../assets/images/search_img.svg')}
          width='28px' height='28px'
          className={css(Styles.searchIconMobileContent)} />
      }
      {!cssBreakpoint &&
        <SearchBar
          searchText={props.searchText}
          onInputChange={props.onInputChange}
          onEndEditing={props.onEndEditing}
          header={true} />
      } */}
      <SearchBar
        searchText={props.searchText}
        placeholder={props.placeholder}
        onInputChange={props.onInputChange}
        onEndEditing={props.onEndEditing}
        header={true} />
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  searchIconMobileContent: {
    width: 28,
    height: 28,
  }
})
