import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { feedbackQuestionOption } from '../data/FormFields';
import { RadioComponent } from './RadioComponent';
import { AppCommonTheme } from '../styles/Styles';

export function FeedbackQuestions(props) {

  const question = (item, index) => {
    return (
      <div className={css(Styles.questionContainer)} key={index}>
        <p className={css(Styles.titleContainer)}>{index + 1}. {item.title}</p>
        <RadioComponent
          item={item}
          option={feedbackQuestionOption}
          handleState={props.handleState}
          value={(props.result.courseprogs && props.result.courseprogs.items && props.result.courseprogs.items[`feedbackquestion-${item.id}`]) &&
            props.result.courseprogs.items[`feedbackquestion-${item.id}`].response.value } />
      </div>
    )
  }

  return (
    <div className={css(Styles.outerContainer)}>
      {Object.keys(props.items) &&
        Object.keys(props.items).map((item, index) => {
          return question(props.items[item], index)
        })}
    </div>
  )
}

const Styles = StyleSheet.create({
  outerContainer: {
    marginTop: 24,
    '@media (max-width: 767px)': {
      marginBottom: 16
    },
  },
  questionContainer: {
    marginBottom: 40,
    '@media (max-width: 767px)': {
      marginBottom: 30
    },
  },
  titleContainer: {
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    fontSize: 22,
    marginBottom: 24,
    '@media (max-width: 767px)': {
      fontSize: 16
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      fontSize: 19
    }
  }
})