/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
export const StringTableCourse = [
  {
    "key": "cats",
    "en": "Categories"
  },
  {
    "key": "ccats",
    "en": "Categories"
  },
  {
    "key": "categories",
    "en": "Categories"
  },
  {
    "key": "level1",
    "en": "L1"
  },
  {
    "key": "level2",
    "en": "L2"
  },
  {
    "key": "level3",
    "en": "L3"
  },
  {
    "key": "brands",
    "en": "Brands"
  },
  {
    "key": "courses",
    "en": "Courses"
  },
  {
    "key": "course",
    "en": "Course"
  },
  {
    "key": "banners",
    "en": "Banners"
  },
  {
    "key": "imageUploaderTitle",
    "en": "Images"
  },
  {
    "key": "sections",
    "en": "Sections"
  },
  {
    "key": "chapters",
    "en": "Sub-Sections"
  },
  {
    "key": "info1",
    "en": "Info1"
  },
  {
    "key": "info2",
    "en": "Info2"
  },
  {
    "key": "resources",
    "en": "Resources"
  },
  {
    "key": "courseNotAssigned",
    "en": "Course is not assigned to you"
  },
  {
    "key": "feedbackConfigurationError",
    "en": "Invalid course configuration: Add feedback questions"
  },
  {
    "key": "assessmentConfigurationError",
    "en": "Invalid course configuration: Add assessment questions & answers"
  },
  {
    "key": "sectionConfigurationError",
    "en": "Invalid course configuration: Add sections/sub-sections"
  },
  {
    "key": "chapterConfigurationError",
    "en": "Invalid course configuration: Add sections/sub-sections"
  },
  {
    "key": "quizConfigurationError",
    "en": "Invalid course configuration: Add answers to Knowledge Check"
  },
  {
    "key": "nextConsents",
    "en": "Next (Consents)"
  },
  {
    "key": "nextFeedbacks",
    "en": "Next (Course Feedback)"
  },
  {
    "key": "nextCertificate",
    "en": "Next (Course Certificate)"
  },
  {
    "key": "groups",
    "en": "Groups"
  },
  {
    "key": "group",
    "en": "Group(s)"
  }
]
