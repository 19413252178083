import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
// import { AppTheme } from '../../styles/AppTheme';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AppCommonTheme } from '../styles/Styles';
import { Helper } from '../common/helpers/Helper';
import { AppButton } from '../common/components/AppButton';

export const CustomTableComponent = (props) => {
  const Styles = CreateStyleSheet()

  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: "white",
  //   },
  //   '&:nth-of-type(even)': {
  //     backgroundColor: "grey",
  //   },
  // }));

  const renderTableCell = (row, item) => {
    if (row[item.key] && Array.isArray(row[item.key])) {
      let str = ''
      for (const i of row[item.key]) {
        if (str.length === 0) {
          str = i
        } else {
          str = str + ', ' + i
        }
      }
      return str || '-'
    } else {
      return row[item.key] !== undefined ? row[item.key] : '-'
    }
  }

  const header = props.data.header
  const data = props.data.data


  return (
    <Fragment>
      <div className={css(Styles.buttonContainer)}>
        <p className={css(Styles.tableTitle)}>{props.title || ''}</p>
        {props.downloadTable && <AppButton buttonText='Download' buttonStyle={Styles.downloadButton} onClick={() => props.downloadTable(props.data)} />}
      </div>
      <TableContainer className={css(Styles.tableOuterContainer)}>
        <Table className={css(Styles.tableContainer)} style={{}}>
          <TableHead>
            <TableRow>
              {header && header.length > 0 &&
                header.map((item, index) => (
                  <TableCell key={index} className={css(Styles.tableHeaderItem)}>{item.title}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody className={css(Styles.tableBodyContainer)}>
            {(header && header.length > 0 && data && data.length > 0) &&
              data.map((row, rowIndex) => {
                return (
                  <TableRow key={rowIndex} className={css(Styles.tableRowContainer)}>
                    {header.map((item, index) => (
                      <TableCell key={index} className={css(Styles.tableItem)}>{renderTableCell(row, item)}</TableCell>
                    ))}
                  </TableRow>
                )
              })

            }
          </TableBody>
        </Table>
        {!(header && header.length > 0 && data && data.length > 0) &&
          <div className={css(Styles.emptyContainer)}>
            <p>{Helper.getString('emptyList')}</p>
          </div>
        }
      </TableContainer>
    </Fragment>
  )
}

function CreateStyleSheet() {
  return StyleSheet.create({
    tableOuterContainer: {
      marginTop: 41,
      border: '1px solid #DCDBE0',
      borderRadius: 12,
      maxHeight: 600,
      display: 'grid',
      '@media(max-width: 1366px)': {
        maxHeight: 'unset',
      },
    },
    tableBodyContainer: {
      overflow: 'scroll'
    },
    tableRowContainer: {
      ':nth-of-type(odd)': {
        backgroundColor: '#F9F9F9'
      }
    },
    tableContainer: {
      // tableLayout: 'fixed',
      // overflow: 'hidden'
      overflow: 'scroll'
    },
    tableHeaderItem: {
      position: 'sticky',
      top: 0,
      textAlign: 'center',
      fontSize: 16,
      color: AppCommonTheme.appFontColor,
      backgroundColor: '#DBE8FB',
      margin: 0,
      fontWeight: 600,
      borderBottom: 'unset',
      padding: '28px 5px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      borderRight: '1px solid #DCDBE0',
      minWidth: 140,
      '@media(max-width: 767px)': {
        fontSize: 12,
        minWidth: 100,
      },
      ':last-child': {
        borderRight: '0px solid #DCDBE0',
      }
    },
    tableHeader: {
      display: 'flex'
    },
    tableItem: {
      fontSize: 14,
      color: AppCommonTheme.appFontColor,
      margin: 0,
      fontWeight: 500,
      borderBottom: 'unset',
      padding: '20px 5px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'center',
      borderRight: '1px solid #DCDBE0',
      '@media(max-width: 767px)': {
        fontSize: 12
      },
      ':last-child': {
        borderRight: '0px solid #DCDBE0',
      }
    },
    emptyContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 100,
    },
    downloadButton: {
      // marginTop: 30,
      // marginBottom: -20
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 40
    },
    tableTitle: {
      flex: 1,
      margin: 0,
      fontSize: 26,
      fontWeight: 'bold',
      color: '#121212'
    }
  })
}