import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { AppHelper } from '../helpers/AppHelper';
import { AppTheme } from '../styles/AppTheme'
import { StyleHandler } from '../helpers/StyleHandler';
import { Helper } from '../common/helpers/Helper';
import ListItem from '@material-ui/core/ListItem';
import { AppCommonTheme } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { AppContext } from '../common/components/contexts/AppContext';
import { UserContext } from '../common/components/contexts/UserContext';

export function MobileFooter(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const { user, userContextStateHandler } = useContext(UserContext)
  const [state, setState] = useState({
  })

  const items = props.items ? props.items : AppHelper.getPublicDrawerMenuItems()

  const listItemContainerStyle = (id) => {
    if (appData && appData.pageKey === id) {
      return css(Styles.listItem, Styles.listItemSelectedContainer)
    } else {
      return css(Styles.listItem, Styles.listItemContainer)
    }
  }

  const itemSelected = (item) => {
    appContextStateHandler({ pageKey: item.id })
    Controller.get().courseMgr().pushRoute(item.route)
  }

  const listTitleContainerStyle = (id) => {
    if (appData && appData.pageKey === id) {
      return css([Styles.listTitle, Styles.listTitleSelectedContainer])
    } else {
      return css(Styles.listTitle)
    }
  }

  const navLinkRoute = (item) => {
    const profileImg = user && user.data && user.data.picture || require('../assets/images/profile_icon.svg')
    return (
      <div
        className={css(Styles.itemContent)}
        key={item.id}>
        <ListItem button
          key={item.id}
          onClick={() => itemSelected(item)}
          className={listItemContainerStyle(item.id)}>
          <div className={css(Styles.imgContainer)}>
            <img
              alt={item.id === 'profile' ? Helper.getString('profileImgAlt') : Helper.getString('menuIconsAlt')}
              width='24px' height='24px'
              src={item.id === 'profile' ? profileImg : StyleHandler.getImage(item.id, appData && appData.pageKey)}
              className={css(Styles.imgContent)} />
          </div>
          <div className={css(Styles.listTitleContainer)}>
            <p className={listTitleContainerStyle(item.id)}>{item.title}</p>
          </div>
        </ListItem>
      </div>
    )
  }

  return (
    <footer className={css(Styles.footer)}>
      {items.map(item => {
        return (
          navLinkRoute(item)
        )
      })}
    </footer>
  )
}

const Styles = StyleSheet.create({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: AppTheme.secondaryBg,
    zIndex: 101,
    padding: '10px 20px',
    paddingTop: 0,
    borderTop: AppCommonTheme.border
  },

  itemContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  imgContainer: {
    width: 24,
    height: 24,
    display: 'flex',
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: 6
  },
  listItemSelectedContainer: {
    padding: 0,
    backgroundColor: AppCommonTheme.bgColor,
    borderTop: '2px solid' + AppTheme.primaryColor,
  },
  listItemContainer: {
    padding: 0,
  },
  listItem: {
    height: 75,
    display: 'flex',
    justifyContent: 'center',
    borderTop: '2px solid' + AppTheme.secondaryBg,
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 100
  },

  listTitleContainer: {
    display: 'grid'
  },
  listTitle: {
    margin: 0,
    fontSize: 12,
    color: '#AAABAF',
    marginTop: 5,
    marginLeft: 3,
    marginRight: 3,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  listTitleSelectedContainer: {
    color: AppTheme.primaryColor
  }
})