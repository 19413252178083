import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppConfig } from '../AppConfig';
import { AppCommonTheme } from '../styles/Styles';

export function Logo(props) {
  const logoImgContainerStyle = props.login ? css(Styles.logoImgContainer, Styles.logoImgContainerLogin) : css(Styles.logoImgContainer)
  return (
    <div className={logoImgContainerStyle}>
      {AppConfig.LOGO ?
        <img
          alt={Helper.getString('logoImg')}
          // width='75px' height='75px'
          src={AppConfig.LOGO}
          className={props.login ? css(Styles.imgContent, Styles.logoImgContent) : css(Styles.imgContent)} />
        :
        <p className={css(Styles.logoText)}>{Helper.getString('logoIcon')}</p>
      }
    </div>
  )
}

const Styles = StyleSheet.create({
  logoImgContainer: {
    width: 75,
    height: 75,
    display: 'flex',
    justifyContent: 'center',
    // backgroundColor: '#D9D9D9',
    borderRadius: AppCommonTheme.borderRadius,
    alignItems: 'center'
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%',
    cursor: 'pointer'
  },
  logoImgContent: {
    cursor: 'default'
  },
  logoText: {
    margin: AppCommonTheme.pMargin,
    fontSize: 12,
    color: AppCommonTheme.appFontColor,
    textAlign: 'center',
    padding: 10
  },
  logoImgContainerLogin: {
    width: 86,
    height: 86,
    '@media (max-width: 767px)': {
      width: 74,
      height: 74
    },
  }
})