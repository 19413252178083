import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { AppTheme } from '../styles/AppTheme';
import { AppCommonTheme } from '../styles/Styles';
import 'react-circular-progressbar/dist/styles.css';

export function Progress(props) {
  return (
    <div className={css(Styles.progressOuterContainer)}>
      <CircularProgressbar value={props.value} text={`${props.value}%`} strokeWidth={4}
        styles={buildStyles({
          textSize: 25,
          pathColor: AppTheme.primaryColor,
          textColor: AppCommonTheme.appFontColor,
          trailColor: AppTheme.primaryColor + '66',
        })} />
    </div>
  )
}

const Styles = StyleSheet.create({
  progressOuterContainer: {
    width: 300,
    height: 300,
    '@media (max-width: 767px)': {
      width: 164,
      height: 164,
    }
  },
})