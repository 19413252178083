import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { Category } from '../components/Category/Category';
import { Courses } from '../components/Courses/Courses';
import { AppContext } from '../common/components/contexts/AppContext';

export function Explore(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {}
  })
  const orgId = Controller.get().roleHelper().getOrgId()

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getExploreDetails(orgId)
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        categories: response[0],
        courses: response[1]
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'explore' })
    fetchData()
  }, [])

  return (
    <ScreenWrapper
      state={state}
      title={Helper.getString('explore')}
      favourite={true}
      logo={true}
      search={true}
      filter={false}
      placeholder={Helper.getString('searchCourses')}
    >

      {state.categories && state.categories.items && state.categories.items.length > 0 &&
        <Category
          showTitle={true}
          title={Helper.getString('knowledgeSets')}
          // viewMoreButtonTop={true}
          borderContainer={[CommonStyles.borderContainer]}
          data={state.categories}
          backButton={{ explore: true }} />
      }
      {state.courses && state.courses.items && state.courses.items.length > 0 &&
        <Courses
          showTitle={true}
          title={Helper.getString('allCourses')}
          // viewMoreButtonTop={true}
          borderContainer={[CommonStyles.borderContainer, CommonStyles.courseContainer]}
          data={state.courses}
          backButton={{ explore: true }} />
      }
    </ScreenWrapper>
  )
}

const Styles = StyleSheet.create({
})