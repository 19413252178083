import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { TitleComponent } from './TitleComponent';
import { AppTheme } from '../styles/AppTheme';

export function Rating(props) {

  const rate = () => {
    const data = []
    for (let i = 0; i < 5; i++) {
      const imgSrc = props.rating > i ? require('../assets/images/star_selected.svg') : require('../assets/images/star.svg')
      const borderStyle = props.rating > i ? '1.5px solid' + AppTheme.primaryColor : '1.5px solid #AAABAF'
      data.push(
        <div key={i} className={css(Styles.rateImgContainer)} style={{ border: borderStyle }} onClick={() => props.selectRate(i)}>
          <img
            alt={Helper.getString('starImgAlt')}
            width='42px' height='42px'
            src={imgSrc}
            className={css(Styles.imgContent)} />
        </div>
      )
    }
    return data
  }

  return (
    <Fragment>
      <TitleComponent
        title={Helper.getString('rateThisCourse')} />
      <div className={css(Styles.rateOuterContainer)}>
        {rate()}
      </div>
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  rateOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24
  },
  rateImgContainer: {
    width: 84,
    height: 84,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 18,
    margin: '0px 12px',
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      width: 46,
      height: 46,
      margin: '0px 5px',
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      width: 60,
      height: 60,
    }
  },
  imgContent: {
    width: 42,
    height: 42,
    '@media (max-width: 767px)': {
      width: 24,
      height: 24,
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      width: 30,
      height: 30,
    }
  }
})