import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme, CommonStyles } from '../../styles/Styles';
import { Controller } from '../../common/Controller';
import { Courses } from '../../components/Courses/Courses';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Status } from '../../components/Status/Status';
import { ScreenWrapper } from '../../components/ScreenWrapper';
import { Helper } from '../../common/helpers/Helper';
import { AppContext } from '../../common/components/contexts/AppContext';
import { UserContext } from '../../common/components/contexts/UserContext';
import { AppHelper } from '../../helpers/AppHelper';
import { EmptyView } from '../../components/EmptyView';
import { useParams } from 'react-router-dom';
import { CourseMgr } from '../mgrs/CourseMgr';
import { Player } from '@lottiefiles/react-lottie-player';


export function CoursesOfUser(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const { user, userContextStateHandler } = useContext(UserContext)
  const params = useParams();
  console.log('params', params)
  const [state, setState] = useState({
    result: {}
  })
  const [courseData, setCourseData] = useState({})
  const [inProgress, setInProgress] = useState(false)
  const [userData, setUserData] = useState(null)
  // const orgId = '1677072721980' //params.orgId
  // const userId = '4d137c50b35811ed978005f7b4d41588' //params.userId
  const orgId = params.orgId
  const userId = params.userId
  const statusItems = AppHelper.status()
  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const resultData = await Promise.all([
        await Controller.get().courseMgr().getDashboard(orgId, userId),
        await Controller.get().courseMgr().getUserDetails(orgId, userId),
      ])
      const response = resultData[0]
      const user = resultData[1]
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response,
        courseItems: response.courses && response.courses || {}
      })
      setUserData(user)
      setCourseData({ items: response.courses.items })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'dashboard' })
    fetchData()
  }, [])

  const handleStatusClick = (status) => {
    console.log('handleStatusClick', status)
    setInProgress(true)
    const courses = []
    state.courseItems.items.map(item => {
      if (item._userContext) {
        if (status === 'assigned') {
          courses.push(item)
        } else if (status === 'selfenrolled' && item._userContext.selfenrolled === true) {
          courses.push(item)
        } else if (status === 'ongoing' && item._userContext.state === 'STARTED') {
          courses.push(item)
        } else if (status === 'duesoon' && item._userContext.duesoon === true) {
          courses.push(item)
        } else if (status === 'overdue' && item._userContext.overdue === true) {
          courses.push(item)
        } else if (status === 'completed' && (item._userContext.state === CourseMgr.CourseStatus.COMPLETED)) {
          courses.push(item)
        }
      }
    })

    setCourseData({ items: courses })
    setTimeout(() => {
      setInProgress(false)
    }, 200)
  }

  const backButton = {
    title: Helper.getString('back'),
    link: '/admin/orgs/' + orgId + '/users'
  }
  const getName = () => {
    if (!userData) return ''
    // console.log('userData', userData)
    const defaultName = userData.givenname
    let currentOrg = {}
    for(const org of userData.orgs) {
      if(org.orgId === orgId) currentOrg = org
    }
    const name = (currentOrg && Helper.formatName(currentOrg.orgUserProfile)) || defaultName
    return name
  }

  const title = getName()

  return (
    <ScreenWrapper
      // title={userData ? 'Courses of ' + Helper.formatName(userData) : ''}
      title={title}
      favourite={false}
      dashboard={false}
      state={state}
      logo={true}
      backButton={backButton}
    >

      {state.result &&
        <Fragment>
          {state.result.dashboard &&
            <Status items={statusItems} data={state.result.dashboard} tileSize={2} handleStatusClick={handleStatusClick} />
          }
          {!inProgress ?
            <Fragment>
              {state.result && state.result.courses && state.result.courses.items && state.result.courses.items.length > 0 ?
                <Courses
                  showTitle={true}
                  disableTileClick={true}
                  title={Helper.getString('courses')}
                  showSearch={true}
                  searchPlaceholder={Helper.getString('searchCourses')}
                  borderContainer={[CommonStyles.borderContainer, CommonStyles.courseContainer]}
                  titleOuterContainer={Styles.titleOuterContainer}
                  data={courseData} />
                :
                <EmptyView
                  title={Helper.getString('noItemsToShow')} />
              }
            </Fragment>
            :
            <div className={css(Styles.container)}>
              <Player
                autoplay
                loop
                src={require('../../assets/images/circle_loading_progress_indicator.json')}
                className={css(Styles.loadingContainer)} />
              {navigator && navigator.userAgent.startsWith('Azsincx')  ? '' :
                <p className={css(Styles.loadingText)}>{Helper.getString('loading')}</p>
              }
            </div>
          }
        </Fragment>
      }
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  titleOuterContainer: {
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 15
    }
  },
  loadingContainer: {
    height: 220,
    width: 220,
    '@media (max-width: 767px)': {
      height: 135,
      width: 135,
    }
  },
  loadingText: {
    fontSize: 48,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 5,
    '@media (max-width: 767px)': {
      fontSize: 28,
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
})