import { useEffect, useState } from 'react'

export default function useWindowResize() {

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobileView: false
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobileView: window.innerWidth <= 768
      });
    }
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}