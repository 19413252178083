import React, { Fragment, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme } from '../styles/Styles';
import { Helper } from '../common/helpers/Helper';
import { AppTheme } from '../styles/AppTheme';
import { Divider } from '@material-ui/core';
import { ResourceTile } from './Resources/ResourceTile';
import { CourseMgr } from '../common/mgrs/CourseMgr';
import LinearProgress from '@material-ui/core/LinearProgress';
import App from '../App';

export function Section(props) {
  const lockIcon = (status, item) => {
    if (status === CourseMgr.Status.COMPLETED) {
      return 'check_icon'
    }
    return 'lock_icon'
  }


  const data = props.data
  return (
    <div>
      {props.courseCompletionInfo && props.courseCompletionInfo.percentageCompletion > 0 && <div className={css(Styles.progressContainer)}>
        <p className={css(Styles.progressTitle)}>{Helper.getString('courseCompleted')} {props.courseCompletionInfo.percentageCompletion}%</p>
        <LinearProgress variant='determinate' value={props.courseCompletionInfo.percentageCompletion} className={css(Styles.progress)} />
      </div>}
      <p className={css(Styles.sectionTitle)}>{data.title}</p>

      {!props.preview &&
        <div className={css(Styles.progressContainer)}>
          <p className={css(Styles.progressTitle)}>{data.sectionwiseCompletedChapter}/{data.chapters.length} {Helper.getString('complete')}</p>
          <LinearProgress variant='determinate' value={data.progressValue} className={css(Styles.progress)} />
        </div>
      }

      {data.chapters && data.chapters.length > 0 &&
        data.chapters.map((item, index) => {
          const counter = index + 1
          const completed = item.status ? true : false
          return (
            <div className={css(Styles.titleOuterContainer)} key={index}>
              <div className={css(Styles.titleContainer)}>
                <p className={(completed && !props.preview) ? css(Styles.title, Styles.titleCompleted) : css(Styles.title)}>
                  <span className={css(Styles.counterContainer)}>{counter}</span>
                  <span className={css(Styles.titleContent)}>{item.title}</span></p>
              </div>
              {!props.preview &&
                <img src={require(`../assets/images/${lockIcon(item.status, item)}.svg`)} width='18px' height='18px' className={css(Styles.iconContent)} />
              }
            </div>
          )
        })}

      {data.resources && data.resources.length > 0 &&
        <Fragment>
          <Divider className={css(Styles.divider)} />
          <p className={css(Styles.resourcesTitle)}>{Helper.getString('resources')}</p>

          {data.resources.map((item, index) => {
            return (
              <ResourceTile
                mobileView={true}
                key={index}
                item={item} />
            )
          })}
        </Fragment>
      }
    </div>
  )
}

const Styles = StyleSheet.create({
  sectionTitle: {
    fontSize: 22,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginBottom: 24
  },
  resourcesTitle: {
    fontSize: 18,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginBottom: 18
  },
  titleOuterContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginBottom: 30
  },
  titleContainer: {
    flex: 1,
    marginRight: 10,
    display: 'grid'
  },
  title: {
    margin: AppCommonTheme.pMargin,
    color: AppTheme.primaryColor,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  titleCompleted: {
    color: AppCommonTheme.appFontColor,
  },
  titleContent: {
    fontSize: 18,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 12
  },
  iconContent: {
    width: 18,
    height: 18
  },
  counterContainer: {
    minWidth: 24,
    minHeight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    border: '1px solid' + AppTheme.primaryColor,
    borderRadius: '50%'
  },

  divider: {
    margin: '40px 0px',
    color: '#DCDBE0'
  },

  progressContainer: {
    marginBottom: 24
  },
  progressTitle: {
    color: AppTheme.primaryColor,
    fontSize: 10,
    margin: AppCommonTheme.pMargin,
    marginBottom: 3
  },
  progress: {
    borderRadius: 6
  }
})