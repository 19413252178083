import React, { Component, Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Grid, Card, CardContent, CardActionArea, Tooltip, Paper, Button, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { BaseEntityPage } from '../BaseEntityPage';
import { ApiHelper } from '../../helpers/ApiHelper';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Helper } from '../../helpers/Helper';
import { SubEntityCard } from '../SubEntity/SubEntityCard';
import { SubEntityList } from '../SubEntity/SubEntityList';
import { UiHelper } from '../../helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { CommonStyles } from '../../../styles/Styles';
import { Controller } from '../../Controller';
import moment from 'moment';
import { learnerFields, learnerTeamFields } from '../../../data/FormFields';

export class CoursePeople extends BaseEntityPage {
  constructor(props, options) {
    super(props)
    this.creatorPurposeOne = options.creatorPurposeOne
    this.pageTitle = Helper.getString('people')
    this.addNewLabel = 'Add'
    this.pageKey = 'People'
    this.noAdd = true
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.itemId = this.props.match.params.id
    this.uploadLocation = this.orgId + '/' + this.itemId
    this.emptyText = ' ' // space
    this.apiSpecForImageUploader = {}
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: 'Courses', to: this.baseRoute() + '/courses' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], {
          orgId: this.orgId, orgCourseId: this.itemId,
          allvisible: true,
          pb: 'fieldDefCourseSettings'
        })
      },
      { key: 'orgUsers', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { parentOrgId: this.orgId, maxPages: 5 }) },
      { key: 'subOrgs', method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs'], { parentOrgId: this.orgId, maxPages: 5 }) },
      { key: 'users', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { orgId: this.orgId }) }
    ]
    this.apiData = {
    }
    this.entityAttrName = 'people'

    this.tableHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      Title: (current) => current.text,
    }
    this.tableHeaders = this.tableHeadersMap

    this.tableLearnerHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      Title: (current) => <div className={css(Styles.leanerDetails)}><span>{current.name}</span><span>{current.email}</span></div>,
      'Due Date': (current) => current.dueDate,
    }

    this.tableOwnerHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      Title: (current) => <div className={css(Styles.leanerDetails)}><span>{current.name}</span><span>{current.email}</span></div>,
    }

    this.tableAuthorHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      Title: (current) => <div className={css(Styles.leanerDetails)}><span>{current.name}</span><span>{current.email}</span></div>,
    }

    this.tableLearnerHeaders = this.tableLearnerHeadersMap
    this.tableOwnerHeaders = this.tableOwnerHeadersMap
    this.tableAuthorHeaders = this.tableAuthorHeadersMap

  }

  onFetchSuccess(results) {
    const item = this.getResult(results, 'item')

    if (Controller.get().roleHelper().disableEditAccess(results[0], true)) {
      this.hideEditAction = true
      this.hideFormAction = true
    }

    // const fields = []
    // item.fieldDef.steps[0].fields.forEach(f => {
    //   // exclude anything not required
    //   if(f.key === 'slug') return
    //   fields.push(f)
    // })
    // item.fieldDef.steps[0].fields = fields

    this.pageTitle = item.title
    this.fieldDefCourseSettings = item.fieldDefCourseSettings
    this.fieldDefCourseSettings.steps[0].fields.forEach(i => i.placement = 'left')

    this.fieldDefOwners = {
      steps: [
        { fields: item.fieldDefCourseSettings.steps[0].fields.filter(i => Helper.inArray(['ownerIds'], i.key)) }
      ]
    }
    this.fieldDefAuthors = {
      steps: [
        { fields: item.fieldDefCourseSettings.steps[0].fields.filter(i => Helper.inArray(['authorIds'], i.key)) }
      ]
    }
    // this.fieldDefLearnerTeams = {
    //   steps: [
    //     { fields: item.fieldDefCourseSettings.steps[0].fields.filter(i => Helper.inArray(['learnerTeamIds'], i.key)) }
    //   ]
    // }

    this.fieldDefLearnerTeams = learnerTeamFields

    this.fieldDefLearnersNew = learnerFields

    const orgUsers = this.getResult(results, 'orgUsers')
    const users = this.getResult(results, 'users')
    const userById = {}
    for (const user of users.items) {
      userById[user.userId] = user
    }
    const keys = {}
    const userItems = []
    orgUsers.items.forEach(i => {
      if (keys[i.userId]) return
      if (!userById[i.userId]) return
      i.id = i.userId // We will keep userId (not the orgUserId)
      i.title = Helper.formatName(i) + ' (' + i.email + ')'
      i.name = Helper.formatName(i)
      keys[i.userId] = i
      userItems.push(i)
    })

    const userCheck = []
    userItems.forEach(i => {
      if (i.roles && (Helper.inArray(i.roles, 'SUPERUSER') || Helper.inArray(i.roles, 'CREATOR'))) {
        userCheck.push(i)
      }
    })

    const subOrgs = this.getResult(results, 'subOrgs')

    UiHelper.populateOptions(this.fieldDefOwners, { items: userCheck }, 'ownerIds')
    UiHelper.populateOptions(this.fieldDefAuthors, { items: userCheck }, 'authorIds')
    UiHelper.populateOptions(this.fieldDefLearnerTeams, { items: subOrgs.items }, 'learnerTeams')

    if (Controller.get().roleHelper().isOrgManagerUser() && !(Controller.get().userMgr().isProductSU() ||  Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isOrgCreatorUser())) {
      const managerOrgUsers = []
      const appUser = Controller.get().userMgr().getAppUser()
      for(const item of userItems) {
        if(item.managerUserId === item.parentOrgId + '-' + appUser.userId) {
          managerOrgUsers.push(item)
          continue
        }
        for(const org of appUser.orgs) {
          if(item.subOrgs && (item.subOrgs).includes(org.orgId)) managerOrgUsers.push(item)
        }
      }
      UiHelper.populateOptions(this.fieldDefLearnersNew, { items: managerOrgUsers }, 'learners')
    } else {
      UiHelper.populateOptions(this.fieldDefLearnersNew, { items: userItems }, 'learners')
    }

    this.ownerTable = []
    if (results[0].ownerIds && results[0].ownerIds.length > 0) {
      results[0].ownerIds.map(item => {
        const data = this.fieldDefOwners.steps[0].fields[0].options.filter(i => i.key === item)
        if (data[0]) {
          data[0].ownerIds = [data[0].key]
          data[0].name = Helper.formatName(userById[data[0].key])
          data[0].email = userById[data[0].key].email
          this.ownerTable.push({ ...data[0], id: data[0].key })
        }
      })
    }

    this.authorTable = []
    if (results[0].authorIds && results[0].authorIds.length > 0) {
      results[0].authorIds.map(item => {
        const data = this.fieldDefAuthors.steps[0].fields[0].options.filter(i => i.key === item)
        if (data[0]) {
          data[0].authorIds = [data[0].key]
          data[0].name = Helper.formatName(userById[data[0].key])
          data[0].email = userById[data[0].key].email
          this.authorTable.push(data[0])
        }
      })
    }

    if (Controller.get().roleHelper().isOrgManagerUser() && !(Controller.get().userMgr().isProductSU() ||  Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isOrgCreatorUser())) {
      const pOpts = []
      const subOrgIds = Controller.get().roleHelper().getSubOrgIds() || []
      for (const o of this.fieldDefLearnerTeams.steps[0].fields[0].options) {
        if (subOrgIds.indexOf(o.key) > -1) {
          pOpts.push(o)
        }
      }
      this.fieldDefLearnerTeams.steps[0].fields[0].options = pOpts
    }

    this.learnerTeamTable = []
    if (results[0].learnerTeams && Object.keys(results[0].learnerTeams).length > 0) {
      for (const item in results[0].learnerTeams) {
        const data = this.fieldDefLearnerTeams.steps[0].fields[0].options.filter(i => i.key === item)
        if (data[0]) {
          data[0].learnerTeams = [data[0].key]
          data[0].dueDate = results[0].learnerTeams[item].dueDate
          this.learnerTeamTable.push(data[0])
        }
      }
    }

    this.learnerNewTable = []
    if (results[0].learners && Object.keys(results[0].learners).length > 0) {
      for (const item in results[0].learners) {
        const data = this.fieldDefLearnersNew.steps[0].fields[0].options.filter(i => i.key === item)
        if (data[0]) {
          data[0].learners = [data[0].key]
          data[0].dueDate = results[0].learners[item].dueDate
          data[0].email = userById[data[0].key].email
          data[0].name = Helper.formatName(userById[data[0].key])
          this.learnerNewTable.push(data[0])
        }
      }
    }

    this.setState({
      item: item,
      items: Helper.dictToArray(results[0]),
      course: results[0]
    })
  }

  canAddOwner = () => {
    if(Controller.get().userMgr().isProductSU() ||  Controller.get().roleHelper().isOrgSU()) return false
    if(Controller.get().roleHelper().isOrgCreatorUser()) {
      const appUser = Controller.get().userMgr().getAppUser()
      const userId = appUser.userId
      if (this.state.item.ownerIds && this.state.item.ownerIds.indexOf(userId) > -1) {
        return false
      }
    }
    //if(Controller.get().roleHelper().isOrgManagerUser()) return false
    return true
  }

  renderAboveTable() {
    const { item } = this.state
    const { pathname } = this.props.location;
    const currentdDate = new Date();
    const defaultDueDate = (this.state.item && this.state.item.dueDays) && new Date(currentdDate.setDate(currentdDate.getDate() + Number(this.state.item.dueDays)));
    return (
      <div className={css(Styles.containerNarrow)}>
        {item &&
          <div className={css(CommonStyles.courseActionListContainer)}>
            <div className={css(CommonStyles.courseActionListInnerContainer)}>
              {UiHelper.isOrgClient(pathname) ? UiHelper.courseActionList(this, item, this.pageKey, this.orgId, this.itemId) :
                UiHelper.courseAdminActionList(this, item, this.pageKey, this.itemId)}
            </div>
            <Divider className={css(CommonStyles.courseActionListDivider)} />
          </div>
        }

        <div className={css(Styles.containerTableNarrow)}>
          <SubEntityList
            title={Helper.getString('owners')}
            tableHeadersMap={this.tableOwnerHeaders}
            items={this.ownerTable}
            openModalForAdd={() => this.openModalForAdd(this.fieldDefOwners)}
            noEdit={true}
            noDelete={this.canAddOwner()}
            openModalForDelete={(current) => this.openModalForDelete(current, this.fieldDefOwners)}
            noAdd={(this.hideEditAction || this.canAddOwner())}
          />
        </div>

        <div className={css(Styles.containerTableNarrow)}>
          <SubEntityList
            title={Helper.getString('authors')}
            tableHeadersMap={this.tableAuthorHeaders}
            items={this.authorTable}
            openModalForAdd={() => this.openModalForAdd(this.fieldDefAuthors)}
            noEdit={true}
            noDelete={Controller.get().roleHelper().isOrgManagerUser() && !(Controller.get().userMgr().isProductSU() ||  Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isOrgCreatorUser())}
            openModalForDelete={(current) => this.openModalForDelete(current, this.fieldDefAuthors)}
            noAdd={(this.hideEditAction || Controller.get().roleHelper().isOrgManagerUser()) && !(Controller.get().userMgr().isProductSU() ||  Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isOrgCreatorUser())}
          />
        </div>

        <div className={css(Styles.containerTableNarrow)}>
          <SubEntityList
            title={Helper.getString('groups')}
            tableHeadersMap={this.tableHeaders}
            items={this.learnerTeamTable}
            // openModalForAdd={() => this.openModalForAdd(this.fieldDefLearnerTeams)}
            openModalForAdd={() => this.openModalForEdit(defaultDueDate ? { dueDate: defaultDueDate } : {}, this.fieldDefLearnerTeams)}
            noEdit={true}
            noAdd={this.state.course.state !== 'LIVE' ? true : false }
            openModalForDelete={(current) => this.openModalForDelete(current, this.fieldDefLearnerTeams)}
          />
        </div>

        <div className={css(Styles.containerTableNarrow)}>
          <SubEntityList
            title={Helper.getString('learners')}
            tableHeadersMap={this.tableLearnerHeaders}
            items={this.learnerNewTable}
            openModalForAdd={() => this.openModalForEdit(defaultDueDate ? { dueDate: defaultDueDate } : {}, this.fieldDefLearnersNew)}
            noEdit={true}
            noAdd={this.state.course.state !== 'LIVE' ? true : false }
            // noDelete={Controller.get().roleHelper().isOrgManagerUser() && !(Controller.get().userMgr().isProductSU() ||  Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isOrgCreatorUser())}
            openModalForDelete={(current) => this.openModalForDelete(current, this.fieldDefLearnersNew)}
          />
        </div>

      </div>
    );
  }

  onAdd() { }

  onDelete() { }

  onEdit() { }

  customSubmit(options) {
    const { formType, formData } = options
    let keyName = ''

    for (const k in formData) {
      if (k === 'learners' || k === 'learnerTeams') {
        keyName = k
        break
      } else {
        keyName = k
      }
    }

    let uniqueArr = [];
    if (keyName !== 'learners' && keyName !== 'learnerTeams') {
      if (this.state.item[keyName]) {
        const combineArr = this.state.item[keyName].concat(formData[keyName])
        for (const i of combineArr) {
          if (uniqueArr.indexOf(i) === -1) {
            uniqueArr.push(i);
          }
        }
      } else {
        uniqueArr = formData[keyName]
      }

      if (formType === 'delete') {
        const index = uniqueArr.indexOf(formData[keyName][0]);
        if (index > -1) {
          uniqueArr.splice(index, 1);
        }
      }
    }

    const combineObj = {}
    console.log('formData', formData)
    if (keyName === 'learners') {
      for (const learnerId in formData[keyName]) {
        combineObj[formData[keyName][learnerId]] = {
          dueDate: moment.utc(formData.dueDate).local().format('YYYY-MM-DD'),
          assignedDate: moment.utc().local().format('YYYY-MM-DD'),
        }
      }
    }

    if (keyName === 'learnerTeams') {
      for (const learnerTeamId in formData[keyName]) {
        combineObj[formData[keyName][learnerTeamId]] = {
          dueDate: moment.utc(formData.dueDate).local().format('YYYY-MM-DD'),
          assignedDate: moment.utc().local().format('YYYY-MM-DD'),
        }
      }
    }

    const payload = { [keyName]: (keyName === 'learners' || keyName === 'learnerTeams') ? combineObj : uniqueArr }
    console.log('formType', formType)
    if (formType === 'delete') {
      payload.action = 'delete'
    }
    if (formType === 'edit') {
      payload.action = 'add'
    }
    ApiHelper.call({
      method: 'PUT', endPoint: ApiHelper.makeUrlPath(['orgcourses', this.itemId]),
      jsonBody: payload
    },
    (err, data) => {
      if (err) {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
        return
      } else {
        this.setState({ modalOpen: false })
        this.fetchItems()
      }
    })
  }

  openModalForOrgCourseEdit(current, fieldDef) {
    this.formDefinition = fieldDef
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onOrgCourseEdit(current);
    this.setState({
      modalOpen: true
    });
  }
  onOrgCourseEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: {},
      apiEndPoint: ApiHelper.makeUrlPath(['orgcourses', current.id])
    });
  }
}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  container: {
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
    marginBottom: 20,
    width: '100%',
  },

  containerTableNarrow: {
    marginBottom: 20,
    width: '100%'
  },
  leanerDetails: {
    display: 'flex',
    flexDirection: 'column'
  }
})