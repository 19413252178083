import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme } from '../styles/Styles';

export function Html(props) {

  return (
    <div className={css(Styles.outerContainer)}>
      {props.htmlLink &&
          <iframe
            title='Html'
            width='100%'
            height='642px'
            src={props.htmlLink}
            frameBorder='0'
            type='text/html'
            className={css(Styles.htmlContainer)} />
      }
    </div>
  )
}

const Styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  htmlContainer: {
    borderRadius: AppCommonTheme.borderRadius,
    height: 642,
    '@media(max-width: 767px)': {
      height: 500
    },
  }
})