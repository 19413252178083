import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../helpers/ApiHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { UiHelper } from '../../helpers/UiHelper';
import { CommonStyles } from '../../../styles/Styles';
import { Divider } from '@material-ui/core';
import { AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

export class CourseKnowledgeCheck extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.pageTitle = Helper.getString('knowledgeCheck')
    this.addNewLabel = 'Add'
    this.pageKey = 'CourseKnowledgeCheck'
    // this.orgId = (Controller.get().roleHelper().isProductUser() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.orgId = props.match.params.orgId ? props.match.params.orgId : Controller.get().roleHelper().getOrgId()
    this.itemId = this.props.match.params.id
    this.courseId = this.itemId.split('-')[0]
    this.chapterId = this.itemId.split('-')[1]
    this.uploadLocation = this.orgId + '/' + this.itemId
    this.groupId = 'coursekc' + this.itemId
    this.chapterDetails = props.location.state ? props.location.state : ''
    if(Controller.get().roleHelper().isProductStaff()) {
      this.breadCrumbs = [
        { title: Helper.getString('courses'), to: this.baseRoute() + '/courses' },
        { title: Helper.getString('sections'), to: this.baseRoute() + '/courses/' + this.courseId + '/sections' },
        { title: Helper.getString('chapters'), to: this.baseRoute() + '/courses/' + this.courseId + '/' + this.chapterDetails.sectionId + '/chapters' },
        // TODO: Possibly add chapters here after resolving from this.chapterId and chapters of this course
        { title: this.pageTitle },
      ]
    } else {
      this.breadCrumbs = [
        { title: Helper.getString('home'), to: this.baseRoute() },
        { title: Helper.getString('courses'), to: this.baseRoute() + '/courses' },
        { title: Helper.getString('sections'), to: this.baseRoute() + '/courses/' + this.courseId + '/sections' },
        { title: Helper.getString('chapters'), to: this.baseRoute() + '/courses/' + this.courseId + '/' + this.chapterDetails.sectionId + '/chapters' },
        // TODO: Possibly add chapters here after resolving from this.chapterId and chapters of this course
        { title: this.pageTitle },
      ] 
    }
    this.toFetch = [
      {
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['items'],
          { groupId: this.groupId, pb: 'fieldDef' })
      },
      {
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['courses', this.courseId])
      }
    ]
    if(this.orgId !== 'PRODUCT') {
      this.toFetch.push(
        {
          key: 'item',
          method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], {
            orgId: this.orgId,
            allvisible: true,
            orgCourseId: `${this.orgId}-${this.courseId}`,
          })
        }
      )
    }
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Order: (current) => { return current.order },
      Title: (current) => { return current.title },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {}
  }

  onFetchSuccess(results) {
    if (this.orgId !== 'PRODUCT' && Controller.get().roleHelper().disableEditAccess(results[2])) {
      this.headerActions = []
      this.hideFormAction = true
    }

    this.formDefinition = results[0].fieldDef
    this.pageTitle = this.chapterDetails && this.chapterDetails.title ?  this.chapterDetails.title : results[0].title
    this.setState({
      items: Helper.sortDictArray(results[0].items),
      item: results[0],
      courseDetails: results[1]
    })
  }

  renderAboveTable() {
    const { item } = this.state
    const { pathname } = this.props.location;
    return (
      <div className={css(Styles.containerNarrow)}>
        {item &&
          <div className={css(CommonStyles.courseActionListContainer)}>
            <div className={css(CommonStyles.courseActionListInnerContainer)}>
              {UiHelper.isOrgClient(pathname) ? UiHelper.courseActionList(this, item, this.pageKey, this.orgId, this.courseId, this.state.courseDetails.slug) :
                UiHelper.courseAdminActionList(this, item, this.pageKey, this.courseId, this.state.courseDetails.slug)}
            </div>
            <Divider className={css(CommonStyles.courseActionListDivider)} />
          </div>
        }
      </div>
    )
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['items'], { groupId: this.groupId }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['items', current.id], { groupId: this.groupId }),
    });
  }

  onActions(current) {
    const actions = []
    return actions
  }

  onMenuItems(current) {
    const actions = []
    const menuItems = [
      {
        current: current,
        key: current.id + 'editTT',
        action: () => this.openModalForEdit(current),
        title: Helper.getString('edit'),
        img: require('../../../assets/images/edit-icon.svg')
      },
      {
        current: current,
        key: current.id + 'deleteTT',
        action: () => this.openModalForDelete(current),
        title: Helper.getString('delete'),
        img: require('../../../assets/images/trash-icon.svg'),
        style: CommonStyles.deleteText
      },
    ]
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions
  }

}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  spanValue: {
    fontSize: 16
  }
})