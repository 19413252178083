import React, { useState, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CheckBoxComponent } from '../components/CheckBoxComponent';
import { AppButton } from './AppButton';
import { ErrorMsg } from '../components/ErrorMsg';
import { Controller } from '../common/Controller';

export function Checklist(props) {
  const [state, setState] = useState({
    selectedChecklist: {},
    showAnswer: false,
  })
  let countAnswer = 0

  const onClose = () => {
    setState({
      ...state,
      selectedChecklist: {},
      showAnswer: false,
    })
    props.onClose()
  }

  const selectedValue = (values) => {
    setState({
      ...state,
      selectedChecklist: values
    })
  }

  const submitChecklist = async () => {
    if (state.selectedChecklist === undefined || Object.keys(state.selectedChecklist).length === 0) {
      setState({
        ...state,
        openError: true,
        errorMsg: Helper.getString('pleaseSelectAllOptions')
      })
    } else {
      Object.keys(state.selectedChecklist).map(item => {
        if (state.selectedChecklist[item] && state.selectedChecklist[item] === true) {
          countAnswer = countAnswer + 1
        }
      })
      if (countAnswer === Object.keys(props.data.options).length) {
        setState({
          ...state,
          inProcess: true,
          showAnswer: true
        })
        try {
          const funcs = []
          for(const item in state.selectedChecklist) {
            funcs.push(Controller.get().courseMgr().submitChecklist(item, props.courseprogs))
          }
          await Promise.all(funcs)
        } catch (e) {
          setState({ ...state, openError: true, errorMsg: Helper.getErrorMsg(e), inProcess: false })
          return
        }
        setState({
          ...state,
          inProcess: false,
          showAnswer: false,
        })
        props.quizDialogOpen()
      } else {
        setState({
          ...state,
          openError: true,
          errorMsg: Helper.getString('pleaseSelectAllOptions')
        })
      }
    }
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  return (
    <Fragment>
      <Dialog
        onClose={() => onClose()}
        open={props.dialogOpen || false}
        disableScrollLock={true}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.dialog),
        }}>
        <DialogContent className={css(Styles.dialogContent)}>
          <CheckBoxComponent
            dialog={true}
            data={props.data}
            title={Helper.getString('knowledgeCheck')}
            selectedValue={selectedValue}
            dataSend={true}
            showAnswer={state.showAnswer} />
        </DialogContent>
        <DialogActions className={css(Styles.dialogActions)}>
          <AppButton
            onClick={() => submitChecklist()}
            inProcess={state.inProcess}
            buttonText={Helper.getString('submit')}
          />
        </DialogActions>
      </Dialog>
      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  dialog: {
    minWidth: 625,
    borderRadius: AppCommonTheme.borderRadius,
    margin: 10,
    '@media (max-width: 767px)': {
      minWidth: 'unset',
      width: '100%'
    }
  },
  dialogContent: {
    padding: 40,
    paddingBottom: 10,
    overflow: 'unset',
    '@media (max-width: 767px)': {
      padding: '24px 16px',
      paddingBottom: 10,
    }
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 40px 40px',
    '@media (max-width: 767px)': {
      padding: '0px 16px 24px',
    }
  },
})
