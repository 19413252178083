import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { UiHelper } from '../common/helpers/UiHelper';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { Courses } from '../components/Courses/Courses';
import { AppCommonTheme } from '../styles/Styles';
import { AppTheme } from '../styles/AppTheme';
import { Helper } from '../common/helpers/Helper';
import { AppContext } from '../common/components/contexts/AppContext';

export function Category(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {}
  })
  const orgId = Controller.get().roleHelper().getOrgId()
  const slug = props.match.params.slug

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getCategory(orgId, slug)
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'explore' })
    fetchData()
  }, [])

  const backNav = () => {
    const backNav = props.location && props.location.state && props.location.state
    if(backNav && backNav.explore) {
      return '/learner/orgs/' + orgId + '/explore'
    } else if(backNav && backNav.categories) {
      return '/learner/orgs/' + orgId + '/categories'
    } else if(backNav && backNav.search) {
      return '/learner/orgs/' + orgId + '/search'
    } else {
      return '/learner/orgs/' + orgId + '/explore'
    }
  }

  const backButton = {
    title: Helper.getString('back'),
    link: backNav()
  }

  const bgImage = (state.result && state.result.image) ? state.result.image : require('../assets/images/default_category_img.svg')
  return (
    <ScreenWrapper
      state={state}
      title={state.result && state.result.title}
      favourite={true}
      titleStyle={CommonStyles.titleStyle}
      backButton={backButton}
      logo={true}
    // search={true} //TODO search
    // filter={true}
    >

      {state.result &&
        <Fragment>
          <div className={css(Styles.imgContainer)}
            style={{ background: `linear-gradient(180deg, rgba(18, 18, 18, 0) 49.52%, rgba(18, 18, 18, 0.8) 85.21%), url(${bgImage})` }}>
            {state.result.descr &&
              <p className={css(Styles.descrContainer)}>{state.result.descr}</p>
            }
          </div>

          {state.result.items && state.result.items.length > 0 &&
            <Courses
              showTitle={false}
              data={state.result}
              backButton={{ category: true, slug: slug }} />
          }
        </Fragment>
      }
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  imgContainer: {
    height: 255,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    borderRadius: AppCommonTheme.borderRadius,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginBottom: 40
  },
  descrContainer: {
    fontSize: 18,
    color: AppTheme.secondaryBg,
    margin: 24,
    maxWidth: 707,
    '@media (max-width: 767px)': {
      fontSize: 15,
      margin: 12
    }
  }
})