import React, { Component, Fragment } from 'react'
import { ApiHelper } from '../../helpers/ApiHelper';
import { css, StyleSheet } from 'aphrodite';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { SEStyles } from './SEStyles';
import { Grid, Card, CardContent, CardActionArea, Tooltip, Paper, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Helper } from '../../helpers/Helper';
import { AppHelper } from '../../../helpers/AppHelper';
import { TableComponent } from '../TableComponent';
import { CustomSEStyles } from '../../../styles/CustomStyles';
import { AppConfig } from '../../../AppConfig';
import { AppButton } from '../AppButton';
import { CommonStyles } from '../../../styles/Styles';
import { UiHelper } from '../../helpers/UiHelper';

export class SubEntityList extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={css(Styles.container)}>
        <div className={css(Styles.heading)}>
          <div className={css(Styles.headingContent)}>
            <h2 className={css(Styles.stepFormTitle, this.props.stepFormTitle)}>{this.props.title}</h2>
            {!this.props.noAdd &&
              <AppButton
                onClick={() => this.props.openModalForAdd()}
                buttonText={'Add'}
                buttonStyle={Styles.editButton}
                buttonTextStyle={Styles.buttonText} />}
          </div>
        </div>
        <div className={css(Styles.content)}>
          <TableComponent
            compact
            tableHeadersMap={this.props.tableHeadersMap}
            items={this.props.items}
            noTableHead={this.props.noTableHead}
            noTableBorder={this.props.noTableBorder}
            // onActions={this.props.subEntityActions ? this.props.subEntityActions : this.onActions.bind(this)}
            onMenuItems={this.props.subEntityActions ? this.props.subEntityActions : this.onMenuItems.bind(this)}
          />
        </div>
      </div>
    )
  }

  onActions = (current) => {
    const actions = []
    // if (!this.props.noEdit) {
    //   actions.push(
    //     <Tooltip title='Edit' placement='top-start' key={current.id + 'editTT'}>
    //       <span className={css(AppStyles.entityTableLinkIcon, Styles.actionIcon)}>
    //         <i className='material-icons-outlined' onClick={() => this.props.openModalForEdit(current)}>edit</i>
    //       </span>
    //     </Tooltip>
    //   )
    // }
    // if (!this.props.noDelete) {
    //   actions.push(
    //     <Tooltip title='Delete' placement='top-start' key={current.id + 'deleteTT'}>
    //       <span className={css(AppStyles.entityTableLinkIcon, Styles.actionIcon)}>
    //         <i className='material-icons-outlined' onClick={() => this.props.openModalForDelete(current)}>delete</i>
    //       </span>
    //     </Tooltip>
    //   )
    // }
    return actions
  }

  onMenuItems(current) {
    const actions = []
    if (!this.props.noEdit) {
      actions.push(UiHelper.customMenuButton({
        current: current,
        key: current.id + 'editTT',
        action: () => this.props.openModalForEdit(current),
        title: Helper.getString('edit'),
        img: require('../../../assets/images/edit-icon.svg')
      }))
    }
    if (!this.props.noDelete) {
      actions.push(UiHelper.customMenuButton({
        current: current,
        key: current.id + 'deleteTT',
        action: () => this.props.openModalForDelete(current),
        title: Helper.getString('delete'),
        img: require('../../../assets/images/trash-icon.svg'),
        style: CommonStyles.deleteText
      }))
    }
    return actions

  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomSEStyles : SEStyles
