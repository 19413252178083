import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { CustomProductsStyles } from '../../../styles/CustomStyles';
import { Courses } from './Courses';
import { CommonStyles } from '../../../styles/Styles';

export class OrgCatalog extends BaseEntityPage {
  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('catalog')
    this.pageKey = 'OrgCatalog'
    this.addNewLabel = 'Add'
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['miscs', 'courses'], queryParams: { orgId: this.orgId, pb: 'fieldDefNew', allvisible: true } },
      { method: 'GET', paths: ['miscs', 'globalcourses'] },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.text}</span><br />
            {/* <span className={css(Styles.spanValue)}>{(current.mode === 'ADOPT' ? 'REUSED' : 'CREATED') + ' | ' + Helper.formatTimestamp(current.createdTs)}</span> */}
          </div>
        )
      },
      // Status: (current) => {
      //   return UiHelper.itemStatusContainer(current)
      // }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }

    this.headerActions = []
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    const courseData = results[0]
    this.formDefinition = courseData.fieldDefNew
    UiHelper.populateOptions(courseData.fieldDefNew, { items: results[1].items }, 'courseId')
    this.handleNextpage(results[0])
    const item = this.formDefinition.steps[0].fields.filter(i => i.key === 'courseId')
    this.setState({
      data: results[0],
      items: item[0].options
      // items: fetchNext ? this.state.items.concat(results[0].items) : results[0].items
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onEdit(current) {
  }

  openModalForEdit(current, fieldDef) {
    const fields = []
    this.formDefinition.steps[0].fields.forEach(i => { if (i.key === 'title') fields.push({ ...i, disabled: true }) })
    delete fields[0].condition
    super.openModalForEdit({ title: current.text }, { title: Helper.getString('useThisCourse'), steps: [{ fields }] })
    this.setState({
      current: current
    })
  }

  onActions = (current) => {
    const actions = []
    return actions
  }

  onMenuItems(current) {
    const selectedItem = this.state.data.items.filter(i => i.courseId === current.key)
    const actions = []
    actions.push(UiHelper.customMenuButton(
      {
        current: current,
        key: current.key + 'course',
        action: () => this.openModalForEdit(current),
        title: Helper.getString('useThisCourse'),
        img: require('../../../assets/images/edit-icon.svg'),
      }
    ))
    if (selectedItem[0] && selectedItem[0].slug) {
      actions.push(UiHelper.customMenuButton(
        {
          current: current,
          key: current.key + 'preview',
          link: '/admin/orgs/' + this.orgId + '/courses/preview/' + selectedItem[0].slug,
          title: Helper.getString('preview'),
          icon: 'subdirectory_arrow_right',
          state: { catalog: true }
        }
      ))
    }
    return actions
  }

  customSubmit(options) {
    const { formType, formData } = options
    const userId = Controller.get().userMgr().getUserId()
    ApiHelper.call({
      method: 'POST', endPoint: ApiHelper.makeUrlPath(['miscs', 'mergedcourse-creation'], {}),
      jsonBody: { ...formData, mode: 'ADOPT', courseId: this.state.current.key, title: '' }
    },
    (err, data) => {
      if (err) {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
        return
      } else {
        ApiHelper.call({
          method: 'PUT', endPoint: ApiHelper.makeUrlPath(['orgcourses', data.id]),
          jsonBody: { ownerIds: [userId] }
        }, () => {
          this.setState({ modalOpen: false })
          Controller.get().pushRoute(this.baseRoute() + '/orgcourses/' + this.orgId + '-' + this.state.current.key + '/details')
        })
        // Controller.get().pushRoute(this.baseRoute() + '/orgcourses/' + this.orgId + '-' + this.state.current.key + '/details')
        // this.fetchItems()
      }
    })
  }
}


const Styles = AppConfig.CUSTOM_STYLE ? CustomProductsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})
