import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { Divider } from '@material-ui/core';
import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme';
import { AppHelper } from '../helpers/AppHelper';
import { Controller } from '../common/Controller';

export class HeaderDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.menuItems = AppHelper.getDrawerMenuItems()
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onDashboardClick = () => {
    const path = Controller.get().getHomeByRole()
    window.location.href = path
  }

  render() {
    const appUser = Controller.get().userMgr().getAppUser()
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <IconButton
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup='true'
          onClick={this.handleMenu}
          color={this.props.public ? 'secondary' : 'primary'}
          className={this.props.public ? css(Styles.iconPublic) : css(Styles.icon)}
        >
          <i className='material-icons-outlined' style={{ fontSize: 42, zIndex: 100000 }}>person_outline</i>
        </IconButton>
        <Popover
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
          classes={{
            paper: css(Styles.popover)
          }}
          disableScrollLock={true}
        >
          {appUser &&
            <MenuItem disabled className={css(Styles.menuItem)}>{appUser.email || appUser.phone}</MenuItem>
          }
          <Divider className={css(Styles.divider)} />

          <MenuItem disabled className={css(Styles.menuItem)}>
            <div className={css(Styles.versionContainer)}>Version
              <span className={css(Styles.version)}>{AppConfig.VERSION}</span>
            </div>
          </MenuItem>
          <Divider className={css(Styles.divider)} />

          <MenuItem onClick={() => this.onDashboardClick()} className={css(Styles.menuItem)}>Dashboard</MenuItem>

          <Divider className={css(Styles.divider)} />

          <MenuItem onClick={this.props.onLogoutClick} className={css(Styles.menuItem)}>Logout</MenuItem>
        </Popover>
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  icon: {
    padding: 0
  },
  iconPublic: {
    padding: 0,
    marginRight: 60
  },
  popover: {
    padding: '10px'
  },
  menuItem: {
    color: '#6E84A3',
    padding: '6px 24px',
    opacity: 1,
    fontWeight: 400,
    ':hover': {
      color: 'black',
      backgroundColor: 'white'
    }
  },
  divider: {
    color: '#edf2f9',
    margin: '8px 0px'
  },
  versionContainer: {
    padding: '8px 16px',
    paddingLeft: 0,
    margin: 0,
    fontSize: 15,
    color: '#6E84A3',
  },
  version: {
    backgroundColor: 'whitesmoke',
    borderRadius: 8,
    color: 'black',
    marginLeft: 5,
    padding: 5,
    fontSize: 12
  },
})
