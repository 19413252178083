import React, { Component } from 'react';
import { ApiHelper } from '../helpers/ApiHelper';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../../styles/AppTheme';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppConfig } from '../../AppConfig';
import { CustomNextPageStyles } from '../../styles/CustomStyles';
import { AppButton } from './AppButton';

export class NextPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchState: ApiHelper.State.LOADING,
    }
  }

  render() {
    return (<div className={css(Styles.nextPageContainer)}>
      {/* <span className={css(Styles.count)}>{this.props.itemCount} items</span> */}
      <AppButton
        onClick={this.props.onNext}
        buttonText={'SHOW MORE'}
        inProcess={this.props.nextPageState === ApiHelper.State.LOADING}
        disabled={this.props.nextPageState === ApiHelper.State.LOADING}
      />
    </div>)
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomNextPageStyles : StyleSheet.create({
  nextPageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
  },
  progress: {
    marginLeft: 10,
    color: 'white'
  },
  button: {
    minWidth: 160,
    boxShadow: 'none',
  },
  count: {
    marginRight: 10,
    color: 'black'
  }
})
