import React, { Component } from 'react';
import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';
import { BaseEntityPage } from './BaseEntityPage';
import { Controller } from '../Controller';
import { UiHelper } from '../helpers/UiHelper';
import { AppConfig } from '../../AppConfig';
import { Style } from '@material-ui/icons';
import { CommonStyles } from '../../styles/Styles';
import { CustomUsersOfOrgStyles } from '../../styles/CustomStyles';
import { StyleSheet, css } from 'aphrodite';
import { countryCodes } from '../../data/CountryCode';

const labelByRoles = {
  SUPERUSER: 'ADMIN',
  MANAGER: 'MANAGER',
  CREATOR: 'CREATOR'
}
export class UsersOfOrg extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.orgId = this.props.orgId ? this.props.orgId :
      (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.teamId = this.props.teamId ? this.props.teamId : this.props.match.params.id
    this.pageTitle = this.orgId === 'PRODUCT' ? AppConfig.NAME : Helper.getString('users')
    this.addNewLabel = 'Add'
    if (this.teamId) {
      this.customSubmit = this.teamCustomSubmit.bind(this)
      this.breadCrumbs = [
        { title: Helper.getString('home'), to: this.baseRoute() },
        { title: Helper.getString('groups'), to: this.baseRoute() + '/teams' },
        { title: this.teamId ? Helper.getString('users') : Helper.getString('admins') },
      ]
    } else {
      this.customSubmit = this.manageUserCustomSubmit.bind(this)
      this.breadCrumbs = [
        { title: Helper.getString('home'), to: Controller.get().roleHelper().getHome() },
        { title: this.teamId ? Helper.getString('users') : Helper.getString('admins') },
      ]
    }
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { orgId: this.orgId }) },
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { orgId: this.teamId || this.orgId, pb: 'fieldDef' }) },
    ]
    if (this.orgId !== 'PRODUCT') {
      this.toFetch.push({ method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs', this.teamId || this.orgId]) })
    }
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Email: (current) => {
        return (
          <div className={css(Styles.statusContainer)}>
            <p className={css(Styles.statusContainerContent)}>{current.email}</p>
            <p className={css(Styles.statusContainerContent)}>State | {current.state}</p>
          </div>
        )
      },
      Roles: (current) => { return current.roles.map(role =>  labelByRoles[role] || '').join(', ') },
      Name: (current) => { return Helper.formatName(current) }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.emptyText = 'No users in this org yet!'
    this.apiData = {}
    this.pageKey = this.props.department ? 'department' : 'user'
    this.noAdd = (Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) ? false : true
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onFetchSuccess(results) {
    this.formDefinition = results[1].fieldDef
    if(this.formDefinition.steps[0].fields) {
      for (const field of this.formDefinition.steps[0].fields) {
        if(field.key === 'country') {
          field.options = countryCodes
        }
      }
    }

    const rolesField = this.formDefinition.steps[0].fields.find(i => i.key === 'roles')
    rolesField.v_required.value = false //TODO: mandatory removed for now
    if (this.teamId) {
      rolesField.options = AppConfig.USER_ROLES_SUBORG
    } else if (this.orgId === 'PRODUCT') {
      rolesField.options = AppConfig.USER_ROLES_PRODUCT
    } else {
      rolesField.options = AppConfig.USER_ROLES_ORG || [
        { key: 'SUPERUSER', label: 'ADMIN' }
      ]
    }

    this.userOption = []
    this.allUsers = []
    for (const item in results[0].items) {
      this.allUsers.push({
        key: results[0].items[item].id, //TODO: give id or userId?
        label: `${results[0].items[item].givenname} ${results[0].items[item].familyname} (${results[0].items[item].email})`
      })
    }
    for (const item in results[0].items) {
      if (!results[0].items[item].roles || results[0].items[item].roles.indexOf('MANAGER') < 0) continue
      this.userOption.push({
        key: results[0].items[item].id, //TODO: give id or userId?
        label: `${results[0].items[item].givenname} ${results[0].items[item].familyname}`
      })
    }

    this.formDefinition.steps[0].fields.forEach((i, index) => { if (i.key === 'managerUserId') this.formDefinition.steps[0].fields[index].options = this.userOption })

    results[1].items.forEach(i => i.id = i.userId)
    const org = results[2]
    if (org) this.pageTitle = org.title
    const appUser = Controller.get().userMgr().getAppUser()
    const items = results[1].items
    let mItems = []
    if ((Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isProductSU() || Controller.get().roleHelper().isOrgCreatorUser()) && !this.teamId) {
      mItems = items
    } else if (Controller.get().roleHelper().isOrgManagerUser() && !this.teamId) {
      for (const i of items) {
        //if (i.managerUserId && i.managerUserId === (this.orgId + '-' + appUser.id) && i.state === 'PENDING') {
        if (i.managerUserId && i.managerUserId === (this.orgId + '-' + appUser.id)) {
          mItems.push(i)
        }
      }
    } else if (this.teamId) {
      const usersById = {}
      for (const item of results[0].items) {
        usersById[item.userId] = item
      }
      for (const item of items) {
        if (usersById[item.userId]) mItems.push(item)
      }
    } else {
      mItems = items
    }

    const managerUsersById = {}
    if (Controller.get().roleHelper().isOrgManagerUser() && !this.teamId) {
      for (const i of items) {
        //if (i.managerUserId && i.managerUserId === (this.orgId + '-' + appUser.id) && i.state === 'PENDING') {
        if (i.managerUserId && i.managerUserId === (this.orgId + '-' + appUser.id)) {
          managerUsersById[i.userId] = managerUsersById
        }
      }
    }

    this.setState({
      items: mItems,
      usersData: results[0],
      managerUsersById: managerUsersById,
      orgDetails: results[2] && results[2]
    })
  }

  onAdd() {
    this.formDefinition.steps[0].fields.find(i => {
      if (i.key === 'state') {
        i.hide = true
        i.disabled = false
      }
      i.disabled = false
    })
    this.setState({
      apiMethod: 'PUT',
      apiData: {
        updateAction: 'addMembers',
      },
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', this.teamId || this.orgId]),
    });
  }

  openModalForAdd(fieldDef) {
    const fields = []
    if (this.orgId !== 'PRODUCT') {
      if (this.state.orgDetails && this.state.orgDetails.subscriptions) {
        if (this.state.items && this.state.items.length > 0) {
          if (this.state.items.length >= this.state.orgDetails.subscriptions) {
            this.setState({
              messageToShow: Helper.getString('maxUserSubscriptionMsg'),
            })
            return
          }
        }
      }
    }
    if (this.teamId) {
      fields.push({
        hint: 'Select user',
        key: 'user',
        label: 'User',
        options: this.allUsers,
        placement: 'left',
        type: 'radio',
        // eslint-disable-next-line quote-props
        'v_required': { value: true, err: 'Select user' }
      })
      this.formDefinition.steps[0].fields.forEach(i => { if (i.key === 'roles') fields.push(i) })
      super.openModalForAdd({ steps: [{ fields }] })
    } else if (this.orgId === 'PRODUCT') {
      this.formDefinition.steps[0].fields.forEach(i => {
        if (i.key === 'givenname' || i.key === 'familyname' || i.key === 'email' || i.key === 'roles') fields.push(i)
      })
      super.openModalForAdd({ steps: [{ fields }] })
    } else {
      super.openModalForAdd()
    }
  }


  openModalForEdit(current, fieldDef) {
    const fields = []
    if (this.teamId) {
      fields.push({
        hint: 'Select user',
        key: 'user',
        label: 'User',
        options: this.allUsers,
        placement: 'left',
        type: 'radio',
        // eslint-disable-next-line quote-props
        'v_required': { value: true, err: 'Select user' },
        disabled: true
      })
      current = {
        ...current,
        user: `${this.orgId}-${current.userId}`
      }

      this.formDefinition.steps[0].fields.forEach(i => { if (i.key === 'roles') fields.push(i) })
      super.openModalForEdit(current, { steps: [{ fields }] })
    } else if (this.orgId === 'PRODUCT') {
      this.formDefinition.steps[0].fields.forEach(i => {
        if (i.key === 'givenname' || i.key === 'familyname' || i.key === 'email' || i.key === 'roles') fields.push(i)
      })
      super.openModalForEdit(current, { steps: [{ fields }] })
    } else {
      super.openModalForEdit(current)
    }
  }

  openModalForDelete(current, fieldDef) {
    const fields = []
    if (this.teamId) {
      fields.push({
        hint: 'Select user',
        key: 'user',
        label: 'User',
        options: this.allUsers,
        placement: 'left',
        type: 'radio',
        // eslint-disable-next-line quote-props
        'v_required': { value: true, err: 'Select user' },
        disabled: true
      })
      current = {
        ...current,
        user: `${this.orgId}-${current.userId}`
      }

      this.formDefinition.steps[0].fields.forEach(i => { if (i.key === 'roles') fields.push(i) })
      super.openModalForDelete(current, { steps: [{ fields }] })
    } else if (this.orgId === 'PRODUCT') {
      this.formDefinition.steps[0].fields.forEach(i => {
        if (i.key === 'givenname' || i.key === 'familyname' || i.key === 'email' || i.key === 'roles') fields.push(i)
      })
      super.openModalForDelete(current, { steps: [{ fields }] })
    } else {
      super.openModalForDelete(current)
    }
  }

  onDelete(current) {
    this.formDefinition.steps[0].fields.find(i => {
      if (i.key === 'state') {
        i.hide = false
        i.disabled = false
      }
      i.disabled = false
    })
    this.setState({
      apiMethod: 'PUT',
      apiData: {
        updateAction: 'delMembers',
      },
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', this.teamId || this.orgId]),
    });
  }

  onEdit(current) {
    this.formDefinition.steps[0].fields.find(i => {
      if (i.key === 'state') {
        if (this.state.orgDetails && current.email &&
          !current.email.endsWith(this.state.orgDetails.domain)) {
          if (Controller.get().userMgr().isProductSU()) return
          if (Controller.get().roleHelper().isOrgManagerUser()) {
            if (!this.state.managerUsersById[current.id]) i.disabled = true
          } else {
            i.disabled = true
          }
        }
        i.hide = false
      } else {
        if (!(Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) && Controller.get().roleHelper().isOrgManagerUser()) {
          i.disabled = true
        } else {
          i.disabled = false
        }
      }
    })
    this.setState({
      apiMethod: 'PUT',
      apiData: {
        updateAction: 'addMembers',
      },
      apiEndPoint: ApiHelper.makeUrlPath(['orgs', this.teamId || this.orgId]),
    });
  }

  onActions(current) {
    const actions = []
    return actions
  }

  onMenuItems(current) {
    const actions = []
    let menuItems = []
    if (Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) {
      menuItems = [
        {
          current: current,
          key: current.id + 'editTT',
          action: () => this.openModalForEdit(current),
          title: Helper.getString('edit'),
          img: require('../../assets/images/edit-icon.svg')
        },
        {
          current: current,
          key: current.id + 'deleteTT',
          action: () => this.openModalForDelete(current),
          title: Helper.getString('delete'),
          img: require('../../assets/images/trash-icon.svg'),
          style: CommonStyles.deleteText
        }
      ]
      if(!this.teamId && this.orgId !== 'PRODUCT') {
        menuItems.push(
          {
            current: current,
            key: current.id + 'courseList',
            action: () => Controller.get().pushRoute('/admin/orgs/' + this.orgId + '/users/' + current.userId + '/courses'),
            title: Helper.getString('Courses'),
            img: require('../../assets/images/course_icon_d.svg'),
          }
        )
      }
    } else if (Controller.get().roleHelper().isOrgManagerUser()) {
      if (this.state.managerUsersById[current.id]) {
        menuItems = [
          {
            current: current,
            key: current.id + 'editTT',
            action: () => this.openModalForEdit(current),
            title: Helper.getString('edit'),
            img: require('../../assets/images/edit-icon.svg')
          }
        ]
        if(!this.teamId && this.orgId !== 'PRODUCT') {
          menuItems.push(
            {
              current: current,
              key: current.id + 'courseList',
              action: () => Controller.get().pushRoute('/admin/orgs/' + this.orgId + '/users/' + current.userId + '/courses'),
              title: Helper.getString('Courses'),
              img: require('../../assets/images/course_icon_d.svg'),
            }
          )
        }
      }
    }

    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions

  }

  manageUserCustomSubmit(options) {
    if (options.formType === 'add') {
      if (this.state.orgDetails && options.formData.email.endsWith(this.state.orgDetails.domain)) {
        options.formData.state = 'ENABLED'
      } else {
        options.formData.state = 'PENDING'
      }
    }
    ApiHelper.call(
      { method: this.state.apiMethod, endPoint: this.state.apiEndPoint, jsonBody: options.formData },
      function (err, data) {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err);
          options.caller.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return;
        }
        options.caller.previousState = options.caller.state;
        if (Helper.inArray(['delete', 'del'], this.formDefinition.formType)) {
          options.caller.setState({
            success: true,
            message: '',
            inProcess: false
          })
        } else {
          options.caller.setState({
            success: true,
            message: Helper.getString('successful'),
            inProcess: false
          })
        }
        this.setState({ modalOpen: false, items: [] })
        this.fetchItems()
      }.bind(this));
  }

  teamCustomSubmit(options) {
    if (this.teamId) {
      const { formType, formData, caller } = options
      const userData = this.state.usersData.items.filter(i => i.id === formData.user)
      console.log('userData', userData)
      if((!userData || userData.length === 0 || !userData[0].roles || userData[0].roles.indexOf('MANAGER') < 0) && formData.roles.indexOf('MANAGER') > -1) {
        options.caller.setState({
          success: false,
          message: Helper.getString('groupNonManagerAddErrorMsg'),
          inProcess: false
        })
        return;
      }
      delete formData.user
      if (formType === 'add') {
        ApiHelper.call(
          {
            method: 'PUT', endPoint: ApiHelper.makeUrlPath(['orgs', this.teamId || this.orgId]),
            jsonBody: {
              ...formData,
              givenname: userData[0].givenname ? userData[0].givenname : '',
              familyname: userData[0].familyname ? userData[0].familyname : '',
              email: userData[0].email ? userData[0].email : '',
              phone: userData[0].phone ? userData[0].phone : '',
              designation: userData[0].designation ? userData[0].designation : '',
              managerUserId: userData[0].managerUserId ? userData[0].managerUserId : '',
              country: userData[0].country ? userData[0].country : '',
              state: userData[0].state ? userData[0].state : ''
            }
          },
          (err, data) => {
            if (err) {
              const errorMessage = Helper.getErrorMsg(err)
              this.setState({
                fetchState: ApiHelper.State.ERROR,
                errMsg: errorMessage,
                success: false,
                message: errorMessage,
                inProcess: false
              })
              return
            } else {
              this.setState({ modalOpen: false })
              this.fetchItems()
            }
          })
      } else if (formType === 'edit') {
        ApiHelper.call(
          {
            method: 'PUT', endPoint: ApiHelper.makeUrlPath(['orgs', this.teamId || this.orgId]),
            jsonBody: {
              ...formData,
              givenname: userData[0].givenname ? userData[0].givenname : '',
              familyname: userData[0].familyname ? userData[0].familyname : '',
              email: userData[0].email ? userData[0].email : '',
              phone: userData[0].phone ? userData[0].phone : '',
              designation: userData[0].designation ? userData[0].designation : '',
              managerUserId: userData[0].managerUserId ? userData[0].managerUserId : '',
              country: userData[0].country ? userData[0].country : '',
              state: userData[0].state ? userData[0].state : ''
            }
          },
          (err, data) => {
            if (err) {
              const errorMessage = Helper.getErrorMsg(err)
              this.setState({
                fetchState: ApiHelper.State.ERROR,
                errMsg: errorMessage,
                success: false,
                message: errorMessage,
                inProcess: false
              })
              return
            } else {
              this.setState({ modalOpen: false })
              this.fetchItems()
            }
          })
      }
      else if (formType === 'delete') {
        ApiHelper.call(
          {
            method: 'PUT', endPoint: ApiHelper.makeUrlPath(['orgs', this.teamId || this.orgId]),
            jsonBody: {
              ...formData,
              givenname: userData[0].givenname ? userData[0].givenname : '',
              familyname: userData[0].familyname ? userData[0].familyname : '',
              email: userData[0].email ? userData[0].email : '',
              phone: userData[0].phone ? userData[0].phone : '',
              designation: userData[0].designation ? userData[0].designation : '',
              managerUserId: userData[0].managerUserId ? userData[0].managerUserId : '',
              country: userData[0].country ? userData[0].country : '',
              state: userData[0].state ? userData[0].state : ''
            }
          },
          (err, data) => {
            if (err) {
              const errorMessage = Helper.getErrorMsg(err)
              this.setState({
                fetchState: ApiHelper.State.ERROR,
                errMsg: errorMessage,
                success: false,
                message: errorMessage,
                inProcess: false
              })
              return
            } else {
              this.setState({ modalOpen: false })
              this.fetchItems()
            }
          })
      }


    }
  }

}

const Styles = (AppConfig.CUSTOM_STYLE) ? CustomUsersOfOrgStyles : StyleSheet.create({
  statusContainer: {
    display: 'flex',
    gap: 5
  },
  statusContainerContent: {
    margin: 0
  }
})