import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { CustomProductsStyles } from '../../../styles/CustomStyles';
import { CommonStyles } from '../../../styles/Styles';

export class Courses extends BaseEntityPage {
  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('globalCourses')
    this.pageKey = 'courses'
    this.addNewLabel = 'Add'
    // this.orgId = (Controller.get().roleHelper().isProductUser() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.orgId = props.match.params.orgId ? props.match.params.orgId : Controller.get().roleHelper().getOrgId()
    if(Controller.get().roleHelper().isProductStaff()) {
      this.breadCrumbs = []
    } else {
      this.breadCrumbs = [
        { title: Helper.getString('home'), to: this.baseRoute() },
        { title: this.pageTitle },
      ]
    }

    this.toFetch = [
      { method: 'GET', paths: ['courses'], queryParams: { orgId: this.orgId, pb: 'fieldDef', limit: 20 } },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span>
            <br />
            <span className={css(Styles.spanValue)}>{current.createdTs && Helper.formatTimestamp(current.createdTs)}</span>
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    const prodData = results[0]
    this.fieldDef = prodData.fieldDef
    this.formDefinition = prodData.fieldDef
    this.handleNextpage(results[0])
    this.setState({
      items: fetchNext ? this.state.items.concat(results[0].items) : results[0].items
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['courses'], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['courses', current.id], {}),
    });
  }

  openModalForAdd(fieldDef) {
    const fields = []
    this.fieldDef.steps[0].fields.forEach(i => { if (i.key === 'title') fields.push(i) })
    fields[0].label = Helper.getString('title')
    super.openModalForAdd({ steps: [{ fields }] })
  }

  onActions = (current) => {
    const actions = []
    // actions.push(
    //   <Link to={this.baseRoute() + '/courses/' + current.id + '/details'} key={current.id + '-details'}>
    //     <Tooltip title='Details' placement='top-start'>
    //       <span className={css(AppStyles.entityTableLinkIcon)}>
    //         <i className='material-icons-outlined'>text_snippet</i>
    //       </span>
    //     </Tooltip>
    //   </Link>
    // )
    // actions.push(
    //   <Link to={this.baseRoute() + '/courses/' + current.id + '/preview'} key={current.id + '-preview'}>
    //     <Tooltip title='Preview' placement='top-start'>
    //       <span className={css(AppStyles.entityTableLinkIcon)}>
    //         <i className='material-icons-outlined'>play_circle</i>
    //       </span>
    //     </Tooltip>
    //   </Link>
    // )
    // actions.push(...Courses.courseActions(this, current))
    // actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

  onMenuItems(current) {
    const actions = []
    const menuItems = [
      {
        current: current,
        key: current.id + '-details',
        link: this.baseRoute() + '/courses/' + current.id + '/details',
        title: Helper.getString('edit'),
        icon: 'text_snippet'
      },
      // {
      //   current: current,
      //   key: current.id + 'deleteTT',
      //   action: () => this.openModalForDelete(current),
      //   title: Helper.getString('delete'),
      //   img: require('../../../assets/images/trash-icon.svg'),
      //   style: CommonStyles.deleteText
      // },
    ]
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions

  }

  // static courseActions(caller, current) {
  //   const actions = []
  //   actions.push(
  //     <Link to={caller.baseRoute() + '/courses/' + current.courseId + '/sections'} key={current.courseId + '-sections'}>
  //       <Tooltip title='Sections' placement='top-start'>
  //         <span className={css(AppStyles.entityTableLinkIcon)}>
  //           <i className='material-icons-outlined'>list</i>
  //         </span>
  //       </Tooltip>
  //     </Link>
  //   )
  //   actions.push(
  //     <Link to={caller.baseRoute() + '/courses/' + current.courseId + '/chapters'} key={current.courseId + '-chapters'}>
  //       <Tooltip title='Chapters' placement='top-start'>
  //         <span className={css(AppStyles.entityTableLinkIcon)}>
  //           <i className='material-icons-outlined'>web_stories</i>
  //         </span>
  //       </Tooltip>
  //     </Link>
  //   )
  //   actions.push(
  //     <Link to={caller.baseRoute() + '/courses/' + current.courseId + '/assessment'} key={current.courseId + '-assessment'}>
  //       <Tooltip title='Assessment' placement='top-start'>
  //         <span className={css(AppStyles.entityTableLinkIcon)}>
  //           <i className='material-icons-outlined'>workspace_premium</i>
  //         </span>
  //       </Tooltip>
  //     </Link>
  //   )
  //   actions.push(
  //     <Link to={caller.baseRoute() + '/courses/' + current.courseId + '/faqs'} key={current.courseId + '-faqs'}>
  //       <Tooltip title='faqs' placement='top-start'>
  //         <span className={css(AppStyles.entityTableLinkIcon)}>
  //           <i className='material-icons-outlined'>bookmark_border</i>
  //         </span>
  //       </Tooltip>
  //     </Link>
  //   )
  //   return actions
  // }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomProductsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})
