import React, { useState, useEffect } from 'react';
import { Controller } from '../../Controller';
import { ApiHelper } from '../../helpers/ApiHelper';
import { Helper } from '../../helpers/Helper';

export const UserContext = React.createContext({
  user: {},
  logout: () => { },
  userContextStateHandler: () => { }
})
UserContext.displayName = 'UserContext';

export function UserContextProvider(props) {
  const [state, setState] = useState({})

  async function fetchData() {
    try {
      setState({ ...state, fetchState: ApiHelper.State.LOADING })
      const response = await ApiHelper.callAwait({
        method: 'GET', paths: ['users', 'me'], queryParams: {}
      })
      Controller.get().userMgr().setAppUser(response);
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        data: response
      })
    } catch (e) {
      setState({ ...state, data: null, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    fetchData()
  }, [state.reFetchUser])

  function logout() {
    setState({})
  }

  function userContextStateHandler(keyValues) {
    const updateParams = {}
    for(const k in keyValues) {
      updateParams[k] = keyValues[k]
    }
    setState({ ...state, ...updateParams })
  }

  return (
    <UserContext.Provider value={{ user: state, logout, userContextStateHandler }}>
      {props.children}
    </UserContext.Provider>
  )
}