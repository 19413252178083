/* eslint-disable*/

export const filterClientFormFields = {
  items: [
    {
      title: 'Sort the view:',
      fields: [
        {
          key: 'favourite',
          type: 'select',
          label: '',
          hint: 'Select type',
          placement: 'full',
          placeholder: 'Select favourite',
          value: 'first',
          options: [
            {
              key: 'first',
              label: 'Favourite first'
            },
            {
              key: 'second',
              label: 'Favourite second'
            },

            {
              key: 'third',
              label: 'Favourite third'
            },
          ],
          v_required: {
            value: false,
            err: 'Select a type'
          },
        }
      ]
    },
    {
      title: 'Filters',
      fields: [
        {
          key: 'course',
          type: 'select',
          label: 'Course',
          hint: 'Select type',
          placement: 'left',
          placeholder: 'Select course',
          options: [
            {
              key: 'first',
              label: 'Course first'
            },
            {
              key: 'second',
              label: 'Course second'
            },

            {
              key: 'third',
              label: 'Course third'
            },
          ],
          v_required: {
            value: false,
            err: 'Select a type'
          },
        },
        {
          key: 'status',
          type: 'select',
          label: 'Status',
          hint: 'Select type',
          placement: 'right',
          placeholder: 'Select status',
          options: [
            {
              key: 'assigned',
              label: 'Assigned'
            },
            {
              key: 'inprocess',
              label: 'Inprocess'
            },

            {
              key: 'completed',
              label: 'Completed'
            },
          ],
          v_required: {
            value: false,
            err: 'Select a type'
          },
        },
        {
          key: 'duration',
          type: 'select',
          label: 'Duration',
          hint: 'Select type',
          placement: 'left',
          placeholder: 'Select duration',
          options: [
            {
              key: 'assigned',
              label: 'less than 1hr'
            },
            {
              key: 'inprocess',
              label: 'less than 30min'
            },

            {
              key: 'completed',
              label: 'less than 2hr'
            },
          ],
          v_required: {
            value: false,
            err: 'Select a type'
          },
        },
        {
          key: 'rating',
          type: 'select',
          label: 'Rating',
          hint: 'Select type',
          placement: 'right',
          placeholder: 'Select rating',
          options: [
            {
              key: '4',
              label: '4 or above'
            },
            {
              key: '3',
              label: '3'
            },

            {
              key: '2',
              label: '2'
            },
            {
              key: '1',
              label: '1'
            },
          ],
          v_required: {
            value: false,
            err: 'Select a type'
          },
        },
        {
          key: 'due',
          type: 'select',
          label: 'Due',
          hint: 'Select type',
          placement: 'left',
          placeholder: 'Select due',
          options: [
            {
              key: '7 week',
              label: '1 week'
            },
            {
              key: '5 days',
              label: '5 days'
            },

            {
              key: '2 week',
              label: '2 week'
            },
          ],
          v_required: {
            value: false,
            err: 'Select a type'
          },
        },
      ]
    }
  ]
}

export const feedbackQuestionOption = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  }
]

export const filterAdminFormFields = {
  items: [
    {
      title: 'Filters',
      fields: [
        {
          key: 'department',
          type: 'select',
          label: 'Group',
          hint: 'Select group',
          placement: 'left',
          placeholder: 'Group',
          v_required: {
            value: false,
            err: 'Select group'
          },
          options: []
        },
        {
          key: 'learner',
          type: 'select',
          label: 'Learner type',
          hint: 'Select type',
          placement: 'right',
          placeholder: 'Learner',
          options: [
            {
              key: 'EXTERNAL',
              label: 'EXTERNAL'
            },
            {
              key: 'EMPLOYEE',
              label: 'EMPLOYEE'
            }
          ],
          v_required: {
            value: false,
            err: 'Select learner'
          },
        },
        {
          key: 'publishedYear',
          type: 'select',
          label: 'Published year',
          hint: 'Select published year',
          placement: 'left',
          placeholder: 'Published year',
          v_required: {
            value: false,
            err: 'Select published year'
          },
          options: [],
        },
        {
          key: 'courseStatus',
          type: 'select',
          label: 'Course status',
          hint: 'Select course status',
          placement: 'right',
          placeholder: 'Course status',
          options: [
            {
              key: 'active',
              label: 'Active'
            },
            {
              key: 'inactive',
              label: 'Inactive'
            }
          ],
          v_required: {
            value: false,
            err: 'Select course status'
          },
        },
        {
          key: 'consent',
          type: 'select',
          label: 'Consent',
          hint: 'Select Consent',
          placement: 'left',
          placeholder: 'Consent',
          options: [
            {
              key: 'yes',
              label: 'Yes'
            },
            {
              key: 'no',
              label: 'No'
            }
          ],
          v_required: {
            value: false,
            err: 'Select consent'
          },
        },
        {
          key: 'sincxCatalog',
          type: 'select',
          label: 'From sincx library',
          hint: 'Select from sincx library',
          placement: 'right',
          placeholder: 'From sincx library',
          options: [
            {
              key: 'yes',
              label: 'Yes'
            },
            {
              key: 'no',
              label: 'No'
            }
          ],
          v_required: {
            value: false,
            err: 'Select from sincx library'
          },
        },
        {
          key: 'assessment',
          type: 'select',
          label: 'Assessment',
          hint: 'Select assessment',
          placement: 'left',
          placeholder: 'Assessment',
          options: [
            {
              key: 'yes',
              label: 'Yes'
            },
            {
              key: 'no',
              label: 'No'
            }
          ],
          v_required: {
            value: false,
            err: 'Select assessment'
          },
        },
        {
          key: 'recurrance',
          type: 'select',
          label: 'Recurrence',
          hint: 'Select recurrance',
          placement: 'right',
          placeholder: 'Recurrence',
          options: [
            {
              key: 'yes',
              label: 'Yes'
            },
            {
              key: 'no',
              label: 'No'
            }
          ],
          v_required: {
            value: false,
            err: 'Select recurrance'
          },
        },
      ]
    }
  ]
}

export const filterManagerFormFields = {
  items: [
    {
      title: 'Filters',
      fields: [
        {
          key: 'department',
          type: 'select',
          label: 'Group',
          hint: 'Select group',
          // placement: 'left',
          placeholder: 'Group',
          options: [],
          v_required: {
            value: false,
            err: 'Select group'
          },
        }
      ]
    }
  ]
}

export const mycourseFields = {
  items: [
    {
      title: 'Sort the view:',
      fields: [
        {
          key: 'sortBy',
          type: 'select',
          label: '',
          hint: 'Select type',
          placement: 'full',
          placeholder: 'Sort by',
          options: [
            {
              key: 'durationAesc',
              label: 'Duration (Low to High)'
            },
            {
              key: 'durationDesc',
              label: 'Duration (High to Low)'
            },
            {
              key: 'alphabeticalAesc',
              label: 'Alphabetical (A-Z)'
            },
            {
              key: 'alphabeticalDesc',
              label: 'Alphabetical (Z-A)'
            },
            {
              key: 'dueDateAesc',
              label: 'Due Date (Latest to Oldest)'
            },
            {
              key: 'dueDateDesc',
              label: 'Due Date (Oldest to Latest)'
            },
            {
              key: 'ratingsAesc',
              label: 'Ratings (Low to High)'
            },
            {
              key: 'ratingsDesc',
              label: 'Ratings (High to Low)'
            },
          ],
          v_required: {
            value: false,
            err: 'Select a type'
          },
        }
      ]
    }
  ]
}


export const learnerTeamFields = {
  "steps": [
    {
      "fields": [
        {
          "key": "learnerTeams",
          "type": "check_box",
          "label": "Learner Teams",
          "hint": "Select Learner Teams",
          "options": [],
          "v_required": {
            "value": false,
            "err": "Select Learner Teams"
          },
          "placement": "left"
        },
        {
          key: 'dueDate',
          type: 'date',
          label: 'Due Date',
          hint: 'Provide Due Date',
          v_required: {
            value: false,
            err: 'Provide Due Date'
          },
          disablePast: true,
        }
      ]
    }
  ]
}

export const learnerFields = {
  steps: [
    {
      fields: [
        {
          key: 'learners',
          type: 'check_box',
          label: 'Learners',
          hint: 'Select Learners',
          options: [],
          v_required: {
            value: false,
            err: 'Select Learners'
          }
        },
        {
          key: 'dueDate',
          type: 'date',
          label: 'Due Date',
          hint: 'Provide Due Date',
          v_required: {
            value: false,
            err: 'Provide Due Date'
          },
          disablePast: true,
        }
      ]
    }
  ]
}