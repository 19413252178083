import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { ResourcesList } from '../components/Resources/ResourcesList';
import { EmptyView } from '../components/EmptyView';
import { AppContext } from '../common/components/contexts/AppContext';
import { FavContext } from '../common/components/contexts/FavContext';
import { AppCommonTheme } from '../styles/Styles';
import { Player } from '@lottiefiles/react-lottie-player';

export function Resources(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    listResources: {},
    listView: true,
  })
  const [searchText, setSearchText] = useState('')

  const { fav, deleteResourcesFromFav } = useContext(FavContext)

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const categoryResources = {}
      const listResources = { items: [] }
      if (fav.resourceData && fav.resourceData.items && fav.resourceData.items.length > 0) {
        for (const f of fav.resourceData.items) {
          if (!(f.title.toLowerCase()).includes(searchText)) {
            continue
          }
          listResources.items.push(f)
          if (categoryResources[f._course.courseId]) {
            categoryResources[f._course.courseId].items.push(f)
          } else {
            categoryResources[f._course.courseId] = {
              title: f._course.title,
              items: [f]
            }
          }
        }
      }
      setState({
        ...state,
        listResources: listResources,
        categoryResources: categoryResources,
        fetchState: (fav.resourceData && fav.resourceData.items) && ApiHelper.State.READY,
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'resources' })
    fetchData()
  }, [fav])

  useEffect(() => {
    fetchData()
  }, [searchText])

  const resourcesListToggle = () => {
    setState({
      ...state,
      listView: !state.listView
    })
  }

  const listView = () => {
    if (state.listView) {
      return (
        (state.listResources && state.listResources.items && state.listResources.items.length > 0) &&
        <ResourcesList
          data={state.listResources.items} />
      )
    } else {
      return (
        state.categoryResources && state.categoryResources && Object.keys(state.categoryResources).length > 0 &&
        Object.keys(state.categoryResources).map((item, index) => {
          return <ResourcesList
            key={index}
            showTitle={true}
            title={state.categoryResources[item].title}
            borderContainer={[CommonStyles.borderContainer, Styles.courseContainer]}
            data={state.categoryResources[item].items} />
        })
      )
    }
  }

  const renderContainer = () => {
    if (state.fetchState === ApiHelper.State.READY) {
      if (state.listResources && state.listResources.items && state.listResources.items.length > 0) {
        return listView()
      } else {
        return <EmptyView
          title={Helper.getString('noResource')} />
      }
    } else {
      return <div className={css(Styles.container)}>
        <Player
          autoplay
          loop
          src={require('../assets/images/circle_loading_progress_indicator.json')}
          className={css(Styles.loadingContainer)} />
        {navigator && navigator.userAgent.startsWith('Azsincx') ? '' :
          <p className={css(Styles.loadingText)}>{Helper.getString('loading')}</p>
        }
      </div>
    }
  }

  const onInputChange = (searchText) => {
    setSearchText(searchText)
  }

  return (
    <ScreenWrapper
      state={state}
      title={Helper.getString('resources')}
      resources={true}
      favourite={true}
      resourcesListToggle={resourcesListToggle}
      listView={state.listView}
      logo={true}
      search={true}
      onInputChange={onInputChange}
      placeholder={Helper.getString('searchResources')}
    // filter={true} //TODO filter
    >
      {renderContainer()}
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  courseContainer: {
    marginBottom: 20,
    '@media(max-width: 767px)': {
      marginBottom: 40,
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  loadingContainer: {
    height: 220,
    width: 220,
    '@media (max-width: 767px)': {
      height: 135,
      width: 135,
    }
  },
  loadingText: {
    fontSize: 48,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 5,
    '@media (max-width: 767px)': {
      fontSize: 28,
    }
  }
})