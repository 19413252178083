import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography'

import { AppConfig } from '../AppConfig';
import { AppTheme, AppStyles } from '../styles/AppTheme';
import { CommonStyles } from '../styles/Styles';
import { PageAnalytics } from '../common/helpers/PageAnalytics'
import { Link } from 'react-router-dom';

export class Footer extends Component {
  render() {
    return (
      <footer className={css(Styles.footer)}>
      </footer>
    );
  }

}

const Styles = StyleSheet.create({
  footer: {
    //borderTop: '1px solid ' + AppTheme.primaryColor,
    padding: 30,
    backgroundColor: AppTheme.primaryColor,
    '@media (max-width: 767px)': {
      padding: 20,
    }
  }
})
