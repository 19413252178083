import React, { useState, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CommonStyles } from '../styles/Styles';
import { CheckBoxComponent } from '../components/CheckBoxComponent';
import { AppButton } from './AppButton';
import { ErrorMsg } from '../components/ErrorMsg';
import { Controller } from '../common/Controller';

export function Quiz(props) {
  const [state, setState] = useState({
    selectedQuestion: 0,
    showAnswer: false,
    selectedChecklist: {}
  })

  const onClose = () => {
    setState({
      ...state,
      selectedQuestion: 0,
      showAnswer: false,
      selectedChecklist: {}
    })
    props.onClose()
  }

  const selectedValue = (values) => {
    setState({
      ...state,
      selectedChecklist: values
    })
  }


  const quizQuestionSubmit = async () => {
    setState({
      ...state,
      inProcess: true,
    })
    try {
      const selectedValues = []
      for (const item in state.selectedChecklist) {
        if (state.selectedChecklist[item] && state.selectedChecklist[item] === true) {
          selectedValues.push(item)
        }
      }
      let chapterQuestionId
      {
        props.data && props.data.length > 0 &&
          props.data.sort((a, b) => a.order - b.order).map((item, index) => {
            if (index === state.selectedQuestion) {
              chapterQuestionId = item.id
            }
          })
      }
      const quizResponse = await Controller.get().courseMgr().submitQuiz(chapterQuestionId, selectedValues, props.courseprogs)

    } catch (e) {
      setState({ ...state, openError: true, errorMsg: Helper.getErrorMsg(e), inProcess: false })
      return
    }

    if (state.selectedQuestion !== (props.data.length - 1)) {
      setState({
        ...state,
        selectedQuestion: state.selectedQuestion + 1,
        inProcess: false,
        showAnswer: false,
      })
    } else {
      setState({
        ...state,
        inProcess: false,
        showAnswer: false,
      })
    }

    if (state.selectedQuestion === (props.data.length - 1)) {
      props.submit()
    }
  }

  const showAnswerFun = () => {
    if (state.selectedChecklist === undefined || Object.keys(state.selectedChecklist).length === 0) {
      setState({
        ...state,
        openError: true,
        errorMsg: Helper.getString('pleaseSelectAnyOption')
      })
    } else {
      setState({
        ...state,
        showAnswer: true,
      })
    }
  }

  const buttonAction = () => {
    if (!state.showAnswer) {
      return () => showAnswerFun()
    } else if (state.showAnswer && state.selectedQuestion < props.data.length) {
      return () => quizQuestionSubmit()
    }
  }

  const buttonText = () => {
    if (!state.showAnswer) {
      return Helper.getString('submit')
    } else if (state.showAnswer && state.selectedQuestion < (props.data.length - 1)) {
      return Helper.getString('next')
    } else if (state.selectedQuestion === (props.data.length - 1)) {
      return Helper.getString('close')
    }
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  return (
    <Fragment>
      <Dialog
        onClose={() => onClose()}
        open={props.dialogOpen || false}
        disableScrollLock={true}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.dialog),
        }}>
        <DialogTitle className={css(Styles.dialogTitleContainer)}>
          <p className={css(Styles.dialogTitle)}>{Helper.getString('quiz')}</p>
        </DialogTitle>
        <DialogContent className={css(Styles.dialogContent)}>
          {props.data && props.data.length > 0 &&
            props.data.sort((a, b) => a.order - b.order).map((item, index) => {
              return (
                state.selectedQuestion === index &&
                <Fragment key={index}>
                  <CheckBoxComponent
                    dialog={true}
                    data={item}
                    count={index + 1}
                    showAnswer={state.showAnswer}
                    selectedValue={selectedValue}
                    dataSend={true} />
                </Fragment>
              )
            })
          }
        </DialogContent>
        <DialogActions className={css(Styles.dialogActions)}>
          <AppButton
            onClick={buttonAction()}
            buttonText={buttonText()}
            inProcess={state.inProcess}
          />
        </DialogActions>
      </Dialog>
      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  dialog: {
    minWidth: 460,
    borderRadius: AppCommonTheme.borderRadius,
    margin: 10,
    '@media (max-width: 767px)': {
      minWidth: 'unset',
      width: '100%'
    }
  },
  dialogTitleContainer: {
    padding: '40px 40px 10px',
    '@media (max-width: 767px)': {
      padding: '24px 16px 5px',
    }
  },
  dialogTitle: {
    fontSize: 16,
    margin: AppCommonTheme.pMargin,
    color: AppCommonTheme.appFontColor,
    '@media (max-width: 767px)': {
      fontSize: 12
    }
  },
  dialogContent: {
    padding: '0px 40px',
    overflow: 'unset',
    '@media (max-width: 767px)': {
      padding: '0px 16px',
      paddingBottom: 10,
    }
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 40px 40px',
    '@media (max-width: 767px)': {
      padding: '0px 16px 24px',
    }
  },
})
