import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme, CommonStyles } from '../styles/Styles';
import { HeaderComponent } from './HeaderComponent';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { MobileFooter } from './MobileFooter';
import { Player } from '@lottiefiles/react-lottie-player';
import { ErrorMsg } from '../components/ErrorMsg';
import { Controller } from '../common/Controller';

export function ScreenWrapper(props) {
  const ERROR_MSG = Helper.getString('defaultApiErrMsg')
  const orgId = Controller.get().roleHelper().getOrgId()

  const readyView = () => {
    if (props.state && props.state.fetchState === ApiHelper.State.LOADING) {
      return <div className={css(Styles.container)}>
        <Player
          autoplay
          loop
          src={require('../assets/images/circle_loading_progress_indicator.json')}
          className={css(Styles.loadingContainer)} />
        {navigator && navigator.userAgent.startsWith('Azsincx') ? '' :
          <p className={css(Styles.loadingText)}>{Helper.getString('loading')}</p>
        }
      </div>
    } else if (props.state && (props.state.fetchState === ApiHelper.State.ERROR || props.state.errMsg)) {
      return <div className={css(Styles.container)}>
        <p className={css(Styles.error)}>{props.state.errMsg || ERROR_MSG}</p>
      </div>
    } else {
      return props.children
    }
  }

  const showMobileFooter = () => {
    if (props.disableFooter) {
      return null
    } else if (props.preview) {
      return null
    } else if (!orgId) {
      return null
    }
    return <MobileFooter />
  }

  return (
    <Fragment>
      <div className={css(CommonStyles.outerContainer, props.outerContainer)}>
        <HeaderComponent {...props} />
        {readyView()}
      </div>
      <div className={css(Styles.mobileFooter)}>
        {showMobileFooter()}
      </div>
      <ErrorMsg
        errMsg={Helper.getString('comingSoon')}
      />
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  mobileFooter: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
    }
  },
  loadingContainer: {
    height: 220,
    width: 220,
    '@media (max-width: 767px)': {
      height: 135,
      width: 135,
    }
  },
  loadingText: {
    fontSize: 48,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 5,
    '@media (max-width: 767px)': {
      fontSize: 28,
    }
  }
})