import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';
import { Search } from './Search';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Logo } from './Logo';
import { Controller } from '../common/Controller';
import { Filter } from './Filter';
import { AppTheme } from '../styles/AppTheme';
import { SettingsMenu } from '../common/components/SettingsMenu';
import Tooltip from '@material-ui/core/Tooltip';

export function HeaderComponent(props) {
  const cssBreakpoint = useMediaQuery('(max-width: 1024px)');
  const [state, setState] = useState({
    settingPopover: null,
  })

  const backButton = () => {
    const backButtonContainerStyle = (props.title || props.subTitle) ? css(Styles.backButtonContainer) : css(Styles.backButtonContainer, Styles.marginBottomUnset)
    return (
      props.backButton &&
      <div className={css(Styles.backButtonOuterContainer)}>
        <div className={backButtonContainerStyle}
          onClick={() => Controller.get().courseMgr().navToBack(props.backButton.link)}>
          <img
            alt={Helper.getString('arrowLeftIconAlt')}
            src={require('../assets/images/arrow_left_icon.svg')}
            width='18px' height='18px'
            className={css(Styles.iconLeft)} />
          <p className={css(Styles.backButtonTitle)}>{props.backButton.title}</p>
        </div>
      </div>
    )
  }

  const title = () => {
    return (
      props.title &&
      <h1 className={css(Styles.title, props.titleStyle)}>{props.dashboard && Helper.getString('welcome')} {props.title}{props.dashboard && '!'}</h1>
    )
  }

  const subTitle = () => {
    const subTitleStyle = props.drawer ? css(Styles.subTitle, Styles.subTitleMarginTop) : css(Styles.subTitle)
    return (
      props.subTitle &&
      <p className={subTitleStyle}>{props.subTitle}</p>
    )
  }

  const settingOpen = (e) => {
    setState({ ...state, settingPopover: e.currentTarget })
  }

  const settingClose = () => {
    setState({ ...state, settingPopover: null })
  }

  const resourcesIcon = props.listView ? 'list_icon' : 'grid_icon'
  return (
    <div className={css(Styles.outerContainer)}>
      <div className={props.logo ? css(Styles.innerContainer, Styles.marginBottom) : css(Styles.innerContainer)}>
        <div className={css(Styles.titleContainer)}>
          {props.logo &&
            <div className={css(Styles.logoContainer)} >
              <Logo />
            </div>
          }
          <div className={css(Styles.desktopView)}>
            {backButton()}
          </div>
          <div className={css(Styles.desktopView)}>
            {title()}
          </div>
          <div className={css(Styles.desktopView)}>
            {subTitle()}
          </div>
        </div>

        <div className={css(Styles.actionContainer)}>
          <div className={css(Styles.actionInnerContainer)}>
            {props.resources &&
              <div className={css(Styles.iconContainer)} onClick={() => props.resourcesListToggle()}>
                <img
                  alt={Helper.getString('resourceImageAlt')}
                  src={require(`../assets/images/${resourcesIcon}.svg`)}
                  width='28px' height='28px'
                  className={css(Styles.icon)} />
              </div>
            }
            {props.favourite &&
              <div className={css(Styles.iconContainer)} onClick={() => Controller.get().courseMgr().navToFavourites()}>
                <img
                  alt={Helper.getString('favouriteImageAlt')}
                  src={require('../assets/images/heart.svg')}
                  width='28px' height='28px'
                  className={css(Styles.icon)} />
              </div>
            }
            {props.search &&
              <div className={css([Styles.iconContainer, Styles.searchDesktopContainer])}>
                <Search
                  searchText={props.searchText}
                  placeholder={props.placeholder}
                  onInputChange={props.onInputChange}
                  onEndEditing={props.onEndEditing} />
              </div>
            }
            {props.filter &&
              <div className={css(Styles.iconContainer)}>
                <Filter />
              </div>
            }
            {props.setting &&
              <div className={css(Styles.link)} onClick={(e) => settingOpen(e)}>
                <Tooltip title={'Settings'} arrow classes={{ popper: css(Styles.tooltip) }}>
                  <div className={css(Styles.imageContainer)}>
                    <i className='material-icons-outlined' style={{ fontSize: 20, margin: 14, color: '#222222' }}>settings</i>
                  </div>
                </Tooltip>
              </div>
            }
          </div>
        </div>
      </div>
      {props.search &&
        <div className={css([Styles.iconContainer, Styles.searchMobContainer])}>
          <Search
            searchText={props.searchText}
            placeholder={props.placeholder}
            onInputChange={props.onInputChange}
            onEndEditing={props.onEndEditing} />
        </div>
      }
      {cssBreakpoint &&
        <div className={css(Styles.titleMobileContainer)} >
          <div className={css(Styles.mobileView)}>
            {backButton()}
          </div>
          <div className={css(Styles.mobileView)}>
            {title()}
          </div>
          <div className={props.drawer ? css(Styles.mobileView, Styles.drawerMarginTop) : css(Styles.mobileView)}>
            {subTitle()}
            {props.drawer &&
              <div className={css(Styles.drawerButtonContainer)} onClick={() => props.onDrawerOpen()}>
                <img
                  src={require('../assets/images/sort_icon.svg')}
                  className={css(Styles.drawerButton)} width='24px' height='24px'
                  alt={Helper.getString('drawerButtonImgAlt')} />
              </div>
            }
          </div>
        </div>
      }
      <SettingsMenu
        settingPopover={state.settingPopover}
        settingClose={settingClose}
        orgId={props.orgId} />
    </div>
  )
}

const Styles = StyleSheet.create({
  outerContainer: {
    // paddingTop: 10,
    paddingBottom: 40,
    '@media (max-width: 767px)': {
      paddingBottom: 28,
    }
  },
  innerContainer: {
    display: 'flex',
  },
  marginBottom: {
    '@media (max-width: 1024px)': {
      marginBottom: 35,
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  logoContainer: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
    }
  },
  title: {
    fontSize: 38,
    margin: AppCommonTheme.pMargin,
    color: AppCommonTheme.appFontColor,
    textTransform: 'capitalize',
    '@media (max-width: 1024px)': {
      fontSize: 28,
      flex: 1,
      marginRight: 10,
    }
  },
  subTitle: {
    fontSize: 18,
    margin: AppCommonTheme.pMargin,
    color: AppCommonTheme.appFontColor,
    textTransform: 'capitalize',
    '@media (max-width: 1024px)': {
      fontSize: 16,
      flex: 1,
      marginRight: 10,
      marginTop: 20
    }
  },
  subTitleMarginTop: {
    '@media (max-width: 1024px)': {
      marginTop: 0
    }
  },
  actionContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    '@media (max-width: 1024px)': {
      alignItems: 'center'
    }
  },
  actionInnerContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconContainer: {
    marginLeft: 20,
    display: 'flex'
  },
  icon: {
    width: 28,
    height: 28,
    ':hover': {
      cursor: 'pointer'
    }
  },

  drawerMarginTop: {
    marginTop: 10
  },
  drawerButtonContainer: {
    backgroundColor: AppCommonTheme.bgColor,
    padding: '15px 20px',
    marginRight: -20,
  },
  drawerButton: {
    width: 24,
    height: 24,
  },
  titleMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  backButtonOuterContainer: {
    display: 'flex'
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 25,
    '@media (max-width: 1024px)': {
      marginBottom: 14
    }
  },
  marginBottomUnset: {
    marginBottom: 0,
    '@media (max-width: 1024px)': {
      marginBottom: 0
    }
  },
  iconLeft: {
    width: 18,
    height: 18,
  },
  backButtonTitle: {
    color: AppTheme.primaryColor,
    fontSize: 16,
    margin: AppCommonTheme.pMargin,
    marginLeft: 4
  },
  desktopView: {
    display: 'block',
    '@media (max-width: 1024px)': {
      display: 'none',
    }
  },
  mobileView: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'flex',
      alignItems: 'center'
    }
  },

  link: {
    textDecoration: 'none',
    marginLeft: 20,
    '@media (max-width: 767px)': {
      marginLeft: 5,
    }
  },
  imageContainer: {
    width: 48,
    height: 48,
    backgroundColor: AppTheme.secondaryBg,
    borderRadius: '50%',
    display: 'flex',
    cursor: 'pointer',
  },
  searchMobContainer: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'flex',
      marginLeft: 0,
      marginBottom: 20,
      justifyContent: 'center'
    }
  },
  searchDesktopContainer: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'none',
    }
  }
})