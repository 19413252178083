import React, { useEffect, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';
import { CourseMgr } from '../common/mgrs/CourseMgr';

const YT_PREFIX = 'YT-'
let player;
export function Video(props) {
  let firstScriptTag
  const autoplayValue = props.data.status === CourseMgr.Status.COMPLETED ? 0 : 1

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    if (firstScriptTag) {
      const myTimeout = setTimeout(onYouTubeIframeAPIReady, 500)
    }
  }, [])

  if (!props.videoLink) return
  const url = Helper.getQueryStringParamsFromUrlYoutube(props.videoLink)
  const videoId = Helper.getVideoId(url)

  const onYouTubeIframeAPIReady = () => {
    if (!window.YT) return
    window.YT.ready(function () {
      player = new window.YT.Player(YT_PREFIX + props.data.id, {
        width: '100%',
        videoId: videoId,
        playerVars: { autoplay: autoplayValue },
        controlBar: {
          pictureInPictureToggle: false
        }
      });
    })
  }

  useEffect(() => {
    if (autoplayValue === 1 && player) {
      if (window.YT) {
        window.YT.ready(function () {
          if (player.H) {
            player.playVideo()
          }
        })
      }
    }
  }, [autoplayValue])

  useEffect(() => {
    if (props.stopVideo && player) {
      if (window.YT) {
        window.YT.ready(function () {
          if (player.H) {
            player.stopVideo()
          }
        })
      }
    }
  }, [props.stopVideo, player])


  return (
    <Fragment>
      {props.videoLink &&
        <div className={css(Styles.wrapper)}>
          {/* <iframe
              title='Video'
              width='100%'
              height='450px'
              src={Helper.processVideoLink(props.videoLink)}
              // srcDoc={Helper.processVideoLinkDoc(props.items[0].videoLink, 'Video')}
              frameBorder='0'
              type='text/html'
              className={css(Styles.videoContainerTile)} /> */}
          <div id={YT_PREFIX + props.data.id} className={css(Styles.videoContainerTile)}></div>

        </div>
      }
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    paddingBottom: ' 56.25%', /* 16:9 */
    paddingTop: 25,
    height: 0
  },
  videoContainerTile: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: AppCommonTheme.borderRadius,
    overflow: 'hidden'
  }
})