import React, { Component, Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Grid, Card, CardContent, CardActionArea, Tooltip, Paper, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { BaseEntityPage } from '../BaseEntityPage';
import { ApiHelper } from '../../helpers/ApiHelper';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Helper } from '../../helpers/Helper';
import { SubEntityCard } from '../SubEntity/SubEntityCard';
import { SubEntityList } from '../SubEntity/SubEntityList';
import { UiHelper } from '../../helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { CommonStyles } from '../../../styles/Styles';
import { Controller } from '../../Controller';

export class CourseEdit extends BaseEntityPage {
  constructor(props, options) {
    super(props)
    this.creatorPurposeOne = options.creatorPurposeOne
    this.pageTitle = Helper.getString('course')
    this.addNewLabel = 'Add'
    this.pageKey = 'Details'
    this.noAdd = true
    // this.orgId = (Controller.get().roleHelper().isProductUser() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.orgId = props.match.params.orgId ? props.match.params.orgId : Controller.get().roleHelper().getOrgId()
    this.courseId = this.props.match.params.id
    this.uploadLocation = this.orgId + '/' + this.courseId
    this.emptyText = ' ' // space
    this.apiSpecForImageUploader = {}
    if(Controller.get().roleHelper().isProductStaff()) {
      this.breadCrumbs = [
        { title: 'Courses', to: this.baseRoute() + '/courses' },
        { title: this.pageTitle },
      ]
    } else {
      this.breadCrumbs = [
        { title: Helper.getString('home'), to: this.baseRoute() },
        { title: 'Courses', to: this.baseRoute() + '/courses' },
        { title: this.pageTitle },
      ]
    }
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['courses', this.courseId], {
          orgId: this.orgId,
          pb: 'fieldDef,fieldDefSEO,fieldDefImages,fieldDefDescr'
        })
      },
      { key: 'cats', method: 'GET', endPoint: ApiHelper.makeUrlPath(['ccats'], { orgId: this.orgId, maxPages: 5 }) },
    ]
    if (this.creatorPurposeOne) {
      this.toFetch.push(
        {
          key: 'creators', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'],
            { purposeOne: this.creatorPurposeOne })
        }
      )
    }
    this.apiData = {
    }
  }

  onFetchSuccess(results) {
    console.log('onFetchSuccess:', results)
    const item = this.getResult(results, 'item')

    const fields = []
    const fieldsSettings = []
    item.fieldDef.steps[0].fields.forEach(f => {
      // exclude anything not required
      if (f.key === 'slug') return
      if (Helper.inArray(['state', 'catIds'], f.key)) {
        fieldsSettings.push(f)
        return
      }
      fields.push(f)
    })
    fields.push(item.fieldDefDescr.steps[0].fields[0])
    item.fieldDef.steps[0].fields = fields

    this.pageTitle = item.title

    this.fieldDef = item.fieldDef
    this.formDefinition = this.fieldDef
    this.fieldDefSettings = {
      steps: [
        { fields: fieldsSettings }
      ]
    }

    this.cats = this.getResult(results, 'cats')
    this.cats.items = this.cats.items && this.cats.items.filter((item) => item.state === 'LIVE')
    const catsToChoose = Helper.processCats(this.cats.items, true)
    UiHelper.populateOptions(this.fieldDefSettings, { items: catsToChoose }, 'catIds')

    // this.creators = this.getResult(results, 'creators')
    // if (this.creators) {
    //   UiHelper.populateOptions(this.fieldDef, this.creators, 'creatorId')
    // }

    this.setState({
      item: item,
    })
  }

  renderAboveTable() {
    const { item, docs, images, variants, variantOptions } = this.state
    const { pathname } = this.props.location;
    return (
      <div className={css(Styles.containerNarrow)}>
        {item &&
          <div className={css(CommonStyles.courseActionListContainer)}>
            <div className={css(CommonStyles.courseActionListInnerContainer)}>
              {UiHelper.isOrgClient(pathname) ? UiHelper.courseActionList(this, item, this.pageKey, this.orgId, this.courseId) :
                UiHelper.courseAdminActionList(this, item, this.pageKey, this.courseId)}
            </div>
            <Divider className={css(CommonStyles.courseActionListDivider)} />
          </div>
        }
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Main'} formDefinition={this.formDefinition}
              item={this.state.item}
              openModalForEdit={this.openModalForEdit.bind(this)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Settings'} formDefinition={this.fieldDefSettings}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForEdit(current, this.fieldDefSettings)}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: {},
      apiEndPoint: ApiHelper.makeUrlPath(['courses', current.id]),
    });
  }

  openModalForSE(options) {
    const { current, entityAttrName, entityAction } = options
    this.formDefinition.formType = entityAction
    if (current) {
      Helper.populateFormDefinitionValues(this.formDefinition, current)
    }

    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction, entityAttrName, id: current ? current.id : undefined },
      apiEndPoint: ApiHelper.makeUrlPath(['courses', this.courseId]),
      modalOpen: true
    })
  }
}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  container: {
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
    marginBottom: 20,
    width: '100%',
  }
})