import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './registerServiceWorker';
import { AppContextProvider } from './common/components/contexts/AppContext';
import { UserContextProvider } from './common/components/contexts/UserContext';
import { FavContextProvider } from './common/components/contexts/FavContext';

ReactDOM.render(<AppContextProvider>
  <UserContextProvider>
    <FavContextProvider>
      <App />
    </FavContextProvider>
  </UserContextProvider>
</AppContextProvider>, document.getElementById('root'));
serviceWorker.unregister();
