import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Courses } from '../components/Courses/Courses';
import { Helper } from '../common/helpers/Helper';
import { CommonStyles } from '../styles/Styles';
import { AppContext } from '../common/components/contexts/AppContext';

export function CoursesPage(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {}
  })
  const orgId = Controller.get().roleHelper().getOrgId()

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getCourses(orgId)
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'explore' })
    fetchData()
  }, [])

  const backButton = {
    title: Helper.getString('back'),
    link: '/learner/orgs/' + orgId + '/explore'
  }

  return (
    <ScreenWrapper
      state={state}
      title={Helper.getString('courses')}
      favourite={true}
      titleStyle={CommonStyles.titleStyle}
      logo={true}
      backButton={backButton}
    // search={true} //TODO search
    // filter={true}
    >

      {state.result && state.result.items && state.result.items.length > 0 &&
        <Courses
          data={state.result}
          backButton={{ coursePage: true }} />
      }
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
})