/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
import { StringTableCommon } from './common/StringTableCommon'
import { StringTableCourse } from './common/components/course/StringTableCourse'
export const StringTable = [
  ...StringTableCommon,
  ...StringTableCourse,
  {
    "key": "sampleStrKey",
    "en": "Sample"
  },
  {
    "key": "menuIconsAlt",
    "en": "Menu icon alt"
  },
  {
    "key": "logoImg",
    "en": "Logo Image"
  },
  {
    "key": "logoIcon",
    "en": "Logo icon"
  },
  {
    "key": "searchPlaceholder",
    "en": "Search for courses, resources..."
  },
  {
    "key": "searchImageAlt",
    "en": "Search image alt"
  },
  {
    "key": "favouriteImageAlt",
    "en": "Favourite image alt"
  },
  {
    "key": "statusImgAlt",
    "en": "Status image alt"
  },
  {
    "key": "myCourses",
    "en": "My courses"
  },
  {
    "key": "courseImgAlt",
    "en": "Course image alt"
  },
  {
    "key": "welcome",
    "en": "Welcome,"
  },
  {
    "key": "startCourse",
    "en": "Start course"
  },
  {
    "key": "arrowRightImgAlt",
    "en": "Arrow right image Alt"
  },
  {
    "key": "explore",
    "en": "Explore"
  },
  {
    "key": "orgs",
    "en": "Clients"
  },
  {
    "key": "info1",
    "en": "Tip"
  },
  {
    "key": "info2",
    "en": "Task"
  },
  {
    "key": "due",
    "en": "Due"
  },
  {
    "key": "viewMore",
    "en": "View more"
  },
  {
    "key": "resources",
    "en": "Resources"
  },
  {
    "key": "resourceImageAlt",
    "en": "Resources image alt"
  },
  {
    "key": "download",
    "en": "Download"
  },
  {
    "key": "resourceListImgAlt",
    "en": "Resources list image alt"
  },
  {
    "key": "downloadImgAlt",
    "en": "Download image alt"
  },
  {
    "key": "favourites",
    "en": "Favourites"
  },
  {
    "key": "profile",
    "en": "Profile"
  },
  {
    "key": "downloadCertificate",
    "en": "Download certificate"
  },
  {
    "key": "links",
    "en": "Links"
  },
  {
    "key": "basicInformation",
    "en": "Basic information"
  },
  {
    "key": "profileImgAlt",
    "en": "Profile image alt"
  },
  {
    "key": "givenname",
    "en": "Name"
  },
  {
    "key": "phoneNumber",
    "en": "Phone number"
  },
  {
    "key": "email",
    "en": "Email"
  },
  {
    "key": "company",
    "en": "Company"
  },
  {
    "key": "myTrophies",
    "en": "My Awards"
  },
  {
    "key": "rewardPoints",
    "en": "Reward points"
  },
  {
    "key": "sincx",
    "en": "SincX"
  },
  {
    "key": "filterImgAlt",
    "en": "Filter image alt"
  },
  {
    "key": "sortFilter",
    "en": "Sort/Filter"
  },
  {
    "key": "reset",
    "en": "Reset"
  },
  {
    "key": "apply",
    "en": "Apply"
  },
  {
    "key": "search",
    "en": "Search"
  },
  {
    "key": "searchPlaceholderPage",
    "en": "Search for courses and knowledge sets"
  },
  {
    "key": "loginBgImgAlt",
    "en": "Login background image alt"
  },
  {
    "key": "loginTitle",
    "en": "Employee training, Simplified"
  },
  {
    "key": "loginSubTitle",
    "en": `"The only thing worse than training your employees and having them leave is not training them and having them stay."`
  },
  {
    "key": "hello",
    "en": "Hello!"
  },
  {
    "key": "loginDescr",
    "en": "Unlock Your Potential with SincX Learning Platform!"
  },
  {
    "key": "courseAssessment",
    "en": "Course assessment"
  },
  {
    "key": "submit",
    "en": "Submit"
  },
  {
    "key": "trophyImgAlt",
    "en": "Trophy image alt"
  },
  {
    "key": "giveFeedback",
    "en": "Give feedback"
  },
  {
    "key": "duration",
    "en": "Duration"
  },
  {
    "key": "dueDate",
    "en": "Due date"
  },
  {
    "key": "rating",
    "en": "Rating"
  },
  {
    "key": "courseContent",
    "en": "Course content"
  },
  {
    "key": "eligibilityTitle",
    "en": "Please complete the following courses before enrolling for this course"
  },
  {
    "key": "chapterImgAlt",
    "en": "Chapter image alt"
  },
  {
    "key": "sectionTitle",
    "en": "Section title"
  },
  {
    "key": "buttonArrowImgAlt",
    "en": "Button arrow image alt"
  },
  {
    "key": "previous",
    "en": "Previous"
  },
  {
    "key": "markAsComplete",
    "en": "Mark as complete"
  },
  {
    "key": "next",
    "en": "Next"
  },
  {
    "key": "infoImgAlt",
    "en": "Info image alt"
  },
  {
    "key": "tip",
    "en": "Tip"
  },
  {
    "key": "taskImgAlt",
    "en": "Task image alt"
  },
  {
    "key": "task",
    "en": "Task"
  },
  {
    "key": "documentArrowImgAlt",
    "en": "Document arrow image alt"
  },
  {
    "key": "salutation",
    "en": "Salutation"
  },
  {
    "key": "jobTitle",
    "en": "Job title"
  },
  {
    "key": "department",
    "en": "Department"
  },
  {
    "key": "managerName",
    "en": "Manager name"
  },
  {
    "key": "closeImgAlt",
    "en": "Close image alt"
  },
  {
    "key": "assessmentContent",
    "en": "Assessment content"
  },
  {
    "key": "question",
    "en": "Question"
  },
  {
    "key": "drawerButtonImgAlt",
    "en": "Drawer button image alt"
  },
  {
    "key": "coursePassedTitle",
    "en": "Congrats 🎉 You have passed the course."
  },
  {
    "key": "courseFailedTitle",
    "en": "Sorry 😔 You didn’t pass the course."
  },
  {
    "key": "courseCertificate",
    "en": "Course certificate"
  },
  {
    "key": "assessmentResult",
    "en": "Assessment result"
  },
  {
    "key": "trophy",
    "en": "Trophy"
  },
  {
    "key": "questionsCorrect",
    "en": "Questions correct"
  },
  {
    "key": "retakeAssessment",
    "en": "Retake assessment"
  },
  {
    "key": "refreshImgAlt",
    "en": "Refresh image alt"
  },
  {
    "key": "viewCertificate",
    "en": "View certificate"
  },
  {
    "key": "emptyImgAlt",
    "en": "Empty image alt"
  },
  {
    "key": "noResource",
    "en": "No resource found!"
  },
  {
    "key": "noCategory",
    "en": "No category found!"
  },
  {
    "key": "loading",
    "en": "Loading..."
  },
  {
    "key": "dismiss",
    "en": "Dismiss"
  },
  {
    "key": "enterDomain",
    "en": "Enter your domain"
  },
  {
    "key": "courseFeedback",
    "en": "Course feedback"
  },
  {
    "key": "rateThisCourse",
    "en": "Rate this course"
  },
  {
    "key": "starImgAlt",
    "en": "Star image alt"
  },
  {
    "key": "letUsKnowYourThougths",
    "en": "Let us know your thougths"
  },
  {
    "key": "yourComments",
    "en": "Your comments"
  },
  {
    "key": "submitFeedback",
    "en": "Submit feedback"
  },
  {
    "key": "sectionTitleGoesHere",
    "en": "Section title goes here"
  },
  {
    "key": "markAsIncomplete",
    "en": "Completed"
  },
  {
    "key": "accolades",
    "en": "Accolades"
  },
  {
    "key": "downloading",
    "en": "Downloading"
  },
  {
    "key": "knowledgeSets",
    "en": "Knowledge sets"
  },
  {
    "key": "myCertificates",
    "en": "My certificates"
  },
  {
    "key": "allCourses",
    "en": "All courses"
  },
  {
    "key": "courses",
    "en": "Courses"
  },
  {
    "key": "noItemsToShow",
    "en": "No items to show"
  },
  {
    "key": "mins",
    "en": "mins"
  },
  {
    "key": "comingSoon",
    "en": "Coming soon"
  },
  {
    "key": "assigned",
    "en": "Assigned"
  },
  {
    "key": "allKnowlegdeSets",
    "en": "All knowlegde sets"
  },
  {
    "key": "assessment",
    "en": "Assessment"
  },
  {
    "key": "complete",
    "en": "Complete"
  },
  {
    "key": "back",
    "en": "Back"
  },
  {
    "key": "arrowLeftIconAlt",
    "en": "Arrow left icon alt"
  },
  {
    "key": "privacyPolicy",
    "en": "Privacy policy"
  },
  {
    "key": "termsOfUse",
    "en": "Terms of use"
  },
  {
    "key": "correct",
    "en": "Correct"
  },
  {
    "key": "ccats",
    "en": "Knowledge Sets"
  },
  {
    "key": "cats",
    "en": "Knowledge Sets"
  },
  {
    "key": "categories",
    "en": "Knowledge Sets"
  },
  {
    "key": "logoutImgAlt",
    "en": "Logout image alt"
  },
  {
    "key": "edit",
    "en": "Edit"
  },
  {
    "key": "delete",
    "en": "Delete"
  },
  {
    "key": "people",
    "en": "Users"
  },
  {
    "key": "users",
    "en": "Users"
  },
  {
    "key": "catalog",
    "en": "Library"
  },
  {
    "key": "useThisCourse",
    "en": "Use this course"
  },
  {
    "key": "preview",
    "en": "Preview"
  },
  {
    "key": "quiz",
    "en": "Knowledge Check"
  },
  {
    "key": "enterDomainName",
    "en": "Enter domain name"
  },
  {
    "key": "learnerMode",
    "en": "Learner mode"
  },
  {
    "key": "knowledgeCheck",
    "en": "Key Takeaways"
  },
  {
    "key": "viewCourse",
    "en": "View Course"
  },
  {
    "key": "pleaseSelectAllOptions",
    "en": "Please select all options"
  },
  {
    "key": "wrong",
    "en": "Wrong"
  },
  {
    "key": "pleaseSelectAnyOption",
    "en": "Please select any option"
  },
  {
    "key": "pleaseRateYourThougths",
    "en": "Please rate your thougths"
  },
  {
    "key": "enroll",
    "en": "Enroll"
  },
  {
    "key": "review",
    "en": "Review"
  },
  {
    "key": "resume",
    "en": "Resume"
  },
  {
    "key": "pleaseRateThisCourse",
    "en": "Please rate this course"
  },
  {
    "key": "pleaseComments",
    "en": "Please add your comments"
  },
  {
    "key": "completed",
    "en": "Completed"
  },
  {
    "key": "leaderboard",
    "en": "Leaderboard"
  },
  {
    "key": "filter",
    "en": "Filter"
  },
  {
    "key": "redoCourse",
    "en": "Redo course"
  },
  {
    "key": "consentText",
    "en": "Consent Text"
  },
  {
    "key": "recurrence",
    "en": "Recurrence"
  },
  {
    "key": "feedbackConfiguration",
    "en": "Feedback configuration"
  },
  {
    "key": "dialogCloseAlt",
    "en": "Dialog close alt"
  },
  {
    "key": "owners",
    "en": "Owners"
  },
  {
    "key": "authors",
    "en": "Authors"
  },
  {
    "key": "learners",
    "en": "Learners"
  },
  {
    "key": "title",
    "en": "Title"
  },
  {
    "key": "domain",
    "en": "Domain"
  },
  {
    "key": "maxUserSubscriptionMsg",
    "en": 'You have reached your maximum subscription count'
  },
  {
    "key": "courseCompleted",
    "en": "Overall Course Progress"
  },
  {
    "key": "eligibileCourseComplete",
    "en": "Please complete the prerequisite courses"
  },
  {
    "key": "close",
    "en": "Close"
  },
  {
    "key": "consents",
    "en": "Consents"
  },
  {
    "key": "feedback",
    "en": "Feedback"
  },
  {
    "key": "completedConsents",
    "en": "You have already completed the consents"
  },
  {
    "key": "courseIncomplete",
    "en": "Course created is incomplete"
  },
  {
    "key": "certification",
    "en": "Certification"
  },
  {
    "key": "emptyList",
    "en": "No data to show yet"
  },
  {
    "key": "pendingUsers",
    "en": "Users"
  },
  {
    "key": "jobs",
    "en": "Jobs"
  },
  {
    "key": "searchMyCourses",
    "en": "Search my courses"
  },
  {
    "key": "searchCourses",
    "en": "Search courses"
  },
  {
    "key": "searchResources",
    "en": "Search resources"
  },
  {
    "key": "renderNotSupported",
    "en": "This view is available only on larger desktops"
  },
  {
    "key": "learnerDashboard",
    "en": "Learner Dashboard"
  },
  {
    "key": "enterFeedback",
    "en": "Enter feedback"
  },
  {
    "key": "userTableTitle",
    "en": "Assigned Learners"
  },
  {
    "key": "courseTableTitle",
    "en": "Assigned Courses"
  },
  {
    "key": "adminTableTitle",
    "en": "Clients"
  },
  {
    "key": "completedOn",
    "en": "Completed"
  },
  {
    "key": "groupNonManagerAddErrorMsg",
    "en": "Selected user does not have 'MANAGER' role in Organisation"
  }
]