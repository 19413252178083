import React, { useState, Fragment, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';
import { CheckBoxComponent } from '../components/CheckBoxComponent';
import { ErrorMsg } from '../components/ErrorMsg';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { AppButton } from '../components/AppButton';
import { AppHelper } from '../helpers/AppHelper';

export function ConsentsPage(props) {
  const [state, setState] = useState({
    selectedConsents: {},
    showAnswer: false,
    submitBtnText: Helper.getString('submit')
  })

  const orgId = Controller.get().roleHelper().getOrgId()
  const courseSlug = props.courseSlug ? props.courseSlug : props.match.params.slug

  async function fetchData() {
    try {
      setState({ ...state, fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getCourseDetails2({ orgId, courseSlug })

      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        data: response,
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  let countAnswer = 0

  const selectedValue = (values) => {
    setState({
      ...state,
      selectedConsents: values
    })
  }

  const submitConsent = async () => {
    if (state.selectedConsents === undefined || Object.keys(state.selectedConsents).length === 0) {
      setState({
        ...state,
        openError: true,
        errorMsg: Helper.getString('pleaseSelectAllOptions')
      })
    } else {
      Object.keys(state.selectedConsents).map(item => {
        if (state.selectedConsents[item] && state.selectedConsents[item] === true) {
          countAnswer = countAnswer + 1
        }
      })
      if (countAnswer === Object.keys(state.data.consents.options).length) {
        setState({
          ...state,
          inProcess: true,
          showAnswer: true
        })
        try {
          for (const item in state.selectedConsents) {
            const consentResponse = await Controller.get().courseMgr().submitConsent(item, state.data.courseprogs)
          }
        } catch (e) {
          setState({ ...state, openError: true, errorMsg: Helper.getErrorMsg(e), inProcess: false })
          return
        }
        setState({
          ...state,
          inProcess: false,
          showAnswer: false,
        })
        if (state.data.showFeedback) {
          Controller.get().courseMgr().navToFeedback(courseSlug)
        } else if (state.data.showCertification) {
          const aid = AppHelper.getAssignmentId(state.data)
          Controller.get().courseMgr().navToCertificate(courseSlug, { course: true }, aid)
        } else {
          Controller.get().courseMgr().navToCourse(courseSlug)
        }
      } else {
        setState({
          ...state,
          openError: true,
          errorMsg: Helper.getString('pleaseSelectAllOptions')
        })
      }
    }
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  const backButton = {
    title: Helper.getString('back'),
    link: '/learner/orgs/' + orgId + '/course/' + courseSlug
  }

  return (
    <ScreenWrapper
      state={state}
      subTitle={Helper.getString('consents')}
      outerContainer={Styles.outerContainer}
      backButton={backButton} >
      {state.data &&
        <Fragment>
          <div className={css(Styles.dialogContent)}>
            <CheckBoxComponent
              dialog={true}
              data={state.data.consents}
              selectedValue={selectedValue}
              dataSend={true}
              showAnswer={state.showAnswer}
              hideCheckbox={state.data.completedConsents} />
          </div>
          <div className={css(Styles.dialogActions)}>
            {state.data.completedConsents ?
              ''
              :
              <AppButton
                onClick={() => submitConsent()}
                inProcess={state.inProcess}
                buttonText={state.submitBtnText}
              />
            }
          </div>
          <ErrorMsg
            open={state.openError}
            errMsg={state.errorMsg}
            closeError={closeError}
          />
        </Fragment>

      }
    </ScreenWrapper>
  )
}

const Styles = StyleSheet.create({
  outerContainer: {
    position: 'relative'
  },
  dialogContent: {
    paddingBottom: 10,
    overflow: 'unset',
    '@media (max-width: 767px)': {
      padding: '10px 16px',
      paddingBottom: 10,
    }
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 40px 40px',
    '@media (max-width: 767px)': {
      padding: '0px 16px 24px',
    }
  },
  consentCompleted: {
    fontSize: 16,
    color: AppCommonTheme.appFontColor,
    margin: 0,
  },
  dialogTitleOuteContainer: {
    padding: '40px 40px 10px',
    '@media (max-width: 767px)': {
      padding: '24px 16px 5px',
    }
  },
  dialogTitleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  closeIconContainer: {
    display: 'flex',
    flex: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  closeIcon: {
    width: 36,
    height: 36
  },

  dialogTitle: {
    fontSize: 26,
    marginBottom: 16,
    color: AppCommonTheme.appFontColor,
    fontWeight: 600,
    margin: AppCommonTheme.pMargin,
    flex: 1,
    '@media(max-width: 767px)': {
      fontSize: 22,
    },
  },
})
