import { AppConfig } from '../../AppConfig';

export class MetaDataHelper {
  static getMetaData(key) {
    switch (key) {
    default:
      return {
        descr: AppConfig.DESCR,
        searchable: AppConfig.SEARCHABLE || AppConfig.DESCR,
        title: AppConfig.TITLE
      }
    }
  }

  static processMetaData = (page, data) => {
    const defaultMetadata = MetaDataHelper.getMetaData(page)
    const metaData = data || {}

    if (!metaData.title) {
      metaData.title = defaultMetadata.title
    }
    if (!metaData.searchable) {
      metaData.searchable = defaultMetadata.searchable
    }
    if (!metaData.descr) {
      metaData.descr = defaultMetadata.descr
    }
    return {
      title: metaData.title,
      description: metaData.descr,
      keywords: metaData.searchable
    }
  }
}
