import React, { useEffect, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';
import { AppConfig } from '../AppConfig';
import { CommonStyles } from '../styles/Styles';
import { HelmetComponent } from '../common/components/HelmetComponent';
import { Helper } from '../common/helpers/Helper';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { AppContext } from '../common/components/contexts/AppContext';

export function Terms(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)

  useEffect(() => {
    appContextStateHandler({ pageKey: 'profile' })
  }, [])


  return (
    <Grid container className={css(CommonStyles.textfulPageContent)} style={props.learner ? { paddaing: 0 } : { padding: '0px 20px' }}>

      <HelmetComponent page={'term'} />

      <h1 className={css(CommonStyles.headerTitle)}>Terms of use</h1>
      <p className={css(CommonStyles.textfulPagePara)}>
        “Terms of Use” is applicable to and binding on all users of the SincX platform and any other services provided by SincX. The Account Holder is responsible for third parties (including End Users) access and use of the SincX platform.
        You agree not to, and not to allow third parties (including End Users) to do any of the following in connection with the SincX platform:
      </p>

      <ul className={css(CommonStyles.textfulPagePara)}>
        <li>Upload, post, transmit, share, or otherwise make available any of Your Data that is unlawful or illegal, including without limitation data that is libellous, invasive of another's privacy, obscene, vulgar, pornographic or indecent, or that contains, promotes or encourages acts of violence, terrorist activity or child exploitation;</li>
        <li>Violate or encourage the violation of the legal rights of others (for example, this may include allowing End Users to infringe or misappropriate the intellectual property rights of others in violation of the Copyright Act);</li>
        <li>Use the SincX App platform to harm minors in any way;
        </li>
        <li>Impersonate any person or entity, including, but not limited to, SincX personnel or representatives, or any other user, or falsely state or otherwise misrepresent your affiliation with a person or entity;
        </li>
        <li>Advocate bigotry, hatred or harassment of any person or group, including SincX personnel or representatives;
        </li>
        <li>Carry out any unlawful, immoral, invasive, infringing, defamatory or fraudulent purpose (for example, this may include phishing, creating a pyramid scheme or mirroring a website);
        </li>
        <li>Intentionally distribute viruses, worms, Trojan horses, corrupted files, hoaxes, or other items of a destructive or deceptive nature;
        </li>
        <li>Disable, interfere with or circumvent any aspect of the SincX platform or any security or authentication measures;
        </li>
        <li>Probe, scan, or test the vulnerability of any system or network, unless done in accordance with SincX’s written consent;
        </li>
        <li>Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by SincX or any other third party (including an End User) to protect the SincX platform;
        </li>
        <li>Generate, distribute, publish or facilitate unsolicited mass email, promotions, advertisings or other solicitations (“spam”);
        </li>
        <li>Access, tamper with, or use parts of the SincX platform to which you have not been invited;
        </li>
        <li>Access, search, or create accounts for the SincX platform by any means other than our publicly supported interfaces (for example, “scraping” or creating accounts in bulk);
        </li>
        <li>Abuse discounts, trials, special offers or promotions to get more users, features, functions or permissions or circumvent any limits on your accounts;
        </li>
        <li>Attempt to access or search the SincX platform, shared materials or training courses or download such materials from the service using any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by SincX, through integrated Third Party Products or other generally available third party web browsers;
        </li>
        <li>Manipulate identifiers in order to disguise the origin of any of Your Data;
        </li>
        <li>Upload, post, transmit, share or otherwise make available any of Your Data that you do not have a right to make available under any law or under contractual or fiduciary relationships;
        </li>
        <li>Upload, post, transmit, share or otherwise make available any of Your Data in a manner that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;
        </li>
        <li>Sublicense, resell, rent, lease, transfer or assign the SincX platform or its use, or offer the SincX platform on a time share basis to any third party, unless expressly permitted in writing by SincX;
        </li>
        <li>Access the SincX platform to build a similar or competitive product or service or copy any ideas, features, functions or graphics of the SincX platform;
        </li>
        <li>Interfere with or disrupt the SincX platform, servers or networks connected to the SincX platform, or the equipment used to provide the SincX platform, or disobey any requirements, procedures, policies or regulations of networks connected to the SincX platform, including using any device or software (for example, by overloading or flooding any part of the SincX platform);
        </li>
        <li>Modify, adapt, or hack the SincX platform, or otherwise attempt to gain unauthorized access to the SincX platform or its related systems or networks;
        </li>
        <li>Intentionally or unintentionally violate any applicable local, state, national or international law where you or your End Users use the SincX platform;
        </li>
        <li>Use the SincX platform to engage in, promote or encourage any unlawful or illegal activities; and/or
        </li>
        <li>Collect or store personal data about other users in connection with any of the prohibited conduct and activities set forth above.
        </li>
      </ul>

      <p className={css(CommonStyles.textfulPagePara)}>
        Violation of this “Terms of Use” may lead to suspension or termination of the Account Holder or End User’s account or legal action. In addition, the Account Holder may be required to pay for the costs of investigation and remedial action related to “Terms of Use” violations. SincX reserves the right to take any other remedial action it sees fit.
        SincX requests that anyone with information about a violation of this “Terms of Use” report it via e-mail to the following address: <span><a href={'mailto:' + AppConfig.SUPPORT_EMAIL}>{AppConfig.SUPPORT_EMAIL}</a></span>. Please provide the date and time (with time zone) of the violation and any identifying information regarding the violator, including e-mail or IP address if available, as well as details of the violation.
        SincX also may report any activity that we suspect violates any law or regulation to appropriate law enforcement officials, regulators or other appropriate third parties.
        SincX may change this “Terms of Use” at any time by posting a new version on this page. The new version will become effective on the date of such notice.
      </p>

    </Grid>
  )
}

const Styles = StyleSheet.create({

})
