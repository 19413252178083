import React, { Fragment, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { AppCommonTheme } from '../styles/Styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export function Faqs(props) {
  const [state, setState] = useState({
    selectedPanelId: null,
    expanded: false,
  })

  const handleChange = (item) => {
    if (item.id !== state.selectedPanelId) {
      setState({
        ...state,
        selectedPanelId: item.id,
        expanded: !state.expanded
      })
    } else {
      setState({
        ...state,
        selectedPanelId: null,
        expanded: !state.expanded
      })
    }
  }

  const data = props.data
  return (
    <Fragment>
      {data && data.length > 0 &&
        data.map((value, index) => {
          return (
            <ExpansionPanel
              key={index}
              className={state.selectedPanelId === value.id ? css(Styles.expansionPanel, Styles.expansionPanelSelected) : css(Styles.expansionPanel)}
              expanded={state.selectedPanelId === value.id}
              onChange={() => handleChange(value)}>
              <ExpansionPanelSummary className={css(Styles.expansionPanelSummary)}
                classes={{ content: css(Styles.expansionPanelSummaryRoot), expandIcon: css(Styles.expandIcon) }}
                expandIcon={<KeyboardArrowDownIcon  />}
              >
                <p className={css(Styles.title)}>Q. {value.title}</p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: css(Styles.expansionPanelDetails) }} >
                <p className={css(Styles.descr)}>{value.descr}</p>
              </ExpansionPanelDetails>
            </ExpansionPanel >
          )
        })
      }
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  expansionPanel: {
    boxShadow: 'unset',
    marginLeft: -20,
    marginRight: -20,
    ':before': {
      height: 0
    },
    '@media (max-width: 1024px)': {
      marginLeft: -10,
      marginRight: -10,
    }
  },
  expansionPanelSelected: {
    backgroundColor: AppCommonTheme.bgColor,
    borderRadius: AppCommonTheme.borderRadius
  },
  expansionPanelSummary: {
    padding: 20,
    minHeight: 'unset',
    '@media (max-width: 1024px)': {
      padding: 10
    }
  },
  expansionPanelSummaryRoot: {
    margin: 0
  },
  title: {
    margin: AppCommonTheme.pMargin,
    fontSize: 18,
    color: AppCommonTheme.appFontColor,
    fontWeight: 600,
    '@media (max-width: 767px)': {
      fontSize: 16
    }
  },
  expansionPanelDetails: {
    padding: '4px 20px 20px',
    '@media (max-width: 767px)': {
      padding: '0px 10px 10px',
    }
  },
  descr: {
    margin: AppCommonTheme.pMargin,
    fontSize: 16,
    color: AppCommonTheme.appFontColor,
    '@media (max-width: 767px)': {
      fontSize: 14
    }
  },
  expandIcon: {
    padding: 0,
    marginRight: -3,
    marginLeft: 5
  }
})