import { StyleSheet } from 'aphrodite';
import { AppTheme } from '../styles/AppTheme';

// Styles to be reused between more than one component
export const AppCommonTheme = {
  drawerWidth: 135,
  bgColor: '#F9F9F9',
  pMargin: 0,
  borderRadius: 12,
  appFontColor: '#121212',
  pagePadding: 40,
  mobilePagePadding: 20,
  border: '1px solid #DCDBE0',
  appFontSize: 16,
  appFontSecondaryColor: '#4F4F4F',
  appFontTertiaryColor: '#828282',
  borderColor: '#8282824f', //TODO: different color given from design
  publicToolbarHeight: '12vh',
  secondaryDivWidth: 385
}
export const CommonTheme = {
  textColor: '#222222',
  containerBorderColor: '1px solid #E2EAFC',
  outerPaddingWeb: 80,
  outerPaddingMob: 20
}

export const CommonStyles = StyleSheet.create({
  headerTitle: {
    margin: 0
  },
  textfulPageContent: {
    maxWidth: AppTheme.viewPort,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    textAlign: 'left',
    //margin: '0px 20px',
    marginTop: 20,
    lineHeight: '1.6',
    // maxWidth: AppTheme.viewPort,
    paddingBottom: 100
  },
  textfulPageSubtitle: {
    fontSize: 28,
    color: AppCommonTheme.appFontColor
  },
  textfulPagePara: {
    fontSize: 16,
    color: AppCommonTheme.appFontColor
  },
  logoImage: {
    height: 37
  },
  linkTextColor: {
    color: 'black'
  },

  outerContainer: {
    flex: 1,
    padding: AppCommonTheme.pagePadding,
    paddingTop: AppCommonTheme.pagePadding + 5,
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 767px)': {
      padding: AppCommonTheme.mobilePagePadding,
      paddingBottom: 100  //Mobile footer bar height
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      padding: '40px 25px',
      paddingBottom: 100  //Mobile footer bar height
    }
  },

  borderContainer: {
    border: AppCommonTheme.border,
    padding: '20px 25px 25px',
    borderRadius: AppCommonTheme.borderRadius,
    '@media(max-width: 767px)': {
      border: 'unset',
      padding: 0
    },
  },
  courseContainer: {
    marginTop: 20,
    '@media(max-width: 767px)': {
      marginTop: 40,
    },
  },
  titleContainer: {
    fontSize: 16,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    textTransform: 'capitalize',
    flex: 1
    // marginBottom: 24,
    // '@media(max-width: 767px)': {
    //   marginBottom: 14,
    // }
  },
  titleOuterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    '@media(max-width: 767px)': {
      marginBottom: 14,
    }
  },

  viewMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    marginBottom: 24,
    '@media(max-width: 767px)': {
      marginBottom: 14,
    }
  },
  buttonTextStyle: {
    textTransform: 'none',
  },

  secondaryButtonContainer: {
    borderRadius: 6,
    minWidth: 113,
    paddding: 12,
    backgroundColor: AppTheme.secondaryBg
  },
  secondaryButtonText: {
    fontSize: 16,
    margin: AppCommonTheme.pMargin,
    textTransform: 'capitalize',
    color: AppTheme.primaryColor,
    fontWeight: 600,
    '@media(max-width: 767px)': {
      fontSize: 14,
    }
  },

  contentStackOuterContainer: {
    display: 'flex',
    flex: 1
  },
  contentStackFirstOuterContainer: {
    display: 'flex',
    flex: 1,
  },
  contentStacksecondOuterContainer: {
    maxWidth: AppCommonTheme.secondaryDivWidth - 81, //81 padding size of the div
    flex: 1,
    borderLeft: AppCommonTheme.border,
    padding: '45px 40px',
    overflowY: 'auto',
    '@media(max-width: 1024px)': {
      display: 'none',
    }
  },
  emptyView: {
    fontSize: 16,
    margin: AppCommonTheme.pMargin,
    color: AppCommonTheme.appFontColor,
    textAlign: 'center'
  },
  titleStyle: {
    fontSize: 32
  },

  deleteText: {
    color: '#E22929'
  },
  courseActionListContainer: {
    width: '100%'
  },
  courseActionListInnerContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  courseActionListDivider: {
    color: '#DCDBE0',
    marginTop: 20,
    marginBottom: 40,
    width: '100%'
  }
});
