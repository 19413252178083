import React, { Fragment, useState, useContext, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';
import { AppTheme } from '../styles/AppTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { filterClientFormFields } from '../data/FormFields';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CommonStyles } from '../styles/Styles';
import { AppButton } from './AppButton';
import { AppContext } from '../common/components/contexts/AppContext';
import { Controller } from '../common/Controller';
import { filterAdminFormFields } from '../data/FormFields';
import { filterManagerFormFields } from '../data/FormFields';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { ErrorMsg } from './ErrorMsg';
import moment from 'moment';

export function Filter(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    dialogOpen: false,
    filterFormFields: props.fields || filterClientFormFields,
  })
  const [formFields, setFormFields] = useState(props.formfieldValues || {})
  const userData = Controller.get().userMgr().getAppUser()

  const fetchData = async () => {
    setFormState()
    setState({
      ...state,
      dialogOpen: true
    })
  }

  useEffect(() => {
    console.log('formfieldValues', props.formfieldValues)
    setFormFields(props.formfieldValues)
  }, [props.formfieldValues])

  const setFormState = () => {
    for (let i = 0; i < state.filterFormFields.items.length; i++) {
      const current = state.filterFormFields.items[i];
      current.value = formFields[current.key]
    }
  }


  const saveData = () => {
    // appContextStateHandler({ filterData: formFields })
    setState({
      ...state,
      dialogOpen: false
    })
    props.onSubmit && props.onSubmit(formFields)
    // setFormFields({})
  }

  const resetData = () => {
    setFormFields({})
  }

  const dialogOpen = () => {
    fetchData()
  }

  const onClose = () => {
    setState({
      ...state,
      dialogOpen: false
    })
  }

  const onAutoCompleteChange = (value, current) => {
    if (value) {
      setFormFields({
        ...formFields,
        [current.key]: value.key
      })
    } else {
      setFormFields({
        ...formFields,
        [current.key]: ''
      })
    }
  }

  const processValueToPreFill = (current) => {
    let value
    if (current.multiSelect) {

      if (!formFields[current.key] || !current.options) return []

      value = []
      for (const k of formFields[current.key]) {
        for (const o of current.options) {
          if (o.key === k) {
            value.push({
              key: o.key,
              text: o.label || o.text
            })
          }
        }
      }
    } else {

      if (!formFields[current.key] || !current.options) return ''

      for (const o of current.options) {
        if (o.key === formFields[current.key]) {
          value = {
            key: o.key,
            text: o.label || o.text
          }
        }
      }
    }
    return value
  }


  const generateAutoCompleteSelection = (current, formControlParams, errorTag) => {
    const value = processValueToPreFill(current)
    const fieldParams = {
      // fullWidth: true,
      multiple: current.multiSelect,
      options: current.options,
      value: value,
    };
    // fieldParams.disabled = shouldDisable(current)

    return (

      <div key={current.key} className={css(Styles.formSection)} >
        {current.label &&
          <p className={css(Styles.textFieldLabel)}>{current.label}</p>
        }
        <FormControl fullWidth {...formControlParams} >
          <Autocomplete
            {...fieldParams}
            onChange={(e, val) => onAutoCompleteChange(val, current)}
            getOptionLabel={(option) => option.label || option.text}
            classes={{
              paper: css(Styles.listBoxPaper),
              popupIndicator: css(Styles.popupIndicator),
              input: css(Styles.autoCompleteInput),
            }}
            popupIcon={<KeyboardArrowDownIcon className={css(Styles.arrowDownIcon)} />}
            renderInput={params =>
              <TextField
                error={formControlParams.error}
                required={formControlParams.required}
                {...params}
                className={css(Styles.textField)}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  classes: {
                    root: css(Styles.textFieldRootContent),
                    input: css(Styles.textFieldInputContent),
                  }
                }}
                placeholder={current.placeholder} />}
          />
        </FormControl>
      </div>

    )
  }

  const generateFields = (leftFields, rightFields, fullFields, fields) => {
    for (const current of fields) {
      const formControlParams = {};
      let field;
      let errorTag = <FormHelperText>{' '}</FormHelperText>

      // setFormControlParams(current, formControlParams);
      if (!current.valid) {
        errorTag = <FormHelperText>{current.v_required.err}</FormHelperText>;
      }
      switch (current.type) {
      case 'select':
        field = generateAutoCompleteSelection(current, formControlParams, errorTag)
        break;
      default:
        console.error(Helper.getString('unknownFieldType'), current.type)
        continue;
      }
      if (!current.placement) {
        current.placement = 'left' // default
      }
      if (current.placement === 'left') {
        leftFields.push(field);
      } else if (current.placement === 'right') {
        rightFields.push(field);
      }
      else if (current.placement === 'full') {
        fullFields.push(field);
      }
    }
  }

  const generateContent = (leftFields, rightFields, fullFields) => {
    if (fullFields.length > 0) {
      return (<Grid container className={css(Styles.fullWidthContainer)}>
        <Grid className={css(Styles.leftGridPadding)} item xs={12} sm={6}>
          {leftFields}
        </Grid>
        <Grid className={css(Styles.rightGridPadding)} item xs={12} sm={6}>
          {rightFields}
        </Grid>
        <Grid item xs={12}>
          {fullFields}
        </Grid>
      </Grid>
      )
    }
    else if (rightFields.length > 0) {
      return <div>
        <Grid container spacing={2}>
          {leftFields.length > 0 &&
            <Grid className={css(Styles.leftGridPadding)} item xs={6} sm={6}>
              {leftFields}
            </Grid>
          }
          {rightFields.length > 0 &&
            <Grid className={css(Styles.rightGridPadding)} item xs={6} sm={6}>
              {rightFields}
            </Grid>
          }
        </Grid>
      </div>
    } else {
      return <div>
        <Grid container>
          <Grid item xs={12}>
            {leftFields}
          </Grid>
        </Grid>
      </div>
    }
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  return (
    <Fragment>
      <img
        onClick={() => dialogOpen()}
        alt={Helper.getString('filterImgAlt')}
        src={require('../assets/images/filter_icon.svg')}
        width='28px' height='28px'
        className={css(Styles.icon)} />
      <Dialog
        onClose={() => onClose()}
        open={state.dialogOpen || false}
        disableScrollLock={true}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.dialog),
        }}>
        <DialogTitle className={css(Styles.dialogTitleOuteContainer)}>
          <div className={css(Styles.dialogTitleContainer)}>
            <p className={css(Styles.dialogTitle)}>{Helper.getString('sortFilter')}</p>
            <div className={css(Styles.closeIconContainer)} onClick={() => onClose()}>
              <img
                src={require('../assets/images/close_dark_icon.svg')}
                alt={Helper.getString('dialogCloseAlt')}
                height={36} width={36}
                className={css(Styles.closeIcon)} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={css(Styles.dialogContent)}>
          {state.filterFormFields && state.filterFormFields.items && state.filterFormFields.items.length > 0 &&
            state.filterFormFields.items.map((item, index) => {
              const leftFields = [];
              const rightFields = [];
              const fullFields = [];
              generateFields(leftFields, rightFields, fullFields, item.fields)
              return (
                <div className={css(Styles.dialogInnerContent)} key={index}>
                  <p className={css(Styles.titleContent)}>{item.title}</p>
                  {generateContent(leftFields, rightFields, fullFields)}
                </div>
              )
            })}
        </DialogContent>
        <DialogActions className={css(Styles.dialogActions)}>
          <AppButton
            onClick={() => resetData()}
            buttonContainer={[CommonStyles.secondaryButtonContainer, Styles.secondaryButtonContainer]}
            buttonTextStyle={CommonStyles.secondaryButtonText}
            buttonText={Helper.getString('reset')}
          />
          <AppButton
            onClick={() => saveData()}
            buttonText={Helper.getString('apply')}
          />
        </DialogActions>
      </Dialog>
      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  icon: {
    width: 28,
    height: 28,
    ':hover': {
      cursor: 'pointer'
    }
  },
  buttonContainer: {
    borderRadius: AppCommonTheme.borderRadius,
    border: '1px solid' + AppTheme.primaryColor,
    minHeight: 42,
    minWidth: 132
  },
  label: {
    fontSize: 12,
    color: AppTheme.primaryColor,
    margin: AppCommonTheme.pMargin
  },

  dialog: {
    minWidth: 460,
    minHeight: 246,
    borderRadius: AppCommonTheme.borderRadius,
    margin: 10,
    '@media (max-width: 767px)': {
      minWidth: 'unset',
      width: '100%'
    }
  },
  dialogTitleOuteContainer: {
    padding: '40px 40px 10px',
    '@media (max-width: 767px)': {
      padding: '24px 16px 5px',
    }
  },
  dialogTitleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogTitle: {
    fontSize: 16,
    margin: AppCommonTheme.pMargin,
    color: AppCommonTheme.appFontColor,
    flex: 1
  },
  dialogContent: {
    padding: '0px 40px',
    overflow: 'unset',
    '@media (max-width: 767px)': {
      padding: '0px 16px',
    }
  },
  titleContent: {
    fontSize: 22,
    color: AppCommonTheme.appFontColor,
    fontWeight: 500,
    margin: AppCommonTheme.pMargin,
    marginBottom: 10
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 40px 40px',
    '@media (max-width: 767px)': {
      padding: '0px 16px 24px',
    }
  },

  textFieldLabel: {
    fontSize: 14,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin
  },
  textField: {
    border: AppCommonTheme.border,
    borderRadius: 6,
    marginTop: 4,
    marginBottom: 24
  },
  textFieldRootContent: {
    padding: '14px 12px'
  },
  textFieldInputContent: {
    padding: 0
  },
  arrowDownIcon: {
    fontSize: 20,
    color: AppCommonTheme.appFontColor
  },
  popupIndicator: {
    marginRight: 12,
  },
  secondaryButtonContainer: {
    marginRight: 5,
  },

  closeIconContainer: {
    display: 'flex',
    flex: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  closeIcon: {
    width: 36,
    height: 36
  }
})
