import React, { Component, Fragment } from 'react';
import { Controller } from '../common/Controller';
import { UiLoginHelper } from '../common/helpers/UiLoginHelper';
import { Helper } from '../common/helpers/Helper';
import { UsernameInput, PasswordInput, NumericOTPInput, DomainInput } from './TextInputs';
import { StyleSheet, css } from 'aphrodite';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme'
import { HelmetComponent } from '../common/components/HelmetComponent';
import { AppCommonTheme } from '../styles/Styles';
import { ErrorMsg } from '../components/ErrorMsg';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.userNameType = AppConfig.USER_PRIMARY === 'email' ?
      UiLoginHelper.UserNameType.EMAIL : UiLoginHelper.UserNameType.PHONE
    this.state = {
      currentPage: UiLoginHelper.Page.DOMAIN,
      loadingAnimation: false,
      message: '',
      domainName: '',
      userName: '',
      pword: '',
      verificationCode: ''
    };
    this.loginHelper = new UiLoginHelper(this, {
      autoSignUp: false,
      autoLogin: true,
      userNameType: this.userNameType,
      jsonBody: this.props.jsonBody ? this.props.jsonBody : {},
      onSuccessLogin: this.onSuccessLogin.bind(this)
    });
  }

  onSuccessLogin() {
    Controller.get().userMgr().setGuestUser(false);
    //console.log('onSuccessLogin');
    if (this.props.onSuccessLogin) {
      this.props.onSuccessLogin()
      return
    }
    localStorage.setItem('domainName', this.state.domainName.toLowerCase())

    if (Controller.get().userMgr().isProductSU()) {
      window.location.href = Controller.get().roleHelper().getHome()
    } else {
      window.location.href = Controller.get().roleHelper().getLearnerHome()
    }
  }

  closeError = () => {
    this.setState({ message: '' })
  }

  render() {
    return (
      <div className={css(Styles.container)}>
        <div
        // className={css([Styles.loginPanel, this.props.loginPanelStyle])}
        >

          <HelmetComponent page={'login'} />

          {/* <h2>{Helper.getString('login')}</h2> */}
          <div className={css(Styles.loginMessageContainer)}>
            {this.state.message.length > 0 &&
              // <p className='loginMessage'>{this.state.message}</p>
              <ErrorMsg
                open={this.state.message.length > 0}
                errMsg={this.state.message}
                closeError={this.closeError}
              />
            }

          </div>

          {this.state.currentPage === UiLoginHelper.Page.DOMAIN &&
            this.domain()
          }
          {this.state.currentPage === UiLoginHelper.Page.LOOKUP &&
            this.welcomeView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP &&
            this.signUpView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP_OTP &&
            this.signUpOTPView()
          }
          {this.state.currentPage === UiLoginHelper.Page.LOGIN &&
            this.loginView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD &&
            this.resetPasswordView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD_OTP &&
            this.resetPasswordOTPView()
          }
        </div>
      </div>
    );
  }

  domain() {
    return (
      <div>
        <DomainInput
          id='domainName'
          value={this.state.domainName}
          onChange={(event) => this.setState({ domainName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitDomain()
            }
          }}
        />

        <div className={css(Styles.paddingTop34px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitDomain.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            <p className={css(Styles.marginRight)}>Continue</p>
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={15} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
      </div>
    )
  }

  welcomeView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLookup()
            }
          }}
        />

        <div className={css(Styles.paddingTop34px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLookup.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            <p className={css(Styles.marginRight)}>Continue</p>
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={15} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        {this.props.showTerms &&
          <div className={css(Styles.termsContainer)}>
            <span>By continuing, you agree to our </span>
            {(navigator && navigator.userAgent.startsWith('Azsincx')) ?
              <Fragment><span style={{ color: AppConfig.TINT_COLOUR }} onClick={() => this.props.openContentDialog({ key: 'terms' })}>terms</span> and <span style={{ color: AppConfig.TINT_COLOUR }} onClick={() => this.props.openContentDialog({ key: 'privacy' })}>privacy</span></Fragment>
              :
              <Fragment><a href={AppConfig.TERMS} className={css(Styles.linkContainer)} target='_blank'>terms</a> and <a target='_blank' href={AppConfig.PRIVACY} className={css(Styles.linkContainer)}>privacy</a></Fragment>}
          </div>
        }
        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  signUpView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          placeholder={Helper.getString('setPassword')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUp()
            }
          }}
        />

        <div className={css(Styles.paddingTop34px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUp.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            <p className={css(Styles.marginRight)}>Signup</p>
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={15} className={css(Styles.marginLeft)} />}
          </Button>
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  signUpOTPView() {
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          onChange={(event) => this.setState({ verificationCode: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUpConfirm()
            }
          }} />

        <div className={css(Styles.paddingTop34px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUpConfirm.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            <p className={css(Styles.marginRight)}>Submit</p>
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={15} className={css(Styles.marginLeft)} />}
          </Button>
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  loginView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLogin()
            }
          }} />

        {/* <button className="loginButton"
                    onClick={this.loginHelper.onSubmitLogin.bind(this.loginHelper)}>LOGIN</button> */}
        <div className={css(Styles.paddingTop34px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLogin.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            <p className={css(Styles.marginRight)}>Login</p>
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={15} className={css(Styles.marginLeft)} />}
          </Button>
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onResetPasswordClicked.bind(this)}><p>Reset password? Get OTP</p></a>
        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    );
  }

  resetPasswordView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitResetPassword()
            }
          }} />

        {/* <button className="loginButton"
                        onClick={this.loginHelper.onSubmitResetPassword.bind(this.loginHelper)}>GET OTP</button> */}
        <div className={css(Styles.paddingTop34px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitResetPassword.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            <p className={css(Styles.marginRight)}>Get OTP</p>
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={15} className={css(Styles.marginLeft)} />}
          </Button>
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  resetPasswordOTPView() {
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          onChange={(event) => this.setState({ verificationCode: event.target.value })} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          placeholder={Helper.getString('setNewPassword')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitConfirmResetPassword()
            }
          }} />

        {/* <button className="loginButton"
                    onClick={this.loginHelper.onSubmitConfirmResetPassword.bind(this.loginHelper)}>SUBMIT</button> */}
        <div className={css(Styles.paddingTop34px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitConfirmResetPassword.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            <p className={css(Styles.marginRight)}>Submit</p>
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={15} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  onStartoverClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.DOMAIN);
  }

  onResetPasswordClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.RESET_PASSWORD);
  }
}

const Styles = StyleSheet.create({
  container: {
    // backgroundColor: AppTheme.secondaryBg,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%'
  },
  loginPanel: {
    borderRadius: 8,
    backgroundColor: AppTheme.primaryBg,
    textAlign: 'center',
    width: 300,
    padding: 20,
    //margin: 10,
    '@media (max-width: 767px)': {
      width: 280,
      padding: 10,
      margin: 5,
    },
    '@media (max-width: 320px)': {
      width: 240,
      padding: 10,
      margin: 5
    }
  },
  loginMessageContainer: {
    margin: 'auto'
  },
  loginMessage: {
    marginTop: 10,
    fontSize: 14,
    color: 'black'
  },
  loginLink: {
    marginTop: 10,
    cursor: 'pointer',
    color: 'black',
    fontSize: 14,
    textAlign: 'center'
  },
  paddingTop34px: {
    paddingTop: 34,
    '@media (max-width: 767px)': {
      paddingTop: 28
    },
  },
  marginRight: {
    margin: 0,
    marginRight: 10
  },
  termsContainer: {
    marginTop: 10,
    fontSize: 14,
    textAlign: 'center',
    color: AppCommonTheme.appFontColor
  },
  buttonStyle: {
    boxShadow: 'none',
    backgroundColor: AppTheme.buttonBg,
    color: AppTheme.buttonTextColor,
    borderRadius: AppCommonTheme.borderRadius,
    textAlign: 'center',
    letterSpacing: 0,
    textTransform: 'uppercase',
    opacity: 1,
    fontSize: 18,
    padding: '16px 10px',
    height: 64,
    '@media (max-width: 767px)': {
      fontSize: 16,
      padding: 10,
      height: 48,
    },
  },
  linkContainer: {
    textDecoration: 'unset'
  }
})
