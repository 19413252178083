import React, { useEffect, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';
import { CommonStyles } from '../styles/Styles';
import { HelmetComponent } from '../common/components/HelmetComponent';
import { Helper } from '../common/helpers/Helper';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { AppContext } from '../common/components/contexts/AppContext';
import { AppConfig } from '../AppConfig';

export function Privacy(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)

  useEffect(() => {
    appContextStateHandler({ pageKey: 'profile' })
  }, [])

  return (
    <Grid container className={css(CommonStyles.textfulPageContent)} style={props.learner ? { paddaing: 0 } : { padding: '0px 20px' }}>

      <HelmetComponent page={'privacy'} />

      <h1 className={css(CommonStyles.headerTitle)}>Privacy policy</h1>

      <p className={css(CommonStyles.textfulPagePara)}>
        There are many different ways you can use our services – to create content, share information and communicate with other people.</p>

      <p className={css(CommonStyles.textfulPagePara)}>When you share information with us, for example, by creating an account with us, we can make those services even better – to help you connect with people or to make sharing with others quicker and easier. As you use our services, we want you to be clear how we’re using information and the ways in which you can protect your privacy.
      </p>

      <p className={css(CommonStyles.textfulPagePara)}>This Privacy Policy ("Privacy Policy" or "Policy") explains:
      </p>
      <ul className={css(CommonStyles.textfulPagePara)}>
        <li>What information we collect and why we collect it.
        </li>
        <li>How we use that information. </li>
        <li>The choices we offer, including how to access and update information.
        </li>
      </ul>

      <p className={css(CommonStyles.textfulPagePara)}>
        We’ve tried to keep it as simple as possible, but if you’re not familiar with terms like cookies, IP addresses and browsers, then read about these key terms first. Your privacy matters to us; whether you are new to SincX or a long-time customer, please do take the time to get to know our practices – and if you have any questions, contact us at <span><a href={'mailto:' + AppConfig.CONTACT_EMAIL}>{AppConfig.CONTACT_EMAIL}</a></span>.
      </p>


      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Transparency and choice</h2>
      <p className={css(CommonStyles.textfulPagePara)}>People have different privacy concerns. Our goal is to be clear about what information we collect, so that you can make meaningful choices about how it is used and control who you share information with. By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of this Site or the products and/or services offered on or through it.
        You may also set your browser to block all cookies, including cookies associated with our services, or to indicate when a cookie is being set by us. However, it’s important to remember that many of our services may not function properly if your cookies are disabled. For example, you may not be able to login to SincX App or utilise other services. </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>What information we collect
      </h2>
      <p className={css(CommonStyles.textfulPagePara)}>We collect information to provide better services to all of our users – from figuring out basic stuff like which language you speak, to more complex things like what content matters most to you.
      </p>
      <p className={css(CommonStyles.textfulPagePara)}>We collect information in the following ways: </p>
      <ul className={css(CommonStyles.textfulPagePara)}>
        <li>Information you give us. We collect information about you and your company as you register for an account with us, create or modify your profile, use, access or interact with our services or websites (including but not limited to when you upload, download, collaborate on or share content, including photos and videos). Such content includes any personal information or other sensitive information that you choose to include. For example, when you sign up for an account with us, we may ask you to provide us with certain information, including but not limited to first name, last name and email address. We may present your name or  email address to other users in your organisation, or otherwise associated with your account in order to assist in sharing or recommendations. You may also choose to sign up on our website for webinars or to receive more information about our products and services.
        </li>
        <li>Information we get from your use of our services. As with most websites and technology services delivered over the Internet, our servers automatically collect information when you access or use the Service and record it in log files. This information includes: </li>
        <ul>
          <li>Device information: We may collect device-specific information (such as your hardware model, operating system version, unique device identifiers, and mobile network information, which, for some users may include a phone number). We may associate your device identifiers or phone number with your account.
          </li>
          <li>Log Information: When you use our services or view content on our services, we may automatically collect and store certain information in server logs.
          </li>
          <li>This may include: details of how you used our service, such as search queries; your Internet Protocol (IP) address; browser type and settings;
            the date and time the service was used; information about browser configuration, plugins, crashes, system activity and hardware settings; language preferences; and cookie data that may uniquely identify your browser or your account.
          </li>
          <li> Unique application numbers – Certain services include a unique application number. This number and information about your installation (for example, the operating system type and application version number) may be sent to us when you install or uninstall that service or when that service periodically contacts our servers, such as for automatic updates.
          </li>
          <li> Local storage – We may collect and store information (including personal information) locally on your device using mechanisms such as browser web storage (including HTML 5) and application data caches.
          </li>
          <li>Cookies and anonymous identifiers – We use various technologies to collect and store information when you visit our services, and this may include sending one or more cookies or anonymous identifiers to your device.
          </li>
        </ul>
        <li>Information we get from your employer. We may also receive your information from your employer (our client), who will have a contractual relationship with SincX, to provide learning to you (its employees).
        </li>
      </ul>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>What do we do with your information?
      </h2>
      <p className={css(CommonStyles.textfulPagePara)}>We may use the information we collect, including your personal information and transaction information, from all our services in any one or more of the locations that SincX operates or otherwise conducts business (these locations currently being Canada & India) for the following purposes: </p>
      <ul className={css(CommonStyles.textfulPagePara)}>
        <li>for internal and service-related purposes, such as to provide, maintain, protect, improve, and personalize our services, to develop new ones and to protect the rights, property, or safety of SincX and our users;</li>
        <li>to communicate with you in order to provide you with information we think may be useful for relevant to you (including communicating with you about billing, account management services and administrative matters, delivering customer support and sending you prizes that you may have won through your activity on the service); </li>
        <li>to promote our services and related services;
        </li>
        <li>to analyse information in order to offer aggregated anonymised data products to third parties including for research, marketing, analytics, and other purposes;
        </li>
        <li>to enforce the Privacy Policy and our terms of service for use of our services and website;
        </li>
        <li>to monitor and analyse trends, usage, and activities in connection with our services and for marketing or advertising purposes or to offer to you tailored content; </li>
        <li>to investigate and prevent fraudulent transactions, unauthorised access to or use of our services, and other illegal or unusual activities;
        </li>
        <li>when you contact us, to keep a record of your communication to help solve any issues you might be facing. We may use your email address to inform you about our services, such as letting you know about upcoming changes or improvements;
        </li>
        <li>from cookies and other technologies, to improve your user experience and the overall quality of our services; and
        </li>
        <li>as otherwise stated in this Privacy Policy.
        </li>
      </ul>
      <p className={css(CommonStyles.textfulPagePara)}>To use the information we collect, we may require our systems to access, screen capture, store, video and/or scan your information. Where appropriate or required by data protection laws, we will ask for your consent before using information for a purpose other than those that are set out in this Privacy Policy.</p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>How long do we keep your data?</h2>
      <p className={css(CommonStyles.textfulPagePara)}>We will retain your personal information for the time necessary to provide the services we perform for you, or to achieve other purposes outlined in this Privacy Policy, and you can always request that we stop processing or delete your personal information.
        We may be required to keep some of your information for certain periods of time under law. When we no longer require your information, we will ensure that your information is destroyed or de-identified.
      </p>
      <p className={css(CommonStyles.textfulPagePara)}>We may need to retain certain personal information after we cease providing you with products or services to enforce our terms, for fraud prevention, to identify, issue or resolve legal claims and/or for proper record keeping.</p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Disclosure of personal information outside Canada
      </h2>
      <p className={css(CommonStyles.textfulPagePara)}>
        SincX processes personal information on our servers in many countries around the world. We may process your personal information on a server located outside the country where you live.
        We may disclose personal information outside of Canada to third parties for the purposes outlined in this Privacy Policy. You can request a list of sub-processors currently engaged by SincX and authorised by you by emailing us at <span><a href={'mailto:' + AppConfig.CONTACT_EMAIL}>{AppConfig.CONTACT_EMAIL}</a></span>.
        By providing us with personal information, you consent to the disclosure of your personal information to third parties who reside outside Canada. Where we disclose your personal information to third parties, we will take reasonable steps to ensure that any overseas recipient will deal with such information in a way that is consistent with the Canadian Privacy Principles.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Information you share
      </h2>
      <p className={css(CommonStyles.textfulPagePara)}>Many of our services let you share information with others. Remember that when you share information publicly, it may be indexable by search engines. Our services provide you with different options on sharing and removing your content.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Information we share
      </h2>
      <p className={css(CommonStyles.textfulPagePara)}>We do not share personal information with third parties unless one of the following circumstances apply: </p>
      <ul className={css(CommonStyles.textfulPagePara)}>
        <li>With your consent: We will share personal information with companies, organisations or individuals outside of SincX where we have your consent to do so.
        </li>
        <li>With account administrators (such as your employer): If your account is managed for you by an organisation administrator (such as your employer) then your organisation’s administrator and resellers who provide support to your organisation will have access to your information (including your email and other data). Your account administrator may be able to:</li>
        <ul className={css(CommonStyles.textfulPagePara)}>
          <li>view statistics regarding your account;
          </li>
          <li>change your account password;</li>
          <li>suspend or terminate your account access; </li>
          <li>access or retain information stored as part of your account;
          </li>
          <li>receive your account information in order to satisfy applicable law, regulation, legal process or enforceable governmental request;
          </li>
          <li>restrict your ability to delete or edit information.
          </li>
        </ul>
        <li>With third party service providers: We may disclose personal information to:</li>
        <ul className={css(CommonStyles.textfulPagePara)}>
          <li>third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage or management, web-hosting and server providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, customer lifecycle management providers, data analytics or reporting providers, finance systems, cyber security providers, project management or collaboration providers, testing, research and alert tools, email service providers and payment system operators; </li>
          <li>our existing or potential agents or business partners;
          </li>
          <li>third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to you.</li>
        </ul>
        <li>To address fraud, security or technical issues: We will share your personal information with trusted third parties where necessary to detect, prevent or otherwise address fraud, security or technical issues.
        </li>
        <li>For legal reasons
          : We may disclose your information if required by applicable law, regulation or as part of any actual or prospective legal process (including if reasonably necessary to enforce applicable Terms of Service or in order to establish, exercise or defend our legal rights). If we receive a request from a regulatory body or law enforcement agency, and if permitted under GDPR, the CCPA and other laws, we may disclose certain information to such bodies or agencies.
        </li>
        <li>Merger or acquisition: If we merge with or are acquired by another business, we may share your personal information with the new owners of the business and their advisors. We will continue to ensure the confidentiality of any personal information and give affected users notice (for example, via a message to the email address associated with your account) before personal information is transferred or becomes subject to a different privacy policy.
          We may share aggregated, non-personally identifiable information publicly and with our partners – like publishers, advertisers or connected sites. For example, we may share information publicly to show trends about the general use of our services. This could also include government bodies, industry groups, insurers and educational/training facilities.
        </li>
      </ul>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}> Information security
      </h2>
      <p className={css(CommonStyles.textfulPagePara)}>We have put in place robust measures regarding the security of the information we collect and store about you (including through the use of network and database security measures) and will use our reasonable endeavours to protect your personal data from unauthorised access to or unauthorised alteration, disclosure or destruction. In particular:
      </p>
      <ul className={css(CommonStyles.textfulPagePara)}>
        <li>We encrypt many of our services using Secure Sockets Layer (SSL) or Transport Layer Security (TLS).
        </li>
        <li>We review our information collection, storage and processing practices, including physical security measures, to guard against unauthorised access to systems.</li>
        <li>We restrict access to personal information to our employees, contractors and agents who need to know that information in order to process it for us, and who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
        </li>
        <li>We have multiple authentication and access control measures to ensure data is only accessed by authorised personnel.
        </li>
        <li>We enforce strong encryption of all data at rest through the use of the Advanced Encryption Standard (AES-256).
        </li>
      </ul>
      <p className={css(CommonStyles.textfulPagePara)}>The transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our servers via third party networks; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Application
      </h2>
      <p className={css(CommonStyles.textfulPagePara)}>Our Privacy Policy applies to all of the services offered by SincX and its affiliates, but excludes services that have separate privacy policies that do not incorporate this Privacy Policy.
        Our Privacy Policy does not apply to services offered by other companies or individuals, including products or sites that may be displayed to you in search results, sites that may include SincX services, or other sites linked from our services. Our Privacy Policy does not cover the information practices of other companies and organizations who advertise our services.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Enforcement </h2>
      <p className={css(CommonStyles.textfulPagePara)}>We regularly review our compliance with our Privacy Policy. We also adhere to several self regulatory frameworks. When we receive formal written complaints, we will contact the person who made the complaint to follow up. We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that we cannot resolve with our users directly.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Restrict </h2>
      <p className={css(CommonStyles.textfulPagePara)}>You may choose to restrict the collection or use of your personal information.  If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Access</h2>
      <p className={css(CommonStyles.textfulPagePara)}>You may request details of the personal information that we hold about you. An administrative fee may be payable for the provision of such information. In certain circumstances, as set out in the Privacy Act, we may refuse to provide you with personal information that we hold about you.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Correction</h2>
      <p className={css(CommonStyles.textfulPagePara)}>If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Complaints</h2>
      <p className={css(CommonStyles.textfulPagePara)}>If you believe that we have breached the Canadian Privacy Principles and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>External Links
      </h2>
      <p className={css(CommonStyles.textfulPagePara)}>Our service may contain links to other sites that are not operated by us. If you click on a third- party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of (including information that is submitted to or collected by)  any third-party sites or services.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Children's Privacy
      </h2>
      <p className={css(CommonStyles.textfulPagePara)}>Our services are not designed to appeal to minors. We do not knowingly attempt to solicit or receive any information from anyone under the age of 13. If we discover that a child under 13 has provided us with personal information, we will immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us immediately at <span><a href={'mailto:' + AppConfig.CONTACT_EMAIL}>{AppConfig.CONTACT_EMAIL}</a></span>.
      </p>
      <h2 className={css(CommonStyles.textfulPageSubtitle)}>Changes</h2>
      <p className={css(CommonStyles.textfulPagePara)}>We may revise this Privacy Policy from time to time and will post the most current version on our website. If a revision meaningfully reduces your rights or involves a material change to our processing your personal information, we will notify you. </p>
    </Grid>
  )
}

const Styles = StyleSheet.create({

})
