import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { UiLoginHelper } from '../common/helpers/UiLoginHelper';
import { Helper } from '../common/helpers/Helper';
import TextField from '@material-ui/core/TextField';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme } from '../styles/Styles';

export class NumericOTPInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FormControl fullWidth className={css(Styles.textFieldContainer)}>
        <p className={css(Styles.textFieldLabel)} htmlFor='OTP'>{Helper.getString('otp')}</p>
        <TextField
          {...this.props}
          type='password'
          placeholder={Helper.getString('enterOTP')}
          className={css(Styles.textField)}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: css(Styles.textFieldRootContent),
              input: css(Styles.textFieldInputContent),
            }
          }} />
      </FormControl>
    )
  }
}

export class PasswordInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FormControl fullWidth className={css(Styles.textFieldContainer)}>
        <p className={css(Styles.textFieldLabel)} htmlFor='password'>{this.props.label ? this.props.label : Helper.getString('password')}</p>
        <TextField
          {...this.props}
          type='password'
          placeholder={this.props.placeholder ? this.props.placeholder : Helper.getString('enterPassword')}
          className={css(Styles.textField)}
          InputProps={{
            disableUnderline: true,
            autoCapitalize: 'none',
            classes: {
              root: css(Styles.textFieldRootContent),
              input: css(Styles.textFieldInputContent),
            }
          }} />
      </FormControl>
    )
  }
}

export class UsernameInput extends Component {
  constructor(props) {
    super(props);
    this.userNameType = this.props.userNameType
  }

  render() {
    return (
      <FormControl fullWidth className={css(Styles.textFieldContainer)}>
        {this.userNameType === UiLoginHelper.UserNameType.PHONE ?
          <p className={css(Styles.textFieldLabel)} htmlFor='mobile-number'>{Helper.getString('mobile')}</p>
          :
          <p className={css(Styles.textFieldLabel)} htmlFor='email' >{Helper.getString('email')}</p>
        }
        <TextField
          {...this.props}
          placeholder={this.userNameType === UiLoginHelper.UserNameType.PHONE ?
            Helper.getString('enterMobile') : Helper.getString('enterEmail')}
          className={css(Styles.textField)}
          InputProps={{
            disableUnderline: true,
            autoCapitalize: 'none',
            classes: {
              root: css(Styles.textFieldRootContent),
              input: css(Styles.textFieldInputContent),
            }
          }} />
      </FormControl>
    )
  }
}

export class DomainInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FormControl fullWidth className={css(Styles.textFieldContainer)}>
        <p className={css(Styles.textFieldLabel)} htmlFor='domain' >{Helper.getString('domain')}</p>
        <TextField
          {...this.props}
          placeholder={Helper.getString('enterDomain')}
          className={css(Styles.textField)}
          InputProps={{
            disableUnderline: true,
            autoCapitalize: 'none',
            classes: {
              root: css(Styles.textFieldRootContent),
              input: css(Styles.textFieldInputContent),
            }
          }} />
      </FormControl>
    )
  }
}

const Styles = StyleSheet.create({
  textFieldContainer: {
    marginTop: 30,
    '@media (max-width: 767px)': {
      marginTop: 28
    },
  },
  textFieldLabel: {
    fontSize: 18,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginBottom: 13,
    '@media (max-width: 767px)': {
      fontSize: 16,
      marginBottom: 8
    },
  },
  textField: {
    border: AppCommonTheme.border,
    borderRadius: 12,
  },
  textFieldRootContent: {
    padding: 20,
    '@media (max-width: 767px)': {
      padding: 12,
    },
  },
  textFieldInputContent: {
    padding: 0,
    WebkitBoxShadow: '0 0 0 1000px white inset',
  },
})