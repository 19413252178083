import React, { useState, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CheckBoxComponent } from './CheckBoxComponent';
import { AppButton } from './AppButton';
import { ErrorMsg } from './ErrorMsg';
import { Controller } from '../common/Controller';
import DialogTitle from '@material-ui/core/DialogTitle';

export function Consents(props) {
  const [state, setState] = useState({
    selectedConsents: {},
    showAnswer: false,
  })
  let countAnswer = 0

  const onClose = () => {
    setState({
      ...state,
      selectedConsents: {},
      showAnswer: false,
    })
    props.onClose()
  }

  const selectedValue = (values) => {
    setState({
      ...state,
      selectedConsents: values
    })
  }

  const submitConsent = async () => {
    if (state.selectedConsents === undefined || Object.keys(state.selectedConsents).length === 0) {
      setState({
        ...state,
        openError: true,
        errorMsg: Helper.getString('pleaseSelectAllOptions')
      })
    } else {
      Object.keys(state.selectedConsents).map(item => {
        if (state.selectedConsents[item] && state.selectedConsents[item] === true) {
          countAnswer = countAnswer + 1
        }
      })
      if (countAnswer === Object.keys(props.data.consents.options).length) {
        setState({
          ...state,
          inProcess: true,
          showAnswer: true
        })
        try {
          for (const item in state.selectedConsents) {
            const consentResponse = await Controller.get().courseMgr().submitConsent(item, props.courseprogs)
          }
        } catch (e) {
          setState({ ...state, openError: true, errorMsg: Helper.getErrorMsg(e), inProcess: false })
          return
        }
        setState({
          ...state,
          inProcess: false,
          showAnswer: false,
        })
        props.onSubmit()
      } else {
        setState({
          ...state,
          openError: true,
          errorMsg: Helper.getString('pleaseSelectAllOptions')
        })
      }
    }
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  return (
    <Fragment>
      <Dialog
        onClose={() => onClose()}
        open={props.dialogOpen || false}
        disableScrollLock={true}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.dialog),
        }}>
        <DialogTitle className={css(Styles.dialogTitleOuteContainer)}>
          <div className={css(Styles.dialogTitleContainer)}>
            <p className={css(Styles.dialogTitle)}>{Helper.getString('consents')}</p>
            <div className={css(Styles.closeIconContainer)} onClick={() => onClose()}>
              <img
                src={require('../assets/images/close_dark_icon.svg')}
                alt={Helper.getString('dialogCloseAlt')}
                height={36} width={36}
                className={css(Styles.closeIcon)} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={css(Styles.dialogContent)}>
          <CheckBoxComponent
            dialog={true}
            data={props.data.consents}
            // title={Helper.getString('consents')}
            selectedValue={selectedValue}
            dataSend={true}
            showAnswer={state.showAnswer}
            hideCheckbox={props.data.completedConsents} />
        </DialogContent>
        <DialogActions className={css(Styles.dialogActions)}>
          {props.data.completedConsents ?
            // <p className={css(Styles.consentCompleted)}>{Helper.getString('completedConsents')}</p>
            ''
            :
            <AppButton
              onClick={() => submitConsent()}
              inProcess={state.inProcess}
              buttonText={Helper.getString('submit')}
            />
          }
        </DialogActions>
      </Dialog>
      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  dialog: {
    minWidth: 625,
    borderRadius: AppCommonTheme.borderRadius,
    margin: 10,
    '@media (max-width: 767px)': {
      minWidth: 'unset',
      width: '100%'
    }
  },
  dialogContent: {
    padding: '0px 40px 0px',
    paddingBottom: 10,
    overflow: 'unset',
    '@media (max-width: 767px)': {
      padding: '10px 16px',
      paddingBottom: 10,
    }
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 40px 40px',
    '@media (max-width: 767px)': {
      padding: '0px 16px 24px',
    }
  },
  consentCompleted: {
    fontSize: 16,
    color: AppCommonTheme.appFontColor,
    margin: 0,
  },

  dialogTitleOuteContainer: {
    padding: '40px 40px 10px',
    '@media (max-width: 767px)': {
      padding: '24px 16px 5px',
    }
  },
  dialogTitleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  closeIconContainer: {
    display: 'flex',
    flex: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  closeIcon: {
    width: 36,
    height: 36
  },

  dialogTitle: {
    fontSize: 26,
    marginBottom: 16,
    color: AppCommonTheme.appFontColor,
    fontWeight: 600,
    margin: AppCommonTheme.pMargin,
    flex: 1,
    '@media(max-width: 767px)': {
      fontSize: 22,
    },
  },
})
