import React, { useState, useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { AppTheme } from '../styles/AppTheme';
import { Controller } from '../common/Controller';
import { AppHelper } from '../helpers/AppHelper';
import { HeaderDropdown } from './HeaderDropdown';
import { AppConfig } from '../AppConfig';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AppContext } from '../common/components/contexts/AppContext';
import { DrawerMenuPublic } from './DrawerMenuPublic';
import { MobileFooter } from '../components/MobileFooter';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
    width: `calc(100% - ${theme.spacing(7) + 30}px)`,
  },
  appBarShift: {
    width: `calc(100% - ${AppTheme.drawerWidth + 12}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

function HeaderInternal(props) {
  const [state, setState] = useState({
    anchorEl: null,
    drawerIsOpen: window.innerWidth <= 1024 ? false : true,
  })
  const items = AppHelper.getDrawerMenuItems()
  const { appContextStateHandler } = useContext(AppContext)

  const handleMenu = event => {
    setState({ ...state, anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setState({ ...state, anchorEl: null });
  };

  const drawerIsOpen = (value) => {
    setState({
      ...state, drawerIsOpen: value
    })
    appContextStateHandler({ toggleDrawer: value })
  }

  const showMobileFooter = () => {
    if (props.disableFooter) {
      return null
    } else if (props.preview) {
      return null
    }
    return <MobileFooter items={items} />
  }

  const appUser = Controller.get().userMgr().getAppUser()
  const { anchorEl } = state;
  const open = Boolean(anchorEl);
  const { classes, theme } = props;

  return (
    <Fragment>
      <AppBar
        color='primary' position='fixed' elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: state.drawerIsOpen,
        })}>
      </AppBar>

      <div className={css(Styles.drawerDektopView)}>
        {items && items.length > 0 &&
          <DrawerMenuPublic
            items={items}
            location={props.location}
          />
        }
      </div>
      <div className={css(Styles.mobileFooter)}>
        {showMobileFooter()}
      </div>
    </Fragment>
  );
}

HeaderInternal.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

const Styles = StyleSheet.create({
  appbar: {
    backgroundColor: 'white', // Lets keep white admin bar always
  },
  toolbar: {
    minHeight: AppTheme.toolbarHeight,
    paddingLeft: 10,
    paddingRight: 10,
  },
  logoImage: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  logoContainer: {
    flex: 1,
    textAlign: 'left'
  },
  logoImageContainer: {
    height: 40
  },
  drawerMobileView: {
    display: 'none',
    '@media(max-width: 767px)': {
      display: 'block'
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      display: 'block'
    }
  },
  drawerDektopView: {
    display: 'block',
    '@media(max-width: 1024px)': {
      display: 'none'
    }
  },

  mobileFooter: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
    }
  },
})

export const Header = withStyles(styles, { withTheme: true })(HeaderInternal);
