/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
export const AppConfigGen = {
  "ADMIN_DRAWER_MENU_ENABLED": true,
  "ORGSU_ACCESS_ALLOWED": true,
  "APPAK": "web-75d73bbc-dfec-11ec-9d64-0242ac120002",
  "CLOUD_REGION": "us-east-1",
  "COGNITO_USER_POOL": "us-east-1_IjoV6U6OM",
  "COGNITO_USER_POOL_CLIENT_ID": "49kbqe33l5fvld4b00k0dlrc63",
  "COMPANY_NAME": "SincX Technologies Inc",
  "DEFAULT_COUNTRY": "IN",
  "DEFAULT_CURRENCY": "INR",
  "DEFAULT_LANG": "en",
  "DEFAULT_ZONEINFO": "Asia/Calcutta",
  "ENDPOINT": "https://yifw5g3zva.execute-api.us-east-1.amazonaws.com/Prod",
  "NAME": "SincX",
  "PASSWORD_POLICY": "Password should be minumum 6 characters with numbers and letters.",
  "SUPPORT_EMAIL": "admin@sincxapp.com",
  "TINT_COLOUR": "#4B8BE9",
  "USER_PRIMARY": "email",
  "WEBAPP_LINK": "https://d37woh9mydma00.cloudfront.net"
}