module.exports = `
<!DOCTYPE html>
<html>

<head>
    <link href='https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap' rel='stylesheet'>

    <style>
    .container {
        // display: -webkit-box;
        // -webkit-box-pack: center;
        // width: 100%;
        display: flex
    }
    @media only screen and (max-device-width: 767px) {
        .container {
            display: flex;
            scale: .35;
        }
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .container {
            display: flex;
            scale: .70;
        }
      }

        .mainOuterContainer {
            display: -webkit-box;
            -webkit-box-pack: center;
            font-family: 'Raleway', sans-serif;
        }
        .mainInnerContainer {
            padding: 10px;
            width: 832px;
            min-height: 585px;
            display: -webkit-box;
        }

        .borderFirst {
            border: 2px solid #DFC578;
            padding: 14px;
            background-color: #F9F9F9;
            display: -webkit-box;
            -webkit-box-flex: 1;
        }

        .borderSecond {
            border: 6px solid #AAABAF;
            background-color: #FFFFFF;
            display: -webkit-box;
            -webkit-box-flex: 1;
        }

        .trophyOuterContainer {
            margin: 0px 50px 0px 62px;
            border-Left: 4px solid #DFC578;
            border-right: 4px solid #DFC578;
            background-color: #F9F9F9;
            padding: 36px;
            position: relative;
        }

        .trophyInnerContainer {
            margin-top: 74px;
            -webkit-box-flex: 1;
            margin-right: 160px;
        }

        .title {
            font-size: 32px;
            font-weight: 500;
            margin: 0px;
            color: #121212;
        }

        .name {
            font-size: 18px;
            font-weight: 500;
            margin: 0px;
            margin-top: 6px;
            color: #121212;
        }

        .course {
            font-size: 24px;
            font-weight: 600;
            margin: 0px;
            margin-top: 60px;
            color: #121212;
        }

        .courseDetailContainer {
            display: -webkit-box;
            -webkit-box-align: center;
            margin-top: 6px;
        }

        .courseDetails {
            font-size: 14px;
            font-weight: 500;
            margin: 0px;
            color: #121212;
        }

        .dot {
            padding: 6px;
            background-color: #AAABAF;
            border-radius: 50%;
            margin: 0px 16px
        }

        .descr {
            font-size: 12px;
            font-weight: 400;
            margin: 0px;
            color: #121212;
            margin-top: 40px;
        }

        .company {
            font-size: 18px;
            font-weight: 700;
            margin: 0px;
            color: #121212;
            margin-top: 57px;
        }
        .trophyContainer {
            border: 2px solid #DFC578;
            background-color: #FFFFFF;
            width: 120px;
            height: 120px;
            display: -webkit-box;
            -webkit-box-pack: center;
            -webkit-box-align: center;
            position: absolute;
            top: 116px;
            left: -25px;
            border-radius: 12px;
        }
        .trophyImgInnerContainer {
            width: 64px;
            height: 64px;
            display: -webkit-box;
            -webkit-box-pack: center;
            -webkit-box-align: center;
        }
        .trophyImg {
        max-width: 100%;
        max-height: 100%
        }
    </style>
</head>

<body>
<div class='container'>
    <div class='mainInnerContainer'>
        <div class='borderFirst'>
            <div class='borderSecond'>
                <div class='trophyOuterContainer'>
                    <div class='trophyContainer'>
                    <div class='trophyImgInnerContainer'>
                        <img src={{image}} class='trophyImg' />
                        </div>
                    </div>
                </div>
                <div class='trophyInnerContainer'>
                    <h1 class='title'>{{templateName}}</h1>
                    <p class='name'>Congratulations, <span>{{name}}</span> </p>
                    <p class='course'>{{courseName}}</p>
                    <div class='courseDetailContainer'>
                        <p class='courseDetails'>Course completed on {{completedDate}}</p>
                        <div class='dot'></div>
                        <p class='courseDetails'>Duration: {{duration}} min</p>
                    </div>
                    <p class='company'>{{companyName}}</p>
                </div>
            </div>
        </div>
    </div>
    </div>
</body>

</html>`