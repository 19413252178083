
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';

import { StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { Controller } from '../../../common/Controller';

export class CoursesByCat extends BaseEntityPage {

  constructor(props) {
    super(props)
    this.orgId = this.props.orgId ? this.props.orgId : (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.catId = this.props.catId ? this.props.catId : this.props.match.params.catId
    this.pageTitle = Helper.getString('courses')
    this.addNewLabel = 'Add'
    this.pageKey = 'CoursesByCat'
    this.noAdd = true
    this.breadCrumbs = this.props.teams ?
      [
        { title: Helper.getString('home'), to: this.baseRoute() },
        { title: Helper.getString('groups'), to: this.baseRoute() + '/teams' },
        { title: this.pageTitle },
      ]
      :
      [
        { title: Helper.getString('home'), to: this.baseRoute() },
        { title: Helper.getString('categories'), to: this.baseRoute() + '/cats' },
        { title: this.pageTitle },
      ]
    if (this.props.teams) {
      this.toFetch = [
        { method: 'GET', paths: ['miscs', 'courses'], queryParams: { orgId: this.orgId, subOrgId: this.catId } },
      ]
    } else {
      const slug = this.props.catagoryDetails && this.props.catagoryDetails.slug ? this.props.catagoryDetails.slug : ''
      this.toFetch = [
        { method: 'GET', paths: ['miscs', 'courses'], queryParams: { orgId: this.orgId, catSlug: slug } },
        { method: 'GET', paths: ['ccats', this.catId] },
      ]
    }
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      'Slug / ID': (current) => {
        return UiHelper.itemDetails(current)
      },
      Title: (current) => current.title,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
    }
  }

  onFetchSuccess(results, options) {
    if (this.props.teams) {
      const { fetchNext } = options || {}
      this.handleNextpage(results[0])
      this.setState({
        items: Helper.sortDictArray(fetchNext ? this.state.items.concat(results[0].items) : results[0].items),
      })
      this.pageTitle = this.props.courseDetails.title
    } else {
      const { fetchNext } = options || {}
      this.handleNextpage(results[0])
      const items = Helper.sortDictArray(fetchNext ? this.state.items.concat(results[0].items) : results[0].items)
      let mItems = []
      if(Controller.get().userMgr().isProductSU() ||  Controller.get().roleHelper().isOrgSU() || Controller.get().roleHelper().isOrgManagerUser()) {
        mItems = items
      } else if(Controller.get().roleHelper().isOrgCreatorUser()) {
        const appUser = Controller.get().userMgr().getAppUser()
        const userId = appUser.userId
        for(const i of items) {
          if(i.ownerIds && i.ownerIds.indexOf(userId) > -1 || i.authorIds && i.authorIds.indexOf(userId) > -1) {
            mItems.push(i)
          }
        }
      }
      this.setState({
        items: mItems,
        item: results[1]
      })
      this.pageTitle = this.state.item.title
    }
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []
    return actions
  }

  onMenuItems(current) {
    const actions = []
    const menuItems = [
      {
        current: current,
        key: current.id + '-courses',
        link: this.baseRoute() + '/orgcourses/' + current.orgId + '-' + current.courseId + '/details',
        state: current,
        title: 'Explore',
        icon: 'subdirectory_arrow_right'
      }
    ]
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})