import { MockData } from '../data/MockData'

export class MockHelper {

  call(options, callback) {
    setTimeout(() => {
      return this._call(options, callback)
    }, 100)
  }

  hasMock(options) {
    const Url = require('url-parse')
    const url = new Url(options.endPoint)

    const chapterId = window.location.pathname.split('/')
    const id = chapterId[3]

    let courseSlug = 'assigned'
    if (window.location.search.replace('?courses=', '')) {
      courseSlug = window.location.search.replace('?courses=', '')
    }

    // const params = new URLSearchParams(window.location.search)
    // for (const param of params) {
    //   console.log(param)
    // }
    let data
    // console.log(url.href.split('?')[1]['chapterId'],'paramsparams')
    if (url.pathname.endsWith('/miscs/dashboard')) {
      data = MockData.dashboard
    } else if (url.pathname.endsWith('/miscs/explore')) {
      data = MockData.explore
    } else if (url.pathname.endsWith('/miscs/courses')) {
      data = MockData[courseSlug]
    }
    else if (url.pathname.endsWith('/miscs/course')) {
      data = MockData.courseById['1653898475176']
    } else if (url.pathname.endsWith('/miscs/category')) {
      data = MockData.category
    } else if (url.pathname.endsWith('/miscs/categories')) {
      data = MockData.categories
    } else if (url.pathname.endsWith('/miscs/chapter')) {
      data = MockData.chapterById[id]
    } else if (url.pathname.endsWith('/miscs/feedback')) {
      data = MockData.feedback
    }

    else if (url.pathname.endsWith('/miscs/profile')) {
      data = MockData.profile
    }

    else if (url.pathname.endsWith('/miscs/resources')) {
      data = MockData.resources
    } else if (url.pathname.endsWith('/miscs/favourites')) {
      data = MockData.favourites
    } else if (url.pathname.endsWith('/miscs/search')) {
      data = MockData.search
    } else if (url.pathname.endsWith('/miscs/assessment')) {
      data = MockData.assessment
    } else if (url.pathname.endsWith('/miscs/resource')) {
      data = MockData.resource
    } else if (url.pathname.endsWith('/miscs/certificate')) {
      data = MockData.certificate
    } else if (url.pathname.endsWith('/miscs/result')) {
      data = MockData.result
    }
    return data
  }

  _call(options, callback) {
    const data = this.hasMock(options)
    if (!data) return callback({ message: 'mock not found!' })
    return callback(null, data)
  }
}