import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../styles/AppTheme';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Helper } from '../common/helpers/Helper';

export function DrawerComponent(props) {
  return (
    <SwipeableDrawer
      anchor='right'
      open={props.open}
      onClose={props.onClose}
      onOpen={props.onOpen}
      className={css(Styles.swipe)}
      classes={{
        paper: css(Styles.drawerPaper),
      }}
    >
      <div className={css(Styles.outerContainer)}>
        <div className={css(Styles.iconContainer)}>
          <img
            onClick={props.onClose}
            src={require('../assets/images/close_icon.svg')}
            className={css(Styles.closeImgContent)} width='32px' height='32px'
            alt={Helper.getString('closeImgAlt')} />
        </div>
        {props.children}
      </div>
    </SwipeableDrawer>
  )
}

const Styles = StyleSheet.create({
  swipe: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
    },
  },
  outerContainer: {
    padding: '60px 20px',
  },
  drawerPaper: {
    width: '93%',
    backgroundColor: AppTheme.secondaryBg,
    display: 'flex',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20
  },
  closeImgContent: {
    width: 32,
    height: 32
  }
})