import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { Courses } from '../components/Courses/Courses';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Status } from '../components/Status/Status';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { Helper } from '../common/helpers/Helper';
import { AppContext } from '../common/components/contexts/AppContext';
import { UserContext } from '../common/components/contexts/UserContext';
import { AppHelper } from '../helpers/AppHelper';
import { EmptyView } from '../components/EmptyView';

export function Dashboard(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const { user, userContextStateHandler } = useContext(UserContext)
  const [state, setState] = useState({
    result: {}
  })
  const orgId = Controller.get().roleHelper().getOrgId()
  const userId = Controller.get().userMgr().getUserId()
  const statusItems = AppHelper.status()

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getDashboard(orgId, userId)
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response,
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'dashboard' })
    fetchData()
  }, [])

  const getName = () => {
    if (!user || !user.data) return ''
    const defaultName = user.data.givenname && user.data.givenname

    const currentOrg = Controller.get().roleHelper().getOrg()

    const name = (currentOrg && Helper.formatName(currentOrg.orgUserProfile)) || defaultName
    return name
  }

  const title = getName()

  const filterCourses = (data) => {
    data.items = data.items.filter(course => course.id.split('-')[0] === orgId) || []
    return data
  }

  return (
    <ScreenWrapper
      title={title}
      favourite={true}
      dashboard={true}
      state={state}
      logo={true}
    // search={true} //TODO search
    // filter={true}
    >

      {state.result &&
        <Fragment>
          {state.result.dashboard &&
            <Status items={statusItems} data={state.result.dashboard} tileSize={2} />
          }

          {state.result && state.result.courses && state.result.courses.items && state.result.courses.items.length > 0 ?
            <Courses
              showTitle={true}
              title={Helper.getString('myCourses')}
              showSearch={true}
              // viewMoreButtonBottom={true}
              borderContainer={[CommonStyles.borderContainer, CommonStyles.courseContainer]}
              titleOuterContainer={Styles.titleOuterContainer}
              // data={filterCourses(state.result.courses)} />
              data={state.result.courses} />
            :
            <EmptyView
              title={Helper.getString('noItemsToShow')} />
          }
        </Fragment>
      }
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  titleOuterContainer: {
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 15
    }
  }
})