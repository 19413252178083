import React, { Fragment, useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme } from '../styles/Styles';
import { CourseMgr } from '../common/mgrs/CourseMgr';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';




export function ShortVideo(props) {
  // const autoplayValue2 = props.data.status === CourseMgr.Status.COMPLETED ? false : true
  const autoplayValue = !props.data.buttonCompleted
  const [videoDuration, setVideoDuration] = useState(null)
  const [isPlaying, setIsPlaying] = useState(autoplayValue)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [volume, setVolume] = useState(80)
  const [isVolumeOpen, setIsVolumeOpen] = useState(false)
  const [showControls, setShowControls] = useState(!autoplayValue)
  const [isLandscape, setIsLandscape] = useState(false)

  const timeRef = useRef(null)
  const videoContainer = useRef(null)


  // const zeroFormatter = new Intl.NumberFormat(undefined, {
  //   minimumIntegerDigits: 2,
  // })

  // const formatDuration = (time) => {
  //   if (!time) return
  //   const seconds = Math.floor(time % 60)
  //   const minutes = Math.floor(time / 60) % 60
  //   const hours = Math.floor(time / 3600)
  //   if (hours === 0) {
  //     return `${minutes}:${zeroFormatter.format(seconds)}`
  //   } else {
  //     return `${hours}:${zeroFormatter.format(
  //       minutes
  //     )}:${zeroFormatter.format(seconds)}`
  //   }
  // }

  const playVideo = () => {
    props.videoRef.current.play().catch(err => {
      setIsPlaying(false)
    })
  }

  const loadVidData = () => {
    autoplayValue && playVideo()
    setVideoDuration(props.videoRef.current.duration)
  }

  const timeUpdate = e => {
    // if (!timeRef.current || !props.videoRef.current) return
    if ( !props.videoRef.current) return
    if(props.videoRef.current.duration - props.videoRef.current.currentTime < 5) props.videoCompleted()
    // timeRef.current.innerText = `${formatDuration(props.videoRef.current.currentTime)}/${formatDuration(props.videoRef.current.duration)}`
  }


  useEffect(() => {
    // Loading video duration
    props.videoRef.current.addEventListener('loadeddata', loadVidData)
    // Updating video watched duration

    props.videoRef.current.addEventListener('timeupdate', timeUpdate)
    return () => {
      props.videoRef.current.removeEventListener('loadeddata', loadVidData)
      props.videoRef.current.removeEventListener('timeupdate', timeUpdate)

    }
  }, [autoplayValue])

  // useEffect(() => {
  //   function handleResize() {
  //     if (window.innerWidth > window.innerHeight) {
  //       setIsLandscape(true)
  //     } else {
  //       setIsLandscape(false)
  //     }
  //   }
  //   window.addEventListener('resize', handleResize);
  //   handleResize();
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);


  // const playPause = (e) => {
  //   setIsMouseMoving(true)
  //   !isPlaying ? props.videoRef.current.play() : props.videoRef.current.pause()
  //   setIsPlaying(!isPlaying)
  // }

  // useEffect(() => {
  //   setIsPlaying(autoplayValue)
  //   autoplayValue && props.videoRef.current.pause()
  // }, [autoplayValue]);



  // const fullScreenHelper = (e) => {
  //   e.stopPropagation()
  //   if (document.fullscreenElement == null) {
  //     videoContainer.current.requestFullscreen()
  //   } else {
  //     document.exitFullscreen()
  //   }
  //   setIsFullScreen(!isFullScreen)
  // }

  // const handleVolume = (e) => {
  //   e.stopPropagation()
  //   const value = e.target.value
  //   setVolume(value)
  //   props.videoRef.current.volume = value / 100
  // }

  // Mouse Not Moving Logic
  // const idleTimeThreshold = 2000;
  // const [isMouseMoving, setIsMouseMoving] = useState(true);

  // useEffect(() => {
  //   let timeoutId;

  //   const resetMouseMoving = () => {
  //     setIsMouseMoving(false);
  //   };

  //   const handleMouseMove = () => {
  //     setIsMouseMoving(true);
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(resetMouseMoving, idleTimeThreshold);
  //   };

  //   document.getElementById('sincx-vid-container').addEventListener('mousemove', handleMouseMove);

  //   return () => {
  //     document.getElementById('sincx-vid-container').removeEventListener('mousemove', handleMouseMove);
  //     clearTimeout(timeoutId);
  //   };
  // }, [idleTimeThreshold]);

  // useEffect(() => {
  //   if (!isMouseMoving) {
  //     setShowControls(false)
  //   } else {
  //     setShowControls(true)
  //   }
  // }, [isMouseMoving]);

  // useEffect(() => {
  //   function fullScreenHelper() {
  //     if(document.fullscreenElement) {
  //       setIsFullScreen(true)
  //       return
  //     } else {
  //       setIsFullScreen(false)
  //       return
  //     }
  //   }
  //   fullScreenHelper()
  //   document.addEventListener('fullscreenchange', fullScreenHelper)
  //   return () => {
  //     document.removeEventListener('fullscreenchange', fullScreenHelper)
  //   };
  // }, []);

  useEffect(() => {
    if(!props.videoRef) return
    props.videoRef.current.disablePictureInPicture = true
  }, []);


  return (
    <Fragment>
      {props.videoLink &&
        <div className={css(Styles.wrapper, isFullScreen && Styles.fullScreenWrapper)} ref={videoContainer}
          // onMouseEnter={() => setShowControls(true)}
          // onMouseLeave={() => {
          //   isPlaying && setShowControls(false)
          // }}
          id='sincx-vid-container'
          // onClick={playPause}
          // style={{ cursor: !isMouseMoving && 'none' }}
        >
          {/* {<div onClick={(e) => e.stopPropagation()}
            className={showControls ? css(Styles.controlsContainer) : css(Styles.controlsContainerHidden)} style={{ bottom: (isLandscape && isFullScreen) ? 25 : 0 }}>
            <div className={css(Styles.playPauseVolumeTimerContainer)} >
              {!isPlaying ?
                <PlayArrowIcon className={css(Styles.icon)} onClick={playPause} /> :
                <PauseIcon className={css(Styles.icon)} onClick={playPause} />
              }

          <span className={css(Styles.time)} ref={timeRef} />
            </div>

            <div onClick={fullScreenHelper} className={css(Styles.iconContainer)}>
              {!isFullScreen ?
                <FullscreenIcon className={css(Styles.icon)} /> :
                <FullscreenExitIcon className={css(Styles.icon)}/>
              }
            </div>
          </div>
          }*/}

          <video
            onContextMenu={(e)=>e.preventDefault()}
            ref={props.videoRef}
            autoPlay = {isPlaying}
            title='Video'
            width='100%'
            height='450px'
            controls
            controlsList='noplaybackrate nodownload'
            className={css(Styles.videoContainerTile)}>
            <source src={props.videoLink} type='video/mp4' />
          </video>
        </div>
      }
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    paddingBottom: ' 56.25%', /* 16:9 */
    paddingTop: 25,
    height: 0,
  },
  fullScreenWrapper:{
    '@media(orientation: landscape)': {
      paddingBottom: 'unset',
      height:'100vh'
    }
  },
  videoContainerTile: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: AppCommonTheme.borderRadius,
    border: '1px solid ' + AppCommonTheme.borderColor,
    backgroundColor: 'black',
    zIndex: 5,
  },
  controlsContainer: {
    position: 'absolute',
    width: '100%',
    left: 0,
    right: 0,
    zIndex: 10,
    padding: 10,
    boxSizing: 'border-box',
    color: '#ffffff',
    background: '#00000060',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 5,
    borderRadius: `0 0 ${AppCommonTheme.borderRadius}px ${AppCommonTheme.borderRadius}px`,
    bottom:0
  },
  controlsContainerHidden: {
    display: 'none',
    position: 'absolute',
    width: '100%',
    left: 0,
    right: 0,
    zIndex: 10,
    padding: 10,
    boxSizing: 'border-box',
    color: '#ffffff',
    background: '#00000060',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 5,
    borderRadius: `0 0 ${AppCommonTheme.borderRadius}px ${AppCommonTheme.borderRadius}px`
  },
  icon: {
    color: '#ffffff',
    cursor: 'pointer',
    '@media(max-width: 768px)': {
      // fontSize: 18
    }
  },
  iconMarginLeft: {
    marginLeft: 5
  },

  playPauseVolumeTimerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  time: {
    fontSize: 14,
    marginLeft: 5,
    '@media(max-width: 768px)': {
      fontSize: 12
    }
  },
  auidocontrol: {
    width: '70px',
    height: 3,
    background: '#ffffff60',
    border: 'none',
    outline: 'none',
    '-webkit-appearance': 'none',
    '::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      width: '15px',
      height: '15px',
      background: '#ffffff',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    marginRight: 5,
    marginLeft: 5
  },
  volumeContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  iconContainer: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})