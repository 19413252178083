import React, { useState, useEffect } from 'react';

export const AppContext = React.createContext({
  appData: { },
  appContextStateHandler: () => { }
})

export const AppContextProvider = (props) => {
  const [state, setState] = useState({ toggleDrawer: true })

  function appContextStateHandler(keyValues) {
    const updateParams = {}
    for (const k in keyValues) {
      updateParams[k] = keyValues[k]
    }
    setState({ ...state, ...updateParams })
  }

  return (
    <AppContext.Provider value={{ appData: state, appContextStateHandler }}>
      {props.children}
    </AppContext.Provider>
  )
}