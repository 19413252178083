import { Controller } from '../common/Controller';
import { Helper } from '../common/helpers/Helper';
import { AppConfig } from '../AppConfig';

const vsprintf = require('sprintf-js').vsprintf

export class AppHelper {
  static getDrawerMenuItems() {
    return AppHelper.isDrawerMenuEnabled()
  }

  static getStaffDrawerMenuItems() {
    return [
      {
        id: 'courses',
        key: 'Courses',
        route: '/admin/courses/',
        title: Helper.getString('courses'),
        icon: 'ballot',
      }
    ]
  }

  static getAdminDrawerMenuItems(role) {
    return [
      {
        id: 'client',
        route: vsprintf('/admin/'),
        title: Helper.getString('orgs'),
        icon: 'dashboard',
        key: 'Orgs'

      },
      {
        id: 'courses',
        key: 'Courses',
        route: '/admin/courses/',
        title: Helper.getString('courses'),
        icon: 'ballot',
      },
      {
        id: 'user',
        route: vsprintf('/admin/users/'),
        title: Helper.getString('admins'),
        icon: 'people',
        key: 'UsersOfOrg',
      },
      {
        id: 'dashboardAdmin',
        key: 'dashboardAdmin',
        route: vsprintf('/admin/dashboard'),
        title: Helper.getString('dashboard'),
        icon: 'dashboard'
      }
    ]

    // [
    //   {
    //     route: vsprintf('/admin/users/'),
    //     title: Helper.getString('admins'),
    //     icon: 'people',
    //     key: 'UsersOfOrg',
    //   }
    // ]
    // ]
  }

  static getOrgUserDrawerMenuItems(orgId) {
    return (
      [
        {
          id: 'dashboard',
          key: 'DashboardOrg',
          route: vsprintf('/admin/orgs/%s', [orgId]),
          title: Helper.getString('dashboard'),
          icon: 'dashboard'
        },
        {
          id: 'courses',
          key: 'OrgCourses',
          route: vsprintf('/admin/orgs/%s/courses', [orgId]),
          title: Helper.getString('courses'),
          icon: 'school'
        },
        {
          id: 'category',
          key: 'CatsL1',
          route: vsprintf('/admin/orgs/%s/cats', [orgId]),
          title: Helper.getString('ccats'),
          icon: 'category'
        },
        {
          id: 'department',
          key: 'OrgTeams',
          route: vsprintf('/admin/orgs/%s/teams', [orgId]),
          title: Helper.getString('groups'),
          icon: 'groups_3'
        },
        {
          id: 'user',
          key: 'UsersOfOrg',
          route: vsprintf('/admin/orgs/%s/users', [orgId]),
          title: Helper.getString('users'),
          icon: 'admin_panel_settings'
        },
        {
          id: 'jobs',
          key: 'Jobs',
          route: vsprintf('/admin/orgs/%s/jobs', [orgId]),
          title: Helper.getString('jobs'),
          icon: 'add_task'
        }
        // ],
        // [
        //   {
        //     key: 'settings',
        //     route: vsprintf('/admin/orgs/%s/settings', [orgId]),
        //     title: Helper.getString('settings'),
        //     icon: 'supervisor_account'
        //   },
        //   {
        //     id: 'accolades',
        //     key: 'OrgTeams',
        //     route: vsprintf('/admin/orgs/%s/teams', [orgId]),
        //     title: Helper.getString('groups'),
        //     icon: 'groups_3'
        //   },
        //   {
        //     id: 'profile',
        //     key: 'UsersOfOrg',
        //     route: vsprintf('/admin/orgs/%s/users', [orgId]),
        //     title: Helper.getString('users'),
        //     icon: 'admin_panel_settings'
        //   },
        // ]
      ])
  }

  static getCreatorDrawerMenuItems(orgId) {
    return (
      [
        {
          id: 'courses',
          key: 'OrgCourses',
          route: vsprintf('/admin/orgs/%s/courses', [orgId]),
          title: Helper.getString('courses'),
          icon: 'school'
        },
        {
          id: 'category',
          key: 'CatsL1',
          route: vsprintf('/admin/orgs/%s/cats', [orgId]),
          title: Helper.getString('ccats'),
          icon: 'category'
        },
        {
          id: 'department',
          key: 'OrgTeams',
          route: vsprintf('/admin/orgs/%s/teams', [orgId]),
          title: Helper.getString('groups'),
          icon: 'groups_3'
        },
        {
          id: 'user',
          key: 'UsersOfOrg',
          route: vsprintf('/admin/orgs/%s/users', [orgId]),
          title: Helper.getString('users'),
          icon: 'admin_panel_settings'
        },
      ]
    )
  }

  static getOrgManagerDrawerMenuItems(orgId) {
    return (
      [
        {
          id: 'dashboard',
          key: 'DashboardOrg',
          route: vsprintf('/admin/orgs/%s', [orgId]),
          title: Helper.getString('dashboard'),
          icon: 'dashboard'
        },
        {
          id: 'courses',
          key: 'OrgCourses',
          route: vsprintf('/admin/orgs/%s/courses', [orgId]),
          title: Helper.getString('courses'),
          icon: 'school'
        },
        {
          id: 'category',
          key: 'CatsL1',
          route: vsprintf('/admin/orgs/%s/cats', [orgId]),
          title: Helper.getString('ccats'),
          icon: 'category'
        },
        {
          id: 'department',
          key: 'OrgTeams',
          route: vsprintf('/admin/orgs/%s/teams', [orgId]),
          title: Helper.getString('groups'),
          icon: 'groups_3'
        },
        {
          id: 'user',
          key: 'UsersOfOrg',
          route: vsprintf('/admin/orgs/%s/users', [orgId]),
          title: Helper.getString('pendingUsers'),
          icon: 'admin_panel_settings'
        },
      ]
    )
  }

  static isDrawerMenuEnabled() {
    let orgId = Controller.get().roleHelper().getOrgId()
    const pName = window.location.pathname
    let menu = []
    if (pName.startsWith('/admin/orgs/')) {
      // if(pName.endsWith('/preview')) { // TODO
      //   return null
      // }
      orgId = pName.split('/')[3]
      if (Controller.get().userMgr().isProductSU()) {
        menu = AppHelper.getOrgUserDrawerMenuItems(orgId)
      } else if (AppConfig.ORGSU_ACCESS_ALLOWED && Controller.get().userMgr().hasOrgRole(orgId, 'SUPERUSER')) {
        menu = AppHelper.getOrgUserDrawerMenuItems(orgId)
      } else if (AppConfig.ORGSU_ACCESS_ALLOWED && Controller.get().userMgr().hasOrgRole(orgId, 'MANAGER')) {
        menu = AppHelper.getOrgManagerDrawerMenuItems(orgId)
      } else if (AppConfig.ORGSU_ACCESS_ALLOWED && Controller.get().userMgr().hasOrgRole(orgId, 'CREATOR')) {
        menu = AppHelper.getCreatorDrawerMenuItems(orgId)
      }
    } else if (pName.startsWith('/admin')) {
      if (Controller.get().userMgr().isProductSU()) {
        menu = AppHelper.getAdminDrawerMenuItems()
      }
      if (Controller.get().roleHelper().isProductStaff()) {
        menu = AppHelper.getStaffDrawerMenuItems()
      }
    }
    if (AppConfig.ADMIN_DRAWER_MENU_ENABLED && menu.length > 0) {
      return menu
    }
    return null
  }

  //NOTE: sample format for drawer menu
  /*{
    key: 'home',
    id: 'home',
    route: '/',
    title: 'Home'
  }*/
  static getPublicDrawerMenuItems() {
    const orgId = Controller.get().roleHelper().getOrgId()

    return [
      {
        key: 'dashboard',
        id: 'dashboard',
        route: '/learner/orgs/' + orgId + '/dashboard',
        title: 'Dashboard',
      },
      {
        key: 'explore',
        id: 'explore',
        route: '/learner/orgs/' + orgId + '/explore',
        title: 'Explore'
      },
      {
        key: 'resources',
        id: 'resources',
        route: '/learner/orgs/' + orgId + '/resources',
        title: 'Resources'
      },
      {
        key: 'accolades',
        id: 'accolades',
        route: '/learner/orgs/' + orgId + '/accolades',
        title: 'Accolades'
      },
      {
        key: 'profile',
        id: 'profile',
        route: '/learner/orgs/' + orgId + '/profile',
        title: 'Profile'
      },
    ]
  }

  static sortDictByDuration(docs) {
    const attrName = 'duration'
    return docs.sort((a, b) => {
      return Number(a.duration) - Number(b.duration)
    })
  }
  static sortDictByDurationRev(docs) {
    const attrName = 'duration'
    return docs.sort((a, b) => {
      return Number(b.duration) - Number(a.duration)
    })
  }
  static sortDictByDueDate(docs) {
    return docs.sort((a, b) => {
      return new Date(a._userContext.dueDate) - new Date(b._userContext.dueDate)
    })
  }
  static sortDictByDueDateRev(docs) {
    return docs.sort((a, b) => {
      return new Date(b._userContext.dueDate) - new Date(a._userContext.dueDate)
    })
  }
  static sortDictByRatings(docs) {
    return docs.sort((a, b) => {
      return Number(a.stats.ratings.average) - Number(b.stats.ratings.average)
    })
  }
  static sortDictByRatingsRev(docs) {
    return docs.sort((a, b) => {
      return Number(b.stats.ratings.average) - Number(a.stats.ratings.average)
    })
  }
  static shuffleArray(array, shuffle) {
    if (shuffle) {
      let currentIndex = array.length, randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    }

    return array.sort((a, b) => a.order - b.order)
  }

  static status() {
    return [
      {
        id: 'assigned',
        title: 'Assigned',
        image: require('../assets/images/document_icon.svg'),
        state: 'assigned'
      },
      {
        id: 'selfenrolled',
        title: 'Self-enrolled',
        image: require('../assets/images/document_self_icon.svg'),
        state: 'selfenrolled'
      },
      {
        id: 'ongoing',
        title: 'Ongoing',
        image: require('../assets/images/timer_icon.svg'),
        state: 'ongoing'
      },
      {
        id: 'duesoon',
        title: 'Due Soon',
        image: require('../assets/images/due_icon.svg'),
        state: 'duesoon'
      },
      {
        id: 'overdue',
        title: 'Overdue',
        image: require('../assets/images/calendar_icon.svg'),
        state: 'overdue'
      },
      {
        id: 'completed',
        title: 'Completed',
        image: require('../assets/images/medal_icon.svg'),
        state: 'completed'
      }
    ]
  }

  static links() {
    const orgId = Controller.get().roleHelper().getOrgId()
    return [
      // {
      //   key: 'legal',
      //   title: 'Legal',
      //   link: ''
      // },
      {
        key: 'terms',
        title: 'Terms of use',
        link: '/learner/terms-of-use'
      },
      {
        key: 'privacy',
        title: 'Privacy policy',
        link: '/learner/privacy-policy'
      },
      {
        key: 'privacy',
        title: 'Terms & Conditions',
        link: '/learner/terms-and-conditions'
      },
      {
        key: 'adminMode',
        title: 'Admin mode',
        link: '/admin/orgs/' + orgId
      },
      // {
      //   key: 'contact',
      //   title: 'Contact admin',
      //   link: ''
      // },
      {
        key: 'logout',
        title: 'Logout',
      }
    ]
  }

  static downloadFileForMobileApp(url, filename) {
    const link = document.createElement('a');
    link.href = 'azsincx://download?url=' + encodeURIComponent(url) + '&filename=' + filename
    document.body.appendChild(link);
    link.click()
  }


  static downloadFile(url) {
    const link = document.createElement('a');
    link.href = url;
    let iOSSafari = null
    try {
      const ua = window.navigator.userAgent;
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      const webkit = !!ua.match(/WebKit/i);
      iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    } catch (err) {
      console.log('error', err)
    }
    if (iOSSafari) {
      link.setAttribute('target', '_self') //Solution for ios download issue
    } else {
      link.setAttribute('target', '_blank')
    }
    document.body.appendChild(link);
    link.click()
  }

  static adminStatus() {
    return [
      {
        id: 'learners',
        title: 'Total learners',
        image: require('../assets/images/profile.svg'),
        state: 'learners'
      },
      {
        id: 'groups',
        title: 'Total groups',
        image: require('../assets/images/group_people_icon.svg'),
        state: 'groups'
      },
      {
        id: 'creators',
        title: 'Total creators',
        image: require('../assets/images/creator_icon.svg'),
        state: 'creators'
      },
      {
        id: 'courses',
        title: 'Total courses',
        image: require('../assets/images/book_icon.svg'),
        state: 'courses'
      },
      {
        id: 'hours',
        title: 'Learning hours',
        image: require('../assets/images/timer_icon.svg'),
        state: 'hours'
      }
    ]
  }


  static managerStatus() {
    return [
      {
        id: 'learners',
        title: 'Total learners',
        image: require('../assets/images/profile.svg'),
        state: 'learners'
      },
      {
        id: 'courses',
        title: 'Total courses',
        image: require('../assets/images/creator_icon.svg'),
        state: 'courses'
      },
      {
        id: 'hours',
        title: 'Learning hours',
        image: require('../assets/images/timer_icon.svg'),
        state: 'hours'
      }
    ]
  }

  static filter() {
    return [
      {
        key: 'department',
        title: 'Group',
      },
      {
        key: 'learner',
        title: 'Learner type',
      },
      {
        key: 'publishedYear',
        title: 'Published year',
      },
      {
        key: 'courseStatus',
        title: 'Course status',
      },
      {
        key: 'consent',
        title: 'Consent',
      },
      {
        key: 'sincxCatalog',
        title: 'From sincx library',
      },
      {
        key: 'assessment',
        title: 'Assessment',
      },
      {
        key: 'recurrance',
        title: 'Recurrence',
      }
    ]
  }

  static getFileName(item) {
    let path = ''
    if (item.iType === 'FILE') {
      path = item.contentFile
    } else if (item.iType === 'PDF') {
      path = item.contentPDF
    }
    const strs = path.split('/')
    return strs[strs.length - 1]
  }


  static courseCompletionInfo(options) {
    const { course, progress, questions, chapterCount } = options
    let completedCount = 0
    let completedAssessments = 0
    let completedConsents = 0
    let completedFeedbacks = 0
    let assessmentCompletion = course.assessment === 'YES' ? false : true
    let consentCompletion = course.consents && Object.keys(course.consents).length ? false : true
    let feedbackCompletion = course.feedback === 'YES' ? false : true

    for(const k in progress.items) {
      if(k.startsWith('chapter-') && progress.items[k].state === 'COMPLETEMARKED') {
        completedCount += 1
      }
      if(course.assessment === 'YES') {
        if(k.startsWith('assessmentquestion-') && progress.items[k].state === 'SUBMITTED') {
          completedAssessments += 1
        }
      }
      if(course.consents) {
        if(k.startsWith('consent-') && progress.items[k].state === 'SUBMITTED') {
          completedConsents += 1
        }
      }
      if(course.feedback === 'YES') {
        if(k.startsWith('feedbackquestion-') && progress.items[k].state === 'SUBMITTED') {
          completedFeedbacks += 1
        }
      }
    }

    let totalItemsCount = chapterCount
    let afterCourseAction = false
    if(course.assessment === 'YES') {
      afterCourseAction  = true
      // if(questions.items.length === completedAssessments) {
      if(course.assessments.length === completedAssessments) {
        assessmentCompletion = true
      }
    }
    if(course.consents &&  Object.keys(course.consents).length) {
      afterCourseAction  = true
      if(Object.keys(course.consents).length === completedConsents) {
        consentCompletion = true
      }
    }
    if(course.feedback === 'YES' && course.feedbackQuestions && Object.keys(course.feedbackQuestions).length) {
      afterCourseAction  = true
      if (Object.keys(course.feedbackQuestions).length === completedFeedbacks) {
        feedbackCompletion = true
      }
    }

    const completedItemsCount = completedCount + ((assessmentCompletion && feedbackCompletion && consentCompletion && afterCourseAction) ? 1 : 0)
    totalItemsCount = totalItemsCount + (afterCourseAction ? 1 : 0)
    const percentageCompletion = progress.state === 'COMPLETED' ? 100 : Math.round((completedItemsCount / totalItemsCount) * 100)
    console.log('totalItemsCount', totalItemsCount)
    console.log('chapterCount', chapterCount)
    console.log('completedItemsCount', completedItemsCount)
    console.log('percentageCompletion', percentageCompletion)

    console.log('chapterCount:', chapterCount)
    console.log('completedCount:', completedCount)
    console.log('completedAssessments:', completedAssessments)
    console.log('completedConsents:', completedConsents)
    console.log('completedFeedbacks:', completedFeedbacks)

    return {
      completedCount,
      assessmentCompletion,
      feedbackCompletion,
      consentCompletion,
      percentageCompletion
    }
  }

  static getAssignmentId(data) {
    const id  = data.courseprogs && data.courseprogs.id || null
    const aid = id && id.split('-').length === 5 && id.split('-')[4] || null
    return aid
  }
}
