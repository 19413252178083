import React, { useEffect, useState, Fragment, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { CheckBoxComponent } from '../components/CheckBoxComponent';
import { AssessmentContent } from '../components/AssessmentContent';
import { DrawerComponent } from '../components/DrawerComponent';
import { AppButton } from '../components/AppButton';
import { AppContext } from '../common/components/contexts/AppContext';
import { ErrorMsg } from '../components/ErrorMsg';
import { AppHelper } from '../helpers/AppHelper';

export function Assessment(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {},
    drawerIsOpen: false,
    selectedChecklist: {}
  })
  const [questions, setQuestions] = useState([])
  const [currIndex, setCurrIndex] = useState(0)
  const [totalSubmissions, setTotalSubmissions] = useState(0)
  const [submittedAssessIds, setSubmittedAssessIds] = useState([])
  const [allQues, setAllQues] = useState([])




  const orgId = Controller.get().roleHelper().getOrgId()
  const courseSlug = props.courseSlug ? props.courseSlug : props.match.params.slug

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getCourseDetails2({ orgId, courseSlug })
      console.log(response.assessmentCompleted, 'RES_COMPLETED');
      if (response.assessmentCompleted) {
        Controller.get().courseMgr().navToResult(courseSlug)
        return
      }

      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response,
        selectedAssessment: response.totalAssesmentAnsCount,
      })

      const submittedAssessIds = []
      for (const key in response.courseprogs.items) {
        if (key.startsWith('assessmentquestion-') && response.courseprogs.items[key].state === 'SUBMITTED' && response.courseprogs.items[key].response.value.length > 0) {
          submittedAssessIds.push(key.split('-')[1])
        }
      }
      let nsQues = []
      const submittedQues = []
      response.assessments.forEach(ques => {
        if (!submittedAssessIds.includes(ques.id)) {
          nsQues.push(ques)
        } else {
          submittedQues.push(ques)
        }
      })

      nsQues = [...AppHelper.shuffleArray(nsQues, true)]
      setAllQues([...submittedQues, ...nsQues])
      // setQuestions(holdQues.filter(ques => nsQues.findIndex(v => v.id === ques.id) >= 0))
      setQuestions(nsQues)
      setSubmittedAssessIds(submittedAssessIds)
      setTotalSubmissions(submittedQues.length)
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    fetchData()
    console.log('RERENDER_CHECK');
  }, [])

  const assessmentSubmit = async () => {
    if (state.selectedChecklist === undefined || Object.keys(state.selectedChecklist).length === 0) {
      setState({
        ...state,
        openError: true,
        errorMsg: Helper.getString('pleaseSelectAnyOption')
      })
      return
    }

    setState({
      ...state,
      inProcess: true
    })
    try {
      const selectedValues = []

      for (const item in state.selectedChecklist) {
        if (state.selectedChecklist[item] && state.selectedChecklist[item] === true) {
          selectedValues.push(item)
        }
      }

      const response = await Controller.get().courseMgr().submitAssessment(questions[currIndex].id, selectedValues, state.result.courseprogs)
      setSubmittedAssessIds([...submittedAssessIds, questions[currIndex].id])
      setState({
        ...state,
        inProcess: false,
        selectedChecklist: {}
      })
      if (currIndex + 1 < questions.length) { setCurrIndex(currIndex + 1) }
      console.log(currIndex, questions.length - 1, 'VALUES_CHECK')

      if (currIndex === questions.length - 1) {
        console.log('FETCH DATA');
        fetchData()
      }
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }



    if (state.result.assessments.length === (state.selectedAssessment + 1)) {
      Controller.get().courseMgr().navToResult(courseSlug)
    }
  }

  const onDrawerOpen = () => {
    setState({
      ...state,
      drawerIsOpen: true,
    })
  }

  const onDrawerClose = () => {
    setState({
      ...state,
      drawerIsOpen: false,
    })
  }

  const selectedValue = (values) => {
    console.log(values, 'INNER_OUTER');
    setState({
      ...state,
      selectedChecklist: values
    })
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  const backButton = {
    title: Helper.getString('back'),
    link: '/learner/orgs/' + orgId + '/course/' + courseSlug
  }



  console.log(questions.map(q => q.order), 'Q');
  return (
    <div className={css(CommonStyles.contentStackOuterContainer)}>
      <div className={css(CommonStyles.contentStackFirstOuterContainer)}>
        <ScreenWrapper
          state={state}
          drawer={true}
          subTitle={Helper.getString('courseAssessment')}
          onDrawerOpen={() => onDrawerOpen()}
          backButton={backButton}>
          {questions.length > 0 && questions[currIndex] && !submittedAssessIds.includes(questions[currIndex].id) &&
            <CheckBoxComponent
              data={questions[currIndex]}
              count={totalSubmissions + currIndex + 1}
              selectedValue={selectedValue}
              dataSend={true}
              shuffle
            />}

          <div className={css(Styles.buttonOuterContainer)}>
            <AppButton
              onClick={() => assessmentSubmit()}
              buttonText={Helper.getString('submit')}
              inProcess={state.inProcess}
            />
          </div>
        </ScreenWrapper>
      </div>

      <div className={css(CommonStyles.contentStacksecondOuterContainer)}>
        <AssessmentContent data={allQues} submittedAssessIds={submittedAssessIds} />
      </div>

      <DrawerComponent
        open={state.drawerIsOpen}
        onClose={() => onDrawerClose()} >
        <AssessmentContent data={allQues} submittedAssessIds={submittedAssessIds} />
      </DrawerComponent>

      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
    </div>
  )


}

const Styles = StyleSheet.create({
  buttonOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10
  }
})