import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Dialog from '@material-ui/core/Dialog';

import { AppStyles, AppTheme } from '../../styles/AppTheme';
import { CustomMessageDialogStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';

export class MessageDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onCloseModal() {
    this.props.onClosed()
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        aria-labelledby={this.props.title}
        fullWidth={false}
        maxWidth='md'
        classes={{
          root: css(Styles.customRoot),
          paper: css(Styles.customPaper),
        }}
      >
        <div className={css([Styles.modalHeader, AppStyles.modalHeader])}>
          <div className={css(Styles.modalTitle)}>{this.props.title}</div>
          <button className={css(Styles.modalCloseButton)} onClick={this.onCloseModal.bind(this)} disabled={this.state.inProcess}>
            <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        </div>
        <div className={css(Styles.modalContent)}>
          {this.props.message}
        </div>
      </Dialog>
    );
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomMessageDialogStyles : StyleSheet.create({
  modalHeader: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14
  },
  modalTitle: {
    color: '#222222',
    fontSize: 26,
  },
  modalContent: {
    maxWidth: 400,
    minWidth: 200,
    paddingTop: 16,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
  modalCloseButton: {
    position: 'absolute',
    top: 23,
    right: 30,
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    fill: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius
  },
  root: {
    zIndex: 13000,
  },
  paper: {
    borderRadius: AppTheme.borderRadius
  },
})
