import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';

export function Image(props) {

  return (
    <div>
      {props.imgLink &&
        <div className={css(Styles.imageContainer)}>
          <img
            alt={Helper.getString('chapterImgAlt')}
            width='1200' height='1200'
            src={props.imgLink}
            className={css(Styles.img)}
          />
        </div>
      }
    </div>
  )
}

const Styles = StyleSheet.create({
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    display: 'block',
    overflow: 'hidden',
    inset: '0px',
    boxSizing: 'border-box',
    margin: '0px',
  },
  img: {
    inset: '0px',
    position: 'absolute',
    boxSizing: 'border-box',
    padding: '0px',
    border: 'none',
    margin: 'auto',
    display: 'block',
    width: '0px',
    height: '0px',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
})