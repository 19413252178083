import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';
import { ResourceTile } from './ResourceTile';
import { ViewMoreButton } from '../ViewMoreButton';
import { AppCommonTheme, CommonStyles } from '../../styles/Styles';
import { Helper } from '../../common/helpers/Helper';

export function ResourcesList(props) {

  const data = props.data
  return (
    <div className={css(props.borderContainer)}>
      {(props.showTitle || props.viewMoreButtonTop) &&
        <div className={css(CommonStyles.titleOuterContainer)}>
          {props.showTitle &&
            <p className={css(CommonStyles.titleContainer)}>{props.title ? props.title : Helper.getString('resources')}</p>
          }
          {props.viewMoreButtonTop &&
            <div className={css(CommonStyles.viewMoreButtonContainer)}>
              <ViewMoreButton
                buttonTextStyle={CommonStyles.buttonTextStyle} />
            </div>
          }
        </div>
      }

      {data && data.length > 0 &&
        <Grid container spacing={2}>
          {data.map((item, index) => {
            return (
              <ResourceTile
                key={index}
                item={item}
                {...props} />
            )
          })}
        </Grid>
      }
      {props.viewMoreButtonBottom &&
        <div className={css(Styles.viewMoreButtonContainer)}>
          <ViewMoreButton
            buttonStyle={Styles.buttonStyle}
            buttonTextStyle={Styles.buttonTextStyle}
          />
        </div>
      }
    </div>

  )
}

const Styles = StyleSheet.create({
  viewMoreButtonContainer: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    '@media(max-width: 767px)': {
      marginTop: 20
    }
  },
  buttonStyle: {
    border: AppCommonTheme.border,
    padding: '5px 15px'
  },
  buttonTextStyle: {
    fontSize: 12,
    textDecoration: 'unset',
    '@media(max-width: 767px)': {
      fontSize: 10
    }
  }
})