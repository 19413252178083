import React, { Fragment, useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme, CommonStyles } from '../styles/Styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { AppTheme } from '../styles/AppTheme';
import { Helper } from '../common/helpers/Helper';
import FormGroup from '@material-ui/core/FormGroup';
import { AppHelper } from '../helpers/AppHelper';

export function CheckBoxComponent(props) {
  const [state, setState] = useState({
  })
  useEffect(() => {
    props.dataSend && props.selectedValue(state[props.data.id])
  }, [state[props.data.id]])


  const handleCheckbox = event => {
    if (props.data.iType === 'OPTIONS-MUL') {
      setState({
        ...state,
        [props.data.id]: {
          ...state[props.data.id],
          [event.target.name]: event.target.checked,
        }
      })
    } else {
      setState({
        ...state,
        [props.data.id]: {
          [event.target.name]: event.target.checked,
        }
      })
    }
  }

  const renderAnswer = (item) => {
    if (props.showAnswer && data.options[item].correctOption === 'YES') {
      return (
        <div className={css(Styles.answerOuterContainer)}>
          <div className={css(Styles.correctIconContainer)}>
            <img src={require('../assets/images/correct.svg')} width='18px' height='18px' className={css(Styles.correctIconStyle)} />
            <p className={css(Styles.correctText)}>{Helper.getString('correct')}</p>
          </div>
          {/* <p className={css(Styles.correctDescr)}>{data.answerTitle}</p> */}
        </div>
      )
    } else if (props.showAnswer && data.options[item].correctOption === 'NO' && state[props.data.id] && state[props.data.id][item]) {
      return (
        <div className={css(Styles.answerOuterContainer, Styles.wrongOuterContainer)}>
          <div className={css(Styles.correctIconContainer)}>
            <img src={require('../assets/images/wrong.svg')} width='18px' height='18px' className={css(Styles.correctIconStyle)} />
            <p className={css(Styles.wrongText)}>{Helper.getString('wrong')}</p>
          </div>
          {/* <p className={css(Styles.correctDescr)}>{data.answerTitle}</p> */}
        </div>
      )
    }
    return null
  }

  const data = props.data
  const titleContainerStyle = props.dialog ? css(Styles.titleContainer, Styles.titleContainerDialog) : css(Styles.titleContainer)
  const iconStyle = props.dialog ? css(Styles.icon, Styles.iconDialog) : css(Styles.icon)
  const iconSelectedStyle = props.dialog ? css(Styles.icon, Styles.selectedIcon, Styles.iconDialog) : css(Styles.icon, Styles.selectedIcon)
  const checkedIconStyle = props.dialog ? css(Styles.checkedIcon, Styles.checkedIconDialog) : css(Styles.checkedIcon)
  const formControlLabelStyle = props.dialog ? css(Styles.formControlLabel, Styles.formControlLabelDialog) : css(Styles.formControlLabel)
  const formLabelStyle = props.dialog ? css(Styles.formLabel, Styles.formLabelDialog) : css(Styles.formLabel)
  const indexValue = props.count ? `${props.count}.` : ''

  const [options, setOptions] = useState(null)
  useEffect(() => {
    if (!data.options) return
    setOptions(AppHelper.shuffleArray(Object.keys(data.options), props.shuffle))
  }, [data]);



  return (
    <div>
      {(data.title || props.title) &&
        <p className={titleContainerStyle}>{indexValue} {data.title || props.title}</p>
      }
      {data.subTitle &&
        <p className={css(Styles.subTitleContainer)}>{data.subTitle}</p>
      }
      {data && data.options && Object.keys(data.options).length > 0 && options && options.length > 0 &&
        <div className={css(Styles.checkBoxContainer)}>
          <FormGroup>
            {options.map((item, index) => {
              if (!data.options[item]) return
              return (
                props.hideCheckbox ?
                  <p className={css(Styles.labelContainer)}>{index + 1}.  {data.options[item].title}</p>
                  :
                  <Fragment key={index}>
                    <FormControlLabel
                      disabled={props.showAnswer}
                      key={index}
                      control={

                        <Checkbox
                          checked={(state[props.data.id] && state[props.data.id][data.options[item].id]) ? state[data.id][data.options[item].id] : false}
                          onChange={handleCheckbox}
                          value={(state[props.data.id] && state[props.data.id][data.options[item].id]) ? state[data.id][data.options[item].id] : false}
                          name={data.options[item].id}
                          color='primary'
                          checkedIcon={
                            <div className={iconSelectedStyle}>
                              <img src={require('../assets/images/check_icon.svg')} width='24px' height='24px' className={checkedIconStyle} />
                            </div>
                          }
                          icon={
                            <div className={iconStyle} />
                          }
                        />
                      }
                      label={data.options[item].title}
                      classes={{
                        label: formLabelStyle
                      }}
                      className={formControlLabelStyle}
                    />
                    {renderAnswer(item)}
                  </Fragment>
              )
            })}
          </FormGroup>
        </div>
      }
    </div >
  )
}

const Styles = StyleSheet.create({
  titleContainer: {
    fontSize: 32,
    color: AppCommonTheme.appFontColor,
    fontWeight: 600,
    margin: AppCommonTheme.pMargin,
    marginBottom: 30,
    '@media(max-width: 767px)': {
      fontSize: 24
    },
  },
  titleContainerDialog: {
    fontSize: 26,
    marginBottom: 16,
    '@media(max-width: 767px)': {
      fontSize: 22,
    },
  },
  subTitleContainer: {
    fontSize: 16,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginBottom: 16,
    '@media(max-width: 767px)': {
      fontSize: 14,
      marginTop: -6
    },
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  formLabel: {
    marginLeft: 3,
    fontSize: 18,
    color: AppCommonTheme.appFontColor,
    marginTop: 10
  },
  formLabelDialog: {
    marginLeft: 0,
    fontSize: 14,
  },
  icon: {
    width: 24,
    height: 24,
    background: AppTheme.secondaryBg,
    border: AppCommonTheme.border,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconDialog: {
    width: 20,
    height: 20,
  },
  selectedIcon: {
    border: '1px solid' + AppTheme.primaryColor
  },
  checkedIcon: {
    width: 24,
    height: 24
  },
  checkedIconDialog: {
    width: 20,
    height: 20
  },
  formControlLabel: {
    marginBottom: 25,
    display: 'flex',
    alignItems: 'flex-start',
  },
  formControlLabelDialog: {
    marginBottom: 5,
  },

  correctIconStyle: {
    width: 18,
    height: 18,
    marginRight: 8,
    '@media(max-width: 767px)': {
      width: 14,
      height: 14,
      marginRight: 6
    },
  },
  answerOuterContainer: {
    display: 'flex',
    marginLeft: 30,
    backgroundColor: '#D8FFE3',
    padding: '8px 12px',
    borderRadius: 4,
    marginTop: 3,
    maxWidth: 80
  },
  wrongOuterContainer: {
    backgroundColor: '#FFD8D8'
  },
  correctIconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  correctText: {
    margin: AppCommonTheme.pMargin,
    fontSize: 14,
    color: '#16D134',
    '@media(max-width: 767px)': {
      fontSize: 12
    },
  },
  wrongText: {
    margin: AppCommonTheme.pMargin,
    fontSize: 14,
    color: '#FF0000',
    '@media(max-width: 767px)': {
      fontSize: 12
    },
  },
  correctDescr: {
    margin: AppCommonTheme.pMargin,
    fontSize: 12,
    color: AppCommonTheme.appFontColor,
    marginTop: 9
  },
  labelContainer: {
    fontSize: 14,
    color: AppCommonTheme.appFontColor,
    marginTop: 10,
    marginBottom: 13
  }
})