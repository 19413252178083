import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme, CommonStyles } from '../styles/Styles';
import { Helper } from '../common/helpers/Helper';
import { AppTheme } from '../styles/AppTheme';
import Grid from '@material-ui/core/Grid';

export function Trophies(props) {

  const trophies = [
    {
      key: 'Trophy',
      img: 'trophy'
    },
    {
      key: 'Shield',
      img: 'shield'
    },
    {
      key: 'Medal',
      img: 'medal'
    },
  ]

  const data = props.data
  return (
    <div className={css(CommonStyles.borderContainer, Styles.borderContainer)}>
      <div className={css(CommonStyles.titleOuterContainer)}>
        <p className={css(CommonStyles.titleContainer)}>{Helper.getString('myTrophies')}</p>
        {props.points &&
          <p className={css(Styles.pointContainer)}>{Helper.getString('rewardPoints')}:
            <span className={css(Styles.point)}> {props.points}</span></p>
        }
      </div>


      <div className={css(Styles.trophyWrapContainer)}>
        {trophies.map((item, index) => {
          return (
            data[item.key] &&
            // <Grid key={index} lg={6} xs={4} sm={3} md={2} item >
            <div className={css(Styles.trophyOuterContainer)} key={index}>
              <div className={css(Styles.trophyImgContainer)}>
                <img
                  alt={Helper.getString('trophyImgAlt')}
                  width='140px' height='140px'
                  src={require(`../assets/images/${item.key}_img.svg`)}
                  className={css(Styles.imgContent)} />
              </div>
              <div className={css(Styles.titleContent)}>
                <p className={css(Styles.title)}>{item.key}</p>
                <p className={css(Styles.value)}> ({data[item.key]})</p>
              </div>
            </div>
            // </Grid>
          )
        })}
      </div>

    </div >

  )
}

const Styles = StyleSheet.create({
  borderContainer: {
    flex: 1
  },
  trophyWrapContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  trophyOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 100,
    '@media(min-width: 768px) and (max-width: 1024px)': {
      marginRight: 60
    },
    '@media(max-width: 767px)': {
      marginBottom: 15,
      marginRight: 30,
    }
  },
  trophyImgContainer: {
    width: 140,
    height: 140,
    display: 'flex',
    '@media(max-width: 767px)': {
      width: 80,
      height: 80,
    }
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  title: {
    fontSize: 16,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    textAlign: 'center',
    marginTop: 6,
    textTransform: 'capitalize',
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  value: {
    fontWeight: 600,
    letterSpacing: 1,
    fontSize: 16,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    textAlign: 'center',
    marginTop: 6,
    marginLeft: 5,
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  pointContainer: {
    color: AppCommonTheme.appFontColor,
    fontSize: 16,
    margin: AppCommonTheme.pMargin,
    flex: 1,
    textAlign: 'end',
    // marginBottom: 24,
    // marginLeft: 10,
    '@media(max-width: 767px)': {
      fontSize: 14,
      // marginBottom: 14,
    }
  },
  point: {
    color: AppTheme.primaryColor
  }
})