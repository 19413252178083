import React, { Fragment, Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CoursesByCat } from '../common/components/course/CoursesByCat';
import { BaseEntityPage } from '../common/components/BaseEntityPage';
import { Questions } from '../common/components/quizing-simple/Questions';
import { Controller } from '../common/Controller';

export class QuizQuestionsWrappper extends Component {
  constructor(props) {
    super(props)
    // this.orgId = (Controller.get().roleHelper().isProductUser() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.orgId = props.match.params.orgId ? props.match.params.orgId : Controller.get().roleHelper().getOrgId()
    this.id = this.props.match.params.id ? this.props.match.params.id : ''
    this.chapterDetails = props.location.state ? props.location.state : ''
    this.pathname = this.props.location ? this.props.location : ''
  }

  render() {
    return (
      <Questions quiz={true} orgId={this.orgId} id={this.id}  chapterDetails={this.chapterDetails} pathname={this.pathname} />
    )
  }
}

const Styles = StyleSheet.create({
})