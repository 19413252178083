import React, { Component, Fragment } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Grid, Card, CardContent, CardActionArea, Tooltip, Paper, Button, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { BaseEntityPage } from '../BaseEntityPage';
import { ApiHelper } from '../../helpers/ApiHelper';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Helper } from '../../helpers/Helper';
import { SubEntityCard } from '../SubEntity/SubEntityCard';
import { SubEntityList } from '../SubEntity/SubEntityList';
import { UiHelper } from '../../helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { CommonStyles } from '../../../styles/Styles';
import { Controller } from '../../Controller';
import moment from 'moment';
import { feedbackData } from '../../../data/MockData';

export class CourseFeedback extends BaseEntityPage {
  constructor(props, options) {
    super(props)
    // this.creatorPurposeOne = options.creatorPurposeOne
    this.pageTitle = Helper.getString('Feedbacks')
    // this.addNewLabel = 'Add'
    this.pageKey = 'feedbacks'
    this.noAdd = true
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.itemId = this.props.match.params.id
    // this.uploadLocation = this.orgId + '/' + this.itemId
    // this.emptyText = ' ' // space
    this.apiSpecForImageUploader = {}
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: 'Courses', to: this.baseRoute() + '/courses' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'coursefeedbacks'], {
          orgId: this.orgId, orgCourseId: this.itemId,
          allvisible: true,
        })
      },
    ]
    this.apiData = {
    }
    this.entityAttrName = 'feedbacks'

    this.tableHeadersMap = {
      Index: (current) => { return <span className={css(Styles.valueText)}>{current.__index + 1}</span> },
      Rating: (current) => String(current.rating) + '/5',
      Comment: (current) => current.comment,
      Questions: (current) => {
        return current.questions.map((item, index) => {
          return <div className={css(Styles.feedbackContainer)} style={index < current.questions.length - 1 ? { borderBottom: '1px solid lightgrey'} : { border: 'none'}}>
            <span>{item.title}</span>
            <span>Response: { String(item.value) + '/10'}</span>
          </div>
        })
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions(current) {
    const actions = []
    return actions
  }

  onFetchSuccess(results) {
    const resp = results[0]//feedbackData
    const course = resp.course
    const cps = resp.courseProgress.items
    const items = []
    const feedbackQuestions = course.feedbackQuestions
    this.pageTitle = course.title
    for(const cp of cps) {
      const questions = []
      for(const key in feedbackQuestions) {
        if(cp.items && cp.items['feedbackquestion-' + key]) {
          if(cp.items['feedbackquestion-' + key].response && cp.items['feedbackquestion-' + key].response.value !== undefined) {
            questions.push({
              title: feedbackQuestions[key].title,
              value: cp.items['feedbackquestion-' + key].response.value
            })
          } else {
            questions.push({
              title: feedbackQuestions[key].title,
              value: 'Not available'
            })
          }
        }
      }
      if(questions.length > 0) {
        const f = {
          comment: cp.comment || 'Not Available',
          rating: cp.rating !== undefined ? cp.rating : 'Not Available',
          questions: questions
        }
        items.push(f)
      }
    }
    this.setState({
      items,
      item: course
    })
  }

  renderAboveTable() {
    const { item } = this.state
    const { pathname } = this.props.location;
    return (
      <div className={css(Styles.containerNarrow)}>
        {item &&
          <div className={css(CommonStyles.courseActionListContainer)}>
            <div className={css(CommonStyles.courseActionListInnerContainer)}>
              {UiHelper.isOrgClient(pathname) ? UiHelper.courseActionList(this, item, this.pageKey, this.orgId, this.itemId) :
                UiHelper.courseAdminActionList(this, item, this.pageKey, this.itemId)}
            </div>
            <Divider className={css(CommonStyles.courseActionListDivider)} />
          </div>
        }
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  container: {
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
    marginBottom: 20,
    width: '100%',
  },

  containerTableNarrow: {
    marginBottom: 20,
    width: '100%'
  },
  feedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 0px'
  }
})