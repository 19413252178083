import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { CustomProductsStyles } from '../../../styles/CustomStyles';
import { CommonStyles } from '../../../styles/Styles';

export class QuestionOptions extends BaseEntityPage {
  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('question')
    this.addNewLabel = 'Add'
    this.pageKey = 'QuestionOptions'
    // this.orgId = (Controller.get().roleHelper().isProductUser() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.orgId = props.match.params.orgId ? props.match.params.orgId : Controller.get().roleHelper().getOrgId()
    this.questionId = this.props.currentId ? this.props.currentId : this.props.match.params.id
    this.quizId = this.props.match.params.quizId
    // this.courseId = this.quizId.split('-')[0]
    // this.chapterId = this.quizId.split('-')[1]
    // if(this.chapterId) {
    //   this.breadCrumbs = [
    //     { title: Helper.getString('home'), to: this.baseRoute() },
    //     { title: Helper.getString('chapters'), to: this.baseRoute() + '/courses/' + this.courseId + '/chapters' },
    //     { title: Helper.getString('quiz'), to: this.baseRoute() + '/quiz/' + this.quizId },
    //     { title: this.pageTitle },
    //   ]
    // } else { // This is under a course; So an assessment
    //   this.breadCrumbs = [
    //     { title: Helper.getString('home'), to: this.baseRoute() },
    //     { title: Helper.getString('courses'), to: this.baseRoute() + '/courses' },
    //     { title: Helper.getString('assessment'), to: this.baseRoute() + '/quiz/' + this.quizId },
    //     { title: this.pageTitle },
    //   ]
    // }
    this.toFetch = [
      { method: 'GET', paths: ['questions', this.questionId], queryParams: { pb: 'fieldDefOptions' } },
    ]
    this.tableHeadersMap = {
      Order: (current) => { return <span className={css(Styles.valueText)}>{current.order}</span> },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span>
            <br />
            <span className={css(Styles.spanValue)}>{'Answer: ' + (current.correctOption || 'NO')}</span>
          </div>
        )
      },
    }
    this.entityAttrName = 'options'
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
    }
  }

  onFetchSuccess(results, options) {
    this.formDefinition = results[0].fieldDefOptions
    this.formDefinition.steps[0].fields =
      this.formDefinition.steps[0].fields.filter(i => Helper.inArray(['title', 'order', 'correctOption'], i.key))
    this.setState({
      items: Helper.sortDictArray(Helper.dictToArray(results[0].options))
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath(['questions', this.questionId], {}),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['questions', this.questionId], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['questions', this.questionId], {}),
    });
  }

  onActions = (current) => {
    const actions = []
    return actions
  }

  onMenuItems(current) {
    const actions = []
    const menuItems = [
      {
        current: current,
        key: current.id + 'editTT',
        action: () => this.openModalForEdit(current),
        title: Helper.getString('edit'),
        img: require('../../../assets/images/edit-icon.svg')
      },
      {
        current: current,
        key: current.id + 'deleteTT',
        action: () => this.openModalForDelete(current),
        title: Helper.getString('delete'),
        img: require('../../../assets/images/trash-icon.svg'),
        style: CommonStyles.deleteText
      },
    ]
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions
  }

}

const Styles = AppConfig.CUSTOM_STYLE ? CustomProductsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})
