import { parseNumber, formatNumber} from 'libphonenumber-js'
import { BaseError } from '../errors';
import { Helper } from '../helpers/Helper';
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export class PhoneNumberHelper {
  static formatNumber(number, defaultCountry) {

    try {
      if(!phoneUtil.isValidNumber(phoneUtil.parse(number, defaultCountry))) {
        throw new BaseError(Helper.getString('invalidNumber'));
      }
      const parsed = phoneUtil.parseAndKeepRawInput(number, defaultCountry);
      return phoneUtil.format(parsed, PNF.E164)

    } catch (error) {
      throw new BaseError(Helper.getString('invalidNumber'));
    }

  }

  // static formatNumber(number, defaultCountry) {
  //   if(!number) return;
  //   const mobileRegEx =  new RegExp(/^(\+?\d{1,4}-?)?\d{7,14}$/);
  //   number = number.replace(/[-()]/g, '')
  //   if(!number.match(mobileRegEx)) {
  //     throw new BaseError(Helper.getString('invalidNumber'));
  //   }
  //   return number
  // }

  // static formatNumber(number, defaultCountry) {
  //   const parsed = parseNumber(number, defaultCountry);
  //   if (!parsed || !parsed.country || !parsed.phone) {
  //     throw new BaseError(Helper.getString('invalidNumber'));
  //   }
  //   console.log('parsed number:', parsed);
  //   return formatNumber(parsed, 'E.164');
  // }
  static formatNumberV2(number, defaultCountry) { //TODO: why we need
    const parsed = parseNumber(number, defaultCountry);
    if (!parsed || !parsed.country || !parsed.phone) {
      return false
    }
    return formatNumber(parsed, 'E.164');
  }
}
