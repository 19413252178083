import React, { Fragment, useEffect, useState, useContext } from 'react';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme, CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import Button from '@material-ui/core/Button';
import { Video } from '../components/Video';
import { Html } from '../components/Html';
import { Image } from '../components/Image';
import { DocumentFile } from '../components/DocumentFile';
import { ShortVideo } from '../components/ShortVideo';
import { File } from '../components/File';
import { Text } from '../components/Text';
import { Helper } from '../common/helpers/Helper';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { TitleComponent } from '../components/TitleComponent';
import { AppTheme } from '../styles/AppTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Section } from '../components/Section';
import { DrawerComponent } from '../components/DrawerComponent';
import { Checklist } from '../components/Checklist';
import { Quiz } from '../components/Quiz';
import { AppButton } from '../components/AppButton';
import { AppContext } from '../common/components/contexts/AppContext';
import { useRef } from 'react'
import { CourseMgr } from '../common/mgrs/CourseMgr';
import { AppHelper } from '../helpers/AppHelper';

export function Chapter(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const cssBreakpoint = useMediaQuery('(max-width:1024px)');
  const [state, setState] = useState({
    result: {},
    drawerIsOpen: false,
  })
  const [isVideoCompleted, setVideoCompleted] = useState(false)
  const orgId = props.orgId ? props.orgId : (Controller.get().userMgr().isProductSU() ? (props.match.params.orgId ? props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
  const courseSlug = props.courseSlug ? props.courseSlug : props.match.params.slug
  const chapterId = props.chapterId ? props.chapterId : props.match.params.chapterId
  let response = {}
  const videoRef = useRef(null);

  useEffect(() => {
    fetchData(courseSlug, chapterId)
  }, [])

  async function fetchData(courseSlug, chapterId, pageLoadDisable) {
    try {
      if (!pageLoadDisable) {
        setState({ fetchState: ApiHelper.State.LOADING })
      }
      response = await Controller.get().courseMgr().getChapterDetails({ orgId, courseSlug, chapterId })
      // if (Controller.get().userMgr().isProductSU() && orgId === 'PRODUCT') {
      //   response = await Controller.get().courseMgr().getGlobalChapterBySlug(orgId, courseSlug, chapterId)
      // } else {
      //   response = await Controller.get().courseMgr().getChapterDetails({ orgId, courseSlug, chapterId } )
      // }
      if (response) {
        setState({
          ...state,
          fetchState: ApiHelper.State.READY,
          result: response[0],
          selectedItem: response[1],
          checklistDialogOpen: false,
          quizDialogOpen: false,
          inProcess: false,
          courseCompletionInfo: response[2]
        })
        setVideoCompleted(false)
        console.log('response', response)
        // const reviewStatus = props.location && props.location.state && props.location.state
        // if (response[0].courseComplete && !reviewStatus.review) {
        //   if (response[0].showAssessment) {
        //     Controller.get().courseMgr().navToAssessment(courseSlug)
        //   } else if (response[0].showConsent) {
        //     Controller.get().courseMgr().navToCourse(courseSlug)
        //   } else if (response[0].showFeedback) {
        //     Controller.get().courseMgr().navToFeedback(courseSlug)
        //   } else {
        //     Controller.get().courseMgr().navToCourse(courseSlug)
        //   }
        // }
      }
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  const onPreviousButtonAction = () => {
    Controller.get().courseMgr().navToChapter(courseSlug, state.selectedItem.previousChapterId, props.preview, orgId)
    fetchData(courseSlug, state.selectedItem.previousChapterId)
  }

  const onNextButtonAction = () => {
    if (state.selectedItem.isLastChapter) {
      const reviewStatus = props.location && props.location.state && props.location.state
      //if (state.result.courseComplete && !reviewStatus.review) {
      if (!reviewStatus.review) {
        if (state.result.showAssessment) {
          Controller.get().courseMgr().navToAssessment(courseSlug)
        } else if (state.result.showConsent) {
          Controller.get().courseMgr().navToConsent(courseSlug)
        } else if (state.result.showFeedback) {
          Controller.get().courseMgr().navToFeedback(courseSlug)
        } else if (state.result.showCertification) {
          const aid = AppHelper.getAssignmentId(state.result)
          Controller.get().courseMgr().navToCertificate(courseSlug, { course: true, aid})
        } else {
          Controller.get().courseMgr().navToCourse(courseSlug)
        }
      }
    } else {
      Controller.get().courseMgr().navToChapter(courseSlug, state.selectedItem.nextChapterId, props.preview, orgId)
      fetchData(courseSlug, state.selectedItem.nextChapterId)
    }
  }

  const onDrawerOpen = () => {
    setState({
      ...state,
      drawerIsOpen: true,
    })
  }

  const onDrawerClose = () => {
    setState({
      ...state,
      drawerIsOpen: false,
    })
  }

  const checklistDialogOpen = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.pause();
    }
    setState({
      ...state,
      checklistDialogOpen: true,
      stopVideo: true
    })
  }

  const checklistDialogClose = () => {
    setState({
      ...state,
      checklistDialogOpen: false,
    })
  }

  const quizDialogOpen = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.pause();
    }
    setState({
      ...state,
      quizDialogOpen: true,
      checklistDialogOpen: false,
      stopVideo: true
    })
  }

  const quizDialogClose = () => {
    setState({
      ...state,
      quizDialogOpen: false,
    })
  }

  const markAsCompleted = async () => {
    if (videoRef && videoRef.current) {
      videoRef.current.pause();
    } setState({
      ...state,
      quizDialogOpen: false,
      checklistDialogOpen: false,
      inProcess: true,
      stopVideo: true
    })
    try {
      const markAsCompletedResponse = await Controller.get().courseMgr().markChapterComplete(chapterId, state.result.courseprogs)
      if (markAsCompletedResponse) {
        const selectedItem = state.selectedItem
        const result = state.result
        for (const c of result.chapters) {
          if (c.id === selectedItem.id) {
            c.status = CourseMgr.Status.COMPLETED
          }
        }
        selectedItem.buttonCompleted = true
        selectedItem.buttonLabel = Helper.getString('markAsIncomplete')


        // if(selectedItem.isLastChapter) {
        //   const reviewStatus = props.location && props.location.state && props.location.state
        //   //if (state.result.courseComplete && !reviewStatus.review) {
        //   if (!reviewStatus.review) {
        //     if (state.result.showAssessment) {
        //       Controller.get().courseMgr().navToAssessment(courseSlug)
        //     } else if (state.result.showConsent) {
        //       Controller.get().courseMgr().navToCourse(courseSlug)
        //     } else if (state.result.showFeedback) {
        //       Controller.get().courseMgr().navToFeedback(courseSlug)
        //     } else {
        //       Controller.get().courseMgr().navToCourse(courseSlug)
        //     }
        //   }
        // } else {
        //   selectedItem.showNextButton = true
        // }

        selectedItem.showNextButton = true

        setState({
          ...state,
          selectedItem,
          result,
          //TODO: state handling, following state variables had to be set otherwise the state setting before markChapterComplete was not getting applied
          quizDialogOpen: false,
          checklistDialogOpen: false,
          stopVideo: true
        })

        //await fetchData(courseSlug, chapterId, true)
      }
    } catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  const markAsInCompleted = async () => {
    if (videoRef && videoRef.current) {
      videoRef.current.pause();
    }
    setState({
      ...state,
      inProcess: true,
      stopVideo: true
    })
    try {
      const markAsInCompletedResponse = await Controller.get().courseMgr().markChapterInComplete(chapterId, state.result.courseprogs)
      if (markAsInCompletedResponse) {
        const selectedItem = state.selectedItem
        const result = state.result
        for (const c of result.chapters) {
          if (c.id === selectedItem.id) {
            c.status = CourseMgr.Status.PROGRESS
          }
        }
        selectedItem.showNextButton = false
        selectedItem.buttonCompleted = false
        selectedItem.buttonLabel = Helper.getString('markAsComplete')
        setState({
          ...state,
          selectedItem,
          result,
          //TODO: state handling, following state variables had to be set otherwise the state setting before markChapterInComplete was not getting applied
          inProcess: false,
          stopVideo: true
        })
        //await fetchData(courseSlug, chapterId, true)
      }
      if (videoRef && videoRef.current) {
        videoRef.current.play();
      }

    } catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  const videoCompleted = () => {
    setVideoCompleted(true)
  }

  const typeHandler = (data) => {
    if (data.iType === 'YOUTUBE') {
      return <div className={css(Styles.typeContainer)}><Video videoLink={data.contentLink} data={data} stopVideo={state.stopVideo} /></div>
    } else if (data.iType === 'LINK') {
      return <div className={css(Styles.typeContainer)}><Html htmlLink={data.contentLink} /></div>
    } else if (data.iType === 'IMAGE') {
      return <div className={css(Styles.typeContainer)}><Image imgLink={data.contentLink} /></div>
    } else if (data.iType === 'PDF') {
      return <div className={css(Styles.typeContainer)}><DocumentFile data={data} height={580} /></div>
    } else if (data.iType === 'SHORTVIDEO') {
      return <div className={css(Styles.typeContainer)}><ShortVideo videoCompleted={videoCompleted} videoLink={data.contentLink} data={data} videoRef={videoRef} state={state} /></div>
    } else if (data.iType === 'FILE') {
      return <div className={css(Styles.typeContainer)}><File link={data.contentLink} /></div>
    }
  }

  const buttonAction = () => {
    if (state.selectedItem.buttonCompleted) {
      return markAsInCompleted()
    } else if (state.result.buttonAction === 'checklistDialogOpen') {
      return checklistDialogOpen()
    } else if (state.result.buttonAction === 'quizDialogOpen') {
      return quizDialogOpen()
    } else {
      return markAsCompleted()
    }
  }

  const desktopNextButton = () => {
    if (props.preview) {
      if (state.selectedItem.nextChapterId) {
        return <div
          className={css(Styles.secondaryButtonContainer)}
          onClick={() => onNextButtonAction()}>
          <p className={css(CommonStyles.secondaryButtonText, Styles.secondaryButtonText)}>{Helper.getString('next')}</p>
          <img
            src={require('../assets/images/double_arrow_right_icon.svg')}
            className={css(Styles.arrowImgContent)} width='16px' height='16px'
            alt={Helper.getString('buttonArrowImgAlt')} />
        </div>
      }
    } else if (state.selectedItem.showNextButton) {
      return <div
        className={css(Styles.secondaryButtonContainer)}
        onClick={() => onNextButtonAction()}>
        <p className={css(CommonStyles.secondaryButtonText, Styles.secondaryButtonText)}>{Helper.getString('next')}</p>
        <img
          src={require('../assets/images/double_arrow_right_icon.svg')}
          className={css(Styles.arrowImgContent)} width='16px' height='16px'
          alt={Helper.getString('buttonArrowImgAlt')} />
      </div>
    }
    return null
  }

  const mobileNextButton = () => {
    if (props.preview) {
      if (state.selectedItem.nextChapterId) {
        return <div className={css(Styles.mobileInnerContent)}>
          <img onClick={() => onNextButtonAction()}
            src={require('../assets/images/double_arrow_right_icon.svg')}
            className={css(Styles.arrowImgMobileContent)} width='24px' height='24px'
            alt={Helper.getString('buttonArrowImgAlt')} />
        </div>
      }
    } else if (state.selectedItem.showNextButton) {
      return <div className={css(Styles.mobileInnerContent)}>
        <img onClick={() => onNextButtonAction()}
          src={require('../assets/images/double_arrow_right_icon.svg')}
          className={css(Styles.arrowImgMobileContent)} width='24px' height='24px'
          alt={Helper.getString('buttonArrowImgAlt')} />
      </div>
    }
    return null
  }

  const bottomButtonHandler = () => {
    const buttonContainerStyle = !state.selectedItem.buttonCompleted ? Styles.buttonContainer : [Styles.buttonContainer, Styles.buttonContainerComplete]
    const buttonTextStyle = !state.selectedItem.buttonCompleted ? Styles.buttonTextStyle : [Styles.buttonTextStyle, Styles.buttonTextCompleteStyle]
    const showActionButton = (state.selectedItem.iType === 'SHORTVIDEO' && isVideoCompleted) || state.selectedItem.iType !== 'SHORTVIDEO'
    if (!cssBreakpoint) {
      return (
        <div className={css(Styles.buttonOuterContainer)}>
          <div className={css(Styles.buttonInnerContainer)}>
            {state.selectedItem.showPreviousButton &&
              <div
                className={css(Styles.secondaryButtonContainer)}
                onClick={() => onPreviousButtonAction()}>
                <img
                  src={require('../assets/images/double_arrow_left_icon.svg')}
                  className={css(Styles.arrowImgContent)} width='16px' height='16px'
                  alt={Helper.getString('buttonArrowImgAlt')} />
                <p className={css(CommonStyles.secondaryButtonText, Styles.previousButtonText)}>{Helper.getString('previous')}</p>
              </div>
            }
          </div>
          <div className={css(Styles.buttonInnerCenterContainer)}>
            {(!props.preview && !state.result.courseComplete && showActionButton) &&
              <AppButton
                // onClick={() => Controller.get().courseMgr().navToChapter(courseSlug, state.selectedItem.nextChapterId)}
                onClick={() => buttonAction()}
                buttonContainer={buttonContainerStyle}
                buttonTextStyle={buttonTextStyle}
                buttonText={state.selectedItem.buttonLabel}
                inProcess={state.inProcess}
              />
            }
          </div>
          <div className={css(Styles.buttonInnerEndContainer)}>
            {desktopNextButton()}
          </div>
        </div>
      )
    } else {
      return (
        <div className={props.preview ? css(Styles.buttonMobileOuterContainer, Styles.buttonMobilePreviewOuterContainer) : css(Styles.buttonMobileOuterContainer)}>
          <div className={css(Styles.mobileInnerContainer)}>
            <div className={css(Styles.mobileInnerContent)}>
              {state.selectedItem.previousChapterId &&
                <img onClick={() => onPreviousButtonAction()}
                  src={require('../assets/images/double_arrow_left_icon.svg')}
                  className={css(Styles.arrowImgMobileContent)} width='24px' height='24px'
                  alt={Helper.getString('buttonArrowImgAlt')} />
              }
            </div>
            {/* <div className={css(Styles.mobileInnerContent)}> */}
            <img
              onClick={() => onDrawerOpen()}
              src={require('../assets/images/sort_icon.svg')}
              className={css(Styles.arrowImgMobileContent, Styles.sortImgMobileContent)} width='24px' height='24px'
              alt={Helper.getString('drawerButtonImgAlt')} />
            {/* </div> */}
            {mobileNextButton()}
          </div>
          <div className={css(Styles.mobileInnerContainer, Styles.mobileInnerSecondContainer)}>
            {(!props.preview && !state.result.courseComplete && showActionButton) &&
              <AppButton
                // onClick={() => Controller.get().courseMgr().navToChapter(courseSlug, state.selectedItem.nextChapterId)}
                onClick={() => buttonAction()}
                buttonContainer={buttonContainerStyle}
                buttonTextStyle={buttonTextStyle}
                buttonText={state.selectedItem.buttonLabel}
                inProcess={state.inProcess}
              />
            }
          </div>
        </div>
      )
    }
  }

  const backNav = () => {
    const backNav = props.location && props.location.state && props.location.state
    if (orgId === 'PRODUCT' && props.preview) {
      return '/admin/courses/preview/' + courseSlug
    } else if (orgId !== 'PRODUCT' && props.preview) {
      return '/admin/orgs/' + orgId + '/courses/preview/' + courseSlug
    } else {
      return '/learner/orgs/' + orgId + '/course/' + courseSlug
    }
  }

  const backButton = {
    title: Helper.getString('back'),
    link: backNav()
  }

  return (
    <div className={css(CommonStyles.contentStackOuterContainer)}>
      <div className={css(CommonStyles.contentStackFirstOuterContainer)}>

        <ScreenWrapper
          state={state}
          disableFooter={true}
          outerContainer={props.preview ? [Styles.outerContainer, Styles.outerPreviewContainer] : Styles.outerContainer}
          backButton={backButton}
          preview={props.preview} >
          {state.selectedItem &&
            <Fragment>
              {state.selectedItem.title &&
                <TitleComponent
                  title={state.selectedItem.title} />
              }

              {(state.selectedItem.info1 || state.selectedItem.info2) &&
                <div className={css(Styles.borderContainer)}>

                  {state.selectedItem.info1 &&
                    <div className={css(Styles.detailTipContainer)}>
                      <img
                        src={require('../assets/images/info_circle_icon.svg')}
                        className={css(Styles.detailImgContent)} width='20px' height='20px'
                        alt={Helper.getString('infoImgAlt')} />
                      <p className={css(Styles.detailTitleContainer)}>{Helper.getString('tip')}:</p>
                      <p className={css(Styles.detailTitleContent, Styles.detailInfo)}>{state.selectedItem.info1}</p>
                    </div>
                  }
                  {state.selectedItem.info2 &&
                    <div className={css(Styles.detailTaskContainer)}>
                      <img
                        src={require('../assets/images/task_icon.svg')}
                        className={css(Styles.detailImgContent)} width='20px' height='20px'
                        alt={Helper.getString('taskImgAlt')} />
                      <p className={css(Styles.detailTitleContainer)}>{Helper.getString('task')}:</p>
                      <p className={css(Styles.detailTitleContent)}>{state.selectedItem.info2}</p>
                    </div>
                  }
                </div>
              }

              {state.selectedItem.iType &&
                typeHandler(state.selectedItem)
              }

              {state.selectedItem.contentText &&
                <div className={css(Styles.textContainer)}>
                  <Text descr={state.selectedItem.contentText} />
                </div>
              }

              {bottomButtonHandler()}

            </Fragment>
          }
        </ScreenWrapper>
      </div>

      {state.fetchState === ApiHelper.State.READY &&
        <div className={css(CommonStyles.contentStacksecondOuterContainer)}>
          {state.result &&
            state.result.chapters &&
            state.result.chapters.length > 0 &&
            <Section data={state.result} preview={props.preview} courseCompletionInfo={state.courseCompletionInfo} />
          }
        </div>
      }

      <DrawerComponent
        open={state.drawerIsOpen}
        onClose={() => onDrawerClose()} >
        {state.result && state.result.chapters && state.result.chapters.length > 0 &&
          <Section data={state.result} preview={props.preview} courseCompletionInfo={state.courseCompletionInfo} />
        }
      </DrawerComponent>

      {state.result && state.result.chapterkcsObj &&
        <Checklist
          dialogOpen={state.checklistDialogOpen}
          onClose={() => checklistDialogClose()}
          quizDialogOpen={() => (state.result && state.result.chapterquestions && state.result.chapterquestions.length > 0) ?
            quizDialogOpen() : markAsCompleted()}
          data={state.result.chapterkcsObj}
          courseprogs={state.result.courseprogs}
        />
      }

      {state.result && state.result.chapterquestions && state.result.chapterquestions.length > 0 &&
        <Quiz
          dialogOpen={state.quizDialogOpen}
          onClose={() => quizDialogClose()}
          data={state.result.chapterquestions}
          submit={() => markAsCompleted()}
          courseprogs={state.result.courseprogs}
        />
      }


    </div>
  )

}

const drawerWidth = 135
const Styles = StyleSheet.create({
  outerContainer: {
    paddingBottom: 100,
    '@media(min-width: 768px) and (max-width: 1024px)': {
      padding: '40px 25px 85px'
    }
  },
  outerPreviewContainer: {
    '@media(max-width: 1024px)': {
      paddingBottom: 185
    }
  },
  buttonOuterContainer: {
    position: 'fixed',
    left: drawerWidth,
    right: AppCommonTheme.secondaryDivWidth,
    bottom: 0,
    display: 'flex',
    padding: '25px 40px',
    backgroundColor: AppTheme.secondaryBg,
    zIndex: 15
  },
  buttonMobileOuterContainer: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    padding: 20,
    backgroundColor: AppTheme.secondaryBg,
    '@media(min-width: 768px) and (max-width: 1024px)': {
      padding: '20px 25px',
      paddingTop: 15
    }
  },
  buttonMobilePreviewOuterContainer: {
    '@media(max-width: 1024px)': {
      bottom: 86
    }
  },
  buttonInnerContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  buttonInnerCenterContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1
  },
  buttonInnerEndContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    alignItems: 'center'
  },
  button: {
    minWidth: 100,
    marginRight: 10
  },
  typeContainer: {
    marginTop: 25,
    '@media (max-width: 767px)': {
      marginTop: 30,
    },
  },
  textContainer: {
    marginTop: 34,
    '@media (max-width: 767px)': {
      marginTop: 24,
    },
  },
  previousButtonText: {
    fontSize: 14,
    marginLeft: 8
  },
  secondaryButtonText: {
    fontSize: 14,
    marginRight: 8
  },
  buttonContainer: {
    padding: '8px 20px',
    minWidth: 175
  },
  buttonContainerComplete: {
    backgroundColor: '#DCDBE0'
  },
  buttonTextStyle: {
    textTransform: 'none'
  },
  buttonTextCompleteStyle: {
    color: AppTheme.primaryColor
  },
  secondaryButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer'
    }
  },
  arrowImgContent: {
    width: 16,
    height: 16
  },

  borderContainer: {
    padding: 20,
    border: AppCommonTheme.border,
    borderRadius: AppCommonTheme.borderRadius,
    marginTop: 24,
    '@media (max-width: 767px)': {
      padding: 12,
      marginTop: 16
    }
  },
  detailTipContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  detailTaskContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 20
  },
  detailContainerSecondary: {
    marginTop: 12
  },
  detailImgContent: {
    width: 20,
    height: 20
  },
  detailTitleContainer: {
    fontSize: 18,
    color: AppTheme.primaryColor,
    margin: AppCommonTheme.pMargin,
    marginLeft: 5,
    '@media (max-width: 767px)': {
      fontSize: 16
    },
  },
  detailTitleContent: {
    fontSize: 18,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginLeft: 6,
    '@media (max-width: 767px)': {
      fontSize: 16
    },
  },
  detailInfo: {
    marginLeft: 16,
  },

  mobileInnerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  mobileInnerSecondContainer: {
    justifyContent: 'flex-end',
    marginLeft: 10,
    flex: 'unset',
  },
  arrowImgMobileContent: {
    width: 24,
    height: 24,
    cursor: 'pointer'
  },
  sortImgMobileContent: {
    margin: '0px 35px'
  },

  mobileInnerContent: {
    width: 24,
    height: 24,
  }
})