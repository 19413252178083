import React, { useEffect, useState, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { TitleComponent } from '../components/TitleComponent';
import { DocumentFile } from '../components/DocumentFile';
import { Helper } from '../common/helpers/Helper';
import { AppButton } from '../components/AppButton';
import { AppContext } from '../common/components/contexts/AppContext';
import { AppHelper } from '../helpers/AppHelper';
import Handlebars from 'handlebars';
import moment from 'moment';
import { ErrorMsg } from '../components/ErrorMsg';

export function Certificate(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {}
  })
  const courseSlug = props.match.params.slug
  const aid = props.match.params.aid
  const orgId = Controller.get().roleHelper().getOrgId()

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const profileData = Controller.get().userMgr().getAppUser()
      const courseData = await Controller.get().courseMgr().getCourseDetails2( { orgId, courseSlug, aid } )
      const domainName = localStorage.getItem('domainName')
      const profileInformation = profileData.orgs.filter(i => i.domain === domainName)
      const certificateImg = profileInformation[0].logo || 'https://d3jag1klxt4ik.cloudfront.net/images/certificate-trophy.png'
      if (profileInformation) {
        const data = {
          templateName: courseData.certificateTitle,
          name: `${profileInformation[0].orgUserProfile.givenname} ${profileInformation[0].orgUserProfile.familyname}`,
          courseName: courseData.title,
          duration: courseData.duration,
          companyName: profileInformation[0].title,
          completedDate: (courseData.courseprogs && courseData.courseprogs.completionTs) &&
            moment.utc(courseData.courseprogs.completionTs).local().format('MMM DD,  YYYY'),
          image: certificateImg
        }
        const htmlContent = require('../components/Certificate/template.js')
        const template = Handlebars.compile(htmlContent);
        const htmlString = template(data);
        try {
          const response = await Controller.get().courseMgr().getHtmlConvs(htmlString)
          if (response) {
            setState({
              ...state,
              fetchState: ApiHelper.State.READY,
              result: response,
              fileLink: response.file,
              htmlString: htmlString
            })
          }
        } catch (e) {
          setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
        }
      }
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const backNav = () => {
    const backNav = props.location && props.location.state && props.location.state
    if(backNav && backNav.accolades) {
      return '/learner/orgs/' + orgId + '/accolades'
    } else if(backNav && backNav.result) {
      return '/learner/orgs/' + orgId + '/course/' + courseSlug + '/result'
    } else if(backNav && backNav.course) {
      return '/learner/orgs/' + orgId + '/course/' + courseSlug
    } else {
      return '/learner/orgs/' + orgId + '/accolades'
    }
  }

  const backButton = {
    title: Helper.getString('back'),
    link: backNav()
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  const downloadFile = () => {
    const fName ='Certificate.pdf'
    console.log('state.fileLink', state.fileLink)

      if(navigator && navigator.userAgent.startsWith('Azsincx')) {
        AppHelper.downloadFileForMobileApp(state.fileLink, fName)
      } else {
        AppHelper.downloadFile(state.fileLink)
      }
  }

  return (
    <ScreenWrapper
      state={state}
      backButton={backButton}
      subTitle={Helper.getString('courseCertificate')} >
      <TitleComponent
        title={Helper.getString('coursePassedTitle')} />

      {state.fileLink &&
        <div className={css(Styles.buttonOuterContainer)}>
          <AppButton
            buttonContainer={Styles.buttonContainer}
            buttonText={Helper.getString('download')}
            img={'download_icon_light'}
            imgAlt={Helper.getString('downloadImgAlt')}
            onClick={() => downloadFile()}
            // onClick={() => setState({
            //   ...state,
            //   openError: true,
            //   errorMsg: Helper.getString('comingSoon')
            // })}
          />
        </div>
      }

      <div className={css(Styles.certificateContainer)}>
        <div className={css(Styles.certificateInnerContainer)}>
          {state.fileLink &&
            <div dangerouslySetInnerHTML={{ __html: state.htmlString }} className={css(Styles.htmlContainer)} />
          }
        </div>
      </div>
      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  buttonOuterContainer: {
    marginTop: 20,
    marginBottom: 24,
    '@media (max-width: 767px)': {
      marginTop: 24,
      marginBottom: 40,
    },
  },
  buttonContainer: {
    padding: '8px 20px',
    '@media (max-width: 767px)': {
      padding: '8px 15px',
    },
  },
  certificateContainer: {
    display: 'grid',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center'
  },
  certificateInnerContainer: {
    overflow: 'hidden',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  },
  htmlContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '@media (max-width: 500px)': {
      marginTop: -150
    },
  }
})