import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { BaseEntityPage } from '../common/components/BaseEntityPage';
const vsprintf = require('sprintf-js').vsprintf

export class Jobs extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = 'Jobs'
    this.pageKey = 'jobs'
    this.orgId = this.props.match.params.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: 'Job History' },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['jobs'], { groupId: this.orgId, pb: 'fieldDefJob' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Job: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.action}</span>
            <br/>
            <span className={css(Styles.spanValue)}>Job started at: {Helper.formatTimestamp(current.createdTs)}</span>
            <br/>
          </div>
        )
      },
      Status: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.state}</span>
            <br/>
            {current.state === 'SUCCESS' && current.action === 'IMPORT_PRODUCTS' &&
              <span>
                <span className={css(Styles.spanValue)}>{current.result.message}</span>
                <br/>
              </span>
            }
            {current.state === 'ERROR' &&
              <span>
                <span className={css(Styles.spanValue)}>
                  {vsprintf('%s', [current.result.payload ? current.result.payload.message : 'Unknown'])}
                </span>
                <br/>
              </span>
            }
          </div>
        )
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      groupId: this.orgId
    }
    this.headerActions = [
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.fetchItems()
      },
      {
        label: 'New Job',
        onClick: () => this.openModalForAdd()
      },
    ]
  }

  onFetchSuccess(results) {
    this.fieldDef = this.formDefinition = results[0].fieldDefJob
    const fieldAction = this.formDefinition.steps[0].fields.find(i => i.key === 'action')
    fieldAction.options = fieldAction.options.filter(i => !i.key.startsWith('_'))
    this.setState({
      items: results[0].items,
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['jobs']),
    });
  }

  onActions(current) {
    const actions = []
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})