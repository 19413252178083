import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../../../styles/Styles';
import { Controller } from '../../../common/Controller';
import { Courses } from '../../../components/Courses/Courses';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Status } from '../../../components/Status/Status';
import { ScreenWrapper } from '../../../components/ScreenWrapper';
import { Helper } from '../../../common/helpers/Helper';
import { AppContext } from '../../../common/components/contexts/AppContext';
import { UserContext } from '../../../common/components/contexts/UserContext';
import { AppHelper } from '../../../helpers/AppHelper';
import { EmptyView } from '../../../components/EmptyView';
import { MockData } from '../../../data/MockData';
import { CustomTableComponent } from '../../../components/CustomTableComponent';


export function DashboardAdmin(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const { user, userContextStateHandler } = useContext(UserContext)
  const [state, setState] = useState({
  })
  const exportData = { format: 'xlsx' }

  const onExport = (tableData) => {
    if (exportData && exportData.format === 'xlsx') {
      const DwnldUtil = require('../../../common/helpers/FileDownloader');
      const fileName = makeFileName();
      const rows = prepExcelDownload(tableData)
      DwnldUtil.FileDownloader.exportAsExcelFile(rows, fileName)
    }
  }

  const downloadTable = (data) => {
    exportData.headers = data.header
    onExport(data)
  }

  const makeFileName = () => {
    return 'doc-excel';
  }


  const prepExcelDownload = (data) => {
    const rows = []
    data.data.forEach(rowData => {
      const e = {}
      data.header.forEach(header => {
        if (rowData[header.key] && Array.isArray(rowData[header.key])) {
          e[header.title] = rowData[header.key].join(', ')
        } else {
          e[header.title] = rowData[header.key] ? rowData[header.key] : '-'
        }

      });
      rows.push(e)
    });
    return rows
  }

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await ApiHelper.callAwait({
        method: 'GET',
        paths: ['miscs', 'sudashboard'],
      })
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        dashboard: {
          data: response.items || [],
          ...MockData.tableSU
        },
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'dashboardAdmin' })
    fetchData()
  }, [])

  return (
    <ScreenWrapper
      title={'Dashboard'}
      favourite={false}
      dashboard={false}
      state={state}
      logo={false}
      //outerContainer={dynamicStyles.outerContainer}
      outerContainer={Styles.outerContainer}
    >
      {state.dashboard &&
        <CustomTableComponent title={Helper.getString('adminTableTitle')}data={state.dashboard} downloadTable={downloadTable}/>
      }
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  outerContainer: {
    flex: 'unset',
    display: 'unset',
    width: '100%',
    overflow: 'scroll'
  }
})