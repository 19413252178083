import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import TextField from '@material-ui/core/TextField';

import { AppCommonTheme } from '../styles/Styles';

export function MultiTextFiled(props) {
  return (
    <TextField
      fullWidth
      multiline
      rows={props.rows}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onInputChange}
      className={css(Styles.textField)}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: css(Styles.textFieldRootContent),
          input: css(Styles.textFieldInputContent),
        }
      }}  />
  )
}

const Styles = StyleSheet.create({
  textField: {
    border: '1px solid #AAABAF',
    borderRadius: 12,
    marginTop: 10
  },
  textFieldRootContent: {
    padding: 20,
    '@media (max-width: 767px)': {
      padding: 12,
    },
  },
  textFieldInputContent: {
    padding: 0,
    fontSize: 22,
    color: AppCommonTheme.appFontColor,
    '@media (max-width: 767px)': {
      fontSize: 16,
    },
  },
})