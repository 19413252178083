import React, { useEffect, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { Helper } from '../common/helpers/Helper';
import { TermsAndConditions } from './TermsAndConditions';


export function TermsAndConditionsWrapper(props) {

  return (
    <ScreenWrapper
      logo={true}>
      <TermsAndConditions learner={true}/>
    </ScreenWrapper>
  )
}

const Styles = StyleSheet.create({

})
