import React, { useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppConfig } from '../AppConfig';
import { AppCommonTheme } from '../styles/Styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { AppContext } from '../common/components/contexts/AppContext';

export function ErrorMsg(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const ERROR_MSG = Helper.getString('defaultApiErrMsg')

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={props.open ? props.open : (appData && appData.errorMsgOpen)}
      message={props.errMsg || ERROR_MSG}
      action={
        <React.Fragment >
          <Button onClick={() => props.closeError ? props.closeError() : appContextStateHandler({ errorMsgOpen: false })} className={css(Styles.buttonAction)}>
            {Helper.getString('dismiss')}
          </Button>
        </React.Fragment>
      }
      ContentProps={{
        classes: {
          root: css(Styles.root),
          message: css(Styles.message),
          action: css(Styles.action),
        }
      }}
    />
  )
}

const Styles = StyleSheet.create({
  root: {
    backgroundColor: '#FFD8D8',
    border: '1px solid #FF0000',
    borderRadius: AppCommonTheme.borderRadius,
    padding: 0,
    color: AppCommonTheme.appFontColor,
    fontSize: 16,
    minWidth: 500,
    '@media (max-width: 767px)': {
      minWidth: 'unset',
      width: '100%',
      fontSize: 12
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      minWidth: 500,
    }
  },
  message: {
    display: 'flex',
    flex: 1,
    padding: '15px 25px',
    '@media (max-width: 767px)': {
      padding: '15px 10px',
    },
  },
  action: {
    borderLeft: '1px solid #FF0000',
    padding: 0
  },
  buttonAction: {
    padding: '15px 40px',
    paddingRight: 48,
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 600,
    color: AppCommonTheme.appFontColor,
    '@media (max-width: 767px)': {
      fontSize: 14,
      padding: '10px 20px',
      paddingRight: 28
    },
  }
})