import React, { Fragment, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../../common/helpers/Helper';
import { AppCommonTheme } from '../../styles/Styles';
import { AppTheme } from '../../styles/AppTheme';
import Grid from '@material-ui/core/Grid';
import { Controller } from '../../common/Controller';
import { Player } from '@lottiefiles/react-lottie-player';
import { AppContext } from '../../common/components/contexts/AppContext';
import { FavContext } from '../../common/components/contexts/FavContext';
import { AppHelper } from '../../helpers/AppHelper';
import { ErrorMsg } from '../ErrorMsg';

export function ResourceTile(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const { fav, deleteResourcesFromFav, addResourcesToFav } = useContext(FavContext)
  const [state, setState] = useState({
    favProgress: false
  })

  useEffect(() => {
    isPresentInFavList()
  }, [fav.resourceData, state.favProgress])

  const isPresentInFavList = () => {
    if (fav && fav.resourceData && fav.resourceData.items && fav.favCourseData) {
      for (const f of fav.resourceData.items) {
        if (f.id === item.id) {
          setState({
            ...state,
            favIcon: true,
            selectedFav: f,
            favLearnerId: fav.favCourseData.favLearnerId
          })
          return
        }
      }
    }
  }

  const toggleResources = async (e) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    setState({
      ...state,
      favProgress: true,
    })
    if (state.favIcon) {
      const orgId = Controller.get().roleHelper().getOrgId()
      const id = orgId + '-' + state.favLearnerId + '-favresource-' + item.id
      const deletedFav = await deleteResourcesFromFav({ courseResourceId: state.selectedFav.id, id: id })
      if (deletedFav) {
        setState({
          ...state,
          favProgress: false,
          favIcon: false,
        })
      }
    } else {
      const addFav = await addResourcesToFav({ courseResourceId: item.id })
      if (addFav) {
        setState({
          ...state,
          favProgress: false,
          favIcon: true
        })
      }
    }

  }

  const shareResources = async (e) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    setState({
      ...state,
      openError: true,
      errorMsg: Helper.getString('comingSoon')
    })
  }

  const downloadResources = async (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    // setState({
    //   ...state,
    //   openError: true,
    //   errorMsg: Helper.getString('comingSoon')
    // })
    setState({
      ...state,
      downloadProgress: true,
    })
    const courseId = item.id.split('courseresource').pop().split('-')[0]
    const course = await Controller.get().courseMgr().getCourseSlug(courseId)

    const result = await Controller.get().courseMgr().getResourceDetail(item.id, course.slug)
    if (result && result.link) {
      setState({
        ...state,
        downloadProgress: false,
      })
      const fName = AppHelper.getFileName(item)
      if (navigator && navigator.userAgent.startsWith('Azsincx')) {
        AppHelper.downloadFileForMobileApp(result.link, fName)
      } else {
        AppHelper.downloadFile(result.link)
      }
    }
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  const item = props.item
  const favouriteIcon = state.favIcon ? 'heart_selected_icon' : 'heart'
  const mobileView = props.mobileView
  return (
    <Fragment>
      <Grid key={item.id} lg={mobileView ? 12 : 6} xs={12} sm={12} md={6} item className={css(Styles.borderContainer)}
        onClick={(e) => {
          if (item.iType === 'FILE') {
            // setState({
            //   ...state,
            //   openError: true,
            //   errorMsg: Helper.getString('comingSoon')
            // })
            downloadResources(e, item)
          } else {
            Controller.get().courseMgr().navToResourceDetail(item.id, props.backButton)
          }
        }}>

        <div className={mobileView ? css(Styles.outerContainer, Styles.outerContainerMobile) : css(Styles.outerContainer)}>
          <div className={css(Styles.innerContainer)}>
            <div className={css(Styles.textContainer)}>
              <p className={css(Styles.title)}>{item.title}</p>
              <p className={css(Styles.fileName)}>{item.fileName}</p>
              <div className={css(Styles.descrContainer)}>
                <p className={css(Styles.descr)}>{item.descr}</p>
              </div>
            </div>

            {(item.isDownloadable === 'YES' || item.iType === 'FILE') &&
              <div className={mobileView ? css(Styles.downloadContainer, Styles.downloadContainerMobile) : css(Styles.downloadContainer)}>
                {state.downloadProgress ?
                  <Player
                    autoplay
                    loop
                    src={require('../../assets/images/circle_loading_progress_indicator.json')}
                    className={mobileView ? css(Styles.downloadImgContent, Styles.downloadImgContentMobile) : css(Styles.downloadImgContent)} />
                  :
                  <img
                    onClick={(e) => downloadResources(e, item)}
                    alt={Helper.getString('downloadImgAlt')}
                    width='32px' height='32px'
                    src={require('../../assets/images/download_icon.svg')}
                    className={mobileView ? css(Styles.downloadImgContent, Styles.downloadImgContentMobile) : css(Styles.downloadImgContent)} />
                }
                <p className={css(Styles.download)}>{Helper.getString('download')}</p>
              </div>
            }
            <div className={mobileView ? css(Styles.iconContainer, Styles.iconContainerMobile) : css(Styles.iconContainer)}>
              {/* <img
                onClick={(e) => shareResources(e)}
                alt={Helper.getString('resourceListImgAlt')}
                width='24px' height='24px'
                src={require('../../assets/images/share_icon.svg')}
                className={mobileView ? css(Styles.imgContent, Styles.imgContentMobile) : css(Styles.imgContent)} /> */}
              <div className={css(Styles.heartImgContent)}>
                {state.favProgress ?
                  <div className={css(Styles.imgContainer)}>
                    <Player
                      autoplay
                      loop
                      src={require('../../assets/images/circle_loading_progress_indicator.json')}
                      className={css(Styles.downloadImgContentMobile)} />
                  </div>
                  :
                  <div className={css(Styles.imgContainer)}>
                    <img
                      alt={Helper.getString('resourceListImgAlt')}
                      onClick={(e) => toggleResources(e)}
                      width='24px' height='24px'
                      src={require(`../../assets/images/${favouriteIcon}.svg`)}
                      className={mobileView ? css(Styles.imgContent, Styles.imgContentMobile) : css(Styles.imgContent)} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  borderContainer: {
    display: 'flex',
    cursor: 'pointer'
  },
  outerContainerMobile: {
    padding: 12,
  },
  outerContainer: {
    display: 'flex',
    flex: 1,
    border: AppCommonTheme.border,
    padding: '12px 24px',
    borderRadius: AppCommonTheme.borderRadius,
    minHeight: 75,
    '@media (max-width: 767px)': {
      padding: '15px 12px',
    }
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  textContainer: {
    flex: 1
  },
  downloadImgContent: {
    width: 32,
    height: 32,
    '@media (max-width: 767px)': {
      width: 28,
      height: 28
    },
  },
  downloadImgContentMobile: {
    width: 24,
    height: 24
  },
  heartImgContent: {
    marginTop: 10
  },
  imgContent: {
    width: 24,
    height: 24,
    '@media (max-width: 767px)': {
      width: 22,
      height: 22
    },
    ':hover': {
      cursor: 'pointer'
    }
  },
  imgContentMobile: {
    width: 18,
    height: 18,
  },
  title: {
    color: AppCommonTheme.appFontColor,
    fontSize: 12,
    margin: AppCommonTheme.pMargin,
    marginBottom: 4,
    '@media (max-width: 767px)': {
      marginBottom: 6,
    }
  },
  fileName: {
    color: AppCommonTheme.appFontColor,
    fontSize: 18,
    margin: AppCommonTheme.pMargin,
    marginBottom: 8,
    fontWeight: 600,
    '@media (max-width: 767px)': {
      fontSize: 16,
      marginBottom: 6
    },
  },
  descrContainer: {
    display: 'grid'
  },
  descr: {
    color: AppCommonTheme.appFontColor,
    fontSize: 14,
    margin: AppCommonTheme.pMargin,
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      fontSize: 12
    }
  },
  download: {
    color: AppTheme.primaryColor,
    fontSize: 14,
    margin: AppCommonTheme.pMargin,
    marginTop: 5
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 30,
    '@media (max-width: 767px)': {
      marginLeft: 15,
    }
  },
  iconContainerMobile: {
    marginLeft: 15,
  },
  downloadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 30,
    '@media (max-width: 767px)': {
      marginLeft: 15,
    },
    ':hover': {
      cursor: 'pointer'
    }
  },
  downloadContainerMobile: {
    marginLeft: 15,
  },

  imgContainer: {
    width: 24,
    height: 24
  }
})