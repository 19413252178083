import React, { useContext, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../../common/helpers/Helper';
import { AppCommonTheme } from '../../styles/Styles';
import { AppTheme } from '../../styles/AppTheme';
import { CourseTileButton } from './CourseTileButton';
import { Controller } from '../../common/Controller';
import moment from 'moment';
import { AppContext } from '../../common/components/contexts/AppContext';
import { CourseMgr } from '../../common/mgrs/CourseMgr';
import { ErrorMsg } from '../../components/ErrorMsg';
import { AppHelper } from '../../helpers/AppHelper';

export function CourseTile(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({})

  const buttonAction = async () => {
    if (props.disableTileClick) return
    if (props.comingSoonMsg) {
      return appContextStateHandler({ errorMsgOpen: true })
    } else if (item.courseState === CourseMgr.CourseStatus.COMPLETED) {
      const id = item.cpId
      const aid = AppHelper.getAssignmentId({ courseprogs: { id: item.cpId }})
      return Controller.get().courseMgr().navToCertificate(item.slug, { accolades: true }, aid)
    } else {
      return Controller.get().courseMgr().navToCourse(item.slug, props.backButton)
    }
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  const statusValue = () => {
    if (item.courseState && (item.courseState === CourseMgr.CourseStatus.COMPLETED)) {
      return Helper.getString('completed')
    } else if (item._userContext && item._userContext.state) {
      return item._userContext.state
    }
    return null
  }

  const item = props.item
  const courseImg = item.image || require('../../assets/images/default_category_img.svg')

  const stateViewRender = () => {

    if (props.item._userContext && props.item._userContext.state === 'STARTED' && props.item._userContext.courseCompletionInfo && props.item._userContext.courseCompletionInfo.percentageCompletion) {
      return (
        <div className={css(Styles.progressbarContainer)}>
          <div className={css(Styles.progressBar)} style={{ width: `${props.item._userContext.courseCompletionInfo.percentageCompletion}%` || 0 }}>{props.item._userContext.courseCompletionInfo.percentageCompletion}%</div>
        </div>
      )
    } else return <CourseTileButton
      icon={true}
      status={item.courseState} />

  }

  return (
    <div className={props.disableTileClick ? css(Styles.imgContainer, Styles.pointerDisabled) : css(Styles.imgContainer)}
      style={{ background: courseImg && `linear-gradient(180deg, rgba(18, 18, 18, 0) 10%, rgba(18, 18, 18, 0.8) 50.21%), url(${courseImg}), #D9D9D9` }}
      onClick={() => buttonAction()}>
      <div className={css(Styles.innerContainer)}>
        <div className={css(Styles.statusContainer)}>
          <p className={css(Styles.status)}>{statusValue()}</p>
          {item.showCompletionTs &&
            <p className={css(Styles.completionTs)}>{Helper.formatDate2({ utcTimestamp: item.completionTs })}</p>
          }
        </div>
        <div className={css(Styles.titleContainer)}>
          {item.title &&
            <p className={css(Styles.title)}>{item.title}</p>
          }
        </div>
        <div className={css(Styles.detailContainer)}>
          {!item.showCompletionTs && item.duration &&
            <div className={css(Styles.detailInnerContainer, Styles.detailDurationContainer)}>
              <img src={require('../../assets/images/clock_icon.svg')} width='18px' height='18px' className={css(Styles.iconContainer)} />
              <p className={css(Styles.detailTextContent)}>{item.duration} {Helper.getString('mins')}</p>
            </div>
          }
          {item._userContext && item._userContext.dueDate &&
            <div className={css(Styles.detailInnerContainer)}>
              <img src={require('../../assets/images/calendar_white_icon.svg')} width='18px' height='18px' className={css(Styles.iconContainer)} />
              {item._userContext.state === 'COMPLETED' ?
                <p className={css(Styles.detailTextContent)}>{Helper.getString('completedOn')}: {item._userContext.completedDate}</p>
                :
                <p className={css(Styles.detailTextContent)}>{Helper.getString('due')}: {item._userContext.dueDate}</p>
              }
            </div>
          }
        </div>
        {!props.disableTileClick &&
          <div className={css(Styles.buttonContainer)}>
            {stateViewRender()}
            {
              item.showMedal &&
              <div className={css(Styles.medalContainer)}>
                <img src={require('../../assets/images/medal_light_icon.svg')} width='32px' height='32px' className={css(Styles.medalIconContainer)} />
              </div>
            }
          </div>
        }
      </div>
      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
    </div>
  )
}

const MARGINRIGHT = 30
const Styles = StyleSheet.create({
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
    overflow: 'hidden',
    flex: 1
  },
  imgContainer: {
    height: 260,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    borderRadius: AppCommonTheme.borderRadius,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ':hover': {
      cursor: 'pointer'
    },
    '@media (max-width: 767px)': {
      height: 140,
    }
  },
  pointerDisabled: {
    cursor: 'default',
    ':hover': {
      cursor: 'default'
    },
  },
  titleContainer: {
    display: 'grid',
    marginTop: 6,
    marginBottom: 13,
    marginRight: MARGINRIGHT,
    '@media (max-width: 767px)': {
      marginBottom: 9
    }
  },
  title: {
    color: AppTheme.secondaryBg,
    fontSize: 22,
    margin: AppCommonTheme.pMargin,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      fontSize: 18,
    }
  },
  status: {
    color: AppTheme.secondaryBg,
    fontSize: 14,
    margin: AppCommonTheme.pMargin,
    textTransform: 'uppercase',
    flex: 1,
    '@media (max-width: 767px)': {
      fontSize: 12
    }
  },
  completionTs: {
    color: AppTheme.secondaryBg,
    fontSize: 14,
    margin: AppCommonTheme.pMargin,
    textTransform: 'uppercase',
    '@media (max-width: 767px)': {
      fontSize: 12
    }
  },
  detailContainer: {
    display: 'flex',
  },
  detailInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    '@media (max-width: 767px)': {
      marginBottom: 8,
    }
  },
  iconContainer: {
    width: 18,
    height: 18,
    '@media (max-width: 767px)': {
      width: 16,
      height: 16,
    }
  },
  detailDurationContainer: {
    // flex: 1, //TODO alignment issue
    // justifyContent: 'flex-end',
    marginRight: 40,
    '@media (max-width: 767px)': {
      marginRight: 30
    }
  },
  detailTextContent: {
    margin: AppCommonTheme.pMargin,
    fontSize: 12,
    color: AppTheme.secondaryBg,
    marginLeft: 5,
    '@media (max-width: 767px)': {
      fontSize: 10
    }
  },
  statusContainer: {
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  medalContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  medalIconContainer: {
    width: 32,
    height: 32
  },
  progressbarContainer: {
    width: '100%',
    height: 33,
    borderRadius: 5,
    background: '#ffffff40'
  },
  progressBar: {
    background: '#4B8BE9',
    borderRadius: 5,
    height: '100%',
    boxSizing: 'border-box',
    color: '#fff',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})