import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';
import { StatusTile } from './StatusTile';

export function Status(props) {

  return (
    <div>
      {props.items && props.items.length > 0 &&
        <Grid container spacing={2}>
          {props.items.map((item, index) => {
            return (
              <StatusTile
                key={index}
                item={item}
                data={props.data}
                {...props} />
            )
          })}
        </Grid>
      }
    </div>
  )
}

const Styles = StyleSheet.create({
})