import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Login } from '../components/Login'
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';
import { Logo } from '../components/Logo';
import { Link } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { Terms } from './Terms';
import { Privacy } from './Privacy';
import { TermsAndConditions } from './TermsAndConditions';

export const LoginPage = (props) => {
  const [showContentDialog, setShowContentDialog] = useState(false)
  const [contentKey, setContentKey] = useState(null)
  const footerLinks = [
    {
      to: '/terms-of-use',
      title: 'Terms Of Use',
      key: 'terms'
    },
    {
      to: '/privacy-policy',
      title: 'Privacy Policy',
      key: 'privacy'
    },
    {
      to: '/terms-and-conditions',
      title: 'Terms & Conditions',
      key: 'tc'
    }
  ]

  const renderContentInDialog = () => {
    return <Dialog
      aria-labelledby={'content'}
      fullWidth={true}
      fullScreen={true}
      open={showContentDialog}
      maxWidth='sm'
    >
      <div className={css(Styles.dialogContainer)}>
        <div className={css(Styles.closeIconContainer)} onClick={() => setShowContentDialog(false)}>
          <img
            src={require('../assets/images/close_dark_icon.svg')}
            alt={Helper.getString('dialogCloseAlt')}
            height={36} width={36}
            className={css(Styles.closeIcon)} />
        </div>
        {contentKey === 'terms' && <Terms />}
        {contentKey === 'privacy' && <Privacy />}
        {contentKey === 'tc' && <TermsAndConditions />}
      </div>
    </Dialog>
  }

  const openContentDialog = (options) => {
    setContentKey(options.key)
    setShowContentDialog(true)
  }


  return (
    <div className={css(Styles.outerContainer)}>
      <div className={css(Styles.innerContainer, Styles.innerImgContainer)}>
        <div className={css(Styles.logoMobileContainer)}>
          <Logo
            login={true} />
        </div>
        <div className={css(Styles.imgContainer)}>
          <img
            alt={Helper.getString('loginBgImgAlt')}
            width='660px' height='500px'
            src={require('../assets/images/login_bg_img.svg')}
            className={css(Styles.imgContent)} />
        </div>
        <p className={css(Styles.title)}>{Helper.getString('loginTitle')}</p>
        <p className={css(Styles.subTitle)}>{Helper.getString('loginSubTitle')}</p>
      </div>
      <div className={css(Styles.innerContainer)}>
        <div className={css(Styles.formFieldContainer)}>
          <div className={css(Styles.logoDesktopContainer)}>
            <Logo
              login={true} />
          </div>
          <p className={css(Styles.header)}>{Helper.getString('hello')}</p>
          <p className={css(Styles.descr)}>{Helper.getString('loginDescr')}</p>
          <Login
            openContentDialog={openContentDialog}
            showTerms={true} />
        </div>
        <div className={css(Styles.footerLinkContainer)}>
          {footerLinks.map((item, index) => {
            if (navigator && navigator.userAgent.startsWith('Azsincx')) {
              return (
                <div onClick={() => openContentDialog(item)}>
                  <p className={css(Styles.titleContainer)}>{item.title}</p>
                </div>
              )
            } else {
              return (
                <Link to={item.to} key={index} className={css(Styles.link)}>
                  <p className={css(Styles.titleContainer)}>{item.title}</p>
                </Link>
              )
            }
          })}
        </div>
      </div>
      {renderContentInDialog()}
    </div>
  )
}
const Styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flex: 1,
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    },
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    '@media (max-width: 767px)': {
      justifyContent: 'flex-start',
    }
  },
  innerImgContainer: {
    backgroundColor: '#FFE8E8',
    justifyContent: 'center',
    '@media (max-width: 767px)': {
      flex: .5,
      padding: '30px 20px 10px',
    },
  },

  imgContainer: {
    width: 660,
    height: 500,
    display: 'flex',
    '@media(min-width: 768px) and (max-width: 1024px)': {
      width: 340,
      height: 300,
    },
    '@media (max-width: 767px)': {
      width: '100%',
      height: 200
    },
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  title: {
    fontSize: 38,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 30,
    //maxWidth: 400,
    textAlign: 'center',
    fontWeight: 600,
    '@media (max-width: 767px)': {
      display: 'none'
    },
  },
  subTitle: {
    fontSize: 18,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 24,
    maxWidth: 500,
    textAlign: 'center',
    '@media (max-width: 767px)': {
      display: 'none'
    },
  },
  header: {
    fontSize: 38,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 40,
    textAlign: 'center',
    fontWeight: 600,
    '@media (max-width: 767px)': {
      marginTop: 5,
      fontSize: 28,
    },
  },
  descr: {
    fontSize: 18,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 15,
    textAlign: 'center',
    marginBottom: 5,
    '@media (max-width: 767px)': {
      marginBottom: 0,
      fontSize: 14
    }
  },
  formFieldContainer: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  logoDesktopContainer: {
    '@media (max-width: 767px)': {
      display: 'none'
    },
  },
  logoMobileContainer: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'block',
      width: '100%'
    },
  },
  footerLinkContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    width: '100%',
    '@media (max-width: 767px)': {
      fontSize: 12
    }
  },
  link: {
    textDecoration: 'none',
    color: '#000000'
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  closeIconContainer: {
    position: 'fixed',
    alignSelf: 'flex-end',
    right: 10,
    top: 25,
    borderRadius: '50%'
  },
  closeIcon: {
    backgroundColor: '#ededed',
    borderRadius: '50%'
  }
})
