import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Courses } from '../components/Courses/Courses';
import { Helper } from '../common/helpers/Helper';
import { AppHelper } from '../helpers/AppHelper';
import { CommonStyles } from '../styles/Styles';
import { AppContext } from '../common/components/contexts/AppContext';
import { EmptyView } from '../components/EmptyView';
import { CourseMgr } from '../common/mgrs/CourseMgr';

export function CoursesList(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {}
  })
  const orgId = Controller.get().roleHelper().getOrgId()
  const userId = Controller.get().userMgr().getUserId()
  const stateValue = props.location.search ? props.location.search.substring(props.location.search.indexOf('?courses=') + 9) : 'assigned'
  const statusItems = AppHelper.status()
  const dueDateLimit = 6
  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getCoursesList(orgId, userId)
      const courses = []
      if (response && response.courses && response.courses.items && response.courses.items.length > 0) {
        response.courses.items.map(item => {
          if (item._userContext) {
            if (stateValue === 'assigned') {
              courses.push(item)
            } else if (stateValue === 'selfenrolled' && item._userContext.selfenrolled === true) {
              courses.push(item)
            } else if (stateValue === 'ongoing' && item._userContext.state === 'STARTED') {
              courses.push(item)
            } else if (stateValue === 'duesoon' && item._userContext.duesoon === true) {
              courses.push(item)
            } else if (stateValue === 'overdue' && item._userContext.overdue === true) {
              courses.push(item)
            } else if (stateValue === 'completed' && (item._userContext.state === CourseMgr.CourseStatus.COMPLETED)) {
              courses.push(item)
            }
          }
        })
      }

      const courseList = {
        items: courses
      }
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response.courses,
        courseList: courseList
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const backButton = {
    title: Helper.getString('back'),
    link: '/learner/orgs/' + orgId + '/dashboard'
  }

  const statusData = statusItems.find(o => o.id === stateValue)
  return (
    <ScreenWrapper
      state={state}
      title={statusData && statusData.title && statusData.title}
      favourite={true}
      titleStyle={CommonStyles.titleStyle}
      backButton={backButton}
      logo={true}
    // search={true} //TODO search
    // filter={true}
    >

      {(state.courseList && state.courseList.items && state.courseList.items.length > 0) ?
        <Courses
          data={state.courseList} />
        :
        <EmptyView
          title={Helper.getString('noItemsToShow')} />
      }
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
})