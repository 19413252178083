import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { Links } from '../components/Links';
import { Information } from '../components/Information';
import { AppContext } from '../common/components/contexts/AppContext';
import { AppHelper } from '../helpers/AppHelper';
import { UserContext } from '../common/components/contexts/UserContext';
import { AppConfig } from '../AppConfig';

export function Profile(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const { user, userContextStateHandler } = useContext(UserContext)
  const [state, setState] = useState({
    result: {},
    fetchState: ApiHelper.State.LOADING
  })
  const links = AppHelper.links()


  useEffect(() => {
    if (user.data) processUser()
  }, [user.data])

  const processUser = () => {
    const domainName = localStorage.getItem('domainName')
    console.log('user.data', user.data)
    const profileInformation = user.data.orgs.filter(i => i.domain === domainName)

    let departments = ''
    user.data.orgs.map(item => {
      if (!item.domain) {
        departments = departments + item.title + ', '
      }
    })

    setState({
      ...state,
      fetchState: ApiHelper.State.READY,
      result: user.data,
      profileInformation: profileInformation[0],
      departments: departments
    })
  }

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getProfileDetails()

      const domainName = localStorage.getItem('domainName')
      const profileInformation = response.orgs.filter(i => i.domain === domainName)

      let departments = ''
      response.orgs.map(item => {
        if (!item.domain) {
          departments = departments + item.title + ', '
        }
      })

      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response,
        profileInformation: profileInformation[0],
        departments: departments
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'profile' })
    //fetchData()
  }, [])

  return (
    <ScreenWrapper
      state={state}
      title={Helper.getString('profile')}
      logo={true}>
      <Fragment>

        {user && user.data && state.profileInformation &&
          <div className={css(Styles.informationContainer)}>
            <Information data={state.profileInformation} departments={state.departments} result={user.data} />
          </div>
        }

        {links && links.length > 0 &&
          <Links
            borderContainer={[CommonStyles.borderContainer, CommonStyles.courseContainer]}
            data={links} />
        }
        <p className={css(Styles.versionNumber)}>v {AppConfig.VERSION}</p>
      </Fragment>
    </ScreenWrapper>
  )
}

const Styles = StyleSheet.create({
  informationContainer: {
    display: 'flex',
  },
  versionNumber: {
    margin: 0,
    fontSize: 12,
    color: '#aaabaf',
    marginTop: 10,
    textAlign: 'right',
    '@media (max-width: 767px)': {
      margin: 0,
      fontSize: 10,
      color: '#aaabaf',
      textAlign: 'left',
    }
  }
})