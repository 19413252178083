import React, { useState, useEffect, useContext } from 'react';

import { AppConfig } from '../../../AppConfig';
import { ApiHelper } from '../../helpers/ApiHelper';
import { Helper } from '../../helpers/Helper';
import { Controller } from '../../Controller';
import { UserContext } from './UserContext';

export const FavContext = React.createContext({
  fav: {},
  addCourseToFav: () => { },
  deleteCourseFromFav: () => { },
  clearFav: () => { },
  fetchFavData: () => { },
  addResourcesToFav: () => { },
  deleteResourcesFromFav: () => { }
})
FavContext.displayName = 'FavContext';

export function FavContextProvider(props) {
  const [state, setState] = useState({ fetchState: ApiHelper.State.LOADING })

  const { user } = useContext(UserContext) //TODO: should we take from appUser?
  const orgId = Controller.get().roleHelper().getOrgId()

  async function fetchFavData() {
    if (!user || !user.data) {
      setState({ ...state, fetchState: ApiHelper.State.READY, data: null })
      return
    }
    setState({ ...state, fetchState: ApiHelper.State.LOADING })
    try {
      const results = await Promise.all([
        await ApiHelper.callAwait({
          method: 'GET',
          paths: ['miscs', 'courses'],
          queryParams: { orgId: orgId, favLearnerId: user.data.userId }
        }),
        await ApiHelper.callAwait({
          method: 'GET',
          paths: ['miscs', 'resources'],
          queryParams: { orgId: orgId, favLearnerId: user.data.userId }
        })
      ])

      setState({
        ...state,
        favCourseData: results[0] || [],
        resourceData: results[1] || [],
        fetchState: ApiHelper.State.READY,
      })
    } catch (e) {
      setState({ fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  async function addCourseToFav(options) {
    const { courseId } = options
    let jsonBody = {}
    try {
      jsonBody = {
        orgId: orgId,
        favItemId: courseId,
        userId: user.data.userId,
      }
      const response = await ApiHelper.callAwait({
        method: 'POST',
        paths: ['favcourses'],
        // queryParams: { orgId: AppConfig.TENANT_ID },
        jsonBody
      })
      fetchFavData()
      return ({ success: response })
    } catch (e) {
      return ({ fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  async function clearFav() {
    try {
      const response = await ApiHelper.callAwait({
        method: 'PUT',
        paths: ['wishlists', 'me'],
        queryParams: { orgId: AppConfig.TENANT_ID },
        jsonBody: {
          updateAction: 'clear'
        }
      })
      fetchFavData()
      return ({ success: response })
    } catch (e) {
      return ({ fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  async function deleteCourseFromFav(options) {
    const { courseId, id } = options
    let jsonBody = {}

    // if (Object.keys(state.data.items).length === 1) {
    //   const response = clearFav()
    //   return response
    // } else {
    try {
      jsonBody = {
        orgId: orgId,
        favItemId: courseId,
        userId: user.data.userId,
      }
      const response = await ApiHelper.callAwait({
        method: 'DELETE',
        paths: ['favcourses', id],
        // queryParams: id,
        // jsonBody
      })
      fetchFavData()
      return ({ success: response })
    } catch (e) {
      return ({ fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
    // }
  }

  async function addResourcesToFav(options) {
    const { courseResourceId } = options
    let jsonBody = {}
    try {
      jsonBody = {
        orgId: orgId,
        favItemId: courseResourceId,
        userId: user.data.userId,
      }
      const response = await ApiHelper.callAwait({
        method: 'POST',
        paths: ['favresources'],
        // queryParams: { orgId: AppConfig.TENANT_ID },
        jsonBody
      })
      fetchFavData()
      return ({ success: response })
    } catch (e) {
      return ({ fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  // async function clearFav() {
  //   try {
  //     const response = await ApiHelper.callAwait({
  //       method: 'PUT',
  //       paths: ['wishlists', 'me'],
  //       queryParams: { orgId: AppConfig.TENANT_ID },
  //       jsonBody: {
  //         updateAction: 'clear'
  //       }
  //     })
  //     fetchFavData()
  //     return ({ success: response })
  //   } catch (e) {
  //     return ({ fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
  //   }
  // }

  async function deleteResourcesFromFav(options) {
    const { courseResourceId, id } = options
    let jsonBody = {}

    // if (Object.keys(state.data.items).length === 1) {
    //   const response = clearFav()
    //   return response
    // } else {
    try {
      jsonBody = {
        orgId: orgId,
        favItemId: courseResourceId,
        userId: user.data.userId,
      }
      const response = await ApiHelper.callAwait({
        method: 'DELETE',
        paths: ['favresources', id],
        // queryParams: id,
        // jsonBody
      })
      fetchFavData()
      return ({ success: response })
    } catch (e) {
      return ({ fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
    // }
  }

  useEffect(() => {
    fetchFavData()
  }, [user.data])

  return (
    <FavContext.Provider value={{ fav: state, addCourseToFav, deleteCourseFromFav, clearFav, fetchFavData,
      addResourcesToFav, deleteResourcesFromFav }}>
      {props.children}
    </FavContext.Provider>
  )
}