import { Helper } from '../helpers/Helper';

export class UserMgr {
  appUser = null;
  jwtToken = null;
  guestUser = false;
  previousUserName = null;

  constructor() {
    this.cacheAppUser();
  }

  setAppUser(appUser) {
    // console.log('setAppUser', appUser);
    if(appUser && window.ReactNativeWebView && navigator && navigator.userAgent.startsWith('Azsincx')) {
      const uData = {
        appUser: appUser,
        token: this.getUserToken(),
        type: 'LOGIN'
      }
      // console.log('window.ReactNativeWebView', JSON.stringify(uData));
      window.ReactNativeWebView.postMessage(JSON.stringify(uData))
    }
    this.appUser = appUser;
    if (appUser) {
      const appContext = {
        appUser: appUser
      };
      //console.log('appUser:', appContext);
      const thisObj = this;
      return new Promise((resolve, reject) => {
        localStorage.setItem('appContext', JSON.stringify(appContext))
      });
    } else {
      const thisObj = this;
      //console.log('remove appUSer from async storage');
      if(window.ReactNativeWebView && navigator && navigator.userAgent.startsWith('Azsincx')) {
        const uData = {
          type: 'LOGOUT'
        }
        // console.log('window.ReactNativeWebView', JSON.stringify(uData));
        window.ReactNativeWebView.postMessage(JSON.stringify(uData))
      }
      return new Promise((resolve, reject) => {
        localStorage.removeItem('appContext')
        localStorage.removeItem('orgInfo')
      });
    }
  }

  getAppUser() {
    return this.appUser;
  }

  isProductSU() {
    if (this.appUser) {
      for (let i = 0; i < this.appUser.orgs.length; i++) {
        const org = this.appUser.orgs[i]
        if (org.orgId === 'PRODUCT') {
          if (org.role === 'SUPERUSER') return true // for backward compatibility
          if (Helper.inArray(org.roles, 'SUPERUSER')) return true
        }
      }
    }
    return false;
  }

  isInOrg(orgId) {
    if (this.appUser) {
      for (const org of this.appUser.orgs) {
        if (org.orgId === orgId) {
          return true
        }
      }
    }
    return false
  }

  hasOrgRole(orgId, role) {
    if (this.appUser) {
      for (let i = 0; i < this.appUser.orgs.length; i++) {
        const org = this.appUser.orgs[i]
        if (org.orgId === orgId && (Helper.inArray(org.roles, role))) {
          return true
        }
      }
    }
    return false
  }

  getOrgRoles(orgId) {
    if (this.appUser) {
      for (let i = 0; i < this.appUser.orgs.length; i++) {
        const org = this.appUser.orgs[i]
        if (org.orgId === orgId) {
          return org.roles
        }
      }
    }
    return []
  }

  isProductStaff() {
    if (this.appUser) {
      for (let i = 0; i < this.appUser.orgs.length; i++) {
        const org = this.appUser.orgs[i];
        if (org.orgId === 'PRODUCT') {
          if (org.role === 'STAFF') return true; // for backward compatibility
          if (Helper.inArray(org.roles, 'STAFF')) return true;
        }
      }
    }
    return false;
  }

  getPurpose() {
    //console.log('getPurpose: appUser', this.appUser)
    if (this.appUser && this.appUser.purposeOne) {
      return this.appUser.purposeOne
    }
    return null;
  }

  _clearCache() {
    this.appUser = null;
    this.jwtToken = null;
    this.guestUser = false;
    this.previousUserName = null;
  }

  cacheAppUser() {
    //console.log('cacheAppUser');
    const appUserStr = localStorage.getItem('appContext')
    if(appUserStr) {
      this.appUser = JSON.parse(appUserStr).appUser
    }
  }

  getUserId() {
    if (this.appUser) {
      return this.appUser.id;
    } else {
      return null;
    }
  }

  getOrgId() {
    if (this.isProductSU()) {
      return 'PRODUCT';
    }
    if (this.appUser) {
      for (let i = 0; i < this.appUser.orgs.length; i++) {
        const org = this.appUser.orgs[i];
        if (org.orgId !== 'PRODUCT') {
          if (org.role === 'SUPERUSER' || Helper.inArray(org.roles, 'SUPERUSER') || Helper.inArray(org.roles, 'SUPERUSER')) return org.orgId;
          if (Helper.inArray(org.roles, 'MANAGER') || Helper.inArray(org.roles, 'MEMBER')) return org.orgId.split('-')[0]; //TODO - Workaround for Manager/Member
        }
      }
    }
    return null;
  }

  setUserToken(jwtToken) {
    this.jwtToken = jwtToken;
  }

  getUserToken() {
    //console.log('getUserToken:', this.jwtToken ? true : false);
    return this.jwtToken;
  }

  updateUserSettings(settings) {
  }

  setGuestUser(state) {
    //console.log('setGuestUser');
    this.guestUser = state;
  }

  getGuestUser() {
    //console.log('getGuestUser');
    return this.guestUser;
  }

  setCurrentLoggedInUserName(userName) {
    //console.log('setLastLoggedInUserMobileNumber');
    return new Promise((resolve, reject) => {
      localStorage.setItem('__previousUserName', userName)
    });
  }

  cacheLastLoggedInUserName() {
    return new Promise((resolve, reject) => {
      localStorage.getItem('__previousUserName')
    });
  }

  getLastLoggedInUserName() {
    //console.log('getLastLoggedInUserName');
    return this.previousUserName;
  }
}
