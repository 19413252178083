import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { Courses } from '../components/Courses/Courses';
import { useParams, useLocation } from 'react-router-dom';
import { EmptyView } from '../components/EmptyView';

export function SearchScreen(props) {
  const params = useParams();
  const location = useLocation()
  const [searchText, setSearchText] = useState(new URLSearchParams(location.search).get('query') || '')
  const [state, setState] = useState({
    fetchState: ApiHelper.State.LOADING,
    result: { items: [] }
  })

  async function fetchData() {
    if (!searchText || searchText.length === 0) {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getCourses(params.orgId)
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response
      })
      const path = {
        pathname: 'search',
      };
      Controller.get().pushRouteWithQueryParams(path)
      return
    }
    const path = {
      pathname: 'search',
      search: '?query=' + searchText
    };
    Controller.get().pushRouteWithQueryParams(path)
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getSearchDetails({ orgId: params.orgId, searchText })
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    fetchData()
  }, [searchText])

  const onEndEditing = (searchText) => {
    setSearchText(searchText)
  }

  const backNav = () => {
    return '/learner/orgs/' + params.orgId + '/explore'
  }

  const backButton = {
    title: Helper.getString('back'),
    link: backNav()
  }

  return (
    <ScreenWrapper
      state={state}
      title={Helper.getString('searchCourses')}
      logo={true}
      search={true}
      searchText={searchText}
      onEndEditing={onEndEditing}
      backButton={backButton}
      placeholder={Helper.getString('searchCourses')}
    // filter={true} //TODO filter
    >

      <Fragment>
        {state.result && state.result.items && state.result.items.length > 0 ?
          <Courses
            showTitle={true}
            title={Helper.getString('courses')}
            // borderContainer={[CommonStyles.borderContainer, CommonStyles.courseContainer]}
            data={state.result}
            backButton={{ search: true }} />
          :
          <EmptyView
            title={Helper.getString('noItemsToShow')} />
        }
      </Fragment>
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  searchContainer: {
    marginBottom: 40
  }
})