import React, { Fragment, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { AppCommonTheme } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { Helper } from '../common/helpers/Helper';
import { CourseMgr } from '../common/mgrs/CourseMgr';
import { Consents } from './Consents';
import { AppHelper } from '../helpers/AppHelper';

export function CourseContent(props) {
  const [state, setState] = useState({
    selectedPanelId: null,
    expanded: false,
  })
  let additionalIndex = 0

  const handleChange = (item) => {
    if (item.id !== state.selectedPanelId) {
      setState({
        ...state,
        selectedPanelId: item.id,
        expanded: !state.expanded
      })
    } else {
      setState({
        ...state,
        selectedPanelId: null,
        expanded: !state.expanded
      })
    }
  }

  const lockIcon = (status) => {
    if (status === CourseMgr.Status.COMPLETED) {
      return 'check_icon'
    } else if (status === CourseMgr.Status.PROGRESS) {
      return null
    }
    return 'lock_icon'
  }

  const subLockIcon = (status) => {
    if (status === CourseMgr.Status.COMPLETED) {
      return 'check_icon'
    } else if (status === CourseMgr.Status.PROGRESS) {
      return null
    }
    return 'lock_icon_light'
  }

  const assessmentIcon = () => {
    if (!props.data.completedCourseStatus) {
      return <img src={require('../assets/images/lock_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    } else if (props.data.assessmentCompleted && !props.data.coursePassed) {
      return <img src={require('../assets/images/close.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    } else if (props.data.assessmentCompleted) {
      return <img src={require('../assets/images/check_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    } else if (props.data.totalAssesmentAnsCount > 0 && props.data.totalAssesmentAnsCount !== props.data.assessments.length) {
      return null
    }
  }

  const consentIcon = () => {
    if (!props.data.completedCourseStatus) {
      return <img src={require('../assets/images/lock_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    } else if (props.data.completedConsents) {
      return <img src={require('../assets/images/check_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    } else if (!props.data.enableConsent) {
      return <img src={require('../assets/images/lock_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    }
    return null
  }

  const feedbackIcon = () => {
    if (!props.data.completedCourseStatus) {
      return <img src={require('../assets/images/lock_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    } else if (props.data.completedFeedback) {
      return <img src={require('../assets/images/check_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    } else if (!props.data.enablefeedback) {
      return <img src={require('../assets/images/lock_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    }
    return null
  }

  const certificateIcon = () => {
    if (!props.data.isCourseCompleted) {
      return <img src={require('../assets/images/lock_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    } else if (!props.data.enableCertification) {
      return <img src={require('../assets/images/lock_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
    }
    return <img src={require('../assets/images/check_icon.svg')} width='24px' height='24px' className={css(Styles.iconContent)} />
  }

  const checkNav = (disableChapter, chapter) => {
    if (props.preview) {
      return Controller.get().courseMgr().navToChapter(props.courseSlug, chapter.id, props.preview, props.orgId)
    } else if (!disableChapter) {
      return Controller.get().courseMgr().navToChapter(props.courseSlug, chapter.id, props.preview, props.orgId, { review: true })
    }
    return null
  }

  const assessmentNav = () => {
    if (props.data.totalAssesmentAnsCount === props.data.assessments.length) {
      Controller.get().courseMgr().navToResult(props.courseSlug)
    } else {
      Controller.get().courseMgr().navToAssessment(props.courseSlug)
    }
  }

  const showAssessment = () => {
    additionalIndex = additionalIndex + 1
    return (
      <div className={css(Styles.expansionPanelSummary, Styles.expansionPanel)}>
        <div className={props.data.completedCourseStatus ?
          css(Styles.titleOuterContainer, Styles.subTitleHover) : css(Styles.titleOuterContainer)}
        onClick={() => props.data.completedCourseStatus && assessmentNav()}>
          <div className={css(Styles.titleContainer)}>
            <p className={css(Styles.title)}>
              {(data.length + additionalIndex) > 9 ? (data.length + additionalIndex) : ('0' + (data.length + additionalIndex))} <span className={css(Styles.bold)}>
                {Helper.getString('assessment')}</span>
            </p>
          </div>
          {!props.preview &&
            assessmentIcon()
          }
        </div>
      </div>
    )
  }

  const openConsent = () => {
    // setState({
    //   ...state,
    //   consentDialogOpen: true,
    // })
    Controller.get().courseMgr().navToConsent(props.courseSlug)
  }

  const showConsent = () => {
    additionalIndex = additionalIndex + 1
    return (
      <div className={css(Styles.expansionPanelSummary, Styles.expansionPanel)}>
        <div className={props.data.enableConsent ?
          css(Styles.titleOuterContainer, Styles.subTitleHover) : css(Styles.titleOuterContainer)}
        onClick={() => props.data.enableConsent && openConsent()}>
          <div className={css(Styles.titleContainer)}>
            <p className={css(Styles.title)}>
              {(data.length + additionalIndex) > 9 ? (data.length + additionalIndex) : ('0' + (data.length + additionalIndex))} <span className={css(Styles.bold)}>
                {Helper.getString('consents')}</span>
            </p>
          </div>
          {!props.preview &&
            consentIcon()
          }
        </div>
      </div>
    )
  }

  const showFeedback = () => {
    additionalIndex = additionalIndex + 1
    return (
      <div className={css(Styles.expansionPanelSummary, Styles.expansionPanel)}>
        <div className={props.data.enablefeedback ?
          css(Styles.titleOuterContainer, Styles.subTitleHover) : css(Styles.titleOuterContainer)}
        onClick={() => props.data.enablefeedback && Controller.get().courseMgr().navToFeedback(props.courseSlug)}>
          <div className={css(Styles.titleContainer)}>
            <p className={css(Styles.title)}>
              {(data.length + additionalIndex) > 9 ? (data.length + additionalIndex) : ('0' + (data.length + additionalIndex))} <span className={css(Styles.bold)}>
                {Helper.getString('feedback')}</span>
            </p>
          </div>
          {!props.preview &&
            feedbackIcon()
          }
        </div>
      </div>
    )
  }

  const showCertification = () => {
    additionalIndex = additionalIndex + 1
    return (
      <div className={css(Styles.expansionPanelSummary, Styles.expansionPanel)}>
        <div className={props.data.enableCertification && props.data.isCourseCompleted ?
          css(Styles.titleOuterContainer, Styles.subTitleHover) : css(Styles.titleOuterContainer)}
        onClick={() => {
          const aid = AppHelper.getAssignmentId(props.data)
          props.data.enableCertification && props.data.isCourseCompleted && Controller.get().courseMgr().navToCertificate(props.courseSlug, { course: true }, aid)
        }}
        >
          <div className={css(Styles.titleContainer)}>
            <p className={css(Styles.title)}>
              {(data.length + additionalIndex) > 9 ? (data.length + additionalIndex) : ('0' + (data.length + additionalIndex))} <span className={css(Styles.bold)}>
                {Helper.getString('certification')}</span>
            </p>
          </div>
          {!props.preview &&
            certificateIcon()
          }
        </div>
      </div>
    )
  }

  // const consentDialogClose = () => {
  //   setState({
  //     ...state,
  //     consentDialogOpen: false,
  //   })
  // }

  // const onSubmitConsent = () => {
  //   setState({
  //     ...state,
  //     consentDialogOpen: false,
  //   })
  //   if (props.data.showFeedback) {
  //     Controller.get().courseMgr().navToFeedback(props.courseSlug)
  //   }
  // }

  const data = props.data.content
  return (
    <Fragment>
      {data && data.length > 0 &&
        data.map((item, index) => {
          const count = index + 1
          const counter = count > 9 ? count : ('0' + count)
          return (
            <ExpansionPanel
              key={index}
              className={state.selectedPanelId === item.id ? css(Styles.expansionPanel, Styles.expansionPanelSelected) : css(Styles.expansionPanel)}
              expanded={state.selectedPanelId === item.id}
              onChange={() => handleChange(item)}>
              <ExpansionPanelSummary className={css(Styles.expansionPanelSummary)}
                classes={{ content: css(Styles.expansionPanelSummaryRoot) }}
              >
                <div className={css(Styles.titleOuterContainer)}>
                  <div className={css(Styles.titleContainer)}>
                    <p className={css(Styles.title)}>{counter} <span className={css(Styles.bold)}>{item.title}</span></p>
                  </div>
                  {!props.preview && !item.sectionwiseProgressChapter &&
                    <img src={require(`../assets/images/${lockIcon(item.status)}.svg`)} width='24px' height='24px' className={css(Styles.iconContent)} />
                  }
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: css(Styles.expansionPanelDetails) }} >
                {item.chapters.map((chapter, index) => {
                  const count = index + 1
                  const counter = count > 9 ? count : ('0' + count)
                  const disableChapter = !chapter.status ? true : false //TODO need to be change
                  const subTitle = disableChapter && !props.preview ? css(Styles.titleOuterContainer, Styles.subTitleOuterContainer) :
                    css(Styles.titleOuterContainer, Styles.subTitleOuterContainer, Styles.subTitleHover)
                  return (
                    <div
                      className={subTitle}
                      key={index}
                      onClick={() => checkNav(disableChapter, chapter)} >
                      <div className={css(Styles.titleContainer)}>
                        <p className={css(Styles.subTitle)}>{counter} <span className={css(Styles.bold, Styles.subTitleBold)}>{chapter.title}</span></p>
                      </div>
                      {!props.preview && subLockIcon(chapter.status) !== null &&
                        <img src={require(`../assets/images/${subLockIcon(chapter.status)}.svg`)} width='18px' height='18px' className={css(Styles.subIconContent)} />
                      }
                    </div>
                  )
                })}
              </ExpansionPanelDetails>
            </ExpansionPanel >
          )
        })
      }

      {props.data.showAssessment && !props.preview &&
        showAssessment()
      }

      {props.data.showConsent && !props.preview &&
        showConsent()
      }

      {props.data.showFeedback && !props.preview &&
        showFeedback()
      }

      {props.data.showCertification && !props.preview &&
        showCertification()
      }

      {/* {props.data && props.data.showConsent &&
        <Consents
          dialogOpen={state.consentDialogOpen}
          onClose={() => consentDialogClose()}
          data={props.data}
          courseprogs={props.data.courseprogs}
          onSubmit={onSubmitConsent}
        />
      } */}
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  expansionPanel: {
    boxShadow: 'unset',
    marginLeft: -20,
    marginRight: -20,
    ':before': {
      height: 0
    },
    '@media (max-width: 767px)': {
      marginLeft: 0,
      marginRight: 0,
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      marginLeft: 0,
      marginRight: 0,
    }
  },
  expansionPanelSelected: {
    backgroundColor: AppCommonTheme.bgColor,
    borderRadius: AppCommonTheme.borderRadius,
    margin: 0,
    marginLeft: -20,
    marginRight: -20,
  },
  expansionPanelSummary: {
    padding: '25px 20px',
    minHeight: 'unset',
    '@media (max-width: 767px)': {
      padding: '20px 10px',
    }
  },
  expansionPanelSummaryRoot: {
    margin: 0
  },
  title: {
    margin: AppCommonTheme.pMargin,
    fontSize: 20,
    color: AppCommonTheme.appFontColor,
    display: 'flex',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      fontSize: 18
    }
  },
  bold: {
    fontWeight: 600,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 10
  },
  subTitleBold: {
    fontWeight: 'unset',
    marginLeft: 5,
  },
  expansionPanelDetails: {
    padding: '10px 0px 20px',
    display: 'flex',
    flexDirection: 'column',
    borderTop: AppCommonTheme.border,
    margin: '0px 20px',
    '@media (max-width: 767px)': {
      padding: '5px 0px 20px',
      margin: '0px 10px',
    }
  },
  titleOuterContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  subTitleOuterContainer: {
    marginTop: 15
  },
  titleContainer: {
    flex: 1,
    marginRight: 10,
    display: 'grid'
  },
  subTitle: {
    margin: AppCommonTheme.pMargin,
    fontSize: 18,
    color: '#AAABAF',
    display: 'flex',
    overflow: 'hidden',
  },
  iconContent: {
    width: 24,
    height: 24
  },
  subIconContent: {
    width: 18,
    height: 18
  },
  subTitleHover: {
    cursor: 'pointer'
  }
})