import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../../common/helpers/Helper';
import { AppCommonTheme } from '../../styles/Styles';
import { AppTheme } from '../../styles/AppTheme';
import { Controller } from '../../common/Controller';
import Grid from '@material-ui/core/Grid';

export function StatusTile(props) {

  const item = props.item
  const value = props.data[item.id]
  const handleNavigation = () => {
    if(props.handleStatusClick) {
      props.handleStatusClick(item.state)
    } else if(!props.disableNav) Controller.get().courseMgr().navToMyCourses(item.state)
  }
  return (
    <Grid key={item.id} lg={props.tileSize ? props.tileSize : 2} xs={6} sm={4} md={4} item  className={css(Styles.outerTileContainer)}>
      <div className={props.disableNav ? css(Styles.outerContainer, Styles.disableContainer) : css(Styles.outerContainer)}
        onClick={() => handleNavigation()}>
        <div className={css(Styles.innerContainer)}>
          <div className={css(Styles.imgContainer)}>
            <img
              alt={Helper.getString('statusImgAlt')}
              width='14px' height='14px'
              src={item.image}
              className={css(Styles.imgContent)} />
          </div>
          <div>
            <p className={css(Styles.title)}>{item.title}</p>
            <p className={css(Styles.valueMobileView)}>{value ? value : 0}</p>
          </div>
        </div>
        <p className={css(Styles.value)}>{value ? value : 0}</p>
      </div>
    </Grid>
  )
}

const Styles = StyleSheet.create({
  outerTileContainer: {
    display: 'flex'
  },
  outerContainer: {
    border: AppCommonTheme.border,
    padding: '14px 24px 18px',
    borderRadius: AppCommonTheme.borderRadius,
    flex: 1,
    cursor: 'pointer',
    '@media (max-width: 767px)': {
      padding: '20px 10px',
      borderRadius: 8,
    }
  },
  disableContainer: {
    cursor: 'unset'
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  imgContainer: {
    minWidth: 32,
    minHeight: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: AppTheme.primaryColor + 33,
    marginRight: 10,
    '@media (max-width: 767px)': {
      minWidth: 40,
      minHeight: 40
    }
  },
  imgContent: {
    width: 14,
    height: 14,
    '@media (max-width: 767px)': {
      width: 20,
      height: 20
    }
  },
  title: {
    color: AppCommonTheme.appFontColor,
    fontSize: AppCommonTheme.appFontSize,
    margin: AppCommonTheme.pMargin,
    '@media (max-width: 767px)': {
      fontSize: 12
    }
  },
  value: {
    color: AppCommonTheme.appFontColor,
    fontSize: 50,
    margin: AppCommonTheme.pMargin,
    '@media (max-width: 767px)': {
      display: 'none'
    }
  },
  valueMobileView: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'block',
      color: AppCommonTheme.appFontColor,
      fontSize: 28,
      margin: AppCommonTheme.pMargin,
      marginTop: 1
    }
  }
})