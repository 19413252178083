import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'

import { Controller } from '../Controller'
import { ApiHelper } from '../helpers/ApiHelper'
import { Helper } from '../helpers/Helper'
import { UiHelper } from '../helpers/UiHelper'
import { DynamicFormDialog } from './DynamicFormDialog'

import async from 'async'
import { AppTheme } from '../../styles/AppTheme'
import { Headerbar } from './Headerbar'
import { TableComponent } from './TableComponent'
import { AppHelper } from '../../helpers/AppHelper'

export class SubEntityComponent extends Component {

  constructor(props) {
    super(props)
    //props.formDefinition
    //props.tableHeadersMap
    //props.onActions
    //props.headerActions
    const { entityAttrName, apiPath, queryParams, entityItem } = props
    const items = Helper.dictToArray(entityItem[entityAttrName])
    this.sortResult(items, this.sortFields || props.sortFields) //override sortFields
    this.state = {
      entityItem,
      items,
      modalOpen: false,
      fetchState: this.props.fetchAtLoad ? ApiHelper.State.LOADING : ApiHelper.State.READY,
    }
    this.toFetch = props.toFetch || [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath([apiPath, entityItem.id], queryParams) }
    ]
  }

  componentDidMount() {
    this.props.fetchAtLoad && this.fetchItems();
  }

  fetchItems() {
    this.setState({
      fetchState: ApiHelper.State.LOADING
    })
    const fcns = []
    for (const f of this.toFetch) {
      fcns.push(function (callback) {
        ApiHelper.call(f, callback)
      })
    }
    async.parallel(fcns, function (err, results) {
      if (err) {
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: Helper.getErrorMsg(err)
        })
        return
      }
      console.log('user:', Controller.get().userMgr().getAppUser())
      if (this.onFetchSuccess) {
        this.onFetchSuccess(results)
      }
      this.setState({
        fetchState: ApiHelper.State.READY
      })
    }.bind(this))
  }

  onFetchSuccess(results) {
    const result = results[0]
    if (!result) {
      return
    }
    const { entityAttrName, entityItem, sortFields} = this.props
    Object.assign(entityItem, result)
    const items = Helper.dictToArray(entityItem[entityAttrName])
    this.sortResult(items, sortFields || this.sortFields)
    this.setState({
      items
    })
  }

  sortResult(items, sortFields) {
    if (sortFields) {
      items.sort(function (a, b) {
        for (let i = 0; i < sortFields.length; i++) {
          if (a[sortFields[i]] > b[sortFields[i]]) { return 1 }
          if (a[sortFields[i]] < b[sortFields[i]]) { return -1 }
        }
        return 0
      })
    }
    return items
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return (
        <div className={css(Styles.entityContainer)}>
          <div className={css(Styles.row)}>
            {this.renderHeaderbar()}
          </div>
          {UiHelper.componentLoadingView()}
        </div>
      )
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return (
        <div className={css(Styles.entityContainer)}>
          <div className={css(Styles.row)}>
            {this.renderHeaderbar()}
          </div>
          {UiHelper.errorView(this)}
        </div>
      )
    } else {
      return this.readyView()
    }
  }

  readyView() {
    if (this.renderCustomReady) {
      return this.renderCustomReady() // override in derived class
    }

    return (
      <div className={css(Styles.entityContainer)}>
        <div className={css(Styles.row)}>
          {this.renderHeaderbar()}
        </div>
        <div className={css(Styles.row)}>
          {this.renderAboveTable()}
        </div>
        <div className={css(Styles.row)}>
          {this.renderTable()}
        </div>
        <div className={css(Styles.row)}>
          {this.renderBelowTable()}
        </div>
        {this.state.modalOpen ? this.renderModal() : ''}
      </div>
    )
  }

  renderHeaderbar() {
    const title = this.props.pageTitle ? this.props.pageTitle.toUpperCase() : null
    let actions = []
    if (this.props.headerActions || this.headerActions) {
      actions = this.props.headerActions || this.headerActions
    } else if (!this.props.noAdd) {
      actions = [{
        label: 'Add new',
        onClick: () => this.openModalForAdd()
      }]
    }
    if (!this.props.noClose) { // Can be added in custom close.
      actions.push({
        label: 'Close',
        onClick: () => {
          this.props.onCloseSubEntityComponent()
        }
      })
    }
    return (
      <Headerbar title={title} actions={actions} actionComponents={this.state.headerActionComponents} />
    )
  }

  openModalForAdd() {
    this.props.formDefinition.formType = 'add'
    Helper.deleteFormDefinitionValues(this.props.formDefinition)
    this.onAdd()
    this.setState({
      modalOpen: true
    })
  }

  onAdd() {
    const { entityAttrName, apiPath, queryParams, entityItem } = this.props
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName},
      apiEndPoint: ApiHelper.makeUrlPath([apiPath, entityItem.id], queryParams),
    })
  }

  onEdit(current) {
    const { entityAttrName, apiPath, queryParams, entityItem } = this.props
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath([apiPath, entityItem.id], queryParams),
    });
  }

  onDelete(current) {
    const { entityAttrName, apiPath, queryParams, entityItem } = this.props
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath([apiPath, entityItem.id], queryParams),
    });
  }

  openModalForEdit(current) {
    console.log('edit:', current)
    this.props.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.props.formDefinition, current)
    this.onEdit(current)
    this.setState({
      modalOpen: true
    })
  }

  openModalForApproval(current) {
    console.log('approve:', current)
    this.props.formDefinition.formType = 'approve'
    Helper.populateFormDefinitionValues(this.props.formDefinition, current)
    this.onApprove(current)
    this.setState({
      modalOpen: true
    })
  }

  openModalForDelete(current) {
    this.props.formDefinition.formType = 'delete'
    Helper.populateFormDefinitionValues(this.props.formDefinition, current)
    this.onDelete(current)
    this.setState({
      modalOpen: true
    })
  }

  renderModal() {
    return (
      <DynamicFormDialog
        apiMethod={this.state.apiMethod}
        apiEndPoint={this.state.apiEndPoint}
        apiData={this.state.apiData || this.apiData}
        formDefinition={this.props.formDefinition}
        isOpen={this.state.modalOpen}
        onClosed={() => this.setState({ modalOpen: false })}
        onCompleteAction={() => {
          this.setState({ modalOpen: false, items: [] })
          this.fetchItems()
        }
        }
      >
      </DynamicFormDialog>
    )

  }

  renderAboveTable() {
    if(this.props.renderAboveTable) {
      return this.props.renderAboveTable(this.state)
    }
    return ('')
  }

  renderTable() {
    return (
      <TableComponent
        tableHeadersMap={this.props.tableHeadersMap}
        items={this.state.items}
        setItems={this.setItems}
        headerActions={this.props.headerActions || this.headerActions} //Multi checkbox
        onActions={this.props.onActions || this.onActions}
      />
    )
  }

  renderBelowTable() {
    return ('')
  }

  onActions = (current) => {
    const actions = []
    !this.props.noEdit && actions.push(UiHelper.buttonEdit(this, current))
    !this.props.noDelete && actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

  setItems = (itemsList) => { // to trigger render.
    this.setState({ items: itemsList })
  }
}

const Styles = StyleSheet.create({
  entityContainer: {
    marginTop: AppTheme.toolbarHeight,
    paddingTop: 10,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: AppTheme.primaryBg
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})
