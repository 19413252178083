import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { AppTheme } from '../../../styles/AppTheme';
import { AppTheme } from '../styles/AppTheme';
import { Bar } from 'react-chartjs-2';
import { AppCommonTheme } from '../styles/Styles';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.plugins.register(zoomPlugin);

export class ChartCard extends Component {
  constructor(props) {
    super(props);
    this.defaultErrMsg = 'An error occured.'
    this.state = {
      inProcess: false
    };
  }

  render() {
    const DATA_COUNT = 7;
    // const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };
    // console.log(Utils.numbers(NUMBER_CFG))
    let title1 = null
    let title2 = null
    let bg1 = null
    let bg2 = null
    if (this.props.item.data.datasets && this.props.item.data.datasets.length === 2) {
      title1 = this.props.item.data.datasets[0].label
      title2 = this.props.item.data.datasets[1].label
      bg1 = this.props.item.data.datasets[0].borderColor || this.props.item.data.datasets[0].backgroundColor
      bg2 = this.props.item.data.datasets[1].borderColor || this.props.item.data.datasets[1].backgroundColor
    }
    return (
      <div className={css(Styles.outerContainer)}>
        <div className={css(Styles.paperTitle)} elevation={0}>
          <p className={css(Styles.paragraphMargin)}>{this.props.item.title}</p>
          {title1 && title2 &&
            <div className={css(Styles.colorInfoContainer)}>
              <span className={css(Styles.colorCode1)} style={{ backgroundColor: bg1 }}></span>
              <span>{title1}</span>
              <span className={css(Styles.colorCode2)} style={{ backgroundColor: bg2 }}></span>
              <span>{title2}</span>
            </div>
          }
        </div>
        <div>
          {this.state.inProcess &&
            <div className={css(Styles.loader)}>
              <CircularProgress size={48} className={css(Styles.progress)} />
            </div>
          }
        </div>
        <Bar
          height={null}
          width={null}
          options={{
            responsive: true,
            aspectRatio: window.innerWidth < 767 ? 1 : 2,
            legend: {
              display: false,
              // position: 'top',
              // align: 'left',
              // labels: {
              //   padding: 20,
              //   boxWidth: 10,
              //   boxHeight: 10,
              // }
            },
            type: 'bar',
            scales: {
              xAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true
                },
                stacked: true,
                position: 'left'
              }],
              yAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true
                },
                stacked: true
              }]
            },
            plugins: {
              title: {
                display: true,
              },
              // zoom: {
              //   pan: {
              //     enabled: true,
              //     mode: 'xy'
              //   },
              //   zoom: {
              //     pinch: {
              //       enabled: true       // Enable pinch zooming
              //     },
              //     wheel: {
              //       enabled: true       // Enable wheel zooming
              //     },
              //     mode: 'xy',
              //   }
              // }
            },

            // plugins: {
            //   zoom: {
            //     zoom: {
            //       wheel: {
            //         enabled: true,
            //       },
            //       pinch: {
            //         enabled: true
            //       },
            //       mode: 'xy',
            //     }
            //   }
            // }

          }}
          data={this.props.item.data}
        />
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  outerContainer: {
    border: AppCommonTheme.border,
    padding: 20,
    borderRadius: AppCommonTheme.borderRadius,
    flex: 1,
    // ':hover': {
    //   cursor: 'pointer'
    // },
    '@media (max-width: 767px)': {
      padding: '20px 10px',
      borderRadius: 8,
    }
  },
  paperFooter: {
    marginBottom: 0,
    display: 'flex',
    verticalAlign: 'top',
    justifyContent: 'flex-end'
  },
  paperTitle: {
    fontWeight: 'bold',
    color: AppTheme.white,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center'
  },
  subText: {
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 'bold',
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'default',
    '@media(max-width: 768px)': {
      fontSize: 12
    }
  },
  arrowUp: {
    color: 'green'
  },
  arrowDown: {
    color: 'red'
  },
  videoContainerTile: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paragraphMargin: {
    margin: 0,
    flex: 1,
    fontSize: 20,
    fontWeight: 500,
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  progress: {
    color: AppTheme.white
  },
  loader: {
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  colorCode1: {
    width: 15,
    height: 15,
    display: 'flex'
  },
  colorCode2: {
    width: 15,
    height: 15,
    display: 'flex'
  },
  colorInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    fontWeight: 'normal'
  }
});
