import React, { } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Chapter } from '../../../screens/Chapter';
import { Controller } from '../../../common/Controller';

export function ChapterPreview(props) {
  const courseSlug = props.match.params.slug
  const chapterId = props.match.params.chapterId
  // const orgId = (Controller.get().roleHelper().isProductUser() ? (props.match.params.orgId ? props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
  const orgId = props.match.params.orgId ? props.match.params.orgId : Controller.get().roleHelper().getOrgId()
  const location = props.location && props.location

  return (
    <Chapter courseSlug={courseSlug} chapterId={chapterId} preview={true} orgId={orgId} location={location} />
  )
}

const Styles = StyleSheet.create({
})