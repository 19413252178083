import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../styles/AppTheme';
import { AppCommonTheme } from '../styles/Styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { PageAnalytics } from '../common/helpers/PageAnalytics';
import Drawer from '@material-ui/core/Drawer';
import { StyleHandler } from '../helpers/StyleHandler';
import { Helper } from '../common/helpers/Helper';
import { Logo } from './Logo';
import { Controller } from '../common/Controller';
import { AppContext } from '../common/components/contexts/AppContext';
import { UserContext } from '../common/components/contexts/UserContext';
import { Link } from 'react-router-dom';

export function DrawerMenuPublic(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const { user, userContextStateHandler } = useContext(UserContext)
  const [state, setState] = useState({
    mouseEnter: false,
  })

  const mouseEnter = () => {
    setState({
      ...state,
      mouseEnter: true,
    })
  }

  const mouseLeave = () => {
    setState({
      ...state,
      mouseEnter: false,
    })

  }

  const itemSelected = (item) => {
    appContextStateHandler({ pageKey: item.id })
    Controller.get().courseMgr().pushRoute(item.route)
  }

  const listItemContainerStyle = (id) => {
    if (state.mouseEnter) {
      return css(Styles.listItem, Styles.listItemEnterContainer)
    } else if (appData && appData.pageKey === id) {
      return css(Styles.listItem, Styles.listItemSelectedContainer)
    } else {
      return css(Styles.listItem, Styles.listItemContainer)
    }
  }

  const listTitleContainerStyle = (id) => {
    if (appData && appData.pageKey === id) {
      return css(Styles.listTitle, Styles.listTitleSelectedContainer)
    } else {
      return css(Styles.listTitle)
    }
  }


  const navLinkRoute = (item) => {
    const profileImg = user && user.data && user.data.picture || require('../assets/images/profile_icon.svg')
    return (
      <div onClick={() => itemSelected(item)
        //   PageAnalytics.gaTrkEvent(
        //     'header',
        //     'menu-item-click',
        //     item.id,
        //     null)
      }
      key={item.id}
      onMouseEnter={() => mouseEnter(item.id)}
      onMouseLeave={() => mouseLeave()}>
        <ListItem button
          key={item.id}
          className={listItemContainerStyle(item.id)}>
          <div className={css(Styles.imgContainer)}>
            <img
              alt={item.id === 'profile' ? Helper.getString('profileImgAlt') : Helper.getString('menuIconsAlt')}
              width='28px' height='28px'
              src={item.id === 'profile' ? profileImg : StyleHandler.getImage(item.id, appData && appData.pageKey)}
              className={css(Styles.imgContent)} />
          </div>
          <div className={css(Styles.listTitleContainer)}>
            <p className={listTitleContainerStyle(item.id)}>{item.title}</p>
          </div>
        </ListItem>
      </div>
    )
  }

  const renderList = () => {
    return (
      <List>
        {props.items.map(item => {
          return (
            navLinkRoute(item)
          )
        })}
      </List>
    )
  }

  return (
    <Drawer
      variant='permanent'
      open={true}
      className={css(Styles.drawer)}
      classes={{
        paper: css(Styles.drawer),
      }}
    >
      <div className={css(Styles.logoImgOuterContainer)}>
        <Link to={Controller.get().getHomeByRole()}>
          <Logo />
        </Link>
      </div>
      {renderList()}
    </Drawer>
  )
}

const Styles = StyleSheet.create({
  drawer: {
    width: AppCommonTheme.drawerWidth,
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  logoImgOuterContainer: {
    height: 134,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 30px'
  },
  imgContainer: {
    width: 28,
    height: 28,
    display: 'flex',
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: 6
  },
  listItemSelectedContainer: {
    padding: 0,
    backgroundColor: AppCommonTheme.bgColor,
    borderRight: '2px solid' + AppTheme.primaryColor,
  },
  listItemEnterContainer: {
    padding: 0,
    ':hover': {
      backgroundColor: AppCommonTheme.bgColor,
      borderRight: '2px solid' + AppTheme.primaryColor
    },
  },
  listItemContainer: {
    padding: 0,
  },
  listItem: {
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: '2px solid' + AppTheme.secondaryBg,
  },

  listTitleContainer: {
    display: 'grid'
  },
  listTitle: {
    margin: 0,
    fontSize: 12,
    color: '#AAABAF',
    marginTop: 5,
    marginLeft: 15,
    marginRight: 15,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  listTitleSelectedContainer: {
    color: AppTheme.primaryColor
  }
})