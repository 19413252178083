// const courses = [
//   {
//     id: '1653898475176',
//     slug: 'course-a',
//     title: 'Course A',
//     descr: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book'
//   },
//   {
//     id: '1653898492096',
//     slug: 'course-b',
//     title: 'course B',
//     descr: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book'
//   }
// ]

export const MockData = {
  dashboard: {
    title: 'Sony',
    status: [
      {
        id: 100,
        title: 'Assigned',
        value: 10,
        image: require('../assets/images/document_icon.svg'),
        slug: 'assigned'
      },
      {
        id: 101,
        title: 'Self-enrolled',
        value: 6,
        image: require('../assets/images/document_self_icon.svg'),
        slug: 'self-enrolled'
      },
      {
        id: 102,
        title: 'Ongoing',
        value: 4,
        image: require('../assets/images/timer_icon.svg'),
        slug: 'ongoing'
      },
      {
        id: 103,
        title: 'Due Soon',
        value: 2,
        image: require('../assets/images/due_icon.svg'),
        slug: 'due-soon'
      },
      {
        id: 104,
        title: 'Overdue',
        value: 4,
        image: require('../assets/images/calendar_icon.svg'),
        slug: 'overdue'
      },
      {
        id: 105,
        title: 'Completed',
        value: 5,
        image: require('../assets/images/medal_icon.svg'),
        slug: 'completed'
      }
    ],
    courses: {
      title: 'My Courses',
      items: [
        {
          id: 100,
          title: 'Python Basics - 100 Introduction',
          status: 'Assigned',
          image: require('../assets/images/course_bg_1.svg'),
          duration: '45 mins',
          due: '24 days',
          link: ''
        },
        {
          id: 101,
          title: 'Python Basics - 101',
          status: 'Assigned',
          image: require('../assets/images/course_bg_2.svg'),
          duration: '55 mins',
          due: '24 days',
          link: ''
        },
        {
          id: 102,
          title: 'Python Basics - 102',
          status: 'Assigned',
          image: require('../assets/images/course_bg_3.svg'),
          duration: '30 mins',
          due: '24 days',
          link: ''
        },
        {
          id: 103,
          title: 'Python Basics - 103',
          status: 'Assigned',
          image: require('../assets/images/course_bg_4.svg'),
          duration: '45 mins',
          due: '20 days',
          link: ''
        },
        {
          id: 104,
          title: 'Python Basics - 104',
          status: 'Assigned',
          image: require('../assets/images/course_bg_5.svg'),
          duration: '20 mins',
          due: '15 days',
          link: ''
        },
        {
          id: 105,
          title: 'Python Basics - 105',
          status: 'Assigned',
          image: require('../assets/images/course_bg_6.svg'),
          duration: '15 mins',
          due: '10 days',
          link: ''
        },
        {
          id: 106,
          title: 'Python Basics - 106',
          status: 'Assigned',
          image: require('../assets/images/course_bg_7.svg'),
          duration: '35 mins',
          due: '24 days',
          link: ''
        },
        {
          id: 108,
          title: 'Python Basics - 108',
          status: 'Assigned',
          image: require('../assets/images/course_bg_8.svg'),
          duration: '40 mins',
          due: '24 days',
          link: ''
        }
      ]
    }
  },

  explore: {
    categories: {
      title: 'Categories',
      items: [
        {
          id: 'category100',
          title: 'Category name - 100',
          image: require('../assets/images/course_bg_1.svg'),
        },
        {
          id: 'category101',
          title: 'Category name - 101',
          image: require('../assets/images/course_bg_2.svg'),
        },
        {
          id: 'category102',
          title: 'Category name - 102',
          image: require('../assets/images/course_bg_3.svg'),
        },
        {
          id: 'category103',
          title: 'Category name - 103',
          image: require('../assets/images/course_bg_4.svg'),
          slug: 'category103'
        },
        {
          id: 'category104',
          title: 'Category name - 104',
          image: require('../assets/images/course_bg_5.svg'),
        },
        {
          id: 'category105',
          title: 'Category name - 105',
          image: require('../assets/images/course_bg_6.svg'),
        },
      ]
    },
    courses: {
      title: 'Popular courses',
      items: [
        {
          id: 100,
          title: 'Python Basics - 100 Introduction',
          status: 'Assigned',
          image: require('../assets/images/course_bg_1.svg'),
          duration: '45 mins',
          due: '24 days',
          link: ''
        },
        {
          id: 101,
          title: 'Python Basics - 101',
          status: 'Assigned',
          image: require('../assets/images/course_bg_2.svg'),
          duration: '55 mins',
          due: '24 days',
          link: ''
        },
        {
          id: 102,
          title: 'Python Basics - 102',
          status: 'Assigned',
          image: require('../assets/images/course_bg_3.svg'),
          duration: '30 mins',
          due: '24 days',
          link: ''
        },
        {
          id: 103,
          title: 'Python Basics - 103',
          status: 'Assigned',
          image: require('../assets/images/course_bg_4.svg'),
          duration: '45 mins',
          due: '20 days',
          link: ''
        },
      ]
    }
  },

  assigned: {
    title: 'Assigned Courses',
    items: [
      {
        id: 100,
        title: 'Python Basics - 100',
        image: require('../assets/images/course_bg_1.svg'),
        status: 'Assigned',
        link: ''
      },
      {
        id: 101,
        title: 'Python Basics - 101',
        image: require('../assets/images/course_bg_2.svg'),
        status: 'Assigned',
        link: ''
      },
      {
        id: 102,
        title: 'Python Basics - 102',
        image: require('../assets/images/course_bg_3.svg'),
        status: 'Assigned',
        link: ''
      },
      {
        id: 103,
        title: 'Python Basics - 103',
        image: require('../assets/images/course_bg_4.svg'),
        status: 'Assigned',
        link: ''
      },
      {
        id: 104,
        title: 'Python Basics - 104',
        image: require('../assets/images/course_bg_5.svg'),
        status: 'Assigned',
        link: ''
      },
      {
        id: 105,
        title: 'Python Basics - 105',
        image: require('../assets/images/course_bg_6.svg'),
        status: 'Assigned',
        link: ''
      },
      {
        id: 106,
        title: 'Python Basics - 106',
        image: require('../assets/images/course_bg_7.svg'),
        status: 'Assigned',
        link: ''
      },
      {
        id: 108,
        title: 'Python Basics - 107',
        image: require('../assets/images/course_bg_8.svg'),
        status: 'Assigned',
        link: ''
      },
      {
        id: 109,
        title: 'Python Basics - 108',
        image: require('../assets/images/course_bg_5.svg'),
        status: 'Assigned',
        link: ''
      },
      {
        id: 110,
        title: 'Python Basics - 109',
        image: require('../assets/images/course_bg_2.svg'),
        status: 'Assigned',
        link: ''
      },
    ]
  },
  ongoing: {
    title: 'Ongoing Courses',
    items: [
      {
        id: 102,
        title: 'Python Basics - 102',
        image: require('../assets/images/course_bg_3.svg'),
        link: ''
      },
      {
        id: 103,
        title: 'Python Basics - 103',
        image: require('../assets/images/course_bg_4.svg'),
        link: ''
      },
      {
        id: 104,
        title: 'Python Basics - 104',
        image: require('../assets/images/course_bg_5.svg'),
        link: ''
      },
      {
        id: 105,
        title: 'Python Basics - 105',
        image: require('../assets/images/course_bg_6.svg'),
        link: ''
      }
    ]
  },
  'self-enrolled': {
    title: 'Self-enrolled Courses',
    items: [
      {
        id: 100,
        title: 'Python Basics - 100',
        image: require('../assets/images/course_bg_1.svg'),
        link: ''
      },
      {
        id: 101,
        title: 'Python Basics - 101',
        image: require('../assets/images/course_bg_2.svg'),
        link: ''
      },
      {
        id: 102,
        title: 'Python Basics - 102',
        image: require('../assets/images/course_bg_3.svg'),
        link: ''
      },
      {
        id: 103,
        title: 'Python Basics - 103',
        image: require('../assets/images/course_bg_4.svg'),
        link: ''
      },
      {
        id: 104,
        title: 'Python Basics - 104',
        image: require('../assets/images/course_bg_5.svg'),
        link: ''
      },
      {
        id: 105,
        title: 'Python Basics - 105',
        image: require('../assets/images/course_bg_6.svg'),
        link: ''
      }
    ]
  },
  overdue: {
    title: 'Overdue Courses',
    items: [
      {
        id: 100,
        title: 'Python Basics - 100',
        image: require('../assets/images/course_bg_1.svg'),
        link: ''
      },
      {
        id: 101,
        title: 'Python Basics - 101',
        image: require('../assets/images/course_bg_2.svg'),
        link: ''
      },
      {
        id: 102,
        title: 'Python Basics - 102',
        image: require('../assets/images/course_bg_3.svg'),
        link: ''
      },
      {
        id: 103,
        title: 'Python Basics - 103',
        image: require('../assets/images/course_bg_4.svg'),
        link: ''
      },
    ]
  },
  'due-soon': {
    title: 'Due-soon Courses',
    items: [
      {
        id: 109,
        title: 'Python Basics - 109',
        image: require('../assets/images/course_bg_5.svg'),
        link: ''
      },
      {
        id: 105,
        title: 'Python Basics - 105',
        image: require('../assets/images/course_bg_6.svg'),
        link: ''
      }
    ]
  },
  completed: {
    title: 'Completed Courses',
    items: [
      {
        id: 104,
        title: 'Python Basics - 104',
        image: require('../assets/images/course_bg_5.svg'),
        link: ''
      },
      {
        id: 105,
        title: 'Python Basics - 105',
        image: require('../assets/images/course_bg_6.svg'),
        link: ''
      },
      {
        id: 106,
        title: 'Python Basics - 106',
        image: require('../assets/images/course_bg_7.svg'),
        link: ''
      },
      {
        id: 108,
        title: 'Python Basics - 108',
        image: require('../assets/images/course_bg_8.svg'),
        link: ''
      },
      {
        id: 109,
        title: 'Python Basics - 109',
        image: require('../assets/images/course_bg_5.svg'),
        link: ''
      }
    ]
  },

  category: {
    title: 'Category name - 100',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt placerat orci placerat fames faucibus vitae, ut. In fringilla volutpat neque, sit',
    image: require('../assets/images/category_bg_img.svg'),
    items: [
      {
        id: 100,
        title: 'Python Basics - 100',
        image: require('../assets/images/course_bg_1.svg'),
        link: ''
      },
      {
        id: 101,
        title: 'Python Basics - 101',
        image: require('../assets/images/course_bg_2.svg'),
        link: ''
      },
      {
        id: 102,
        title: 'Python Basics - 102',
        image: require('../assets/images/course_bg_3.svg'),
        link: ''
      },
      {
        id: 103,
        title: 'Python Basics - 103',
        image: require('../assets/images/course_bg_4.svg'),
        link: ''
      },
      {
        id: 104,
        title: 'Python Basics - 104',
        image: require('../assets/images/course_bg_5.svg'),
        link: ''
      },
      {
        id: 105,
        title: 'Python Basics - 105',
        image: require('../assets/images/course_bg_6.svg'),
        link: ''
      },
      {
        id: 106,
        title: 'Python Basics - 106',
        image: require('../assets/images/course_bg_7.svg'),
        link: ''
      },
      {
        id: 108,
        title: 'Python Basics - 107',
        image: require('../assets/images/course_bg_8.svg'),
        link: ''
      },
    ]
  },

  resources: {
    list: {
      items: [
        {
          title: 'Python Basics - 101 Introduction',
          fileName: 'File name1.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 102 Introduction',
          fileName: 'File name2.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 103 Introduction',
          fileName: 'File name3.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 104 Introduction',
          fileName: 'File name4.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 105 Introduction',
          fileName: 'File name5.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 106 Introduction',
          fileName: 'File name6.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 107 Introduction',
          fileName: 'File name7.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 108 Introduction',
          fileName: 'File name8.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 109 Introduction',
          fileName: 'File name9.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 110 Introduction',
          fileName: 'File name10.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 111 Introduction',
          fileName: 'File name111.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
      ]
    },
    category: [
      {
        title: 'Python Basics - 101 Introduction',
        items: [
          {
            fileName: 'File name1.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
          {
            fileName: 'File name2.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
          {
            fileName: 'File name3.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
        ]
      },
      {
        title: 'Python Basics - 102',
        items: [
          {
            fileName: 'File name4.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
          {
            fileName: 'File name5.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          }
        ]
      },
      {
        title: 'Python Basics - 103',
        items: [
          {
            fileName: 'File name6.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
          {
            fileName: 'File name7.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
          {
            fileName: 'File name8.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
          {
            fileName: 'File name9.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
        ]
      },
    ],
  },
  favourites: {
    courses: {
      title: 'Courses',
      items: [
        {
          id: 104,
          title: 'Python Basics - 104',
          image: require('../assets/images/course_bg_5.svg'),
          link: '',
          status: 'Assigned',
        },
        {
          id: 105,
          title: 'Python Basics - 105',
          image: require('../assets/images/course_bg_6.svg'),
          link: '',
          status: 'Assigned',
        },
        {
          id: 106,
          title: 'Python Basics - 106',
          image: require('../assets/images/course_bg_7.svg'),
          link: '',
          status: 'Assigned',
        }
      ]
    },
    resources: {
      title: 'Resources',
      items: [
        {
          title: 'Python Basics - 101 Introduction',
          fileName: 'File name1.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 102 Introduction',
          fileName: 'File name2.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 103 Introduction',
          fileName: 'File name3.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 104 Introduction',
          fileName: 'File name4.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
      ]
    }
  },
  profile: {
    image: require('../assets/images/profile_img.svg'),
    salutation: 'Mr',
    // name: 'Sony Kadavan',
    phoneNumber: '+91 - 9876543210',
    // email: 'sonykadavan@gmail.com',
    company: 'sincX',
    jobTitle: 'Frontend developer',
    department: 'Engineering',
    managerName: 'Sabarish Narayana Das',
    certificates: {
      title: 'My Certificates',
      items: [
        {
          id: 104,
          title: 'Python Basics - 104',
          image: require('../assets/images/course_bg_5.svg'),
          link: '',
          status: 'Completed',
        },
        {
          id: 105,
          title: 'Python Basics - 105',
          image: require('../assets/images/course_bg_6.svg'),
          link: '',
          status: 'Completed',
        },
        {
          id: 106,
          title: 'Python Basics - 106',
          image: require('../assets/images/course_bg_7.svg'),
          link: '',
          status: 'Completed',
        }
      ]
    },
    trophies: {
      title: 'My Trophies',
      point: 400,
      Trophy: 3,
      Shield: 1,
      Medal: 2,
    },
    information: {

    }
  },

  links: {
    items: [
      {
        key: 'legal',
        title: 'Legal',
        link: ''
      },
      {
        key: 'terms',
        title: 'Terms of use',
        link: 'terms'
      },
      {
        key: 'privacy',
        title: 'Privacy policy',
        link: '/privacy'
      },
      {
        key: 'contact',
        title: 'Contact admin',
        link: ''
      },
      {
        key: 'logout',
        title: 'Logout',
      }
    ]
  },

  course: {
    title: 'Python 101 - Basic Introduction',
    image: require('../assets/images/category_bg_img.svg'),
    rating: 4.5,
    totalRating: 5,
    duration: '50 minutes',
    dueDate: '20th June 2022',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id elementum tellus nunc, convallis vel proin. Varius tortor sed egestas ipsum morbi hendrerit. Faucibus posuere libero, auctor odio. Blandit sem sed urna, aliquet nunc iaculis enim non, platea. Maecenas integer nunc nec nisi. Purus nunc vulputate vivamus morbi quis nunc neque, integer. Sagittis malesuada pulvinar habitasse donec. Faucibus duis dolor, sit aliquet eget euismod ac euismod ac. Lacus egestas vel tristique pulvinar amet, viverra ultrices luctus maecenas. Habitant sit purus nunc adipiscing felis imperdiet. Felis quis convallis id odio vitae. Sed tellus accumsan, tristique sed porttitor eget pellentesque elementum. Faucibus volutpat pulvinar egestas feugiat eu.\n\nTurpis cursus auctor gravida in ullamcorper sem faucibus proin. Ultrices faucibus sed vestibulum lectus quam id ultricies bibendum egestas.',
    eligibility: {
      items: [
        {
          title: 'Python Basics - 101 Introduction',
          image: require('../assets/images/course_bg_1.svg'),
          status: 'Assigned',
        },
        {
          title: 'Python Basics - 102 Introduction',
          image: require('../assets/images/course_bg_2.svg'),
          status: 'Assigned',
        },
      ],
    },
    resources: {
      items: [
        {
          title: 'Python Basics - 101 Introduction',
          fileName: 'File name1.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 102 Introduction',
          fileName: 'File name2.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
        {
          title: 'Python Basics - 103 Introduction',
          fileName: 'File name3.pptx',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
        },
      ]
    },
    faqs: [
      {
        id: '001',
        title: 'Reference and Primitive Types Refresher',
        descr: 'Not next-gen but super important: Knowing the difference between reference and primitive types. This lecture will explore both.'
      },
      {
        id: '003',
        title: 'Wrap Up',
        descr: 'Let me wrap this module up and summarize what we learned thus far.'
      },
      {
        id: '004',
        title: 'Destructuring',
        descr: 'Time to destructure! Its a new JavaScript feature and it allows you to ... do what? Lets explore the feature in this lecture.'
      },
      {
        id: '005',
        title: 'The Spread & Rest Operator',
        descr: 'React also makes heavy usage of the "Spread" operator. Or React projects do, to be precise. Learn more about that new operator in this lecture.'
      }
    ],
    sections: [
      {
        title: 'Getting Started',
        chapters: [
          {
            id: 787878,
            title: 'Welcome to the course',
            status: 'new',
            type: 'video + text'
          },
          {
            id: 787879,
            title: 'What is React.js?',
            status: 'New',
            type: 'image + text'
          },
          {
            id: 787880,
            title: 'Why React Instead of "Just Javascript"?',
            status: 'New',
            type: 'html + text'
          },
          {
            id: 787881,
            title: 'Building Single-Page Application with React',
            status: 'New',
            type: 'pdf + text'
          }
        ]
      },
      {
        title: 'JavaScript Refresher',
        chapters: [
          {
            id: '787890',
            title: 'Module Introduction',
            status: 'new',
            type: 'text'
          },
          {
            id: '787891',
            title: 'Arrow Functions',
            status: 'Inprogress',
            type: 'pdf + text'
          }
        ]
      },
    ]
  },

  search: {
    categories: {
      title: 'Knowledge Sets',
      items: [
        {
          id: 'category100',
          title: 'Knowledge Sets - 100',
          image: require('../assets/images/course_bg_1.svg'),
        },
        {
          id: 'category101',
          title: 'Knowledge Sets - 101',
          image: require('../assets/images/course_bg_2.svg'),
        },
      ]
    },
    courses: {
      title: 'Courses',
      items: [
        {
          id: 100,
          title: 'Python Basics - 100 Introduction',
          status: 'Assigned',
          image: require('../assets/images/course_bg_1.svg'),
          duration: '45 mins',
          due: '24 days',
          link: ''
        },
        {
          id: 101,
          title: 'Python Basics - 101',
          status: 'Assigned',
          image: require('../assets/images/course_bg_2.svg'),
          duration: '55 mins',
          due: '24 days',
          link: ''
        },
        {
          id: 102,
          title: 'Python Basics - 102',
          status: 'Assigned',
          image: require('../assets/images/course_bg_3.svg'),
          duration: '30 mins',
          due: '24 days',
          link: ''
        },
      ]
    }
  },

  assessment: {
    items: [
      {
        key: 'firstQuestion',
        title: 'Why React Instead of "Just Javascript"?',
        options: [
          {
            id: 101,
            label: 'Slow',
          },
          {
            id: 102,
            label: 'Fast',
          },
          {
            id: 103,
            label: 'Dependent',
          },
          {
            id: 104,
            label: 'Above all',
          }
        ]
      },
      {
        key: 'secondQuestion',
        title: 'Advantages of React.js?',
        options: [
          {
            id: 101,
            label: 'Slow',
          },
          {
            id: 102,
            label: 'Fast',
          },
          {
            id: 103,
            label: 'Dependent',
          },
          {
            id: 104,
            label: 'Above all',
          }
        ]
      },
    ]
  },

  courseById: {
    1653898475176: {
      title: 'Python 101 - Basic Introduction',
      image: require('../assets/images/category_bg_img.svg'),
      duration: '50 min',
      dueDate: '20th June 2022',
      rating: 4.5,
      totalRating: 5,
      descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id elementum tellus nunc, convallis vel proin. Varius tortor sed egestas ipsum morbi hendrerit. Faucibus posuere libero, auctor odio. Blandit sem sed urna, aliquet nunc iaculis enim non, platea. Maecenas integer nunc nec nisi. Purus nunc vulputate vivamus morbi quis nunc neque, integer. Sagittis malesuada pulvinar habitasse donec. Faucibus duis dolor, sit aliquet eget euismod ac euismod ac. Lacus egestas vel tristique pulvinar amet, viverra ultrices luctus maecenas. Habitant sit purus nunc adipiscing felis imperdiet. Felis quis convallis id odio vitae. Sed tellus accumsan, tristique sed porttitor eget pellentesque elementum. Faucibus volutpat pulvinar egestas feugiat eu.\n\nTurpis cursus auctor gravida in ullamcorper sem faucibus proin. Ultrices faucibus sed vestibulum lectus quam id ultricies bibendum egestas.',
      eligibility: {
        items: [
          {
            id: 100,
            title: 'Python Basics - 100 Introduction',
            status: 'Assigned',
            image: require('../assets/images/course_bg_1.svg'),
            duration: '45 mins',
            due: '24 days',
            link: ''
          },
          {
            id: 101,
            title: 'Python Basics - 101',
            status: 'Assigned',
            image: require('../assets/images/course_bg_2.svg'),
            duration: '55 mins',
            due: '20 days',
            link: ''
          },
        ],
      },
      resources: {
        items: [
          {
            title: 'Python Basics - 101 Introduction',
            fileName: 'File name1.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
          {
            title: 'Python Basics - 102 Introduction',
            fileName: 'File name2.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          },
          {
            title: 'Python Basics - 103 Introduction',
            fileName: 'File name3.pptx',
            descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
          }
        ]
      },
      faqs: [
        {
          id: '001',
          title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit nec dictum et molestie id.',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id elementum tellus nunc, convallis vel proin. Varius tortor sed egestas ipsum morbi hendrerit. Faucibus posuere libero, auctor odio. Blandit sem sed urna, aliquet nunc iaculis enim non, platea. Maecenas integer nunc nec nisi. Purus nunc vulputate vivamus morbi quis nunc neque, integer. Sagittis malesuada pulvinar habitasse donec. Faucibus duis dolor, sit aliquet eget euismod ac euismod ac. Lacus egestas vel tristique pulvinar amet, viverra ultrices lu'
        },
        {
          id: '003',
          title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit nec dictum et molestie id.',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id elementum tellus nunc, convallis vel proin. Varius tortor sed egestas ipsum morbi hendrerit. Faucibus posuere libero, auctor odio. Blandit sem sed urna, aliquet nunc iaculis enim non, platea. Maecenas integer nunc nec nisi. Purus nunc vulputate vivamus morbi quis nunc neque, integer. Sagittis malesuada pulvinar habitasse donec. Faucibus duis dolor, sit aliquet eget euismod ac euismod ac. Lacus egestas vel tristique pulvinar amet, viverra ultrices lu'
        },
        {
          id: '004',
          title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit nec dictum et molestie id.',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id elementum tellus nunc, convallis vel proin. Varius tortor sed egestas ipsum morbi hendrerit. Faucibus posuere libero, auctor odio. Blandit sem sed urna, aliquet nunc iaculis enim non, platea. Maecenas integer nunc nec nisi. Purus nunc vulputate vivamus morbi quis nunc neque, integer. Sagittis malesuada pulvinar habitasse donec. Faucibus duis dolor, sit aliquet eget euismod ac euismod ac. Lacus egestas vel tristique pulvinar amet, viverra ultrices lu'
        },
        {
          id: '005',
          title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit nec dictum et molestie id.',
          descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id elementum tellus nunc, convallis vel proin. Varius tortor sed egestas ipsum morbi hendrerit. Faucibus posuere libero, auctor odio. Blandit sem sed urna, aliquet nunc iaculis enim non, platea. Maecenas integer nunc nec nisi. Purus nunc vulputate vivamus morbi quis nunc neque, integer. Sagittis malesuada pulvinar habitasse donec. Faucibus duis dolor, sit aliquet eget euismod ac euismod ac. Lacus egestas vel tristique pulvinar amet, viverra ultrices lu'
        }
      ],
      content: [
        {
          id: '001',
          title: 'Section one title',
          status: 'Completed',
          chapters: [
            {
              title: 'Chapter first name',
              status: 'Completed',
            },
            {
              title: 'Chapter second name',
              status: 'Completed',
            }
          ]
        },
        {
          id: '002',
          title: 'Section second title',
          status: 'Inprogress',
          chapters: [
            {
              title: 'Chapter first name',
              status: 'Completed',
            },
            {
              title: 'Chapter second name',
              status: 'Inprogress',
            },
            {
              title: 'Chapter third name',
              status: 'New',
            }
          ]
        },
        {
          id: '003',
          title: 'Section third title',
          status: 'New',
          chapters: [
            {
              title: 'Chapter first name',
              status: 'New',
            },
            {
              title: 'Chapter second name',
              status: 'New',
            },
            {
              title: 'Chapter third name',
              status: 'New',
            }
          ]
        }
      ]
    }
  },

  categories: {
    title: 'All knowlegde sets',
    items: [
      {
        id: 'category100',
        title: 'Category name - 100',
        image: require('../assets/images/course_bg_1.svg'),
      },
      {
        id: 'category101',
        title: 'Category name - 101',
        image: require('../assets/images/course_bg_2.svg'),
      },
      {
        id: 'category102',
        title: 'Category name - 102',
        image: require('../assets/images/course_bg_3.svg'),
      },
      {
        id: 'category103',
        title: 'Category name - 103',
        image: require('../assets/images/course_bg_4.svg'),
        slug: 'category103'
      },
      {
        id: 'category104',
        title: 'Category name - 104',
        image: require('../assets/images/course_bg_5.svg'),
      },
      {
        id: 'category105',
        title: 'Category name - 105',
        image: require('../assets/images/course_bg_6.svg'),
      },
      {
        id: 'category106',
        title: 'Category name - 106',
        image: require('../assets/images/course_bg_3.svg'),
      },
      {
        id: 'category107',
        title: 'Category name - 107',
        image: require('../assets/images/course_bg_4.svg'),
        slug: 'category103'
      },
      {
        id: 'category108',
        title: 'Category name - 108',
        image: require('../assets/images/course_bg_5.svg'),
      },
    ]
  },

  section: {
    title: 'Section title goes here',
    chapters: [
      {
        title: 'Chapter first name',
        status: 'Completed',
      },
      {
        title: 'Chapter second name',
        status: 'Completed',
      },
      {
        title: 'Chapter third name',
        status: 'Inprogress',
      },
      {
        title: 'Chapter fourth name',
        status: 'New',
      },
      {
        title: 'Chapter fifth name',
        status: 'New',
      }
    ],
    resources: [
      {
        fileName: 'File name1.pptx',
        descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing '
      },
    ]
  },

  assessmentContent: [
    {
      status: 'correct'
    },
    {
      status: 'wrong'
    },
    {
      status: 'inprocess'
    },
    {
      status: 'new'
    },
    {
      status: 'new'
    }
  ],

  result: {
    totalQuestion: 5,
    correctAnswer: 4,
    trophyName: 'Shield'
  },

  checklist: {
    items: [
      {
        key: 'firstQuestion',
        title: 'Checklist heading goes here',
        subTitle: 'Lorem ipsum dolor sit amet, consectet',
        options: [
          {
            id: 101,
            label: 'Option',
          },
          {
            id: 102,
            label: 'Option',
          },
          {
            id: 103,
            label: 'Option',
          },
          {
            id: 104,
            label: 'Above all',
          }
        ]
      }
    ]
  },

  quiz: {
    items: [
      {
        key: 'firstQuestion',
        title: 'This is the space where question is displayed',
        answer: 103,
        answerTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque, quis et eu orci dignissim faucibus. Malesuada neque elit mo',
        options: [
          {
            id: 101,
            label: 'A. Option',
          },
          {
            id: 102,
            label: 'B. Option',
          },
          {
            id: 103,
            label: 'C. Option lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque leo platea egestas hendrerit turpis odio nunc, volutpat ultricies. Porta ipsum',
          },
          {
            id: 104,
            label: 'D. Option',
          }
        ]
      }
    ]
  },

  feedback: {
    items: [
      {
        key: 'ques1',
        title: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
      },
      {
        key: 'ques2',
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed adipiscing amet varius condimentum adipiscing. Aenean elementum metus pharetra rhoncus, tellus in turpis bibendum. ',
      },
      {
        key: 'ques3',
        title: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
      },
      {
        key: 'ques4',
        title: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
      },
      {
        key: 'ques5',
        title: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
      },
      {
        key: 'ques6',
        title: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
      },
      {
        key: 'ques7',
        title: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
      },
      {
        key: 'ques8',
        title: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
      },
      {
        key: 'ques9',
        title: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
      },
      {
        key: 'ques10',
        title: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet',
      },
    ]
  },

  userChapterStatus: {
    1655976471650: 'Completed',
    1656376099667: 'Completed',
    1656376345788: 'Inprogress',
    1656389731813: 'New',
    1656376942553: 'New',
    1656377125778: 'New',
    1656377372363: 'New',
    1656377476783: 'New'
  },

  adminStatusData: {
    learners: 66,
    groups: 4,
    creators: 5,
    courses: 53,
    hours: 1496
  },

  adminTable1: {
    header: [
      {
        title: 'Group Name',
        key: 'group'
      },
      {
        title: 'Rep. Manager',
        key: 'manager'
      },
      {
        title: 'Learner Name',
        key: 'learner'
      },
      {
        title: 'Learner Type',
        key: 'internal'
      },
      {
        title: 'Total Assigned',
        key: 'total'
      },
      {
        title: 'Over due',
        key: 'overDue'
      },
      {
        title: 'Due soon',
        key: 'dueSoon'
      },
      {
        title: 'In-Progress',
        key: 'inProgress'
      },
      {
        title: 'Over due',
        key: 'overDue2'
      },
      {
        title: 'Due soon',
        key: 'dueSoon2'
      },
      {
        title: 'In-Progress',
        key: 'inProgress2'
      }
    ],
    data: [
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2,
        overDue2: 2,
        dueSoon2: 1,
        inProgress2: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2,
        overDue2: 2,
        dueSoon2: 1,
        inProgress2: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2,
        overDue2: 2,
        dueSoon2: 1,
        inProgress2: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2,
        overDue2: 2,
        dueSoon2: 1,
        inProgress2: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2,
        overDue2: 2,
        dueSoon2: 1,
        inProgress2: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2,
        overDue2: 2,
        dueSoon2: 1,
        inProgress2: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2,
        overDue2: 2,
        dueSoon2: 1,
        inProgress2: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2,
        overDue2: 2,
        dueSoon2: 1,
        inProgress2: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2,
        overDue2: 2,
        dueSoon2: 1,
        inProgress2: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2
      },
      {
        group: 'Group 1',
        manager: 'Manager A',
        learner: 'Learner 02',
        internal: 'Internal',
        total: 8,
        overDue: 2,
        dueSoon: 1,
        inProgress: 2
      },
    ]
  },


  adminTable2: {
    header: [
      {
        title: 'Course Name',
        key: 'title'
      },
      {
        title: 'Published Year',
        key: 'publishedYear'
      },
      {
        title: 'Published Month',
        key: 'publishedMonth'
      },
      {
        title: 'Rating (1-5)',
        key: 'rating'
      },
      {
        title: 'Total Learning Hours',
        key: 'totalLearningHours'
      },
      {
        title: 'Complete',
        key: 'COMPLETED'
      },
      {
        title: 'Creator(s)',
        key: 'creators'
      },
      {
        title: 'Knowledge Sets',
        key: 'cats'
      },
      {
        title: 'Consent (Y/N)',
        key: 'consent'
      },
      {
        title: 'From Library?',
        key: 'mode'
      },
      {
        title: 'Course State',
        key: 'state'
      },
      {
        title: 'Recurrence',
        key: 'recurFrequency'
      },
      {
        title: 'Assessment',
        key: 'assessment'
      },
      {
        title: 'Duration',
        key: 'duration'
      }
    ],
    data: [
      {
        name: 'Course 2',
        course: 1,
        year: 2022,
        month: 'Jan',
        creator: 'Creator 2',
        rating: 4.8,
        knowledge: 'KS1',
        consent: 'No',
      },
      {
        name: 'Course 2',
        course: 1,
        year: 2022,
        month: 'Jan',
        creator: 'Creator 2',
        rating: 4.8,
        knowledge: 'KS1',
        consent: 'No',
      },
      {
        name: 'Course 2',
        course: 1,
        year: 2022,
        month: 'Jan',
        creator: 'Creator 2',
        rating: 4.8,
        knowledge: 'KS1',
        consent: 'No',
      },
      {
        name: 'Course 2',
        course: 1,
        year: 2022,
        month: 'Jan',
        creator: 'Creator 2',
        rating: 4.8,
        knowledge: 'KS1',
        consent: 'No',
      },
      {
        name: 'Course 2',
        course: 1,
        year: 2022,
        month: 'Jan',
        creator: 'Creator 2',
        rating: 4.8,
        knowledge: 'KS1',
        consent: 'No',
      },
      {
        name: 'Course 2',
        course: 1,
        year: 2022,
        month: 'Jan',
        creator: 'Creator 2',
        rating: 4.8,
        knowledge: 'KS1',
        consent: 'No',
      },
      {
        name: 'Course 2',
        course: 1,
        year: 2022,
        month: 'Jan',
        creator: 'Creator 2',
        rating: 4.8,
        knowledge: 'KS1',
        consent: 'No',
      },
      {
        name: 'Course 2',
        course: 1,
        year: 2022,
        month: 'Jan',
        creator: 'Creator 2',
        rating: 4.8,
        knowledge: 'KS1',
        consent: 'No',
      },
    ]
  },

  barData: {
    items: [
      {
        title: 'Overall Company Status',
        data: {
          labels: [
            'Overdue',
            'Due soon',
            'In-progress',
            'Completed'
          ],
          datasets: [
            {
              data: [20, 25, 28, 22],
              backgroundColor: '#4B8BE9',
              borderWidth: 20,
              borderRadius: 5,
              borderColor: '#4B8BE9',
              borderSkipped: true,
              barThickness: 15,
            }
          ]
        }
      },
      {
        title: 'Learner by Group',
        data: {
          labels: [
            'Group 1',
            'Group 2',
            'Group 3',
            'Group 4'
          ],
          datasets: [
            {
              data: [5, 3, 7, 6],
              backgroundColor: '#4B8BE9',
              borderWidth: 20,
              borderRadius: 5,
              borderColor: '#4B8BE9',
              borderSkipped: true,
              barThickness: 15,
            },
            {
              data: [9, 6, 5, 3],
              backgroundColor: '#DBE8FB',
              borderWidth: 20,
              borderRadius: 5,
              borderColor: '#DBE8FB',
              borderSkipped: true,
              barThickness: 15,
            }
          ],
        }
      },
      {
        title: 'Overdue by Group',
        data: {
          labels: [
            'Group 1',
            'Group 2',
            'Group 3',
            'Group 4'
          ],
          datasets: [
            {
              data: [25, 27, 20, 23],
              backgroundColor: '#4B8BE9',
              borderWidth: 20,
              borderRadius: 5,
              borderColor: '#4B8BE9',
              borderSkipped: true,
              barThickness: 15,
            }
          ]
        }
      },
      {
        title: 'Course Created by Month',
        data: {
          labels: [
            'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'
          ],
          datasets: [
            {
              data: [9, 10, 7, 5, 8, 9, 7, 4],
              backgroundColor: '#4B8BE9',
              borderWidth: 20,
              borderRadius: 5,
              borderColor: '#4B8BE9',
              borderSkipped: true,
              barThickness: 15,
            }
          ]
        }
      },
      {
        title: 'Course Created by Creator',
        data: {
          labels: [
            'Creator 1',
            'Creator 2',
            'Creator 3',
            'Creator 4',
            'Creator 5'
          ],
          datasets: [
            {
              data: [12, 13, 11, 6, 10],
              backgroundColor: '#4B8BE9',
              borderWidth: 20,
              borderRadius: 5,
              borderColor: '#4B8BE9',
              borderSkipped: true,
              barThickness: 15,
            }
          ]
        }
      },
      {
        title: 'Course by Knowledge Set',
        data: {
          labels: [
            'KS1',
            'KS2',
            'KS3',
            'KS4',
            'KS5',
            'KS6',
            'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan',
            'KS1',
            'KS2',
            'KS3',
            'KS4',
            'KS5',
            'KS6',
            'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'
          ],
          datasets: [
            {
              data: [12, 13, 11, 6, 10, 13, 9, 10, 7, 5, 8, 9, 7, 4, 12, 13, 11, 6, 10, 13, 9, 10, 7, 5, 8, 9, 7, 4],
              backgroundColor: '#4B8BE9',
              borderWidth: 20,
              borderRadius: 5,
              borderColor: '#4B8BE9',
              borderSkipped: true,
              barThickness: 15,
            }
          ]
        }
      },
    ]
  },

  barLine: {
    title: 'Total Enrolled vs Completed',
    data: {
      labels: [
        'Course 1',
        'Course 2',
        'Course 3',
        'Course 4',
        'Course 5',
        'Course 6',
        'Course 7', 'Course 8', 'Course 9', 'Course 10', 'Course 11', 'Course 12', 'Course 13', 'Course 14',
        'Course 15',
        'Course 16',
        'Course 17',
        'Course 18',
        'Course 19',
        'Course 20',
        'Course 21', 'Course 22', 'Course 23', 'Course 24'
      ],
      datasets: [
        {
          type: 'line',
          borderColor: '#DBE8FB',
          borderWidth: 2,
          fill: false,
          data: [8, 5, 3, 1, 8, 10, 2, 5, 6, 1, 2, 7, 2, 5, 2, 4, 6, 3, 4, 1, 5, 6, 2, 4],
        },
        {
          data: [12, 13, 11, 6, 10, 13, 9, 10, 7, 5, 8, 9, 7, 4, 12, 13, 11, 6, 10, 13, 9, 10, 7, 5],
          backgroundColor: '#4B8BE9',
          borderWidth: 20,
          borderRadius: 5,
          borderColor: '#4B8BE9',
          borderSkipped: true,
          barThickness: 15,
        }
      ]
    }
  },

  managerStatusData: {
    learners: 40,
    creators: 36,
    hours: 918
  },

  managerBarChart: {
    title: 'Overdue & Due Soon by Learner',
    data: {
      labels: [
        'Learner 1',
        'Learner 2',
        'Learner 3',
        'Learner 4',
        'Learner 5',
        'Learner 6',
        'Learner 7', 'Learner 8', 'Learner 9', 'Learner 10', 'Learner 11', 'Learner 12', 'Learner 13', 'Learner 14',
        'Learner 15',
        'Learner 16',
        'Learner 17'
      ],
      datasets: [
        {
          data: [5, 7, 3, 6, 7, 5, 6, 3, 7, 5, 3, 6, 5, 6, 3, 6, 7],
          backgroundColor: '#4B8BE9',
          borderWidth: 20,
          borderRadius: 5,
          borderColor: '#4B8BE9',
          borderSkipped: true,
          barThickness: 15,
        },
        {
          data: [3, 2, 1, 4, 2, 3, 2, 1, 2, 4, 2, 4, 5, 2, 3, 4, 1],
          backgroundColor: '#4B8BE9',
          borderWidth: 20,
          borderRadius: 5,
          borderColor: '#DBE8FB',
          borderSkipped: true,
          barThickness: 15,
        }
      ]
    }
  },

  leaderboard: [
    {
      name: 'Learner 1',
      value: 2600,
    },
    {
      name: 'Learner 2',
      value: 2400,
    },
    {
      name: 'Learner 3',
      value: 2200,
    },
    {
      name: 'Learner 4',
      value: 2300,
    },
    {
      name: 'Learner 5',
      value: 2500,
    },
    {
      name: 'Learner 6',
      value: 2000,
    },
    {
      name: 'Learner 7',
      value: 2200,
    },
    {
      name: 'Learner 8',
      value: 2200,
    },
    {
      name: 'Learner 9',
      value: 2600,
    },
    {
      name: 'Learner 10',
      value: 2600,
    },
    {
      name: 'Learner 1',
      value: 2600,
    },
    {
      name: 'Learner 2',
      value: 2400,
    },
    {
      name: 'Learner 3',
      value: 2200,
    },
    {
      name: 'Learner 4',
      value: 2300,
    },
    {
      name: 'Learner 5',
      value: 2500,
    },
    {
      name: 'Learner 6',
      value: 2000,
    },
    {
      name: 'Learner 7',
      value: 2200,
    },
    {
      name: 'Learner 8',
      value: 2200,
    },
    {
      name: 'Learner 9',
      value: 2600,
    },
    {
      name: 'Learner 10',
      value: 2600,
    },
    {
      name: 'Learner 1',
      value: 2600,
    },
    {
      name: 'Learner 2',
      value: 2400,
    },
    {
      name: 'Learner 3',
      value: 2200,
    },
    {
      name: 'Learner 4',
      value: 2300,
    },
    {
      name: 'Learner 5',
      value: 2500,
    },
    {
      name: 'Learner 6',
      value: 2000,
    },
    {
      name: 'Learner 7',
      value: 2200,
    },
    {
      name: 'Learner 8',
      value: 2200,
    },
    {
      name: 'Learner 9',
      value: 2600,
    },
    {
      name: 'Learner 10',
      value: 2600,
    },

    {
      name: 'Learner 1',
      value: 2600,
    },
    {
      name: 'Learner 2',
      value: 2400,
    },
    {
      name: 'Learner 3',
      value: 2200,
    },
    {
      name: 'Learner 4',
      value: 2300,
    },
    {
      name: 'Learner 5',
      value: 2500,
    },
    {
      name: 'Learner 6',
      value: 2000,
    },
    {
      name: 'Learner 7',
      value: 2200,
    },
    {
      name: 'Learner 8',
      value: 2200,
    },
    {
      name: 'Learner 9',
      value: 2600,
    },
    {
      name: 'Learner 10',
      value: 2600,
    },
  ],

  certificate: {
    link: 'https://azsincxapp-pri.s3.amazonaws.com/PRODUCT/4605761431/700513c0-9014-11ed-acb3-d39ff2038ef5.pdf?AWSAccessKeyId=ASIA334QZJ5EWYATTSXP&Expires=1676477503&Signature=Kd4eV5Aq6f5k8q%2BVyGgj%2BCF5GGM%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEJT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCICS7NIwXusmGYXesRFtDgBI0sHgix0hQRZbCv9uEXTQZAiEAoH0KERe1eMnyKPwQEU7iXCn5EHxsLlNKxnVhqv5OEZYqnwMILRAAGgw4MTU4MTA1NjM5MTMiDFKxH8pAmg52xcwlByr8Al%2B7PR%2F6lhH1c%2B9%2B8bmz%2BP%2Fi7HDdZtfQLknCSbanQvEo70RTuiNPWgh12tXNZ8uiW8cfUHamStt6%2FvFkwd%2BMGgGUGkP1ezIFuGqkQaj8PW9uU74Gteo3xdONEBXoql2BjWfqzP2796rpE44rpR9TFBtuuKoM548qKgM%2BUKULNaixxBfZT%2BSPp6DK1a3K6n5VOF87KRHPUl%2FUD2rCAlolVwPLbckkpyJ6S2glxJaSNMXjbuGwhteCr5tc6U1tYR17z9BC1xDKQ5LPKHVHN%2BTYKaGdOb8qsMRAo4gKo6opvgE8tzXZcXZHPXGx82seh6O4%2BwE9xHL8HeBCi7zUZ0ZJiRb9PUfYZ02osT%2Bd2MmDtW9zGXx2JJBZ6yCt9D4mbF2TzfuQLWrNOx6Avpe4aufOL7ZiLhykcckNAn6sXE1iG7kcc%2BZT1s1Bei5gWxpYzT%2FlLLpOMni6wPXfEcHq1ypew4gYutoMrPBJhCuMCYSVMLa9xySM5rkAwMJI6Fw5MISSs58GOp0BFkOuliHuynH%2BmqZcIngC1B3fO0PnEYTACjnNhlMw1%2Bx%2FIA6FQ%2FOHCLug8xnqJ5zCYbXJ94qXmFfKef2GPZkzTFSeG%2F9DppwL8b8zbZ2ip1hpzTqArFcvgstImOzH53QCzXWVIZs2Fn3QmIQHX%2BFSK6wziWffbQrvrYGkc%2Ft6bmeYI%2F7bb9QZtsLGHoBaLWIRxUJchOfd%2BJjQNCOydQ%3D%3D'
  },
  courseTable: {
    header: [
      {
        title: 'Course Name',
        key: 'title'
      },
      {
        title: 'Published Year',
        key: 'publishedYear'
      },
      {
        title: 'Published Month',
        key: 'publishedMonth'
      },
      {
        title: 'Rating (1-5)',
        key: 'rating'
      },
      {
        title: 'Total Learning Hours',
        key: 'totalLearningHours'
      },
      {
        title: 'Total Learners',
        key: 'TOTAL_LEARNERS'
      },
      {
        title: 'Completed',
        key: 'COMPLETED'
      },
      {
        title: 'Pending',
        key: 'PENDING'
      },
      {
        title: 'Creator(s)',
        key: 'creatorList'
      },
      {
        title: 'Knowledge Sets',
        key: 'cats'
      },
      {
        title: 'Consent (Y/N)',
        key: 'consent'
      },
      {
        title: 'From Library?',
        key: 'mode'
      },
      {
        title: 'Course State',
        key: 'state'
      },
      {
        title: 'Recurrence',
        key: 'recurFrequency'
      },
      {
        title: 'Assessment',
        key: 'assessment'
      },
      {
        title: 'Duration',
        key: 'duration'
      }
    ]
  },
  userTable: {
    header: [
      {
        title: 'Learner Name',
        key: 'givenname'
      },
      {
        title: 'Group Name(s)',
        key: 'subOrgs'
      },
      {
        title: 'Rep. Manager',
        key: 'managerName'
      },
      {
        title: 'Learner Type',
        key: 'roles'
      },
      {
        title: 'Total Assigned',
        key: 'ASSIGNED'
      },
      {
        title: 'Over due',
        key: 'OVERDUE'
      },
      {
        title: 'Due soon',
        key: 'DUESOON'
      },
      {
        title: 'In-Progress',
        // key: 'IN_PROGRESS'
        key: 'STARTED'
      },
      {
        title: 'Completed',
        key: 'COMPLETED'
      },
      {
        title: 'Trophy',
        key: 'TROPHY'
      },
      {
        title: 'Shield',
        key: 'SHIELD'
      },
      {
        title: 'Medal',
        key: 'MEDAL'
      },
      {
        title: 'Total learning hours',
        key: 'totalLearningHours'
      },
      {
        title: 'Points',
        key: 'totalPoints'
      }
    ],
  },
  //dep mgr tables
  courseTableMgr: {
    header: [
      {
        title: 'Course Name',
        key: 'title'
      },
      {
        title: 'Completed',
        key: 'COMPLETED'
      },
      {
        title: 'In-progress',
        // key: 'IN_PROGRESS'
        key: 'STARTED'
      },
      {
        title: 'Overdue',
        key: 'OVERDUE'
      },
      {
        title: 'Total Enrolled Learner(s)',
        key: 'TOTAL_LEARNERS'
      },
      {
        title: 'Consent (Yes/No)',
        key: 'consent'
      }
    ]
  },
  userTableMgr: {
    header: [
      {
        title: 'Learner Name',
        key: 'givenname'
      },
      {
        title: 'Group Name(s)',
        key: 'subOrgs'
      },
      {
        title: 'Total Assigned',
        key: 'ASSIGNED'
      },
      {
        title: 'Completed',
        key: 'COMPLETED'
      },
      {
        title: 'Over due',
        key: 'OVERDUE'
      },
      {
        title: 'Due soon',
        key: 'DUESOON'
      },
      {
        title: 'In-Progress',
        key: 'STARTED'
      },
      {
        title: 'Total Learning Hours',
        key: 'totalLearningHours'
      },
      {
        title: 'Points',
        key: 'totalPoints'
      }
    ],
  },
  tableSU: {
    header: [
      {
        title: 'Client Name',
        key: 'title'
      },
      {
        title: 'Country Code',
        key: 'country'
      },
      {
        title: 'Client Added (Year)',
        key: 'year'
      },
      {
        title: 'Client Added (Month)',
        key: 'month'
      },
      {
        title: 'Subscription Status',
        key: 'subscription'
      },
      // {
      //   title: 'Subscription Start Date',
      //   key: 'subscriptionsStartDate'
      // },
      {
        title: 'Subscription End Date',
        key: 'subscriptionsEndDate'
      },
      {
        title: 'Total Learners',
        key: 'learnerCount'
      },
      {
        title: 'Total Creators',
        key: 'creatorCount'
      },
      {
        title: 'Total Courses',
        key: 'courseCount'
      },
      {
        title: 'Course from Library',
        key: 'ADOPT'
      },
      // {
      //   title: 'Total Learning Hours',
      //   key: 'learningHours'
      // }
    ],
  }
}

export const feedbackData =  {
  "course": {
    "catIds": [
      "9287085490"
    ],
    "lastModifiedUserId": "94e4ced0e59e11ed9e0daf00bc918545",
    "modifiedYm": "202305",
    "learnerTeamIds": [
      "1682667593019-1682679379774"
    ],
    "ownerIds": [
      "a96b8f40e5a011ed9bd9698ea98d4f12"
    ],
    "mode": "ADOPT",
    "createdYm": "202304",
    "dueDays": "90",
    "state": "LIVE",
    "feedbackQuestions": {
      "1683971634248": {
        "title": "How?",
        "id": "1683971634248"
      },
      "1684086790541": {
        "title": "Why?",
        "id": "1684086790541"
      }
    },
    "createdTs": 1682682226452,
    "modifiedTs": 1684086790539,
    "orgId": "1682667593019",
    "courseId": "5718868575",
    "learnerIds": [
      "ffbe3630f0eb11ed8f378f6642783490"
    ],
    "priorCourses": [],
    "learners": {
      "ffbe3630f0eb11ed8f378f6642783490": {
        "assignedDate": "2023-05-13",
        "selfenrolled": false,
        "assignmentId": "1683971621439",
        "dueDate": "2023-08-11"
      }
    },
    "feedback": "YES",
    "id": "1682667593019-5718868575",
    "consents": {},
    "chapters": {
      "1682668053225": {
        "info1": "Lom nunc faucibus a. Senectus et netus et malesuada.",
        "contentText": " s pulvinar elementum integer enim neque. Tellus integer feugiat scelerisque varius morbi enim nunc faucibus a. Senectus et netus et malesuada.",
        "sectionId": "1682668024187",
        "id": "1682668053225",
        "title": "s1c1",
        "iType": "TEXT",
        "order": "001",
        "info2": " varius morbi enim nunc faucibus a. Senectus et netus et malesuada."
      }
    },
    "descr": "Pretium aenean pharetra magna ac placerat vestibulum lectus. Consectetur purus ut faucibus pulvinar elementum integer enim neque. Tellus integer feugiat scelerisque varius morbi enim nunc faucibus a. Senectus et netus et malesuada.",
    "slug": "azyd1-logic-pro-course",
    "imageName": "PRODUCT/5718868575/1f9b6220-e598-11ed-a326-d72e40d0f879.jpg",
    "sections": {
      "1682668024187": {
        "title": "s1",
        "order": "001",
        "id": "1682668024187"
      }
    },
    "searchable": "azyd1 jingle logic pro course",
    "duration": "20",
    "title": "Azyd1 Jingle Logic Pro Course",
    "image": "https://dvmnu9nuwv5rk.cloudfront.net/PRODUCT/5718868575/1f9b6220-e598-11ed-a326-d72e40d0f879.jpg",
    "stats": {
      "likeCount": "0",
      "ratings": {
        "sum": 5,
        "average": "5.0",
        "count": 1,
        "ratingCount1star": 0,
        "ratingCount2star": 0,
        "ratingCount3star": 0,
        "ratingCount4star": 0,
        "ratingCount5star": 1
      }
    },
    "__resolves": {
      "catsById": {
        "9287085490": {
          "id": "9287085490",
          "slug": "featured-courses",
          "title": "Featured Courses"
        }
      },
      "priorCourseById": {},
      "certificateTemplatesById": {
        "TEMPATE1": {
          "id": "TEMPATE1",
          "title": "Certificate of Completion"
        },
        "TEMPATE2": {
          "id": "TEMPATE2",
          "title": "Certificate of Appreciation"
        },
        "TEMPATE3": {
          "id": "TEMPATE3",
          "title": "Certificate of Attendance"
        }
      }
    }
  },
  "courseProgress": {
    "items": [
      {
        "lastModifiedUserId": "ffbe3630f0eb11ed8f378f6642783490",
        "modifiedYm": "202305",
        "contextId": "1682667593019-ffbe3630f0eb11ed8f378f6642783490",
        "rating": 5,
        "createdYm": "202305",
        "contextTypeCreatedTs": "cp-5718868575-1683971621439-1683971785636",
        "completionTs": 1684086850092,
        "items": {
          "chapter-1682668053225": {
            "modifiedTs": 1683971785664,
            "id": "chapter-1682668053225",
            "state": "COMPLETEMARKED"
          },
          "feedbackquestion-1683971634248": {
            "modifiedTs": 1683971798773,
            "id": "feedbackquestion-1683971634248",
            "state": "SUBMITTED",
            "response": {
              "value": "8"
            }
          },
          "feedbackquestion-1684086790541": {
            "modifiedTs": 1684086849767,
            "id": "feedbackquestion-1684086790541",
            "state": "SUBMITTED",
            "response": {
              "value": "7"
            }
          }
        },
        "state": "COMPLETED",
        "createdTs": 1683971785637,
        "modifiedTs": 1684086850891,
        "points": 0,
        "userId": "ffbe3630f0eb11ed8f378f6642783490",
        "orgId": "1682667593019",
        "contextType": "cp-5718868575-1683971621439",
        "comment": "Good  Course",
        "id": "1682667593019-ffbe3630f0eb11ed8f378f6642783490-cp-5718868575-1683971621439"
      }
    ]
  }
}