import { RoleHelper } from '../common/helpers/RoleHelper'
import { Controller } from '../common/Controller';
import { Helper } from '../common/helpers/Helper';

export class AppRoleHelper extends RoleHelper {

  getOrgId() {
    const domainName = localStorage.getItem('domainName')
    const selectedOrgId = this.getSelectedOrgId()
    if (selectedOrgId) {
      return selectedOrgId
    }
    // Nothing Selected return default orgId
    const orgs = this.getOrgs()
    if (this.isProductSU() || this.isProductStaff()) {
      return 'PRODUCT'
    } else if (orgs.length && domainName) {
      const orgData = orgs.filter(i => i.domain === domainName)
      if (orgData.length > 0) {
        return orgData[0].orgId
      } else {
        return null
      }
    }
    return null
  }

  isOrgManagerUser() {
    return this.checkOrgAndRoleMatch(this.getOrgId(), 'MANAGER')
  }

  isOrgCreatorUser() {
    return this.checkOrgAndRoleMatch(this.getOrgId(), 'CREATOR')
  }

  isProductStaff() {
    const appUser = Controller.get().userMgr().getAppUser()
    if (appUser) {
      for (let i = 0; i < appUser.orgs.length; i++) {
        const org = appUser.orgs[i]
        if (org.orgId === 'PRODUCT') {
          if (org.role === 'REGULAR') return true // for backward compatibility
          if (Helper.inArray(org.roles, 'REGULAR')) return true
        }
      }
    }
    return false;
  }

  isProductUser() {
    return Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isProductStaff()
  }

  getLearnerHome = () => {
    return'/learner/orgs/' + this.getOrgId() + '/dashboard'
  }

  getHome() {
    if (this.isProductSU()) {
      return '/admin'
    }
    if (window.innerWidth < 768) {
      return '/learner/orgs/' + this.getOrgId() + '/dashboard'
    }
    if (this.isProductStaff()) {
      return '/admin/courses'
    }
    if (this.isOrgSU() || this.isOrgManagerUser()) {
      return '/admin/orgs/' + this.getOrgId()
    }
    if (this.isOrgCreatorUser()) {
      return '/admin/orgs/' + this.getOrgId() + '/courses'
    }
    if (this.isPartnerSU()) {
      return '/admin/partners/' + this.getOrgId()
    }
    return '/learner/orgs/' + this.getOrgId() + '/dashboard' //TODO code to be checked default '/' value
  }

  isAccessAllowed(options) {
    if (this.isProductSU()) {
      return true
    }
    if (this.isOrgSU() && (options.path.startsWith('/admin/orgs/' + this.getOrgId()))) {
      return true
    }
    if (this.isPartnerSU() && (
      options.path === ('/admin/partners/' + this.getOrgId())
    )) {
      return true
    }
    return false
  }

  isOrgCREATOR() {
    return this.checkOrgAndRoleMatch(this.getOrgId(), 'CREATOR')
  }

  getSubOrgIds() {
    const orgId = this.getOrgId()
    const orgs = this.getOrgs()
    const subOrgIds = []
    for(const org of orgs) {
      if((org.orgId).startsWith(orgId + '-')) {
        if(org.roles.indexOf('MANAGER') > -1) {
          subOrgIds.push(org.orgId)
        }
      }
    }
    if(subOrgIds.length > 0) {
      return subOrgIds
    } else {
      return null
    }
  }

  isAllVisibleToBeAdded (orgId) {
    const roles = Controller.get().userMgr().getOrgRoles(orgId)
    if(roles.length === 1 && (roles[0] === 'EMPLOYEE' || roles[0] === 'EXTERNAL')) return true
    if(roles.length === 0) return true
    return false
  }

  disableEditAccess = (courseDetails, peoplePage) => {
    // if (Controller.get().userMgr().hasOrgRole(this.getOrgId(), 'DASHBOARD')) {
    //   if (!Controller.get().userMgr().hasOrgRole(this.getOrgId(), 'CREATOR')) {
    //     return true
    //   } else {
    //     const userId = Controller.get().userMgr().getUserId()
    //     if (peoplePage) {
    //       if (Helper.inArray(courseDetails.ownerIds, userId)) {
    //         return false
    //       } else {
    //         return true
    //       }
    //     } else {
    //       if (Helper.inArray(courseDetails.authorIds, userId) || Helper.inArray(courseDetails.ownerIds, userId)) {
    //         return false
    //       } else {
    //         return true
    //       }
    //     }
    //   }
    // } else {
    //   return false
    // }
    return false
  }


}
