import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helper } from '../helpers/Helper';
import { AppTheme } from '../../styles/AppTheme';
import { AppStyles } from '../../styles/AppTheme';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { AppConfig } from '../../AppConfig';
import { Player } from '@lottiefiles/react-lottie-player';
import { AppCommonTheme } from '../../styles/Styles';
import { Controller } from '../Controller';

const ERROR_MSG = Helper.getString('defaultApiErrMsg')

export class UiHelper {

  static itemIdContainer(current) {
    return (
      <div>
        <span className={css(Styles.spanValue)}>{current.slug}</span>
        <br />
        <span className={css(Styles.spanValue)}>{current.id}</span>
      </div>
    )
  }

  static itemTitleImage(current) {
    return (
      <div className={css(Styles.spanContainer)}>
        <div className={css(Styles.spanImgContainer)}>
          {current.image ?
            <img src={current.image} className={css(Styles.imageContent)} />
            :
            <i className='material-icons-outlined' style={{ fontSize: 40, color: 'lightgray' }}>image</i>
          }
        </div>
        <div>
          <span className={css(Styles.itemValue)}>{current.title}</span>
        </div>
      </div>
    )
  }

  static itemStatusContainer(current) {
    return (
      <div className={css(Styles.statusOuterContainer)}>
        <p className={current.state === 'LIVE' ? css(Styles.statusTitle) : css(Styles.statusTitle, Styles.statusTitleStyle)}>{current.state || ''.toLowerCase()}</p>
        <div>
          {current.likeCount !== undefined &&
            <div className={css(Styles.countContainer)}>
              <div className={css(Styles.imageContainer)}>
                <i className='material-icons-outlined' style={{ fontSize: 20, color: AppTheme.primaryColor }}>thumb_up</i>
              </div>
              <p className={css(Styles.statusValue)}>{current.likeCount || 0}</p>
            </div>
          }
          {current.buyCount !== undefined &&
            <div className={css(Styles.countContainer, Styles.countSecondContainer)}>
              <div className={css(Styles.imageContainer)}>
                <i className='material-icons-outlined' style={{ fontSize: 20, color: AppTheme.primaryColor }}>shopping_basket</i>

              </div>
              <p className={css(Styles.statusValue)}>{current.buyCount || 0}</p>
            </div>
          }
        </div>
      </div>
    )
  }

  static itemDetails(current) {
    return (
      <div className={css(Styles.spanContainer)}>
        <div className={css(Styles.spanImgContainer)}>
          {(current.variantItems && current.variantItems[0].image || current.image) ?
            <img src={current.image || current.variantItems[0].image} className={css(Styles.imageContent)} />
            :
            <i className='material-icons-outlined' style={{ fontSize: 40, color: 'lightgray' }}>image</i>
          }
        </div>
        <div>
          <span className={css(Styles.itemValue)}>{current.slug}</span>
          <br />
          <span className={css(Styles.itemValue)}>{current.id}</span>
        </div>
      </div>
    )
  }

  static chipLive(label) {
    if (label === 'LIVE') return UiHelper.chipPos(label)
    else return ''
  }

  static chipFeatured(label) {
    if (label === 'FEATURED') return UiHelper.chipPos(label)
    else return ''
  }

  static chipHome(label) {
    if (label === 'HOME') return UiHelper.chipPos(label)
    else return ''
  }

  static chip(label) {
    if (!label) return ''
    return (
      <Chip size='small' label={label} />
    )
  }

  static chipPos(label) {
    if (!label) return ''
    return (
      <Chip size='small' label={label} style={{ backgroundColor: '#77DD77', color: 'black' }} />
    )
  }

  static loadingView() {
    return (
      <div className={css(Styles.container)}>
        <CircularProgress size={48} className={css(Styles.progress)} />
      </div>
    )
  }

  static errorView(caller) {
    if (AppConfig.CUSTOM_STYLE) {
      return UiHelper.customErrorView(caller)
    } else {
      return (
        <div className='pageError'>
          {caller.state.errMsg || ERROR_MSG}
        </div>
      )
    }
  }


  static customErrorView(caller) {
    return (
      <div className='pageError'>
        <div className={css(Styles.customEmptyContainer)}>
          <div className={css(Styles.customImageContainer)}>
            <img src={require('../../assets/images/error.svg')} className={css(Styles.image)} />
          </div>
          <p className={css(Styles.customText)}>{caller.state.errMsg || Helper.getString('defaultApiErrMsg')}</p>
        </div>
      </div>
    )
  }

  static staticErrorView(caller) {
    return (
      <div className={css(Styles.errorContainer)}>
        <div className={css(Styles.errImageContainer)}>
          <img src={require('../../assets/images/404.svg')} className={css(Styles.image)} />
        </div>
        <p className={css(Styles.nfMiddle)}>{Helper.getString('serviceErr')}</p>
      </div>
    )
  }

  static componentLoadingView() {
    if (AppConfig.CUSTOM_STYLE) {
      return UiHelper.customLoadingView()
    } else {
      return (
        <div className={css(Styles.container)}>
          <CircularProgress size={48} className={css(Styles.progress)} />
        </div>
      )
    }
  }

  static customLoadingView() {
    return (
      <div className={css(Styles.container2)}>
        <Player
          autoplay
          loop
          src={require('../../assets/images/circle_loading_progress_indicator.json')}
          className={css(Styles.loadingContainer)} />
        {navigator && navigator.userAgent.startsWith('Azsincx') ? '' :
          <p className={css(Styles.loadingText)}>{Helper.getString('loading')}</p>
        }
      </div>
    )
  }


  static emptyView(msg) {
    if (AppConfig.CUSTOM_STYLE) {
      return UiHelper.customEmptyView(msg)
    } else {
      return (
        <div className={css(Styles.emptyContainer)}>
          <p>{msg || Helper.getString('emptyMsg')}</p>
        </div>
      )
    }
  }

  static customEmptyView(msg) {
    return (
      <div className={css(Styles.customEmptyContainer)}>
        <div className={css(Styles.customImageContainer)}>
          <img src={require('../../assets/images/empty.svg')} className={css(Styles.image)} />
        </div>
        <p className={css(Styles.customText)}>{msg || Helper.getString('emptyMsg')}</p>
      </div>
    )
  }

  static componentErrorView(caller) {
    return (
      <div className={css(Styles.container)}>
        <p className={css(Styles.error)}>{caller.state.errMsg || ERROR_MSG}</p>
      </div>
    )
  }

  static buttonEdit(caller, current) {
    return (
      <Tooltip title='Edit' placement='top-start' key={current.id + 'editTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => caller.openModalForEdit(current)}>edit</i>
        </span>
      </Tooltip>
    )
  }

  static customMenuButton(item) {
    return (
      item.link ?
        <Link to={{
          pathname: item.link,
          state: item.state && item.state
        }}
        // to={item.link}
        key={item.key && item.key}
        className={css(Styles.customLink)} >
          <div className={css(Styles.customMenuItem)}>
            {item.title &&
              <p className={css(Styles.customMenuText, item.style)}>{item.title}</p>
            }
            {item.icon &&
              <i className='material-icons-outlined' style={{ color: '#4B8BE9', fontSize: 20 }}>{item.icon}</i>
            }
            {item.img &&
              <img src={item.img} className={css(Styles.image)} />
            }
          </div>
        </Link>
        :
        <div className={css(Styles.customMenuItem)} onClick={item.action && item.action} key={item.key && item.key}>
          {item.title &&
            <p className={css(Styles.customMenuText, item.style)}>{item.title}</p>
          }
          {item.img &&
            <img src={item.img} className={css(Styles.image)} />
          }
          {item.icon &&
            <i className='material-icons-outlined' style={{ color: '#4B8BE9', fontSize: 20 }}>{item.icon}</i>
          }
        </div>
    )
  }

  static buttonDelete(caller, current) {
    return (
      <Tooltip title='Delete' placement='top-start' key={current.id + 'deleteTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => caller.openModalForDelete(current)}>delete</i>
        </span>
      </Tooltip>
    )
  }

  static buttonOpenItemsInCat(caller, current) {
    return (
      <Link to={caller.baseRoute() + '/cats/' + current.id + '/courses'} key={current.id + '-courses'} >
        <Tooltip title={Helper.getString('courses')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>layers</i>
          </span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenItemsInCCat(caller, current) {
    return (
      <Link to={caller.baseRoute() + '/cats/' + current.id + '/courses'} key={current.id + '-courses'} >
        <Tooltip title={Helper.getString('courses')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>layers</i>
          </span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenBlogsInTag(caller, current) {
    return (
      <Link to={caller.baseRoute() + '/btags/' + current.id + '/blogs'} key={current.id + '-blogs'}>
        <Tooltip title={Helper.getString('blogs')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>layers</i>
          </span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenBlog(caller, current) {
    return (
      <Link to={this.baseRoute(current.orgId) + '/blogs/' + current.id} key={current.id}>
        <Tooltip title='Edit details' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}><i className='material-icons-outlined'>link</i></span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenBlogContent(caller, current) {
    return (
      <Link to={this.baseRoute(current.orgId) + '/blogs/content/' + current.id} key={current.id}>
        <Tooltip title='Edit content' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}><i className='material-icons-outlined'>text_format</i></span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenProduct(caller, current) {
    return (
      <Link to={this.baseRoute(current.orgId) + '/products/' + current.id} key={current.id} >
        <Tooltip title='Open' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}><i className='material-icons-outlined'>link</i></span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenCourse(caller, current) {
    return (
      <Link to={this.baseRoute(current.orgId) + '/courses/' + current.courseId} key={current.courseId} >
        <Tooltip title='Open' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}><i className='material-icons-outlined'>link</i></span>
        </Tooltip>
      </Link>
    )
  }

  static buttonEditMenuItem(caller, current) {
    return (
      <MenuItem className={css(AppStyles.menuItem)}>
        <div key={current.id + 'editTT'} onClick={() => caller.openModalForEdit(current)} className={css(AppStyles.menuContainer)}>
          <span className={css(AppStyles.entityTableLinkIcon, AppStyles.entityTableLinkIconMenu)}>
            <i className='material-icons-outlined'>edit</i>
          </span>
          <p className={css(AppStyles.titleContainer)}>Edit</p>
        </div>
      </MenuItem>
    )
  }

  static buttonDeleteMenuItem(caller, current) {
    return (
      <MenuItem className={css(AppStyles.menuItem)}>
        <div key={current.id + 'deleteTT'} onClick={() => caller.openModalForDelete(current)} className={css(AppStyles.menuContainer)}>
          <span className={css(AppStyles.entityTableLinkIcon, AppStyles.entityTableLinkIconMenu)}>
            <i className='material-icons-outlined'>delete</i>
          </span>
          <p className={css(AppStyles.titleContainer)}>Delete</p>
        </div>
      </MenuItem>
    )
  }

  static populateOptions(formDefinition, apiResult, key) {
    const options = []
    for (const d of apiResult.items) {
      options.push({
        key: d.id || d.userId,
        text: d.title || Helper.formatName(d)
      })
    }
    for (const field of formDefinition.steps[0].fields) {
      if (field.key === key) {
        field.options = options
      }
    }
  }

  static baseRoute(orgId) {
    return orgId !== 'PRODUCT' ? '/admin/orgs/' + orgId : '/admin'
  }

  static fieldDefResolve(options) {
    console.log('fieldDefResolve:', options)
    const { def, values, key } = options
    let resolved = values[key]
    if (!def) return resolved
    def.steps[0].fields.forEach(field => {
      if (field.key !== key) return
      if (!field.options) return
      const optionsByKey = Helper.itemsToMap(field.options, 'key')
      const value = values[key]
      if (value) {
        if (field.type === 'radio') {
          const option = optionsByKey[value]
          if (option) {
            resolved = option.label || option.text
            return
          }
        } else if (field.type === 'check_box') {
          const resolves = []
          value.forEach(v => {
            const option = optionsByKey[v]
            if (option) resolves.push(option.label || option.text)
          })
          if (resolves.length > 0) {
            resolved = resolves.join(', ')
            return
          }
        }
      }
    })
    return resolved
  }

  static renderImg = (options) => {
    let imgUrl = ''
    let icon = ''
    switch (options.key) {
    case 'file':
      try {
        imgUrl = require('../../assets/images/document-img.svg')
      } catch (err) {
        imgUrl = null
        icon = 'file_open'
      }
      break
    case 'drawerMenu':
      if (options.drawerIsOpen) {
        try {
          imgUrl = require('../../assets/images/drawer-button-close.svg')
        } catch (err) {
          imgUrl = null
          icon = 'menu_open'
        }
      } else {
        try {
          imgUrl = require('../../assets/images/drawer-button-open.svg')
        } catch (err) {
          imgUrl = null
          icon = 'menu'
        }
      }
      break
    case 'drawerClose':
      try {
        imgUrl = require('../../assets/images/drawer-button-close.svg')
      } catch (err) {
        imgUrl = null
        icon = 'menu_open'
      }
      break
    case 'drawerOpen':
      try {
        imgUrl = require('../../assets/images/drawer-button-open.svg')
      } catch (err) {
        imgUrl = null
        icon = 'menu'
      }
      break
    case 'docUpload':
      try {
        imgUrl = require('../../assets/images/img-uploader.svg')
      } catch (err) {
        imgUrl = null
        icon = 'file_upload'
      }
      break
    case 'imgUpload':
      try {
        imgUrl = require('../../assets/images/export.svg')
      } catch (err) {
        imgUrl = null
        icon = 'file_upload'
      }
      break
    default:
      return ''
    }
    if (imgUrl) {
      return <img src={imgUrl} className={css(Styles.imageContent)} />
    } else {
      return <i className='material-icons-outlined' style={{ fontSize: options.size || 24, color: AppTheme.primaryColor }}>{icon}</i>
    }
  }

  static isOrgClient = (pathname) => {
    return pathname.includes('orgs')
  }

  static courseActionList = (caller, current, pageKey, orgId, courseId, slug) => {
    const actions = []
    const courseIdValue = current.courseId ? current.courseId : courseId
    const orgIdValue = current.orgId ? current.orgId : orgId
    const slugValue =  current.slug ? current.slug : slug
    const ADOPTList = [
      {
        pageKey: 'Section',
        key: courseIdValue + '-sections',
        link: caller.baseRoute() + '/courses/' + courseIdValue + '/sections',
        title: 'Sections',
        order: '002'
      },
      {
        pageKey: 'Questions',
        key: courseIdValue + '-assessment',
        link: caller.baseRoute() + '/courses/' + courseIdValue + '/assessment',
        title: 'Assessment',
        order: '003'
      },
      {
        pageKey: 'CourseResources',
        key: courseIdValue + '-resources',
        link: caller.baseRoute() + '/courses/' + courseIdValue + '/resources',
        title: 'Resources',
        order: '004'
      },
      {
        pageKey: 'CourseFaqs',
        key: courseIdValue + '-faqs',
        link: caller.baseRoute() + '/courses/' + courseIdValue + '/faqs',
        title: 'FAQ',
        order: '005'
      }
    ]
    actions.push({
      pageKey: 'Details',
      key: courseIdValue + '-details',
      link: caller.baseRoute() + '/orgcourses/' + orgIdValue + '-' + courseIdValue + '/details',
      title: 'Details',
      order: '001'
    })
    actions.push({
      pageKey: 'Preview',
      key: courseIdValue + '-preview',
      link: caller.baseRoute() + '/courses/preview/' + slugValue,
      title: 'Preview',
      order: '006'
    })
    actions.push({
      pageKey: 'People',
      key: courseIdValue + '-people',
      link: caller.baseRoute() + '/courses/' + orgIdValue + '-' + courseIdValue + '/people',
      title: 'Users',
      order: '007'
    })
    actions.push({
      pageKey: 'Feedbacks',
      key: courseIdValue + '-feedbacks',
      link: caller.baseRoute() + '/courses/' + orgIdValue + '-' + courseIdValue + '/feedbacks',
      title: 'Feedbacks',
      order: '008'
    })
    if (current.mode !== 'ADOPT' &&
    (Controller.get().roleHelper().isOrgCreatorUser() || Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU())) {
      actions.push(...ADOPTList)
    }

    const actionList = []
    Helper.sortAscending(actions).map(item => {
      actionList.push(
        <Link to={item.link} key={item.key} className={css(Styles.actionListLink)}>
          <div className={item.pageKey === pageKey ?
            css(Styles.listItemContainer, Styles.listItemSelectedContainer) : css(Styles.listItemContainer)}>
            <p className={item.pageKey === pageKey ?
              css(Styles.listItemContainerTitle, Styles.listItemSelectedContainerTitle) : css(Styles.listItemContainerTitle)}>{item.title}</p>
          </div>
        </Link>
      )
    })
    return actionList
  }

  static courseAdminActionList = (caller, current, pageKey, courseId, slug) => {
    const actions = []
    const courseIdValue = current.courseId ? current.courseId : courseId
    const slugValue =  current.slug ? current.slug : slug
    const ADOPTList = [
      {
        pageKey: 'Section',
        key: courseIdValue + '-sections',
        link: caller.baseRoute() + '/courses/' + courseIdValue + '/sections',
        title: 'Sections',
        order: '002'
      },
      {
        pageKey: 'Questions',
        key: courseIdValue + '-assessment',
        link: caller.baseRoute() + '/courses/' + courseIdValue + '/assessment',
        title: 'Assessment',
        order: '003'
      },
      {
        pageKey: 'CourseResources',
        key: courseIdValue + '-resources',
        link: caller.baseRoute() + '/courses/' + courseIdValue + '/resources',
        title: 'Resources',
        order: '004'
      },
      {
        pageKey: 'CourseFaqs',
        key: courseIdValue + '-faqs',
        link: caller.baseRoute() + '/courses/' + courseIdValue + '/faqs',
        title: 'FAQ',
        order: '005'
      }
    ]
    actions.push({
      pageKey: 'Details',
      key: courseIdValue + '-details',
      link: caller.baseRoute() + '/courses/' + courseIdValue + '/details',
      title: 'Details',
      order: '001'
    })
    actions.push({
      pageKey: 'Preview',
      key: courseIdValue + '-preview',
      link: caller.baseRoute() + '/courses/preview/' + slugValue,
      title: 'Preview',
      order: '006'
    })
    // actions.push({
    //   pageKey: 'Feedbacks',
    //   key: courseIdValue + '-feedbacks',
    //   link: caller.baseRoute() + '/courses/' + courseIdValue + '/feedbacks',
    //   title: 'Feedbacks',
    //   order: '008'
    // })
    if (current.mode !== 'ADOPT') {
      actions.push(...ADOPTList)
    }

    const actionList = []
    Helper.sortAscending(actions).map(item => {
      actionList.push(
        <Link to={item.link} key={item.key} className={css(Styles.actionListLink)}>
          <div className={item.pageKey === pageKey ?
            css(Styles.listItemContainer, Styles.listItemSelectedContainer) : css(Styles.listItemContainer)}>
            <p className={item.pageKey === pageKey ?
              css(Styles.listItemContainerTitle, Styles.listItemSelectedContainerTitle) : css(Styles.listItemContainerTitle)}>{item.title}</p>
          </div>
        </Link>
      )
    })
    return actionList
  }

}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    height: '100vh',
  },
  container2: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    flexDirection: 'column',
  },
  emptyContainer: {
    minHeight: '75vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 24,
    color: '#7D7D7D'
  },
  progress: {
    color: AppTheme.primaryColor
  },
  error: {
    textAlign: 'center',
    maxWidth: 500
  },
  errorContainer: {
    flex: 1,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: AppTheme.primaryBg,
    flexDirection: 'column'
  },
  errImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 120,
    height: 120,
    backgroundColor: 'white',
    padding: 20,
    borderRadius: '50%'
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    height: 20
  },
  nfMiddle: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  valueText: {
    fontSize: 16,
    color: '#000000'
  },
  spanContainer: {
    display: 'flex'
  },
  spanImgContainer: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12
  },
  imageContent: {
    height: '100%',
    width: '100%',
    borderRadius: 6,
    objectFit: 'cover'
  },
  itemValue: {
    fontSize: 16,
  },
  statusOuterContainer: {
    display: 'flex'
  },
  statusTitle: {
    color: '#00CA6F',
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    marginRight: 18,
    textTransform: 'capitalize',
    minWidth: 80
  },
  statusTitleStyle: {
    color: '#222222',
  },
  countContainer: {
    display: 'flex'
  },
  countSecondContainer: {
    marginTop: 8
  },
  imageContainer: {
    width: 20,
    height: 20
  },
  statusValue: {
    fontSize: 16,
    color: '#7D7D7D',
    margin: 0,
    marginLeft: 8
  },
  customEmptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  customImageContainer: {
    marginTop: 200,
    width: 250 / 2,
    height: 162 / 2
  },
  customText: {
    color: '#7D7D7D',
    fontSize: 26,
    margin: 0,
    marginTop: 24
  },
  loaderContainer: {
    position: 'relative'
  },
  loaderImageContainer: {
    position: 'absolute',
    width: 39,
    height: 41,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  },

  customMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: '15px 0px'
  },
  customLink: {
    textDecoration: 'none'
  },
  customMenuText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#121212',
    margin: 0,
    marginRight: 5
  },
  listItemContainer: {
    border: '1px solid #AAABAF',
    borderRadius: 6,
    padding: '10px 16px'
  },
  listItemSelectedContainer: {
    border: '1px solid #4B8BE9',
  },
  listItemContainerTitle: {
    fontSize: 18,
    fontWeight: 400,
    margin: 0,
    color: '#AAABAF',
    '@media (max-width: 1200px)': {
      fontSize: 15,
    }
  },
  listItemSelectedContainerTitle: {
    color: '#4B8BE9',
    fontWeight: 600,
  },
  actionListLink: {
    textDecoration: 'none',
    marginRight: 10,
    marginBottom: 10
  },

  loadingContainer: {
    height: 220,
    width: 220,
    '@media (max-width: 767px)': {
      height: 135,
      width: 135,
    }
  },
  loadingText: {
    fontSize: 48,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 5,
    '@media (max-width: 767px)': {
      fontSize: 28,
    }
  }
})
