import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme, CommonStyles } from '../styles/Styles';
import { Helper } from '../common/helpers/Helper';
import Grid from '@material-ui/core/Grid';

export function Information(props) {
  const nameDetails = {
    value: `${props.data.orgUserProfile.givenname} ${props.data.orgUserProfile.familyname}`,
    lable: 'givenname'
  }
  const detailContainerItems = [
    {
      value: `${props.data.title}`,
      lable: 'company'
    },
    {
      value: `${props.data.orgUserProfile.designation}`,
      lable: 'jobTitle'
    },
    {
      value: `${props.data.orgUserProfile.orgPhone}`,
      lable: 'phoneNumber'
    },
    {
      value: props.departments.replace(/,\s*$/, ''),
      lable: 'group'
    },
    {
      value: `${props.data.orgUserProfile.email}`,
      lable: 'email'
    },
    {
      value: (props.data.orgUserProfile.managerUserId && props.result.__resolves && props.result.__resolves.managerUsersById &&
        props.result.__resolves.managerUsersById[props.data.orgUserProfile.managerUserId] &&
        props.result.__resolves.managerUsersById[props.data.orgUserProfile.managerUserId].givenname &&
        props.result.__resolves.managerUsersById[props.data.orgUserProfile.managerUserId].familyname) &&
        `${props.result.__resolves.managerUsersById[props.data.orgUserProfile.managerUserId].givenname} ${props.result.__resolves.managerUsersById[props.data.orgUserProfile.managerUserId].familyname}`,
      lable: 'managerName'
    },
  ]

  const detailContainer = (item, index) => {
    console.log('item', item)
    const count = index + 1
    const lastItem = detailContainerItems.length === count ? true : false
    return (
      <div className={lastItem ? css(Styles.detailLastInnerContainer) : css(Styles.detailInnerContainer)}>
        {item.lable === 'company' ?
          <Fragment>
            <p className={css(Styles.titleContainer)}>{Helper.getString(item.lable)}</p>
            <div className={css(Styles.logoContainer)}>
              {props.data.logo && <img src={props.data.logo} className={css(Styles.imageContainer)}></img>}
              <p className={css(Styles.valueContainer)}>{item.value}</p>
            </div>
          </Fragment>
          :
          <Fragment>
            <p className={css(Styles.titleContainer)}>{Helper.getString(item.lable)}</p>
            <p className={css(Styles.valueContainer)}>{item.value}</p>
          </Fragment>
        }
      </div>
    )
  }

  return (
    <div className={css(CommonStyles.borderContainer, Styles.borderContainer)}>
      <div className={css(CommonStyles.titleOuterContainer)}>
        <p className={css(CommonStyles.titleContainer)}>{Helper.getString('basicInformation')}</p>
      </div>

      <div className={css(Styles.informationContainer)}>
        <div className={css(Styles.profileImgContainer)}>
          <img
            alt={Helper.getString('profileImgAlt')}
            width='280px' height='260px'
            src={props.data.orgUserProfile.picture ? props.data.orgUserProfile.picture : require('../assets/images/empty.svg')}
            className={css(Styles.imgContent)} />
        </div>
        <div className={css(Styles.detailContainer)} >
          <div className={css(Styles.detailDesktopContainer)}>

            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {detailContainer(nameDetails)}
              </Grid>
              {detailContainerItems.map((item, index) => {
                return (
                  (item.value && item.value !== 'undefined') &&
                  <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                    {detailContainer(item, index)}
                  </Grid>
                )
              })
              }
            </Grid>
          </div>
        </div>
      </div>

      <div className={css(Styles.detailMobileContainer)}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {detailContainer(nameDetails)}
          </Grid>
          {detailContainerItems.map((item, index) => {
            return (
              (item.value && item.value !== 'undefined') &&
              <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                {detailContainer(item, index)}
              </Grid>
            )
          })
          }
        </Grid>
      </div>

    </div >
  )
}

const Styles = StyleSheet.create({
  borderContainer: {
    flex: 1,
  },
  informationContainer: {
    display: 'flex',
    flex: 1,
  },
  profileImgContainer: {
    width: 280,
    height: 260,
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      width: 120,
      height: 120,
    }
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: AppCommonTheme.borderRadius
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 24,
    flex: 1,
    '@media(max-width: 767px)': {
      marginLeft: 12
    }
  },
  detailDesktopContainer: {
    '@media(max-width: 767px)': {
      display: 'none'
    }
  },
  detailMobileContainer: {
    display: 'none',
    '@media(max-width: 767px)': {
      display: 'block',
      marginTop: 18
    }
  },
  detailInnerContainer: {
    flex: 1,
    paddingBottom: 25,
    display: 'grid',
    '@media(max-width: 767px)': {
      paddingBottom: 15
    }
  },
  detailLastInnerContainer: {
    flex: 1,
    display: 'grid',
  },
  titleContainer: {
    fontSize: 12,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 5
  },
  valueContainer: {
    fontSize: 18,
    fontWeight: 600,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 5,
    marginRight: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
    overflow: 'hidden'
  },
  imageContainer: {
    height: 50,
    width: 50,
    marginRight: 10,
    marginTop: 5,
    objectFit: 'contain'
  },
  logoContainer: {
    display: 'flex'
  }
})