import React, { useEffect, useState, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { ResourcesList } from '../components/Resources/ResourcesList';
import { Courses } from '../components/Courses/Courses';
import { AppContext } from '../common/components/contexts/AppContext';
import { FavContext } from '../common/components/contexts/FavContext';
import { EmptyView } from '../components/EmptyView';
import { Player } from '@lottiefiles/react-lottie-player';
import { AppCommonTheme } from '../styles/Styles';

export function Favourites(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {},
  })
  const { fav, deleteResourcesFromFav } = useContext(FavContext)
  const orgId = Controller.get().roleHelper().getOrgId()

  async function fetchData() {
    try {
      setState({
        ...state,
        result: fav,
        fetchState: fav && ApiHelper.State.READY,
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: '' })
    fetchData()
  }, [fav])

  const backButton = {
    title: Helper.getString('back'),
    link: '/learner/orgs/' + orgId + '/dashboard'
  }

  return (
    <ScreenWrapper
      state={state}
      title={Helper.getString('favourites')}
      logo={true}
      backButton={backButton}
    // filter={true} //TODO filter
    >
      {fav.fetchState === ApiHelper.State.READY && state.fetchState === ApiHelper.State.READY ?
        <Fragment>
          {state.result && state.result.favCourseData && state.result.favCourseData.items && state.result.favCourseData.items.length > 0 &&
            <Courses
              showTitle={true}
              title={Helper.getString('courses')}
              borderContainer={[CommonStyles.borderContainer]}
              data={state.result.favCourseData}
              backButton={{ favourites: true }} />
          }

          {state.result && state.result.resourceData && state.result.resourceData.items && state.result.resourceData.items.length > 0 &&
            <ResourcesList
              showTitle={true}
              borderContainer={[CommonStyles.borderContainer, CommonStyles.courseContainer]}
              data={state.result.resourceData.items}
              favourites={true}
              backButton={{ favourites: true }} />
          }

          {(state.result && state.result.resourceData && state.result.resourceData.items && state.result.resourceData.items.length === 0 &&
            state.result.favCourseData && state.result.favCourseData.items && state.result.favCourseData.items.length === 0) &&
            <EmptyView
              title={Helper.getString('noItemsToShow')} />
          }
        </Fragment>
        :
        <div className={css(Styles.container)}>
          <Player
            autoplay
            loop
            src={require('../assets/images/circle_loading_progress_indicator.json')}
            className={css(Styles.loadingContainer)} />
          {navigator && navigator.userAgent.startsWith('Azsincx') ? '' :
            <p className={css(Styles.loadingText)}>{Helper.getString('loading')}</p>
          }
        </div>
      }
    </ScreenWrapper>
  )

}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  loadingContainer: {
    height: 220,
    width: 220,
    '@media (max-width: 767px)': {
      height: 135,
      width: 135,
    }
  },
  loadingText: {
    fontSize: 48,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 5,
    '@media (max-width: 767px)': {
      fontSize: 28,
    }
  }
})