import React, { Fragment, Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { UsersOfOrg } from '../common/components/UsersOfOrg';
import { Controller } from '../common/Controller';

export class UsersOfDepartment extends Component {
  constructor(props) {
    super(props)
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.teamId = this.props.match.params.id
  }

  render() {
    return (
      <UsersOfOrg department={true} orgId={this.orgId} teamId={this.teamId} />
    )
  }
}

const Styles = StyleSheet.create({
})