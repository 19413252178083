import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { CommonStyles } from '../../../styles/Styles';

export class CatsL1 extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('categories')
    this.pageKey = 'category'
    this.addNewLabel = 'Add'
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.uploadLocation = this.orgId
    this.parentId = 'ROOT-' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      {
        method: 'GET', paths: ['ccats'],
        queryParams: { orgId: this.orgId, parentId: this.parentId, pb: 'fieldDef', limit: 200 }
      },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      'Slug / ID': (current) => {
        return UiHelper.itemDetails(current)
      },
      Title: (current) => current.title,
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId,
      parentId: this.parentId
    }
    this.noAdd = (Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) ? false :  true
  }

  onFetchSuccess(results) {
    this.fieldDef = results[0].fieldDef
    this.formDefinition = results[0].fieldDef
    const fields = []
    for (const field of this.formDefinition.steps[0].fields) {
      if (field.key !== 'parentId') {
        fields.push(field)
      }
    }
    this.formDefinition.steps[0].fields = fields
    this.handleNextpage(results[0])
    if(!(Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) && results[0] && results[0]) {
      results[0].items = results[0].items.filter((item) => item.state === 'LIVE')
    }
    this.setState({
      items: Helper.sortDictArray(this.state.items.concat(results[0].items), 'title')
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['ccats']),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['ccats', current.id]),
    });
  }

  onEdit(current) {
    this.formDefinition.steps[0].fields.find(i => i.key === 'slug').disabled = true
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['ccats', current.id]),
    });
  }

  openModalForAdd() {
    const fields = []
    this.fieldDef.steps[0].fields.forEach(i => { if (i.key !== 'slug') fields.push(i) })
    this.formDefinition = { steps: [{ fields }] }
    super.openModalForAdd(this.formDefinition)
  }

  openModalForEdit(current) {
    this.formDefinition = this.fieldDef
    super.openModalForEdit(current)
  }

  onActions = (current) => {
    const actions = []
    // actions.push(UiHelper.buttonOpenItemsInCCat(this, current))
    // actions.push(
    //   <Link to={this.baseRoute() + '/cats/' + current.id} key={current.id + '-cats'} >
    //     <Tooltip title='Drill down' placement='top-start'>
    //       <span className={css(AppStyles.entityTableLinkIcon)}>
    //         <i className='material-icons-outlined'>subdirectory_arrow_right</i>
    //       </span>
    //     </Tooltip>
    //   </Link>
    // )
    return actions
  }

  onMenuItems(current) {
    const actions = []
    let menuItems = []
    if(Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) {
      menuItems = [
        {
          current: current,
          key: current.id + 'editTT',
          action: () => this.openModalForEdit(current),
          title: Helper.getString('edit'),
          img: require('../../../assets/images/edit-icon.svg')
        },
        {
          current: current,
          key: current.id + '-courses',
          link: this.baseRoute() + '/cats/' + current.catId + '/courses',
          title: 'Courses',
          icon: 'task_alt',
          state: current,
        },
        {
          current: current,
          key: current.id + 'deleteTT',
          action: () => this.openModalForDelete(current),
          title: Helper.getString('delete'),
          img: require('../../../assets/images/trash-icon.svg'),
          style: CommonStyles.deleteText
        }
      ]
    } else if(Controller.get().roleHelper().isOrgManagerUser() || Controller.get().roleHelper().isOrgCreatorUser()) {
      menuItems = [
        {
          current: current,
          key: current.id + '-courses',
          link: this.baseRoute() + '/cats/' + current.catId + '/courses',
          title: 'Courses',
          icon: 'task_alt',
          state: current,
        },
      ]
    }
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions

  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})