import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme, CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { TitleComponent } from '../components/TitleComponent';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AppTheme } from '../styles/AppTheme';
import { Courses } from '../components/Courses/Courses';
import { ResourcesList } from '../components/Resources/ResourcesList';
import { Faqs } from '../components/Faqs';
import { Text } from '../components/Text';
import { CourseContent } from '../components/CourseContent';
import { AppButton } from '../components/AppButton';
import { AppContext } from '../common/components/contexts/AppContext';
import { CourseTileButton } from '../components/Courses/CourseTileButton';
import { FavContext } from '../common/components/contexts/FavContext';
import { ErrorMsg } from '../components/ErrorMsg';

export function Course(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const cssBreakpoint = useMediaQuery('(max-width:1025px)');
  const [state, setState] = useState({
    result: {},
    selectedDetailTab: 'descr',
    favIcon: false,
    selectedFav: '',
    inProgress: false
  })
  const orgId = props.orgId ? props.orgId : (Controller.get().roleHelper().isProductUser() ? (props.match.params.orgId ? props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
  const courseSlug = props.courseSlug ? props.courseSlug : props.match.params.slug
  const { fav, addCourseToFav, deleteCourseFromFav } = useContext(FavContext)

  const courseDetailsTab = [
    {
      key: 'descr',
      title: 'Description',
      desktop: true,
    },
    {
      key: 'content',
      title: 'Content',
      desktop: false,
    },
    {
      key: 'eligibility',
      title: 'Prerequisite',
      desktop: true,
    },
    {
      key: 'resources',
      title: 'Resources',
      desktop: true,
    },
    {
      key: 'faqs',
      title: 'FAQ',
      desktop: true,
    }
  ]

  async function fetchData(disableReload) {
    try {
      if (!disableReload) {
        setState({ fetchState: ApiHelper.State.LOADING })
      }
      const response = await Controller.get().courseMgr().getCourseDetails2({ orgId, courseSlug })
      // if (Controller.get().userMgr().isProductSU() && orgId === 'PRODUCT') {
      //   response = await Controller.get().courseMgr().getCourseDetails2({ orgId, courseSlug })
      // } else {
      //   response = await Controller.get().courseMgr().getCourseDetails2({ orgId, courseSlug })
      // }
      console.log('response', response)
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response,
        inProcess: false,
        errMsg: response.invalidCourseConfiguration
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    fetchData()
  }, [courseSlug])

  const selectedTabContent = () => {
    if (state.selectedDetailTab === 'descr') {
      return <Text descr={state.result.descr} />
    } else if (state.selectedDetailTab === 'eligibility') {
      return (
        state.result.eligibility && state.result.eligibility.items.length > 0 &&
        <Fragment>
          <p className={css(Styles.eligibilityTitle)}>{Helper.getString('eligibilityTitle')}</p>
          <Courses showTitle={false} data={state.result.eligibility} valueforGrid={4} backButton={{ course: true, slug: courseSlug }} />
        </Fragment>
      )
    } else if (state.selectedDetailTab === 'content') {
      return state.result.content && <CourseContent data={state.result} courseSlug={courseSlug} />
    } else if (state.selectedDetailTab === 'resources') {
      return (state.result.resources && !props.preview) && <ResourcesList showTitle={false} data={state.result.resources} courseSlug={courseSlug} backButton={{ courseSlug: courseSlug }} />
    } else if (state.selectedDetailTab === 'faqs') {
      return state.result.faqs && <Faqs data={state.result.faqs} />
    }
  }

  const selectTab = (key) => {
    setState({
      ...state,
      selectedDetailTab: key,
    })
  }

  useEffect(() => {
    isPresentInFavList()
  }, [state.result, fav.favCourseData])

  const isPresentInFavList = () => {
    if (state.result && state.result.id) {
      if (fav && fav.favCourseData && fav.favCourseData.items) {
        for (const f of fav.favCourseData.items) {
          if (f.courseId === state.result.courseId) {
            setState({
              ...state,
              favIcon: true,
              selectedFav: f,
              favLearnerId: fav.favCourseData.favLearnerId
            })
            return
          }
        }
      }
    }
  }

  const toggleFav = async () => {
    setState({
      ...state,
      inProgress: true,
    })
    if (state.favIcon) {
      const id = state.selectedFav.orgId + '-' + state.favLearnerId + '-favcourse-' + state.result.courseId
      const deleteFav = await deleteCourseFromFav({ courseId: state.selectedFav.courseId, id: id })
      if (deleteFav) {
        setState({
          ...state,
          inProgress: false,
          favIcon: false,
        })
      }
    } else {
      const addFav = await state.result && state.result.courseId && addCourseToFav({ courseId: state.result.courseId })
      if (addFav) {
        setState({
          ...state,
          inProgress: false,
          favIcon: true
        })
      }
    }
  }

  const titleIcons = [
    {
      key: 'fav',
      imgName: state.favIcon ? 'heart_selected_icon' : 'heart',
      onSelected: () => toggleFav()
    }
  ]

  const courseButton = async () => {
    setState({
      ...state,
      inProcess: true,
    })
    if (props.preview) {
      Controller.get().courseMgr().navToChapter(courseSlug, state.result.content[0].chapters[0].id, props.preview, orgId)
    } else if (!state.result.assignedCourseStatus) {
      try {
        const response = await Controller.get().courseMgr().selfenroll(orgId, state.result.courseId)
        if (response) {
          fetchData(true)
          return
        }
      } catch (e) {
        setState({ ...state, openError: true, errorMsg: Helper.getErrorMsg(e), inProcess: false })
        return
      }
    } else if (state.result.newStartCourse) {
      if (!state.result.eligibilityCourse) {
        setState({ ...state, openError: true, errorMsg: Helper.getString('eligibileCourseComplete'), inProcess: false })
        return
      }
      return Controller.get().courseMgr().navToChapter(courseSlug, state.result.chapterId, props.preview, orgId)
    }
    else if (state.result.completedCourseStatus) {
      if (state.result.showAssessment && (!state.result.assessmentCompleted || !state.result.coursePassed)) {
        Controller.get().courseMgr().navToAssessment(courseSlug)
      } else if (state.result.showConsent && !state.result.completedConsents) {
        Controller.get().courseMgr().navToConsent(courseSlug)
      } else if (state.result.showFeedback && !state.result.completedFeedback) {
        Controller.get().courseMgr().navToFeedback(courseSlug)
      } else {
        return Controller.get().courseMgr().navToChapter(courseSlug, state.result.chapterId, props.preview, orgId, { review: true })
      }
    } else {
      return Controller.get().courseMgr().navToChapter(courseSlug, state.result.chapterId, props.preview, orgId)
    }
  }

  const courseButtonTitle = () => {
    if (props.preview) {
      return Helper.getString('preview')
    } else if (!state.result.assignedCourseStatus) {
      return Helper.getString('enroll')
    } else if (state.result.newStartCourse) {
      return Helper.getString('startCourse')
    } else if (state.result.completedCourseStatus && state.result.isCourseCompleted) {
      return Helper.getString('review')
    } else {
      return Helper.getString('resume')
    }
  }

  const buttonContainer = () => {
    if (state.result.content && state.result.content.length === 0) {
      return null
    }
    if (props.preview) {
      return <AppButton
        onClick={() => courseButton()}
        buttonContainer={Styles.buttonContainer}
        buttonText={courseButtonTitle()}
        img={'arrow_right_icon'}
        imgAlt={Helper.getString('arrowRightImgAlt')}
      />
    }
    if (!state.result.assignedCourseStatus) {
      if (state.result.discoveryMode === 'OPEN') {
        return <AppButton
          onClick={() => courseButton()}
          buttonContainer={Styles.buttonContainer}
          buttonText={courseButtonTitle()}
          img={'arrow_right_icon'}
          imgAlt={Helper.getString('arrowRightImgAlt')}
          inProcess={state.inProcess}
        />
      } else {
        return Helper.getString('courseNotAssigned')
      }
    }
    return <AppButton
      onClick={() => courseButton()}
      buttonContainer={Styles.buttonContainer}
      buttonText={courseButtonTitle()}
      img={'arrow_right_icon'}
      imgAlt={Helper.getString('arrowRightImgAlt')}
      inProcess={state.inProcess}
    />
  }

  const backNav = () => {
    const backNav = props.location && props.location.state && props.location.state
    if (orgId === 'PRODUCT' && props.preview && state.result && state.result.courseId) {
      return '/admin/courses/' + state.result.courseId + '/details'
    } else if (orgId !== 'PRODUCT' && props.preview && backNav && backNav.catalog && state.result && state.result.courseId) {
      return '/admin/orgs/' + orgId + '/catalog'
    } else if (orgId !== 'PRODUCT' && props.preview && state.result && state.result.courseId) {
      return '/admin/orgs/' + orgId + '/orgcourses/' + orgId + '-' + state.result.courseId + '/details'
    } else if (backNav && backNav.category) {
      return '/learner/orgs/' + orgId + '/categories/' + backNav.slug
    } else if (backNav && backNav.course) {
      return '/learner/orgs/' + orgId + '/course/' + backNav.slug
    } else if (backNav && backNav.explore) {
      return '/learner/orgs/' + orgId + '/explore'
    } else if (backNav && backNav.favourites) {
      return '/learner/orgs/' + orgId + '/favourites'
    } else if (backNav && backNav.search) {
      return '/learner/orgs/' + orgId + '/search'
    } else if (backNav && backNav.coursePage) {
      return '/learner/orgs/' + orgId + '/courses'
    } else if (backNav && backNav.coursePage) {
      return '/learner/orgs/' + orgId + '/courses'
    } else {
      return '/learner/orgs/' + orgId + '/dashboard'
    }
  }

  const backButton = {
    title: Helper.getString('back'),
    link: backNav(),
  }

  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  const tabHeader = (item, index) => {
    if (item.key === 'resources' && (props.preview || !state.result.assignedCourseStatus)) {
      return null
    } else if (item.key === 'eligibility' && (props.preview || Controller.get().userMgr().isProductSU() || (state.result.eligibility && state.result.eligibility.items.length === 0))) {
      return null
    } else {
      return <div onClick={() => selectTab(item.key)} key={index}
        className={state.selectedDetailTab === item.key ?
          css(Styles.detailTabContainer, Styles.detailSelectedTabContainer) : css(Styles.detailTabContainer)}>
        <p className={state.selectedDetailTab === item.key ?
          css(Styles.detailTabContent, Styles.detailSelectedTabContent) : css(Styles.detailTabContent)}>{item.title}</p>
      </div>
    }
  }

  return (
    <div className={css(CommonStyles.contentStackOuterContainer)}>
      <div className={css(CommonStyles.contentStackFirstOuterContainer)}>
        <ScreenWrapper
          state={state}
          backButton={backButton}
          preview={props.preview} >
          {state.result &&
            <Fragment>
              <TitleComponent
                title={state.result.title}
                icons={titleIcons}
                preview={props.preview}
                inProgress={state.inProgress} />

              <div className={css(Styles.buttonOuterContainer)}>
                {buttonContainer()}
                {/* {state.result.showFeedback && state.result.enablefeedback && !props.preview && state.result.completedCourseStatus &&
                  <AppButton
                    onClick={() => Controller.get().courseMgr().navToFeedback(courseSlug)}
                    buttonContainer={[CommonStyles.secondaryButtonContainer, Styles.secondaryButtonContainer]}
                    buttonTextStyle={Styles.buttonTextStyle}
                    buttonText={Helper.getString('giveFeedback')}
                  />
                } */}
              </div>

              <div className={css(Styles.imgContainer)} >
                <img
                  width='100%' height='300px'
                  src={state.result.image || require('../assets/images/default_category_img.svg')}
                  alt={Helper.getString('courseImgAlt')}
                  className={css(Styles.imgContent)}
                />
              </div>

              <div className={css(Styles.featureContainer)}>
                {state.result.duration &&
                  <p className={css(Styles.featureTitleContainer)}>{Helper.getString('duration')}
                    <span className={css(Styles.boldText)}> - {state.result.duration} {Helper.getString('mins')}</span></p>
                }
                <div className={css(Styles.featureTitleMiddleContainer)}>
                  {state.result.dueDate && !props.preview &&
                    <p className={css(Styles.featureTitleContainer, Styles.featureTitleMiddleContent)}>{Helper.getString('dueDate')}
                      <span className={css(Styles.boldText)}> - {state.result.dueDate}</span></p>
                  }
                </div>

                {state.result.feedback === 'YES' && state.result.stats && state.result.stats.ratings.average &&
                  <p className={css(Styles.featureTitleContainer)}>{Helper.getString('rating')}
                    <span className={css(Styles.boldText)}> -
                      <img src={require('../assets/images/star_icon.svg')} width='18px' height='18px' className={css(Styles.imgStartContent)} />
                      {state.result.stats.ratings.average}/{state.result.totalRating}</span></p>
                }
              </div>

              {state.result.dueDate &&
                <div className={css(Styles.featureTitleMobileContent)}>
                  {!props.preview &&
                    <p className={css(Styles.featureTitleContainer)}>{Helper.getString('dueDate')}
                      <span className={css(Styles.boldText)}> - {state.result.dueDate}</span></p>
                  }
                </div>
              }

              <div className={css(Styles.detailTabOuterContainer)}>
                {courseDetailsTab.map((item, index) => {
                  return (
                    item.desktop === false && !cssBreakpoint ?
                      null
                      :
                      tabHeader(item, index)
                  )
                })}
              </div>

              <div className={css(Styles.selectedTabContainer)}>
                {selectedTabContent()}
              </div>
            </Fragment>
          }
        </ScreenWrapper>
      </div>

      {state.result && state.fetchState === ApiHelper.State.READY &&
        <div className={css(CommonStyles.contentStacksecondOuterContainer)}>
          <p className={css(Styles.courseContentTitle)}>{Helper.getString('courseContent')}</p>
          <CourseContent
            orgId={orgId}
            data={state.result}
            courseSlug={courseSlug}
            preview={props.preview} />
        </div>
      }
      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
    </div>
  )

}

const Styles = StyleSheet.create({
  buttonContainer: {
    padding: '8px 24px',
    '@media (max-width: 767px)': {
      padding: '5px 10px',
    },
  },
  secondaryButtonContainer: {
    marginLeft: 5,
    padding: '8px 24px',
    '@media (max-width: 767px)': {
      padding: '5px 10px',
    },
  },
  buttonOuterContainer: {
    marginTop: 20,
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center'
  },

  imgContainer: {
    height: 300,
    width: '100%',
    display: 'flex',
    '@media (max-width: 767px)': {
      height: 140
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      height: 220
    }
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: AppCommonTheme.borderRadius
  },

  featureContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 40,
    '@media (max-width: 767px)': {
      marginBottom: 24
    }
  },
  featureTitleContainer: {
    fontSize: 18,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      fontSize: 16
    }
  },
  featureTitleMiddleContent: {
    '@media (max-width: 767px)': {
      display: 'none'
    }
  },
  featureTitleMiddleContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 5px'
  },
  boldText: {
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5
  },
  imgStartContent: {
    width: 18,
    height: 18,
    margin: '0px 5px'
  },

  detailTabOuterContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  detailTabContainer: {
    border: AppCommonTheme.border,
    borderRadius: 8,
    padding: '8px 13px',
    marginRight: 10,
    marginBottom: 10,
    ':hover': {
      cursor: 'pointer'
    }
  },
  detailSelectedTabContainer: {
    border: '1px solid' + AppTheme.primaryColor
  },
  detailTabContent: {
    margin: AppCommonTheme.pMargin,
    fontSize: 18,
    color: '#AAABAF',
    '@media (max-width: 767px)': {
      fontSize: 14
    }
  },
  detailSelectedTabContent: {
    color: AppTheme.primaryColor
  },
  selectedTabContainer: {
    marginTop: 20,
    marginBottom: 20,
    '@media (max-width: 767px)': {
      marginBottom: 10
    }
  },
  courseContentTitle: {
    fontSize: 22,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginBottom: 20
  },
  eligibilityTitle: {
    fontSize: 18,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginBottom: 15,
    '@media (max-width: 767px)': {
      fontSize: 16
    }
  },

  featureTitleMobileContent: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'block',
      marginBottom: 30
    }
  },
  buttonTextStyle: {
    color: AppTheme.primaryColor
  }
})