import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';

export class NotFound extends Component {
  render() {
    return (
      <p className={css(Styles.nfMiddle)}>Unknown page. Check the link and try again.</p>
    );
  }
}

const Styles = StyleSheet.create({
  nfMiddle: {
    fontSize: 20,
    paddingTop: '15%',
    textAlign: 'center'
  }
})