import React, { Fragment, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme } from '../styles/Styles';
import { MockData } from '../data/MockData';
import { Helper } from '../common/helpers/Helper';
import { AppTheme } from '../styles/AppTheme';
import { Divider } from '@material-ui/core';
import { ResourceTile } from './Resources/ResourceTile';
import { CourseMgr } from '../common/mgrs/CourseMgr';

export function AssessmentContent(props) {
  // const lockIcon = (status) => {
  //   if (status === CourseMgr.Status.COMPLETED) {
  //     return 'check_icon'
  //   }
  //   else if (status === CourseMgr.Status.NEW) {
  //     return 'lock_icon'
  //   }
  // }

  // const imgContainer = (status) => {
  //   if(status === 'correct') {
  //     return <img src={require('../assets/images/check.svg')} width='18px' height='18px' className={css(Styles.iconContent)} />
  //   } else if(status === 'wrong') {
  //     return <img src={require('../assets/images/close.svg')} width='18px' height='18px' className={css(Styles.iconContent)} />
  //   }
  //   return ''
  // }

  const data = props.data
  const submittedAssessIds = props.submittedAssessIds
  const selectedAns = props.selectedAns
  const titleStyle = (item) => {


    if (submittedAssessIds.includes(item.id))
      return css(Styles.title, Styles.titleDone)
    else
      return css(Styles.title)
    // for (const ques in data.doneAssessmentQues) {
    //   const itemId = data.doneAssessmentQues[ques].id.split('-')
    //   if (item.id === itemId[1]) {

    //   }
    // }
    // return css(Styles.title)
  }

  return (
    <div>
      <p className={css(Styles.sectionTitle)}>{Helper.getString('assessmentContent')}</p>
      {data.length > 0 &&
        data.map((item, index) => {
          const counter = index + 1
          return (
            <div className={css(Styles.titleOuterContainer)} key={index}>
              <div className={css(Styles.titleContainer)}>
                <p className={titleStyle(item)}>{Helper.getString('question')} {counter}</p>
              </div>
              {/* {imgContainer(item.status)} */}
            </div>
          )
        })}
    </div>
  )
}

const Styles = StyleSheet.create({
  sectionTitle: {
    fontSize: 22,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginBottom: 24
  },
  titleOuterContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginBottom: 30
  },
  titleContainer: {
    flex: 1,
    marginRight: 10,
    display: 'grid'
  },
  title: {
    margin: AppCommonTheme.pMargin,
    color: AppCommonTheme.appFontColor,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    fontSize: 18,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  titleCorrect: {
    color: '#16D134'
  },
  titleWrong: {
    color: '#FF0000'
  },
  titleDone: {
    color: AppTheme.primaryColor
  },
  iconContent: {
    width: 18,
    height: 18
  }
})