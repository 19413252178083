import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../helpers/ApiHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { UiHelper } from '../../helpers/UiHelper';
import { AppStyles } from '../../../styles/AppTheme';
import { CommonStyles } from '../../../styles/Styles';
import { Divider } from '@material-ui/core';
import { AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

export class CourseChapters extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('chapters')
    this.addNewLabel = 'Add'
    this.pageKey = 'Section'
    // this.orgId = (Controller.get().roleHelper().isProductUser() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.orgId = props.match.params.orgId ? props.match.params.orgId : Controller.get().roleHelper().getOrgId()
    this.itemId = this.props.match.params.id
    this.sectionId = this.props.match.params.sectionId
    this.uploadLocation = this.orgId + '/' + this.itemId
    this.sectionDetails = props.location.state ? props.location.state : ''
    if(Controller.get().roleHelper().isProductStaff()) {
      this.breadCrumbs = [
        { title: Helper.getString('courses'), to: this.baseRoute() + '/courses' },
        { title: Helper.getString('sections'), to: this.baseRoute() + '/courses/' + this.itemId + '/sections' },
        { title: this.pageTitle },
      ]
    } else {
      this.breadCrumbs = [
        { title: Helper.getString('home'), to: this.baseRoute() },
        { title: Helper.getString('courses'), to: this.baseRoute() + '/courses' },
        { title: Helper.getString('sections'), to: this.baseRoute() + '/courses/' + this.itemId + '/sections' },
        { title: this.pageTitle },
      ]
    }
    this.entityAttrName = 'chapters'
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['courses', this.itemId], { pb: 'fieldDefChapter' }) },
    ]
    if(this.orgId !== 'PRODUCT') {
      this.toFetch.push(
        {
          key: 'item',
          method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], {
            orgId: this.orgId,
            allvisible: true,
            orgCourseId: `${this.orgId}-${this.itemId}`,
          })
        }
      )
    }
    this.tableHeadersMap = {
      // Id: (current) => {
      //   return (
      //     <div>
      //       <span className={css(Styles.spanValue)}>{current.id}</span>
      //     </div>
      //   )
      // },
      Order: (current) => current.order,
      Title: (current) => current.title,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      brandId: this.brandId
    }
  }

  onFetchSuccess(results) {
    if (this.orgId !== 'PRODUCT' && Controller.get().roleHelper().disableEditAccess(results[1])) {
      this.headerActions = []
      this.hideFormAction = true
    }

    this.formDefinition = results[0].fieldDefChapter

    const fileFormField = this.formDefinition.steps[0].fields.find(i => i.key === 'iType')
    const fileFormFieldIndex = fileFormField.options.findIndex(item => item.key === 'FILE')
    this.formDefinition.steps[0].fields.find(i => i.key === 'iType').options.splice(fileFormFieldIndex,1)

    const fieldinfo1 = this.formDefinition.steps[0].fields.find(i => i.key === 'info1')
    if (fieldinfo1) {
      fieldinfo1.label = fieldinfo1.label.replace('Info1', Helper.getString('info1'))
      fieldinfo1.hint = fieldinfo1.hint.replace('Info1', Helper.getString('info1'))
      fieldinfo1.v_required.err = fieldinfo1.v_required.err.replace('Info1', Helper.getString('info1'))
      fieldinfo1.v_min.err = fieldinfo1.v_min.err.replace('Info1', Helper.getString('info1'))
      fieldinfo1.v_max.err = fieldinfo1.v_max.err.replace('Info1', Helper.getString('info1'))
    }
    const fieldinfo2 = this.formDefinition.steps[0].fields.find(i => i.key === 'info2')
    if (fieldinfo2) {
      fieldinfo2.label = fieldinfo2.label.replace('Info2', Helper.getString('info2'))
      fieldinfo2.hint = fieldinfo2.hint.replace('Info2', Helper.getString('info2'))
      fieldinfo2.v_required.err = fieldinfo2.v_required.err.replace('Info2', Helper.getString('info2'))
      fieldinfo2.v_min.err = fieldinfo2.v_min.err.replace('Info2', Helper.getString('info2'))
      fieldinfo2.v_max.err = fieldinfo2.v_max.err.replace('Info2', Helper.getString('info2'))
    }
    const selectedSection = this.formDefinition.steps[0].fields.find(i => i.key === 'sectionId')
    if (selectedSection) {
      selectedSection.disabled = true
      selectedSection.defaultValue = this.sectionDetails.id
    }

    this.pageTitle = this.sectionDetails && this.sectionDetails.title ? this.sectionDetails.title : results[0].title
    UiHelper.populateOptions(this.formDefinition, { items: Helper.dictToArray(results[0].sections) }, 'sectionId')
    const chapters = results[0][this.entityAttrName] ? Helper.dictToArray(results[0][this.entityAttrName]) : []
    const sectionChapters = []
    chapters.map(item => {
      if (item.sectionId === this.sectionId) {
        sectionChapters.push(item)
      }
    })
    this.setState({
      items: sectionChapters,
      item: results[0]
    })
  }

  renderAboveTable() {
    const { item, questionItems } = this.state
    const { pathname } = this.props.location;
    return (
      <div className={css(Styles.containerNarrow)}>
        {item && !this.chapterId &&
          <div className={css(CommonStyles.courseActionListContainer)}>
            <div className={css(CommonStyles.courseActionListInnerContainer)}>
              {UiHelper.isOrgClient(pathname) ? UiHelper.courseActionList(this, item, this.pageKey, this.orgId, this.quizId) :
                UiHelper.courseAdminActionList(this, item, this.pageKey, this.quizId)}
            </div>
            <Divider className={css(CommonStyles.courseActionListDivider)} />
          </div>
        }

      </div>
    );
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.formDefinition.steps[0].fields.find(i => i.key === 'iType').disabled = false
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath(['courses', this.itemId]),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['courses', this.itemId]),
    });
  }

  onEdit(current) {
    this.formDefinition.steps[0].fields.find(i => i.key === 'iType').disabled = true
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['courses', this.itemId]),
    });
  }

  onActions = (current) => {
    const actions = []
    return actions
  }

  onMenuItems(current) {
    const actions = []
    // actions.push(UiHelper.customMenuButton({
    //   current: current,
    //   key: current.id + '-quiz',
    //   link: this.baseRoute() + '/quiz/' + this.itemId + '-' + current.id,
    //   title: 'Quiz',
    //   icon: 'psychology_alt'
    // }))
    const menuItems = [
      {
        current: current,
        key: current.id + '-quiz',
        link: this.baseRoute() + '/quiz/' + this.itemId + '-' + current.id,
        state: current,
        title: Helper.getString('quiz'),
        icon: 'psychology_alt'
      },
      {
        current: current,
        key: current.id + '-knowledgechecks',
        link: this.baseRoute() + '/knowledgechecks/' + this.itemId + '-' + current.id,
        state: current,
        title: Helper.getString('knowledgeCheck'),
        icon: 'task_alt'
      },
      {
        current: current,
        key: current.id + 'editTT',
        action: () => this.openModalForEdit(current),
        title: Helper.getString('edit'),
        img: require('../../../assets/images/edit-icon.svg')
      },
      {
        current: current,
        key: current.id + 'deleteTT',
        action: () => this.openModalForDelete(current),
        title: Helper.getString('delete'),
        img: require('../../../assets/images/trash-icon.svg'),
        style: CommonStyles.deleteText
      },
    ]
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions
  }
}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  spanValue: {
    fontSize: 16
  }
})