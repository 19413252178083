export class StyleHandler {

  static getImage(key, selectedId) {
    const imagesByKey = {
      dashboard: {
        light: require('../assets/images/dashboard_icon.svg'),
        dark: require('../assets/images/dashboard_icon_d.svg')
      },
      dashboardAdmin: {
        light: require('../assets/images/dashboard_icon.svg'),
        dark: require('../assets/images/dashboard_icon_d.svg')
      },
      explore: {
        light: require('../assets/images/explore_icon.svg'),
        dark: require('../assets/images/explore_icon_d.svg')
      },
      resources: {
        light: require('../assets/images/resources_icon.svg'),
        dark: require('../assets/images/resources_icon_d.svg')
      },
      accolades: {
        light: require('../assets/images/award_icon.svg'),
        dark: require('../assets/images/award_icon_d.svg')
      },
      profile: {
        light: require('../assets/images/profile_icon.svg'),
        dark: require('../assets/images/profile_icon_d.svg')
      },

      courses: {
        light: require('../assets/images/course_icon.svg'),
        dark: require('../assets/images/course_icon_d.svg')
      },
      department: {
        light: require('../assets/images/department_icon.svg'),
        dark: require('../assets/images/department_icon_d.svg')
      },
      user: {
        light: require('../assets/images/user_icon.svg'),
        dark: require('../assets/images/user_icon_d.svg')
      },
      category: {
        light: require('../assets/images/category_icon.svg'),
        dark: require('../assets/images/category_icon_d.svg')
      },
      client: {
        light: require('../assets/images/client_icon.svg'),
        dark: require('../assets/images/client_icon_d.svg')
      },
      jobs: {
        light: require('../assets/images/task.svg'),
        dark: require('../assets/images/task_d.svg')
      },
    }
    if (selectedId !== key) {
      return imagesByKey[key].light
    } else {
      return imagesByKey[key].dark
    }
  }

}