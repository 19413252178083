import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Category } from '../components/Category/Category';
import { EmptyView } from '../components/EmptyView';
import { Helper } from '../common/helpers/Helper';
import { AppContext } from '../common/components/contexts/AppContext';
import { CommonStyles } from '../styles/Styles';

export function Categories(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {}
  })
  const orgId = Controller.get().roleHelper().getOrgId()

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getCategories(orgId)
      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'explore' })
    fetchData()
  }, [])

  const backButton = {
    title: Helper.getString('back'),
    link: '/learner/orgs/' + orgId + '/explore'
  }

  return (
    <ScreenWrapper
      state={state}
      title={Helper.getString('allKnowlegdeSets')}
      favourite={true}
      titleStyle={CommonStyles.titleStyle}
      backButton={backButton}
      logo={true}
      // search={true} //TODO search
      // filter={true}
    >

      {state.result && state.result.items && state.result.items.length > 0 ?
        <Category
          data={state.result}
          categories={true}
          backButton={{ categories: true }} />
        :
        <EmptyView
          title={Helper.getString('noCategory')} />
      }
    </ScreenWrapper>
  )


}

const Styles = StyleSheet.create({

})