import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { CustomProductsStyles } from '../../../styles/CustomStyles';
import { CommonStyles } from '../../../styles/Styles';
import { Divider } from '@material-ui/core';
import { QuestionOptions } from './QuestionOptions';
import { CustomDialog } from '../CustomDialog';
import { SubEntityList } from '../SubEntity/SubEntityList';
import { DynamicFormDialog } from '../DynamicFormDialog';

export class Questions extends BaseEntityPage {
  constructor(props) {
    super(props);
    // this.orgId = this.props.orgId ? this.props.orgId : (Controller.get().roleHelper().isProductUser() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.orgId = this.props.orgId ? this.props.orgId : (props.match.params.orgId ? props.match.params.orgId : Controller.get().roleHelper().getOrgId())
    this.quizId = this.props.id ? this.props.id : this.props.match.params.id
    this.courseId = this.quizId.split('-')[0]
    this.addNewLabel = 'Add'
    this.chapterId = this.quizId.split('-')[1]
    this.chapterDetails = props.chapterDetails ? props.chapterDetails : (props.location.state ? props.location.state : '')

    if (this.chapterId) {
      this.pageTitle = Helper.getString('quiz')
      if (Controller.get().roleHelper().isProductUser()) {
        this.breadCrumbs = [
          { title: Helper.getString('courses'), to: this.baseRoute() + '/courses' },
          { title: Helper.getString('sections'), to: this.baseRoute() + '/courses/' + this.courseId + '/sections' },
          { title: Helper.getString('chapters'), to: this.baseRoute() + '/courses/' + this.courseId + '/' + this.chapterDetails.sectionId + '/chapters' },
          { title: this.pageTitle },
        ]
      } else {
        this.breadCrumbs = [
          { title: Helper.getString('home'), to: this.baseRoute() },
          { title: Helper.getString('courses'), to: this.baseRoute() + '/courses' },
          { title: Helper.getString('sections'), to: this.baseRoute() + '/courses/' + this.courseId + '/sections' },
          { title: Helper.getString('chapters'), to: this.baseRoute() + '/courses/' + this.courseId + '/' + this.chapterDetails.sectionId + '/chapters' },
          { title: this.pageTitle },
        ]
      }
    } else { // This is under a course; So an assessment
      this.pageTitle = Helper.getString('assessment')
      if (Controller.get().roleHelper().isProductUser()) {
        this.breadCrumbs = [
          { title: Helper.getString('courses'), to: this.baseRoute() + '/courses' },
          { title: this.pageTitle },
        ]
      } else {
        this.breadCrumbs = [
          { title: Helper.getString('home'), to: this.baseRoute() },
          { title: Helper.getString('courses'), to: this.baseRoute() + '/courses' },
          { title: this.pageTitle },
        ]
      }
    }
    this.pageKey = props.quiz ? 'Quiz' : 'Questions'
    this.toFetch = [
      { method: 'GET', paths: ['questions'], queryParams: { quizId: this.quizId, pb: 'fieldDef' } },
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['courses', this.courseId]) },
    ]
    if (this.orgId !== 'PRODUCT') {
      this.toFetch.push(
        {
          key: 'item',
          method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], {
            allvisible: true,
            orgId: this.orgId, orgCourseId: `${this.orgId}-${this.courseId}`,
          })
        }
      )
    }
    if (!this.chapterId) {
      this.toFetch.push({ method: 'GET', endPoint: ApiHelper.makeUrlPath(['courses', this.quizId]) })
    }
    this.tableHeadersMap = {
      Order: (current) => { return <span className={css(Styles.valueText)}>{current.order}</span> },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span>
            <br />
            <span className={css(Styles.spanValue)}>{current.iType}</span>
          </div>
        )
      },
    }
    this.questionTableHeadersMap = {
      Order: (current) => { return <span className={css(Styles.valueText)}>{current.order}</span> },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span>
            {/* <br />
            <span className={css(Styles.spanValue)}>{'Answer: ' + (current.correctOption || 'NO')}</span> */}
          </div>
        )
      },
      Correct: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{'Answer: ' + (current.correctOption || 'NO')}</span>
          </div>
        )
      },
    }
    this.questionEntityAttrName = 'options'
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results, options) {
    if (this.orgId !== 'PRODUCT' && Controller.get().roleHelper().disableEditAccess(results[2])) {
      this.headerActions = []
      this.hideFormAction = true
      this.hideEditAction = true
    }

    this.formDefinition = results[0].fieldDef
    this.formDefinition.steps[0].fields =
      this.formDefinition.steps[0].fields.filter(i => Helper.inArray(['title', 'order', 'iType'], i.key))
    const fieldiType = this.formDefinition.steps[0].fields.find(i => i.key === 'iType')
    fieldiType.options = fieldiType.options.filter(i => Helper.inArray(['OPTIONS-SINGLE', 'OPTIONS-MUL'], i.key))
    this.pageTitle = (this.chapterDetails && this.chapterDetails.title) ? this.chapterDetails.title :
      this.orgId === 'PRODUCT' ? results[2] && results[2].title : results[3] && results[3].title
    this.setState({
      items: results[0].items,
      item: results[0],
      courseDetails: results[1],
      questionFormField: this.formDefinition
    })
  }

  renderAboveTable() {
    const { item, questionItems } = this.state
    const { pathname } = this.props.pathname ? this.props.pathname : this.props.location;
    return (
      <div className={css(Styles.containerNarrow)}>
        {item &&
          <div className={css(CommonStyles.courseActionListContainer)}>
            <div className={css(CommonStyles.courseActionListInnerContainer)}>
              {UiHelper.isOrgClient(pathname) ? UiHelper.courseActionList(this, item, this.pageKey, this.orgId, this.courseId, this.state.courseDetails.slug) :
                UiHelper.courseAdminActionList(this, item, this.pageKey, this.courseId, this.state.courseDetails.slug)}
            </div>
            <Divider className={css(CommonStyles.courseActionListDivider)} />
          </div>
        }

        <CustomDialog
          isOpen={this.state.customModel}
          onClosed={() => this.setState({ customModel: false })}
          fullWidthDialog={false}>
          <SubEntityList
            title={this.state.questionSelected && this.state.questionSelected.title ? this.state.questionSelected.title : ''}
            // noTableHead={true}
            // noTableBorder={true}
            tableHeadersMap={this.questionTableHeadersMap}
            items={questionItems}
            openModalForAdd={() => this.openModalForQuestionAdd(this.questionFormDefinition)}
            openModalForEdit={(current) => this.openModalForQuestionEdit(current, this.questionFormDefinition)}
            openModalForDelete={(current) => this.openModalForQuestionDelete(current, this.questionFormDefinition)}
            stepFormTitle={Styles.stepFormTitle}
            noAdd={this.hideEditAction}
          />
        </CustomDialog>
      </div>
    );
  }

  _modifyFields = (options) => {
    const { current } = options || {}
    if (this.state.questionSelected.iType === 'OPTIONS-SINGLE' && this.state.questionItems) {
      for (const q of this.state.questionItems) {
        if (q.correctOption === 'YES') {
          for (const f of this.formDefinition.steps[0].fields) {
            if (!current) {
              if (f.key === 'correctOption') {
                f.options = [{ key: 'NO', label: 'NO' }]
                break
              }
            }
            if (f.key === 'correctOption' && q.id !== current.id) {
              f.options = [{ key: 'NO', label: 'NO' }]
              break
            } else if (f.key === 'correctOption' && q.id === current.id) {
              break
            }
          }
        }
      }
    }
  }

  openModalForQuestionAdd(fieldDef) {

    this.formDefinition = fieldDef || this.fieldDef || this.formDefinition
    this.formDefinition.formType = 'add'
    this._modifyFields()
    Helper.deleteFormDefinitionValues(this.formDefinition)
    this.onQuestionAdd();
    this.setState({
      modalOpen: true
    });
  }



  onQuestionAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.questionEntityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath(['questions', this.state.questionSelected.id], {}),
    });
  }

  openModalForQuestionEdit(current, fieldDef) {
    this.formDefinition = fieldDef
    this.formDefinition.formType = 'edit'
    this._modifyFields({ current })
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onQuestionEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  onQuestionEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.questionEntityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['questions', this.state.questionSelected.id], {}),
    });
  }

  openModalForQuestionDelete(current, fieldDef) {
    this.formDefinition = fieldDef
    this.formDefinition.formType = 'delete'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onQuestionDelete(current);
    this.setState({
      modalOpen: true
    });
  }

  onQuestionDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.questionEntityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['questions', this.state.questionSelected.id], {}),
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  openModalForAdd(fieldDef) {
    this.formDefinition = this.state.questionFormField || fieldDef || this.fieldDef || this.formDefinition
    this.formDefinition.formType = 'add'
    Helper.deleteFormDefinitionValues(this.formDefinition)
    this.onAdd();
    this.setState({
      modalOpen: true
    });
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiData: { quizId: this.quizId, groupId: 'DEFAULT' },
      apiEndPoint: ApiHelper.makeUrlPath(['questions'], {}),
      questionSelected: null
    })
  }

  openModalForEdit(current, fieldDef) {
    this.formDefinition = this.state.questionFormField || fieldDef || this.fieldDef || this.formDefinition
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { quizId: this.quizId, groupId: 'DEFAULT' },
      apiEndPoint: ApiHelper.makeUrlPath(['questions', current.id], {}),
    });
  }

  openModalForDelete(current, fieldDef) {
    this.formDefinition = this.state.questionFormField || fieldDef || this.fieldDef || this.formDefinition
    this.formDefinition.formType = 'delete'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onDelete(current);
    this.setState({
      modalOpen: true
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiData: { quizId: this.quizId, groupId: 'DEFAULT' },
      apiEndPoint: ApiHelper.makeUrlPath(['questions', current.id], {}),
    });
  }

  onActions = (current) => {
    const actions = []
    return actions
  }

  fetchOptions = async (current) => {
    if (current) {
      const resultData = await ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['questions', current.id], { pb: 'fieldDefOptions' }),
      })
      this.questionFormDefinition = resultData.fieldDefOptions
      this.questionFormDefinition.steps[0].fields =
        this.questionFormDefinition.steps[0].fields.filter(i => Helper.inArray(['title', 'order', 'correctOption'], i.key))
      this.setState({
        customModel: true,
        questionSelected: current,
        questionItems: Helper.sortDictArray(Helper.dictToArray(resultData.options))
      })
    } else {
      return null
    }
  }

  onMenuItems(current) {
    const actions = []
    const menuItems = [
      {
        current: current,
        key: current.id + 'editTT',
        action: () => this.openModalForEdit(current),
        title: Helper.getString('edit'),
        img: require('../../../assets/images/edit-icon.svg')
      },
      {
        current: current,
        key: current.id + '-quiz-options',
        action: () => this.fetchOptions(current),
        title: 'Options',
        icon: 'list'
      },
      {
        current: current,
        key: current.id + 'deleteTT',
        action: () => this.openModalForDelete(current),
        title: Helper.getString('delete'),
        img: require('../../../assets/images/trash-icon.svg'),
        style: CommonStyles.deleteText
      },
    ]
    menuItems.map(item => {
      actions.push(UiHelper.customMenuButton(item))
    })
    return actions
  }

  renderModal() {
    return (
      <DynamicFormDialog
        apiMethod={this.state.apiMethod}
        apiEndPoint={this.state.apiEndPoint}
        uploadLocation={this.uploadLocation || this.orgId}
        customSubmit={this.customSubmit && this.customSubmit.bind(this)}
        apiData={this.state.apiData || this.apiData}
        formDefinition={this.formDefinition}
        isOpen={this.state.modalOpen}
        onClosed={() => this.setState({ modalOpen: false })}
        onCompleteAction={(options) => {
          this.setState({ modalOpen: false, items: [], dialogApiData: options.apiInvokeData })
          this.fetchItems()
          this.fetchOptions(this.state.questionSelected)
        }
        }
        fullWidthDialog={this.fullWidthDialog}
        hideFormAction={this.hideFormAction}
      >
      </DynamicFormDialog>
    );

  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomProductsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})
