import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, CircularProgress } from '@material-ui/core';
import { AppTheme } from '../styles/AppTheme';
import { Helper } from '../common/helpers/Helper';

export function ViewMoreButton(props) {
  return (
    <Button fullWidth={props.fullWidth}
      onClick={props.onClick}
      disabled={props.inProcess}
      className={css(Styles.button, props.buttonStyle)}>
      {!props.inProcess &&
        <div className={css(Styles.buttonTextContainer)}>
          <p className={css(Styles.buttonText, props.buttonTextStyle)}>{Helper.getString('viewMore')}</p>
        </div>
      }
      {props.inProcess &&
        <CircularProgress size={20} className={css(Styles.progressIcon)} />
      }
    </Button>
  )
}

const Styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    backgroundColor: AppTheme.secondaryBg,
    padding: 0
  },
  buttonTextContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonText: {
    fontSize: 16,
    margin: 0,
    textTransform: 'capitalize',
    color: AppTheme.primaryColor,
    textDecoration: 'underline',
    textUnderlineOffset: 1,
  },
  progressIcon: {
    color: AppTheme.primaryColor
  }
})