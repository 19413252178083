import React, { Fragment, Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { Helper } from '../../../common/helpers/Helper';
import { Controller } from '../../../common/Controller';
import { ScreenWrapper } from '../../../components/ScreenWrapper';
import { Status } from '../../../components/Status/Status';
import { AppHelper } from '../../../helpers/AppHelper';
import { MockData } from '../../../data/MockData';
import { UserContext } from '../../../common/components/contexts/UserContext';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { CustomTableComponent } from '../../../components/CustomTableComponent';
import { ChartCard } from '../../../components/ChartCard';
import { AppCommonTheme } from '../../../styles/Styles';
import { DashboardFilter } from '../../../components/DashboardFilter';
import { Player } from '@lottiefiles/react-lottie-player';

export class DashboardOrg extends BaseEntityPage {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.filterFormData = { orgCourseState : 'LIVE' }
    this.getSubOrgIds()
    this.pageTitle = 'Dashboard'.toLocaleUpperCase()
    this.adminStatusItems = AppHelper.adminStatus()
    this.mockStatusData = MockData.adminStatusData
    this.userData = Controller.get().userMgr().getAppUser()
    this.managerStatusItems = AppHelper.managerStatus()
    this.export = { format: 'xlsx' }
  }

  getSubOrgIds() {
    if (Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) {
      this.toFetch = [
        {
          method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'dashboard'],
            { pb: 'filterFieldDefOrgSU', orgId: this.orgId,...this.filterFormData })
        },
      ]
    } else if (Controller.get().roleHelper().isOrgManagerUser()) {
      const subOrgIds = Controller.get().roleHelper().getSubOrgIds() || []
      if (subOrgIds && subOrgIds.length > 0) {
        this.toFetch = [
          {
            method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs', 'dashboard'],
              { pb: 'filterFieldDefOrgManager', orgId: this.orgId, subOrgIds: subOrgIds,...this.filterFormData })
          },
        ]
      } else {
        this.toFetch = []
      }
    } else {
      this.toFetch = []
    }
  }

  onFetchSuccess(results) {
    console.log('onFetchSuccess', results)
    this.courseTable = {
      data: [],
      ...MockData.courseTable
    }
    this.userTable = {
      data: [],
      ...MockData.userTable
    }
    this.courseTableMgr = {
      data: [],
      ...MockData.courseTableMgr
    }
    this.userTableMgr = {
      data: [],
      ...MockData.userTableMgr
    }
    // if (mockDashboardData.courseList) {
    //   for (const c of Object.keys(mockDashboardData.courseList)) {
    //     this.courseTable.data.push(mockDashboardData.courseList[c])
    //   }
    // }
    if (results[0] && results[0].courseList) {
      for (const c of Object.keys(results[0].courseList)) {
        this.courseTable.data.push(results[0].courseList[c])
        this.courseTableMgr.data.push(results[0].courseList[c])
      }
    }
    if (results[0] && results[0].userList) {
      for (const c of Object.keys(results[0].userList)) {
        this.userTable.data.push(results[0].userList[c])
        this.userTableMgr.data.push(results[0].userList[c])
      }
    }
    if (Controller.get().roleHelper().isOrgManagerUser() && results[0] && results[0].filterFieldDefOrgManager) {
      const orgs = Controller.get().roleHelper().getOrgs()
      const options = []
      for (const org of orgs) {
        if (org.orgId.split('-').length > 1 && org.roles && org.roles.indexOf('MANAGER') > -1) {
          options.push({
            key: org.orgId,
            label: org.title
          })
        }
      }
      results[0].filterFieldDefOrgManager[0].fields[0].options = options
    }
    this.formDefinition = results[0] && (results[0].filterFieldDefOrgManager || results[0].filterFieldDefOrgSU)
    this.setState({
      dashboard: results[0],
      filterFetchState: ApiHelper.State.READY
    })
  }

  _getName = () => {
    if (!this.userData) return ''
    let orgId = this.orgId
    if (Controller.get().userMgr().isProductSU()) {
      orgId = 'PRODUCT'
    }
    for (const org of this.userData.orgs) {
      if (org.orgId === orgId) {
        return Helper.formatName(org.orgUserProfile)
      }
    }
  }

  onSubmit = async (formData) => {
    console.log('onSubmit', formData)
    this.setState({
      filterFetchState: ApiHelper.State.LOADING
    })
    const subOrgParams = {}
    if (!(Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) &&
      Controller.get().roleHelper().isOrgManagerUser()) {
      const subOrgIds = Controller.get().roleHelper().getSubOrgIds() || []
      subOrgParams.subOrgIds = subOrgIds
    }
    if (subOrgParams && subOrgParams.subOrgIds && subOrgParams.subOrgIds.length === 0) {
      return this.setState({
        filterFetchState: ApiHelper.State.READY,
      })
    }
    const queryParams = {
      orgId: this.orgId,
      ...subOrgParams,
      ...formData
    }
    try {
      const resultData = await ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'dashboard'], queryParams),
      })
      this.onFetchSuccess([resultData])
    } catch (err) {
      this.setState({
        filterFetchState: ApiHelper.State.ERROR,
        errMsg: Helper.getErrorMsg(err)
      })
    }
  }

  onExport(tableData) {
    if (this.export && this.export.format === 'xlsx') {
      const DwnldUtil = require('../../../common/helpers/FileDownloader');
      const fileName = this.makeFileName();
      const rows = this.prepExcelDownload(tableData)
      DwnldUtil.FileDownloader.exportAsExcelFile(rows, fileName)
    }
  }

  downloadTable(data) {
    this.export.headers = data.header
    this.onExport(data)
  }

  prepExcelDownload(data) {
    const rows = []
    data.data.forEach(rowData => {
      const e = {}
      data.header.forEach(header => {
        if (rowData[header.key] && Array.isArray(rowData[header.key])) {
          e[header.title] = rowData[header.key].join(', ')
        } else {
          e[header.title] = rowData[header.key] ? rowData[header.key] : '-'
        }

      });
      rows.push(e)
    });
    return rows
  }

  renderCustomReady() {
    const title = this._getName()
    return (
      <ScreenWrapper
        title={title}
        search={false}
        dashboard={true}
        filter={false}
        setting={true}
        disableFooter={true}
        orgId={this.orgId}
        outerContainer={Styles.outerContainer}>

        {/* {(Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) && */}
        <DashboardFilter
          orgId={this.orgId}
          fields={this.state.dashboard && this.formDefinition}
          onSubmit={this.onSubmit}
          prefillData={this.filterFormData}
        />
        {/* } */}

        {this.state.filterFetchState === ApiHelper.State.READY &&
          <Fragment>
            {(Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) &&
              <Fragment>
                <Status items={this.adminStatusItems} data={this.state.dashboard || {}} tileSize={2} disableNav={true} />
                {this.state.dashboard && this.state.dashboard.barData && Object.keys(this.state.dashboard.barData).length > 0 &&
                  <Grid container spacing={2} className={css(Styles.gridContainerVariant)} >
                    {Object.keys(this.state.dashboard.barData).map((key, index) => {
                      const item = this.state.dashboard.barData[key]
                      if (!item) return
                      return (
                        <Grid item xs={12} sm={6} md={4} className={css(Styles.container)} key={index}>
                          <ChartCard
                            item={item}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                }
                <div className={css(Styles.barLineOuterContainer)}>
                  <Grid container spacing={0} className={css(Styles.barLineContainer)} >
                    <Grid item xs={12} sm={12} md={12} lg={12} className={css(Styles.container)}>
                      {this.state.dashboard && this.state.dashboard.enrolledVsCompleted &&
                        <ChartCard
                          //item={MockData.barLine}
                          item={this.state.dashboard && this.state.dashboard.enrolledVsCompleted}
                        />
                      }
                    </Grid>
                  </Grid>
                </div>
                <CustomTableComponent title={Helper.getString('userTableTitle')} data={this.userTable} downloadTable={this.downloadTable.bind(this)} />
                <CustomTableComponent title={Helper.getString('courseTableTitle')} data={this.courseTable} downloadTable={this.downloadTable.bind(this)} />
              </Fragment>
            }

            {(!(Controller.get().userMgr().isProductSU() || Controller.get().roleHelper().isOrgSU()) && Controller.get().roleHelper().isOrgManagerUser()) &&
              <Fragment>
                <div className={css(Styles.managerDashboard)}>
                  <div className={css(Styles.managerFirstContainer)}>
                    <Status items={this.managerStatusItems} data={this.state.dashboard || {}} tileSize={4} disableNav={true} />
                    <div className={css(Styles.barLineContainer)}>
                      {this.state.dashboard && this.state.dashboard.managerBarChart &&
                        <ChartCard item={this.state.dashboard && this.state.dashboard.managerBarChart} />
                      }
                    </div>
                  </div>
                  <div className={css(Styles.managerSecondContainer)}>
                    <div className={css(Styles.secondInnerContainer)}>
                      <p className={css(Styles.leaderTitle)}>{Helper.getString('leaderboard')}</p>
                      <div className={css(Styles.leaderOuterContent)}>
                        <div className={css(Styles.leaderContent)}>
                          {this.state.dashboard && this.state.dashboard.userList && Object.keys(this.state.dashboard.userList).map((key, index) => {
                            const item = this.state.dashboard.userList[key]
                            return (
                              <div className={css(Styles.leaderInnerContent)} key={index}>
                                <div className={css(Styles.leaderNameContent)}>
                                  <p className={css(Styles.leaderIndexContent)}>{index + 1}.</p>
                                  <p className={css(Styles.leaderName)}>{item.givenname}</p>
                                </div>
                                <p className={css(Styles.learnerValue)}>{item.totalPoints}</p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CustomTableComponent title={Helper.getString('userTableTitle')} data={this.userTableMgr} downloadTable={this.downloadTable.bind(this)} />
                <CustomTableComponent title={Helper.getString('courseTableTitle')} data={this.courseTableMgr} downloadTable={this.downloadTable.bind(this)} />
              </Fragment>
            }
          </Fragment>
        }
        {this.state.filterFetchState === ApiHelper.State.LOADING &&
          <div className={css(Styles.loadingOuterContainer)}>
            <Player
              autoplay
              loop
              src={require('../../../assets/images/circle_loading_progress_indicator.json')}
              className={css(Styles.loadingContainer)} />
            {navigator && navigator.userAgent.startsWith('Azsincx') ? '' :
              <p className={css(Styles.loadingText)}>{Helper.getString('loading')}</p>
            }
          </div>
        }

      </ScreenWrapper>
    );
  }
}

const Styles = StyleSheet.create({
  outerContainer: {
    width: '90%',
  },
  gridContainerVariant: {
    marginTop: 32
  },
  barLineOuterContainer: {
    display: 'grid',
  },
  barLineContainer: {
    marginTop: 20,
    overflow: 'hidden'
  },

  managerDashboard: {
    display: 'flex',
    '@media(max-width: 1024px)': {
      flexDirection: 'column',
      gap: 30
    }
  },
  managerFirstContainer: {
    // flex: .65
    width: '70%',
    '@media(max-width: 1024px)': {
      width: '100%'
    }
  },
  managerSecondContainer: {
    // flex: .35,
    width: '30%',
    '@media(max-width: 1024px)': {
      width: '100%'
    }
  },

  secondInnerContainer: {
    marginLeft: 25,
    border: AppCommonTheme.border,
    padding: '20px 14px',
    borderRadius: AppCommonTheme.borderRadius,
    flex: 1,
    '@media(max-width: 1024px)': {
      marginLeft: 0
    }
  },
  leaderTitle: {
    margin: 0,
    flex: 1,
    fontSize: 16,
    fontWeight: 500,
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  leaderOuterContent: {
    display: 'grid',
    overflow: 'hidden'
  },
  leaderContent: {
    marginLeft: 6,
    marginTop: 10,
    overflowY: 'scroll',
    maxHeight: 519,
  },
  leaderInnerContent: {
    display: 'flex',
    marginTop: 10
  },
  leaderNameContent: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  leaderIndexContent: {
    color: '#AAABAF',
    margin: 0,
    fontSize: 12,
    fontWeight: 400
  },
  leaderName: {
    color: AppCommonTheme.appFontColor,
    margin: 0,
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 5
  },
  learnerValue: {
    color: AppCommonTheme.appFontColor,
    margin: 0,
    fontSize: 12,
    fontWeight: 500
  },
  loadingOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  loadingContainer: {
    height: 220,
    width: 220,
    '@media (max-width: 767px)': {
      height: 135,
      width: 135,
    }
  },
  loadingText: {
    fontSize: 48,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 5,
    '@media (max-width: 767px)': {
      fontSize: 28,
    }
  }
})