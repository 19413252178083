import React, { useEffect, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { Privacy } from './Privacy';


export function PrivacyWrapper(props) {

  return (
    <ScreenWrapper
      logo={true}>
        <Privacy learner={true}/>
    </ScreenWrapper>
  )
}

const Styles = StyleSheet.create({

})
