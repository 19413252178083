import React, { Fragment, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';
import { CourseTile } from './CourseTile';
import { ViewMoreButton } from '../ViewMoreButton';
import { AppCommonTheme, CommonStyles } from '../../styles/Styles';
import { Controller } from '../../common/Controller';
import { SearchBar } from '../SearchBar';
import { Helper } from '../../common/helpers/Helper';
import { Filter } from '../Filter';
import { mycourseFields } from '../../data/FormFields';
import { EmptyView } from '../EmptyView';
import { AppHelper } from '../../helpers/AppHelper';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { Player } from '@lottiefiles/react-lottie-player';

export function Courses(props) {
  const [items, setItems] = useState(props.data && props.data.items || [])
  const [sortByTs, setSortByTs] = useState(new Date().valueOf())
  const [formfieldValues, setFormfieldValues] = useState({})
  const [searchText, setSearchText] = useState('')
  const [progress, setProgress] = useState(false)
  const [searchValue] = useDebounce(searchText, 500);

  const [sortBy, setSortBy] = useState('')
  const tileDisplay = (item, index) => {

    return (
      <Grid key={item.id} lg={lg} xs={12} sm={6} md={4} item >
        <CourseTile
          key={index}
          item={item}
          disableTileClick={props.disableTileClick}
          comingSoonMsg={props.comingSoonMsg}
          backButton={props.backButton} />
      </Grid>
    )
  }
  const data = props.data
  const lg = props.valueforGrid ? props.valueforGrid : 3
  const orgId = Controller.get().roleHelper().getOrgId()

  useEffect(() => {
    if (!props.showSearch) return
    filterCourses()
  }, [searchValue, sortBy])


  useEffect(() => {
    setItems(props.data && props.data.items || [])
  }, [props.data])

  const onInputChange = (searchText) => {
    setSearchText(searchText)
  }

  const onSubmit = (values) => {
    setFormfieldValues(values)
    setSortBy(values.sortBy)
  }

  const filterCourses = () => {
    if(!props.data || !props.data.items) return
    let pItems = []
    setProgress(true)
    setItems([])
    if (searchText && searchText.length !== 0) {
      for (const item of props.data.items) {
        if ((item.title.toLowerCase()).includes(searchText)) pItems.push(item)
      }
    } else {
      pItems = props.data.items
    }
    if (sortBy === 'alphabeticalAesc') {
      pItems = Helper.sortDictArray(pItems, 'title')
    } else if (sortBy === 'alphabeticalDesc') {
      pItems = Helper.sortDictArrayRev(pItems, 'title')
    } else if (sortBy === 'durationAesc') {
      pItems = AppHelper.sortDictByDuration(pItems)
    } else if (sortBy === 'durationDesc') {
      pItems = AppHelper.sortDictByDurationRev(pItems)
    } else if (sortBy === 'dueDateAesc') {
      pItems = AppHelper.sortDictByDueDate(pItems)
    } else if (sortBy === 'dueDateDesc') {
      pItems = AppHelper.sortDictByDueDateRev(pItems)
    } else if (sortBy === 'ratingsAesc') {
      pItems = AppHelper.sortDictByRatings(pItems)
    } else if (sortBy === 'ratingsDesc') {
      pItems = AppHelper.sortDictByRatingsRev(pItems)
    }
    setTimeout(() => {
      setItems(pItems)
      setProgress(false)
      setSortByTs(new Date().valueOf())
    }, 500)
  }

  return (
    <div className={css(props.borderContainer)}>
      {(props.showTitle || props.showSearch || data.viewMoreButtonTop) &&
        <div className={css([CommonStyles.titleOuterContainer, props.titleOuterContainer])}>
          {props.showTitle &&
            <p className={css(CommonStyles.titleContainer)}>{props.title}</p>
          }
          {props.showSearch &&
            <div className={css(Styles.searchContainer)}>
              <SearchBar
                onInputChange={onInputChange}
                header={true}
                placeholder={props.searchPlaceholder || Helper.getString('searchMyCourses')}
              />
              <Filter
                fields={mycourseFields}
                onSubmit={onSubmit}
                formfieldValues={formfieldValues}
              />
            </div>
          }
          {data.viewMoreButtonTop &&
            <div className={css(CommonStyles.viewMoreButtonContainer)}>
              <ViewMoreButton
                buttonTextStyle={CommonStyles.buttonTextStyle}
                onClick={() => Controller.get().courseMgr().pushRoute('/learner/orgs/' + orgId + '/courses')} />
            </div>
          }
        </div>
      }

      {items && items.length > 0 && !progress ?
        <Grid container spacing={2}>
          {sortByTs && items.map((item, index) => {
            return (
              data.courseItemLimit ?
                index < data.courseItemLimit &&
                tileDisplay(item, index)
                :
                tileDisplay(item, index)
            )
          })}
        </Grid>
        :
        <Fragment>
          {progress ?
            <div className={css(Styles.container)}>
              <Player
                autoplay
                loop
                src={require('../../assets/images/circle_loading_progress_indicator.json')}
                className={css(Styles.loadingContainer)} />
              {navigator && navigator.userAgent.startsWith('Azsincx') ? '' :
                <p className={css(Styles.loadingText)}>{Helper.getString('loading')}</p>
              }
            </div>
            :
            <EmptyView
              title={Helper.getString('noItemsToShow')} />
          }
        </Fragment>
      }
      {data.viewMoreButtonBottom &&
        <div className={css(Styles.viewMoreButtonContainer)}>
          <ViewMoreButton
            buttonStyle={Styles.buttonStyle}
            buttonTextStyle={Styles.buttonTextStyle}
            onClick={() => Controller.get().courseMgr().pushRoute('/learner/orgs/' + orgId + '/courses')}
          />
        </div>
      }
    </div>

  )
}

const Styles = StyleSheet.create({
  viewMoreButtonContainer: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    '@media(max-width: 767px)': {
      marginTop: 20
    }
  },
  buttonStyle: {
    border: AppCommonTheme.border,
    padding: '5px 15px'
  },
  buttonTextStyle: {
    fontSize: 12,
    textDecoration: 'unset',
    '@media(max-width: 767px)': {
      fontSize: 10
    }
  },
  searchContainer: {
    gap: 20,
    display: 'flex',
    alignItems: 'center'
  },
  loadingContainer: {
    height: 220,
    width: 220,
    '@media (max-width: 767px)': {
      height: 135,
      width: 135,
    }
  },
  loadingText: {
    fontSize: 48,
    color: AppCommonTheme.appFontColor,
    margin: AppCommonTheme.pMargin,
    marginTop: 5,
    '@media (max-width: 767px)': {
      fontSize: 28,
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
})