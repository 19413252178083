import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme, CommonStyles } from '../styles/Styles';
import { Helper } from '../common/helpers/Helper';
import { AppTheme } from '../styles/AppTheme';
import Grid from '@material-ui/core/Grid';
import { Controller } from '../common/Controller';

export function Links(props) {

  const checkAdminMode = (item, index) => {
    if(window.innerWidth < 768) return null
    const orgId = Controller.get().roleHelper().getOrgId()
    if (Controller.get().userMgr().isProductSU() ||
      Controller.get().userMgr().hasOrgRole(orgId, 'SUPERUSER') ||
      Controller.get().userMgr().hasOrgRole(orgId, 'MANAGER') ||
      Controller.get().userMgr().hasOrgRole(orgId, 'CREATOR')) {
      return <Grid key={index} lg={2} xs={6} sm={3} md={2} item >
        <div className={css(Styles.linkItemContent)}>
          <p className={css(Styles.linkItem)} onClick={() => Controller.get().pushRoute(Controller.get().roleHelper().getHome())}>{item.title}</p>
        </div>
      </Grid>
    }
    return null
  }

  const data = props.data
  return (
    <div className={css(props.borderContainer)}>
      {data && data.length > 0 &&
        <Grid container>
          {data.map((item, index) => {
            return (
              item.key === 'adminMode' ?
                checkAdminMode(item, index)
                :
                <Grid key={index} lg={2} xs={6} sm={3} md={2} item >
                  <div className={css(Styles.linkItemContent)}>
                    <p className={css(Styles.linkItem)} onClick={() => Controller.get().courseMgr().linkItemClick(item)}>{item.title}</p>
                  </div>
                </Grid>
            )
          })}
        </Grid>
      }

    </div >

  )
}

const Styles = StyleSheet.create({
  linkItemContent: {
    display: 'flex',
    '@media(max-width: 768px)': {
      marginBottom: 15
    }
  },
  linkItem: {
    fontSize: 14,
    color: AppTheme.primaryColor,
    margin: AppCommonTheme.pMargin,
    ':hover': {
      cursor: 'pointer'
    },
  }
})