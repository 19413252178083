import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../../common/helpers/Helper';
import { Controller } from '../../common/Controller';
import { AppCommonTheme } from '../../styles/Styles';
import { AppTheme } from '../../styles/AppTheme';

export function CategoryTile(props) {
  const item = props.item
  const bgImage = item.image ? item.image : require('../../assets/images/default_category_img.svg')
  return (
    <div onClick={() => Controller.get().courseMgr().navToCategory(item.slug, props.backButton)}
      className={css(Styles.imgContainer)}
      style={{
        background: `linear-gradient(180deg, rgba(18, 18, 18, 0) 30%, rgba(18, 18, 18, 0.8) 85.21%), url(${bgImage})`,
        height: props.categories ? 260 : 200 }}>
      <div className={css(Styles.innerContainer)}>
        <div className={css(Styles.titleContainer)}>
          {item.title &&
            <p className={css(Styles.title)}>{item.title}</p>
          }
        </div>
      </div>
    </div>
  )
}

const Styles = StyleSheet.create({
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    borderRadius: AppCommonTheme.borderRadius,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ':hover': {
      cursor: 'pointer'
    },
    '@media (max-width: 767px)': {
      height: 120,
    }
  },
  titleContainer: {
    display: 'grid'
  },
  title: {
    color: AppTheme.secondaryBg,
    fontSize: 26,
    margin: AppCommonTheme.pMargin,
    padding: 20,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      fontSize: 18,
      padding: '14px 12px'
    }
  },
})