import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme';
import { CommonStyles } from '../styles/Styles';
import { DrawerMenuPublic } from './DrawerMenuPublic';
import { AppHelper } from '../helpers/AppHelper';

export function PublicHeader(props) {
  const [state, setState] = useState({
  })

  return (
    <Fragment>
      <AppBar className={css(Styles.appbar)} color='primary' elevation={0}>
        <Toolbar className={css(Styles.toolbar)}>
          <div className={css(Styles.logoContainer)}>
            <Link to={'/'}>
              <img src={AppConfig.LOGO}
                alt={AppConfig.NAME} className={css(CommonStyles.logoImage)} />
            </Link>
          </div>

          <div className={css(Styles.fillSpace)}></div>

        </Toolbar>
      </AppBar>
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  appbar: {
    // display: 'none',
    position: 'unset',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: '#FFE8E8'
  },
  toolbar: {
    minHeight: AppTheme.toolbarHeight,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flex: 1,
    maxWidth: AppTheme.viewPort
  },
  logoContainer: {
    // flex: 1,
    textAlign: 'left',
    display: 'flex'
  },
  headerInfo: {
    fontSize: 14,
    color: 'black',
    textAlign: 'right'
  },
  headerTelephone: {
    fontSize: 14,
    textDecoration: 'none',
    color: 'black'
  },

  menuOuterContainer: {
    display: 'flex',
    position: 'fixed',
    right: 20,
    justifyContent: 'center',
    zIndex: 1000000000,
  },
  menuInnerContainer: {
    borderRadius: '50%',
    backgroundColor: AppTheme.secondaryColor,
    padding: 10,
    width: 28,
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '@media(max-width: 767px)': {
      width: 23,
      height: 23,
    },
  },
  expandMore: {
    color: AppTheme.secondaryBg
  },
  menuInnerContainerOpen: {
    backgroundColor: AppTheme.secondaryColor,
  },
  closeButtonContent: {
    borderRadius: '50%',
    backgroundColor: AppTheme.secondaryColor,
    color: AppTheme.secondaryBg,
    padding: 2,
    fontSize: 30
  },
  settingsContainer: {
    marginRight: 60,
  },
  fillSpace: {
    flex: 1
  }
})
