import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';

import { Controller } from '../Controller';
import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';
import { UiHelper } from '../helpers/UiHelper';
import { DynamicFormDialog } from './DynamicFormDialog';

import async from 'async';
import { AppTheme, AppStyles } from '../../styles/AppTheme';
import { Headerbar } from './Headerbar';
import { TableComponent } from './TableComponent';
import { SubEntityComponent } from './SubEntityComponent';
import { MessageDialog } from './MessageDialog';
import { BreadCrumbs } from './BreadCrumbs';
import { AppConfig } from '../../AppConfig';
import { CustomBaseEnityStyles } from '../../styles/CustomStyles';
import { ItemPreview } from './Preview/ItemPreview';
import { Cards } from './Cards';

export class BaseEntityPage extends Component {

  constructor(props) {
    super(props);
    this.orgId = this.props.match && this.props.match.params && this.props.match.params.orgId
    this.state = {
      items: [],
      fetchState: ApiHelper.State.LOADING,
      nextPageState: ApiHelper.State.READY,
      modalOpen: false,
      messageToShow: undefined,
      showPreview: false
    };
  }

  componentDidMount() {
    this.fetchItems();
  }

  showMessage(message) {
    this.setState({
      messageToShow: message
    })
  }

  fetchItems() {
    this.setState({
      fetchState: ApiHelper.State.LOADING
    })
    const fcns = [];
    for (const f of this.toFetch) {
      fcns.push(function (callback) {
        ApiHelper.call(f, callback);
      })
    }
    async.parallel(fcns, function (err, results) {
      if (err) {
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: Helper.getErrorMsg(err)
        })
        return
      }
      if (this.onFetchSuccess) {
        this.onFetchSuccess(results);
        this.sortResult(this.sortFields);
      }
      this.setState({
        fetchState: ApiHelper.State.READY
      })
    }.bind(this))
  }

  onFetchSuccess(results) {
    const result = results[0]
    if (!result) {
      return
    }
    if (result.fieldDef) {
      if (!this.formDefinition) {
        this.formDefinition = result.fieldDef;
      }
      this.fieldDef = result.fieldDef
    }
    this.handleNextpage(result)
    this.setState({
      items: this.state.items.concat(result.items),
    })
  }

  handleNextpage(result) {
    this.toFetchNext = Object.assign({}, this.toFetch[0])
    if (this.toFetch && this.toFetch[0] && this.toFetch[0].queryParams) {
      this.toFetchNext.queryParams = Object.assign({}, this.toFetch[0].queryParams)
    }
    if (!this.toFetchNext.queryParams) {
      this.toFetchNext.queryParams = {}
    }
    if (result.nextPage) {
      this.toFetchNext.queryParams.nextPage = result.nextPage
      this.toFetchNext.queryParams.limit = 1000 // we can fetch more on load more
    }
    this.setState({
      nextPage: result.nextPage,
      nextPageState: ApiHelper.State.READY
    })
  }

  sortResult(sortFields) {
    const currentResult = this.state.items;
    if (sortFields) {
      currentResult.sort(function (a, b) {
        for (let i = 0; i < sortFields.length; i++) {
          if (a[sortFields[i]] > b[sortFields[i]]) { return 1 }
          if (a[sortFields[i]] < b[sortFields[i]]) { return -1 }
        }
        return 0
      });
      this.setState({
        items: currentResult
      })
    }
  }

  getResult(results, key) {
    return results[this.toFetch.findIndex(i => i.key === key)]
  }

  renderBreadCrumbs() {
    return (
      <div className={css([AppStyles.entityContent, Styles.breadCrumbsContainer])}>
        <BreadCrumbs breadCrumbs={this.breadCrumbs} />
      </div>
    )
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this)
    } else {
      return this.readyView();
    }
  }

  closeSubEntityComponent = () => {
    this.setState({
      entityProps: null
    })
  }

  getEntityProps(current, entityAttrName) {
    const entityParams = this.subEntities[entityAttrName]
    return {
      entityAttrName,
      entityItem: current,
      ...entityParams
    }
  }

  switchSubView = (current, entityAttrName) => {
    this.setState({
      entityProps: this.getEntityProps(current, entityAttrName)
    })
  }

  readyView() {
    if (this.state.entityProps) {
      const { entityProps } = this.state
      const SubEntComp = this.SubEntityComponent || SubEntityComponent
      //this.SubEntityComponent is derived SubEntityComponent
      return (
        <SubEntComp
          onCloseSubEntityComponent={this.closeSubEntityComponent}
          {...entityProps}
        >
        </SubEntComp>
      )
    }
    if (this.renderCustomReady) {
      return this.renderCustomReady()
    }

    return (
      <div className={css(Styles.entityContainer)}>
        <div className={css(Styles.row)}>
          {this.renderHeaderbar()}
        </div>
        <div className={css(Styles.row)}>
          {this.renderAboveTable()}
        </div>
        <div className={css(Styles.row)}>
          {this.renderTable()}
        </div>
        <div className={css(Styles.row)}>
          {this.renderBelowTable()}
        </div>
        <div className={css(Styles.row)}>
          {this.renderCard()}
        </div>
        {this.state.modalOpen ? this.renderModal() : ''}
        {this.state.messageToShow ? this.renderMessageModal() : ''}
        {this.state.showPreview ? this.renderPreviewModal() : ''}
      </div>
    );
  }

  renderHeaderbar() {
    const title = this.pageTitle ? this.pageTitle : null
    const pageKey = this.pageKey ? this.pageKey : null
    let actions = []
    if (this.headerActions) {
      actions = this.headerActions
    } else if (!this.noAdd) {
      actions = [{
        label: this.addNewLabel || 'Add new',
        onClick: () => this.openModalForAdd()
      }]
    }
    if (this.export) {
      actions.push({
        label: 'Export',
        icon: 'file_download',
        onClick: () => this.onExport()
      })
    }
    if (this.conf && this.conf.previewPath) {
      actions.push({
        tooltip: Helper.getString('preview'),
        icon: 'desktop_windows',
        type: 'icon',
        onClick: () => this.openNewWindow()
      })
    }
    return (
      <Headerbar
        pageKey={pageKey}
        title={title} actions={actions}
        actionComponents={this.state.headerActionComponents}
        breadCrumbs={this.breadCrumbs}
      />
    )
  }

  onExport() {
    if (this.export && this.export.format === 'xlsx') {
      const DwnldUtil = require('../helpers/FileDownloader');

      const fileName = this.makeFileName();
      const rows = this.prepExcelDownload()
      DwnldUtil.FileDownloader.exportAsExcelFile(rows, fileName)
    }
  }

  makeFileName = () => {
    return 'doc-excel';
  }

  prepExcelDownload() {
    const rows = [];

    for (const item of this.state.items) {
      const e = {};
      for (const hKey in this.export.headers) {
        e[this.export.headers[hKey]] = this.exportRowItemValue(hKey, item)
      }
      rows.push(e);
    }
    return rows
  }

  exportRowItemValue(key, current) {
    switch (key) {
    case '__index':
      return current.__index + 1
    default:
      return current[key]
    }
  }

  openModalForAdd(fieldDef) {
    this.formDefinition = fieldDef || this.fieldDef || this.formDefinition
    this.formDefinition.formType = 'add'
    Helper.deleteFormDefinitionValues(this.formDefinition)
    this.onAdd();
    this.setState({
      modalOpen: true
    });
  }

  openModalForEdit(current, fieldDef) {
    console.log('edit:', current);
    this.formDefinition = fieldDef || this.fieldDef || this.formDefinition
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  openModalForSEO(current) {
    console.log('openModalForSEO:', current);
    this.formDefinition = this.fieldDefSEO
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  openModalForApproval(current, fieldDef) {
    console.log('approve:', current);
    this.formDefinition = fieldDef || this.fieldDef || this.formDefinition
    this.formDefinition.formType = 'approve'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onApprove(current);
    this.setState({
      modalOpen: true
    });
  }

  openModalForDelete(current, fieldDef) {
    this.formDefinition = fieldDef || this.fieldDef || this.formDefinition
    this.formDefinition.formType = 'delete'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onDelete(current);
    this.setState({
      modalOpen: true
    });
  }

  renderModal() {
    return (
      <DynamicFormDialog
        apiMethod={this.state.apiMethod}
        apiEndPoint={this.state.apiEndPoint}
        uploadLocation={this.uploadLocation || this.orgId}
        customSubmit={this.customSubmit && this.customSubmit.bind(this)}
        apiData={this.state.apiData || this.apiData}
        formDefinition={this.formDefinition}
        customFormComponents={this.customFormComponents}
        isOpen={this.state.modalOpen}
        onClosed={() => this.setState({ modalOpen: false })}
        onCompleteAction={(options) => {
          this.setState({ modalOpen: false, items: [], dialogApiData: options.apiInvokeData })
          this.fetchItems()
        }
        }

        fullWidthDialog={this.fullWidthDialog}
        hideFormAction={this.hideFormAction}
      >
      </DynamicFormDialog>
    );

  }

  renderMessageModal() {
    return (
      <MessageDialog
        title={'Attention!'}
        isOpen={this.state.messageToShow ? true : false}
        message={this.state.messageToShow}
        onClosed={() => this.setState({ messageToShow: undefined })}
      />
    )
  }

  renderPreviewModal() {
    return (
      <ItemPreview
        title={Helper.getString('preview')}
        isOpen={this.state.showPreview ? true : false}
        onClosed={() => this.setState({ showPreview: false })}
        previewPath={this.conf.previewPath}
        orgId={this.orgId}
      />
    )
  }

  renderAboveTable() {
    return ('')
  }

  renderTable() {
    if (!this.onTableValue) {
      return ''
    }
    return (
      <TableComponent
        tableHeaders={this.tableHeaders}
        noTableHead={this.noTableHead || false}
        onTableValue={this.onTableValue ? this.onTableValue.bind(this) : this.onTableValue}
        tableHeadersMap={this.tableHeadersMap}
        items={this.state.items}
        setItems={this.setItems}
        headerActions={this.headerActions} //Multi checkbox
        onActions={this.onActions.bind(this)}
        onMenuItems={this.onMenuItems && this.onMenuItems.bind(this)}
        nextPage={this.state.nextPage}
        nextPageState={this.state.nextPageState}
        onNext={this.onNext.bind(this)}
        emptyText={this.emptyText}
        cellWidths={this.cellWidths}
      />
    )
  }

  renderBelowTable() {
    return ('')
  }

  renderCard() {
    if (!this.onCardValue) {
      return ''
    }
    return (
      <Cards
        items={this.state.items}
        onActions={this.onActions.bind(this)}
        onMenuItems={this.onMenuItems && this.onMenuItems.bind(this)}
        nextPage={this.state.nextPage}
        nextPageState={this.state.nextPageState}
        onNext={this.onNext.bind(this)}
        onCardValue={this.onCardValue ? this.onCardValue.bind(this) : this.onCardValue}
        emptyText={this.emptyText} />
    )
  }


  fetchNext() {
    const fcns = [];
    const tofetch = []
    tofetch.push(this.toFetchNext)
    for (const f of tofetch) {
      fcns.push(function (callback) {
        ApiHelper.call(f, callback);
      })
    }
    async.parallel(fcns, function (err, results) {
      if (err) {
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: Helper.getErrorMsg(err)
        })
        return
      }
      this.onFetchSuccess(results, { fetchNext: true })
    }.bind(this))
  }

  onNext = () => {
    this.setState({ nextPageState: ApiHelper.State.LOADING })
    this.fetchNext()
  }

  setItems = (itemsList) => {
    this.setState({ items: itemsList })
  }

  baseRoute() {
    return UiHelper.baseRoute(this.orgId)
  }

  openNewWindow = () => {
    window.open('/admin/orgs/' + this.orgId + '/preview?ppath=' + this.conf.previewPath, 'slixtaPreivew');
  }
}
const Styles = (AppConfig.CUSTOM_STYLE) ? CustomBaseEnityStyles : StyleSheet.create({
  entityContainer: {
    marginTop: AppTheme.headerbarHeight,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: AppTheme.primaryBg,
    marginLeft: 60,
    marginRight: 60,
    maxWidth: AppTheme.viewPort,
    '@media (max-width: 767px)': {
      marginLeft: 10,
      marginRight: 10,
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  breadCrumbsContainer: {
    alignItems: 'flex-start',
    paddingBottom: 0
  },
  header: {
    marginBottom: 30
  }
})
