import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { StyleSheet, css } from 'aphrodite';

import { Controller } from './common/Controller';
import { AppTheme, AppStyles } from './styles/AppTheme';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { NoPermission } from './common/components/NoPermission';

import { DashboardAdmin } from './screens/admin/dashboard/DashboardAdmin';
import { DashboardOrg } from './screens/admin/dashboard/DashboardOrg';
import { UsersOfOrg } from './common/components/UsersOfOrg';

import { NotFound } from './screens/NotFound';
import { Terms } from './screens/Terms';
import { Privacy } from './screens/Privacy';
import { UiHelper } from './common/helpers/UiHelper';
import { Home } from './screens/Home';
import { LearnerHeader } from './components/LearnerHeader'
//import { PublicFooter } from './components/PublicFooter'
import { LoginPage } from './screens/LoginPage';

import { AppConfig } from './AppConfig';
import { AppHelper } from './helpers/AppHelper';
import { Orgs } from './common/components/Orgs';
import { CatsL1 } from './common/components/course/CatsL1';
import { CatsL2 } from './common/components/course/CatsL2';
import { CatsL3 } from './common/components/course/CatsL3';
import { CoursesByCat } from './common/components/course/CoursesByCat';
import { Courses } from './common/components/course/Courses';
import { CourseEdit } from './common/components/course/CourseEdit';
import { CourseSections } from './common/components/course/CourseSections';
import { CourseChapters } from './common/components/course/CourseChapters';
import { Questions } from './common/components/quizing-simple/Questions';
import { QuestionOptions } from './common/components/quizing-simple/QuestionOptions';
import { OrgCourses } from './common/components/course/OrgCourses';
import { OrgCourseEdit } from './common/components/course/OrgCourseEdit';
import { CourseFaqs } from './common/components/course/CourseFaqs';
import { CoursePreview } from './common/components/course/CoursePreview';
import { ChapterPreview } from './common/components/course/ChapterPreview';
import { OrgTeams } from './common/components/OrgTeams';
import { CoursePeople } from './common/components/course/CoursePeople';
import { OrgCatalog } from './common/components/course/OrgCatalog';

import { Chapter } from './screens/Chapter';
import { Dashboard } from './screens/Dashboard';
import { AppCommonTheme } from './styles/Styles';
import { Explore } from './screens/Explore';
import { CoursesList } from './screens/CoursesList';
import { Category } from './screens/Category';
import { Resources } from './screens/Resources';
import { Favourites } from './screens/Favourites';
import { Profile } from './screens/Profile';
import { SearchScreen } from './screens/SearchScreen';
import { Assessment } from './screens/Assessment';
import { Course } from './screens/Course';
import { Categories } from './screens/Categories';
import { ResourcesDetail } from './screens/ResourcesDetail';
import { Certificate } from './screens/Certificate';
import { Result } from './screens/Result';
import { Feedback } from './screens/Feedback';
import { Accolades } from './screens/Accolades';
import { CoursesPage } from './screens/CoursesPage';
import { CourseResources } from './common/components/course/CourseResources';
import { CourseKnowledgeCheck } from './common/components/course/CourseKnowledgeCheck';
import { CoursesByCatWrapper } from './components/CoursesByCatWrapper';
import { CoursesByTeamsWrapper } from './components/CoursesByTeamsWrapper';
import { UsersOfDepartment } from './components/UsersOfDepartment';
import { QuizQuestionsWrappper } from './components/QuizQuestionsWrappper';
import { PublicHeader } from './components/PublicHeader'
import { TermsWrapper } from './screens/TermsWrapper';
import { PrivacyWrapper } from './screens/PrivacyWrapper';
import { ConsentsPage } from './screens/ConsentsPage';
import { Jobs } from './screens/Jobs';
import { CourseFeedback } from './common/components/course/CourseFeedback';
import { TermsAndConditions } from './screens/TermsAndConditions';
import { TermsAndConditionsWrapper } from './screens/TermsAndConditionsWrapper';
import { Message } from './screens/Message';
import { CoursesOfUser } from './common/components/CoursesOfUser';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppTheme.primaryColor
    },
    secondary: {
      main: AppTheme.secondaryColor
    }
  },
  typography: {
    fontFamily: [
      'Raleway',
      'sans-serif'
    ].join(',')
  }
});

const PublicRoute = (props) => {
  if (Controller.get().loginMgr().getCurrentUser()) {
    if (props && props.path === '/login') {
      return <Redirect to={Controller.get().getHomeByRole()} />
    }
  }
  return <Route {...props} />
}

const ProtectedRoute = (props) => {
  if (Controller.get().loginMgr().getCurrentUser()) {
    if (props.path === '/') {
      return <Redirect to={Controller.get().roleHelper().getHome()} />
    }
    if (props.userLoaded) {
      if (window.innerWidth < 768 && !props.path.startsWith('/learner')) {
        return <Redirect
          to={{
            pathname: '/learner/message',
            state: { redirectedfrom: props.path }
          }}
        />
      } else {
        return <Route {...props} />
      }
    }
    if (props.noPermission) {
      return <Redirect
        to={{
          pathname: '/no-permission',
          state: { redirectedfrom: props.path }
        }}
      />
    }
    Controller.get().loginMgr().bootstrap((err, data) => {
      if (err) {
        console.error('bootstrap err:', err)
        props.setNoPermission()
        return
      }

      if (props.path.startsWith('/admin/orgs')) {
        // Any path starts with /admin/orgs - users  has to have a role in that Org or a user of PRODUCT
        if (!Controller.get().userMgr().isProductSU() &&
          !Controller.get().userMgr().hasOrgRole(props.computedMatch.params.orgId, 'SUPERUSER') &&
          !Controller.get().userMgr().hasOrgRole(props.computedMatch.params.orgId, 'MANAGER') &&
          !Controller.get().userMgr().hasOrgRole(props.computedMatch.params.orgId, 'CREATOR')) {
          props.setNoPermission()
          return
        }
      } else if (props.path.startsWith('/admin')) {
        // Any path starts with Admin - users has to have a role in PRODUCT Org
        if (!Controller.get().userMgr().isInOrg('PRODUCT')) {
          props.setNoPermission()
          return
        }
      }
      props.setUserLoaded && props.setUserLoaded()
    })
    return UiHelper.componentLoadingView()
  } else {
    return <Redirect
      to={{
        pathname: '/login',
        state: { redirectedfrom: props.path }
      }}
    />
  }
}

class App extends Component {

  constructor(props) {
    //console.log('App::constructor')
    super(props);
    this.state = {
      currentUser: Controller.get().loginMgr().getCurrentUser()
    };
  }

  isPublic = () => {
    const pName = window.location.pathname
    if (pName.startsWith('/admin')) {
      return false
    }
    return true
  }

  isLearnerPublic = () => {
    const pName = window.location.pathname
    if (pName.startsWith('/learner')) {
      return false
    }
    return true
  }

  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <Route path={'/'} component={({ location, history }) => {
            return (
              <div className={(this.isPublic() && this.isLearnerPublic()) ? css(Styles.pagePublic) : css(Styles.page)} style={this.isPublic() ? { backgroundColor: AppTheme.secondaryBg } : { backgroundColor: AppTheme.secondaryBg }}>
                {!this.isPublic() && <Header
                  location={location}
                  className={css(Styles.header)}
                  currentUser={this.state.currentUser}
                  onDashboardClick={() => this.onDashboardClick()}
                  onLogoutClick={() => this.onLogoutClick()} />}
                {!this.isLearnerPublic() && <LearnerHeader //TODO the url check
                  location={location}
                  className={css(Styles.header)}
                  currentUser={this.state.currentUser}
                  onDashboardClick={() => this.onDashboardClick()}
                  onLogoutClick={() => this.onLogoutClick()} />}
                {(this.isPublic() && !window.location.pathname.startsWith('/login') && this.isLearnerPublic()) && <PublicHeader />}
                <div className={css(Styles.content)}>
                  {this.renderRoute(history)}
                </div>
                {/* {this.isPublic() && <PublicFooter />} */}

              </div>
            )
          }}
          />
        </MuiThemeProvider>
      </Router>
    );
  }

  setUserLoaded = () => {
    this.setState({ userLoaded: true });
  };

  setNoPermission = () => {
    this.setState({ noPermission: true });
  };

  getProtectedRoute(path, component) {
    return (
      <ProtectedRoute
        userLoaded={this.state.userLoaded}
        setUserLoaded={this.setUserLoaded}
        noPermission={this.state.noPermission}
        setNoPermission={this.setNoPermission}
        exact
        path={path}
        component={component}
      />
    );
  }

  renderRoute(history) {
    Controller.get().setNavigator(history);
    return (
      <Switch>
        {this.getProtectedRoute('/admin', Orgs)}
        {this.getProtectedRoute('/admin/users', UsersOfOrg)}
        {this.getProtectedRoute('/admin/dashboard', DashboardAdmin)}

        {this.getProtectedRoute('/admin/orgs/:orgId', DashboardOrg)}
        {this.getProtectedRoute('/admin/orgs/:orgId/teams', OrgTeams)}
        {this.getProtectedRoute('/admin/orgs/:orgId/teams/:id/users', UsersOfDepartment)}
        {this.getProtectedRoute('/admin/orgs/:orgId/users', UsersOfOrg)}
        {this.getProtectedRoute('/admin/orgs/:orgId/users/:userId/courses', CoursesOfUser)}

        {this.getProtectedRoute('/admin/orgs/:orgId/cats', CatsL1)}
        {/* {this.getProtectedRoute('/admin/orgs/:orgId/cats/:idl1', CatsL2)}
        {this.getProtectedRoute('/admin/orgs/:orgId/cats/:idl1/:idl2', CatsL3)} */}
        {this.getProtectedRoute('/admin/orgs/:orgId/cats/:catId/courses', CoursesByCatWrapper)}
        {this.getProtectedRoute('/admin/orgs/:orgId/teams/:catId/courses', CoursesByTeamsWrapper)}

        {this.getProtectedRoute('/admin/courses', Courses)}
        {this.getProtectedRoute('/admin/orgs/:orgId/courses', OrgCourses)}

        {this.getProtectedRoute('/admin/orgs/:orgId/Jobs', Jobs)}

        {this.getProtectedRoute('/admin/orgs/:orgId/catalog', OrgCatalog)}

        {this.getProtectedRoute('/admin/courses/:id/details', CourseEdit)}
        {this.getProtectedRoute('/admin/orgs/:orgId/orgcourses/:id/details', OrgCourseEdit)}

        {this.getProtectedRoute('/admin/courses/:id/sections', CourseSections)}
        {this.getProtectedRoute('/admin/orgs/:orgId/courses/:id/sections', CourseSections)}

        {this.getProtectedRoute('/admin/courses/:id/:sectionId/chapters', CourseChapters)}
        {this.getProtectedRoute('/admin/orgs/:orgId/courses/:id/:sectionId/chapters', CourseChapters)}

        {this.getProtectedRoute('/admin/orgs/:orgId/courses/:id/people', CoursePeople)}

        {this.getProtectedRoute('/admin/orgs/:orgId/courses/:id/feedbacks', CourseFeedback)}

        {this.getProtectedRoute('/admin/courses/:id/assessment', Questions)}
        {this.getProtectedRoute('/admin/orgs/:orgId/courses/:id/assessment', Questions)}

        {this.getProtectedRoute('/admin/courses/:id/faqs', CourseFaqs)}
        {this.getProtectedRoute('/admin/orgs/:orgId/courses/:id/faqs', CourseFaqs)}

        {this.getProtectedRoute('/admin/courses/:id/resources', CourseResources)}
        {this.getProtectedRoute('/admin/orgs/:orgId/courses/:id/resources', CourseResources)}

        {this.getProtectedRoute('/admin/quiz/:id', QuizQuestionsWrappper)}
        {this.getProtectedRoute('/admin/orgs/:orgId/quiz/:id', QuizQuestionsWrappper)}

        {this.getProtectedRoute('/admin/knowledgechecks/:id', CourseKnowledgeCheck)}
        {this.getProtectedRoute('/admin/orgs/:orgId/knowledgechecks/:id', CourseKnowledgeCheck)}

        {this.getProtectedRoute('/admin/quiz/:quizId/questions/:id/options', QuestionOptions)}
        {this.getProtectedRoute('/admin/orgs/:orgId/quiz/:quizId/questions/:id/options', QuestionOptions)}

        {this.getProtectedRoute('/admin/courses/preview/:slug', CoursePreview)}
        {this.getProtectedRoute('/admin/orgs/:orgId/courses/preview/:slug', CoursePreview)}

        {this.getProtectedRoute('/admin/courses/preview/:slug/:chapterId', ChapterPreview)}
        {this.getProtectedRoute('/admin/orgs/:orgId/courses/preview/:slug/:chapterId', ChapterPreview)}

        {/* {this.getProtectedRoute('/courselearn/:id', Course)} */}
        {this.getProtectedRoute('/courselearn/:id/:chapterId', Chapter)}

        {this.getProtectedRoute('/learner/orgs/:orgId/dashboard', Dashboard)}
        {this.getProtectedRoute('/learner/orgs/:orgId/explore', Explore)}
        {this.getProtectedRoute('/learner/orgs/:orgId/mycourses', CoursesList)}
        {this.getProtectedRoute('/learner/orgs/:orgId/categories', Categories)}
        {this.getProtectedRoute('/learner/orgs/:orgId/categories/:slug', Category)}
        {this.getProtectedRoute('/learner/orgs/:orgId/resources', Resources)}
        {this.getProtectedRoute('/learner/orgs/:orgId/resources/:id', ResourcesDetail)}
        {this.getProtectedRoute('/learner/orgs/:orgId/favourites', Favourites)}
        {this.getProtectedRoute('/learner/orgs/:orgId/profile', Profile)}
        {this.getProtectedRoute('/learner/orgs/:orgId/search', SearchScreen)}
        {this.getProtectedRoute('/learner/orgs/:orgId/course/:slug/assessment', Assessment)}
        {this.getProtectedRoute('/learner/orgs/:orgId/course/:slug/result', Result)}
        {this.getProtectedRoute('/learner/orgs/:orgId/course/:slug/consent', ConsentsPage)}
        {this.getProtectedRoute('/learner/orgs/:orgId/course/:slug/feedback', Feedback)}
        {this.getProtectedRoute('/learner/orgs/:orgId/course/:slug/:chapterId', Chapter)}
        {this.getProtectedRoute('/learner/orgs/:orgId/course/:slug', Course)}
        {this.getProtectedRoute('/learner/orgs/:orgId/:slug/:aid/certificate', Certificate)}
        {this.getProtectedRoute('/learner/orgs/:orgId/accolades', Accolades)}
        {this.getProtectedRoute('/learner/orgs/:orgId/courses', CoursesPage)}

        {this.getProtectedRoute('/learner/terms-of-use', TermsWrapper)}
        {this.getProtectedRoute('/learner/terms-and-conditions', TermsAndConditionsWrapper)}
        {this.getProtectedRoute('/learner/privacy-policy', PrivacyWrapper)}

        {this.getProtectedRoute('/learner/message', Message)}

        {/*
        <PublicRoute exact path={'/courselearn/:id'} component={Course} />
        <PublicRoute exact path={'/courselearn/:id/:chapterId'} component={Chapter} />

        <PublicRoute exact path={'/dashboard'} component={Dashboard} />
        <PublicRoute exact path={'/explore'} component={Explore} />
        <PublicRoute exact path={'/mycourses'} component={CoursesList} />
        <PublicRoute exact path={'/categories'} component={Categories} />
        <PublicRoute exact path={'/categories/:slug'} component={Category} />
        <PublicRoute exact path={'/resources'} component={Resources} />
        <PublicRoute exact path={'/resources/:id'} component={ResourcesDetail} />
        <PublicRoute exact path={'/favourites'} component={Favourites} />
        <PublicRoute exact path={'/profile'} component={Profile} />
        <PublicRoute exact path={'/search'} component={Search} />
        <PublicRoute exact path={'/course/:slug/assessment'} component={Assessment} />
        <PublicRoute exact path={'/course/:slug/result'} component={Result} />
        <PublicRoute exact path={'/course/:slug/feedback'} component={Feedback} />
        <PublicRoute exact path={'/course/:slug/:chapterId'} component={Chapter} />
        <PublicRoute exact path={'/course/:slug'} component={Course} />
        <PublicRoute exact path={'/certificate'} component={Certificate} />
        <PublicRoute exact path={'/accolades'} component={Accolades} />
        <PublicRoute exact path={'/courses'} component={CoursesPage} /> */}

        <PublicRoute exact path={'/no-permission'} component={NoPermission} />
        <PublicRoute exact path={'/login'} component={LoginPage} />
        <PublicRoute exact path={'/terms-of-use'} component={Terms} />
        <PublicRoute exact path={'/terms-and-conditions'} component={TermsAndConditions} />
        <PublicRoute exact path={'/privacy-policy'} component={Privacy} />


        {this.getProtectedRoute('/', Home)}
        <PublicRoute component={NotFound} />
      </Switch>
    )
  }

  onLogoutClick() {
    //console.log('onLogoutClick');
    Controller.get().loginMgr().signOut();
    this.setState({
      currentUser: null
    })
  }

  onDashboardClick() {
    this.props.history.push('/admin');
  }
}

const Styles = StyleSheet.create({
  pagePublic: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  page: {
    display: 'flex',
    position: 'relative',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  header: {
    flex: '0 0 auto'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    overflowX: 'hidden',
    flex: 1
  },
  footer: {
    flex: '0 0 auto'
  },
  drawerFiller: {
    width: AppTheme.drawerWidth,
    '@media (max-width: 767px)': {
      width: 'unset'
    }
  }
})

export default App;
