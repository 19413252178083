import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, CircularProgress } from '@material-ui/core';
import { AppTheme } from '../styles/AppTheme';
import { AppCommonTheme } from '../styles/Styles';

export function AppButton(props) {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.inProcess}
      className={css(Styles.buttonContainer, props.buttonContainer)}>
      {!props.inProcess &&
        <div className={css(Styles.buttonTextContainer, props.buttonTextContainer)}>
          <p className={css(Styles.buttonText, props.buttonTextStyle)}>{props.buttonText}</p>
          {props.img &&
          <img
            alt={props.imgAlt}
            width='20px' height='20px'
            src={require(`../assets/images/${props.img}.svg`)}
            className={css(Styles.imgContent, props.imgContent)} />
          }
        </div>
      }
      {props.inProcess &&
        <CircularProgress size={20} className={css(Styles.progressIcon)} />
      }
    </Button>
  )
}

const Styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: 6,
    minWidth: 123,
    backgroundColor: AppTheme.primaryColor,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    minHeight: 44
  },
  buttonTextContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonText: {
    fontSize: 16,
    margin: AppCommonTheme.pMargin,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg,
    fontWeight: 600,
    '@media(max-width: 767px)': {
      fontSize: 14,
    }
  },
  imgContent: {
    width: 20,
    height: 20,
    marginLeft: 8,
    '@media (max-width: 767px)': {
      width: 16,
      height: 16,
      marginLeft: 4
    },
  },
  progressIcon: {
    color: AppTheme.secondaryBg
  }
})