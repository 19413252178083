import React, { Fragment } from 'react'
import { StyleSheet, css } from 'aphrodite';
import { AppCommonTheme } from '../styles/Styles';
import { CommonStyles } from '../styles/Styles';
import { AppButton } from './AppButton';
import { AppTheme } from '../styles/AppTheme';
import { Player } from '@lottiefiles/react-lottie-player';
import { Helper } from '../common/helpers/Helper';

export const TitleComponent = (props) => {

  const buttonHandler = () => {
    if(props.nextButton) {
      return nextButton()
    }
    if(props.buttons && props.buttons.length === 1) {
      return <AppButton
      onClick={props.buttons[0].onClick}
      buttonContainer={Styles.buttonContainer}
      buttonText={props.buttons[0].title}
      inProcess={props.inProcess}
    />
    }
    return (
      <Fragment>
        {props.buttons[0] &&
          <AppButton
            onClick={props.buttons[0].onClick}
            buttonContainer={[CommonStyles.secondaryButtonContainer, Styles.secondaryButtonContainer]}
            buttonTextStyle={Styles.buttonTextStyle}
            buttonText={props.buttons[0].title}
            inProcess={props.inProcess}
          />
        }
        {props.buttons[1] &&
          <AppButton
            onClick={props.buttons[1].onClick}
            buttonContainer={Styles.buttonContainer}
            buttonText={props.buttons[1].title}
            inProcess={props.inProcess}
          />
        }
      </Fragment>
    )
  }

  const nextButton = () => {
    return <div className={css(Styles.nextBtnContainer)}>
      <div
      className={css(Styles.secondaryButtonContainer)}
      onClick={props.nextButton.action}>
      <p className={css(CommonStyles.secondaryButtonText, Styles.secondaryButtonText)}>{props.nextButton.title}</p>
      <img
        src={require('../assets/images/double_arrow_right_icon.svg')}
        className={css(Styles.arrowImgContent)} width='16px' height='16px'
        alt={Helper.getString('buttonArrowImgAlt')} />
    </div>
    </div>
  }


  return (
    <Fragment>
      <div className={css(Styles.outerContainer)}>
        <div className={css(Styles.titleContainer)}>
          <h1 className={css(Styles.title)}>{props.title}</h1>
        </div>
        <div className={css(Styles.iconContainer)}>
          {props.icons && !props.preview &&
            props.icons.map((items, index) => {
              return (
                (props.inProgress && items.key === 'fav') ?
                  <div className={css(Styles.iconInnerContainer)} key={index}>
                    <Player
                      autoplay
                      loop
                      src={require('../assets/images/circle_loading_progress_indicator.json')}
                      className={css(Styles.downloadImgContentMobile)} />
                  </div>
                  :
                  <div className={css(Styles.iconInnerContainer)} key={index} onClick={() => items.onSelected()}>
                    <img src={require(`../assets/images/${items.imgName}.svg`)} width='16px' height='16px' className={css(Styles.iconContent)} />
                  </div>
              )
            })}
          {(props.buttons || props.nextButton) &&
            <div className={css(Styles.buttonContainerDesktop)}>
              {buttonHandler()}
            </div>
          }
        </div>
      </div>
      {(props.buttons || props.nextButton) &&
        <div className={css(Styles.buttonContainerMobile)}>
          {buttonHandler()}
        </div>
      }
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    marginRight: 15
  },
  title: {
    color: AppCommonTheme.appFontColor,
    fontSize: 32,
    margin: AppCommonTheme.pMargin,
    '@media (max-width: 767px)': {
      fontSize: 24,
    }
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  iconInnerContainer: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: AppCommonTheme.border,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    cursor: 'pointer'
  },
  iconContent: {
    width: 16,
    height: 16
  },
  buttonContainer: {
    minWidth: 187,
    padding: '8px 20px',
    marginLeft: 24,
    '@media (max-width: 767px)': {
      padding: '8px 15px',
      marginLeft: 0,
      marginRight: 18
    },
  },

  buttonContainerDesktop: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'none'
    },
  },
  buttonContainerMobile: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      marginTop: 24
    },
  },
  buttonTextStyle: {
    color: AppTheme.primaryColor,
    textDecoration: 'underline',
    textUnderlineOffset: 1,
  },
  secondaryButtonContainer: {
    padding: 0,
    '@media (max-width: 767px)': {
      padding: 0,
    },
  },

  downloadImgContentMobile: {
    width: 16,
    height: 16
  },
  secondaryButtonText: {
    fontSize: 14,
    marginRight: 8
  },
  secondaryButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer'
    }
  },
  arrowImgContent: {
    width: 16,
    height: 16
  },
  nextBtnContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  }
})
