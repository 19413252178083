import React, { Fragment, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppButton } from './AppButton';
import { AppHelper } from '../helpers/AppHelper';
import { ErrorMsg } from './ErrorMsg';

export function File(props) {
  const [state, setState] = useState({
    selectedQuestion: 0,
    showAnswer: false,
    selectedChecklist: {}
  })


  const closeError = () => {
    setState({
      ...state,
      openError: false
    })
  }

  return (
    <Fragment>
      <ErrorMsg
        open={state.openError}
        errMsg={state.errorMsg}
        closeError={closeError}
      />
      <AppButton
        //onClick={() => AppHelper.downloadFile(props.link)}
        onClick={() => setState({
          ...state,
          openError: true,
          errorMsg: Helper.getString('comingSoon')
        })}
        buttonContainer={Styles.buttonContainer}
        buttonText={Helper.getString('download')}
        img={'download_icon_light'}
        imgAlt={Helper.getString('downloadImgAlt')}
      />
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  buttonContainer: {
    padding: '8px 20px',
    '@media (max-width: 767px)': {
      padding: '8px 15px',
    },
  }
})