import React, { useContext } from 'react';
import { Controller } from '../Controller';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { MockData } from '../../data/MockData'
import { UserContext } from '../../common/components/contexts/UserContext';
import { Helper } from '../helpers/Helper';
import moment from 'moment-timezone';
import { UiHelper } from '../helpers/UiHelper';
import { AppHelper } from '../../helpers/AppHelper';

// const { user, userContextStateHandler } = useContext(UserContext)
export class CourseMgr {
  // static contextType = UserContext

  static Status = Object.freeze({
    NEW: 'New',
    PROGRESS: 'PROGRESS',
    COMPLETED: 'COMPLETEMARKED'
  });

  static CourseStatus = Object.freeze({
    COMPLETED: 'COMPLETED'
  });

  getDashboard = async (orgId, accessUserId) => {
    return await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'learnerdashboard'], { orgId: orgId, learnerId: accessUserId }),
    })
  }

  getUserDetails = async (orgId, userId) => {
    return await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['users', userId]),
    })
  }

  getExploreDetails = async (orgId) => {
    const resultData = await Promise.all([
      ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'ccats'], { orgId: orgId }),
      }),
      ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], { orgId: orgId }),
      }),
    ])
    const categorieItemLimit = 6
    const courseItemLimit = 4
    resultData[0].categorieItemLimit = categorieItemLimit
    resultData[0].viewMoreButtonTop = resultData[0].items.length > categorieItemLimit ? true : false
    resultData[1].courseItemLimit = courseItemLimit
    resultData[1].viewMoreButtonTop = resultData[1].items.length > courseItemLimit ? true : false

    const filteredCourses = []
    resultData[1].items.map(item => {
      if (item.discoveryMode === 'OPEN' && item.state === 'LIVE') {
        filteredCourses.push(item)
      }
    })
    resultData[1].items = filteredCourses

    return resultData
  }

  pushRoute(route) {
    Controller.get().pushRoute(route)
  }

  getCoursesList = async (orgId, accessUserId) => {
    return await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'learnerdashboard'], { orgId: orgId, learnerId: accessUserId }),
    })
  }

  getAccoladesDetails = async (orgId, accessUserId) => {
    return await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'learnercoursehistory'], { orgId: orgId, learnerId: accessUserId }),
    })
  }

  navToMyCourses = (state) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRoute({
      pathname: '/learner/orgs/' + orgId + '/mycourses',
      search: 'courses=' + state
    })
  }

  navToCategory = (slug, backButton) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRouteWithParams('/learner/orgs/' + orgId + '/categories/' + slug, backButton)
  }

  getCategory = async (orgId, slug) => {
    const resultData = await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], { orgId: orgId, catSlug: slug }),
    })

    const filteredCourses = []
    resultData.items.map(item => {
      if (item.discoveryMode === 'OPEN' && item.state === 'LIVE') {
        filteredCourses.push(item)
      }
    })
    resultData.items = filteredCourses

    return resultData
  }

  getResourceDetail = async (id, courseSlug) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    return await ApiHelper.callAwait({
      method: 'POST',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'resourcecontentaccess']),
      jsonBody: { orgId: orgId, slug: courseSlug, resourceId: id }
    })
  }


  getfavouritesDetails = async () => {
    // return await ApiHelper.callAwait({
    //   authWithKey: true,
    //   method: 'PUT',
    //   endPoint: ApiHelper.makeUrlPath(['miscs', 'favourites']),
    // })
    return MockData.favourites
  }

  navToFavourites = () => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRoute('/learner/orgs/' + orgId + '/favourites')
  }

  getProfileDetails = async () => {
    let result = await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['users', 'me'], {}),
    })
    result = {
      ...result,
    }
    return result
  }

  getSearchDetails = async (options) => {
    return await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'search'], { orgId: options.orgId, query: options.searchText }),
    })
  }

  getAssessment = async (courseSlug) => {
    return await MockData.assessment
    // return await ApiHelper.callAwait({
    //   authWithKey: true,
    //   method: 'PUT',
    //   endPoint: ApiHelper.makeUrlPath(['miscs', 'assessment']),
    // })
  }

  checkChapterStatus = (learnerCourseContext, chapterId) => {
    if (learnerCourseContext && learnerCourseContext.progress && learnerCourseContext.progress.items) {
      const chapterStatus = Object.keys(learnerCourseContext.progress.items).filter(i => i === 'chapter-' + chapterId)
      if (learnerCourseContext.progress.items[chapterStatus]) {
        return learnerCourseContext.progress.items[chapterStatus[0]].state
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  getCourseConsumptionDetails = async (orgId, courseSlug, chapterId, resultValue) => {
    const userId = Controller.get().userMgr().getUserId()
    let resultData = []
    if (resultValue === undefined) {
      const qParams = { orgId: orgId, slug: courseSlug }
      if (!Controller.get().roleHelper().isAllVisibleToBeAdded(orgId)) {
        qParams.allvisible = true
      }
      resultData = await Promise.all([
        ApiHelper.callAwait({
          method: 'GET',
          endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], qParams),
        })
      ])
    }

    if (resultValue !== undefined) {
      resultData[0] = resultValue
    }

    let learnerCourseContext = {}
    if (resultValue === undefined) {
      learnerCourseContext = await ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'learnercoursecontext'],
          { orgId: orgId, courseId: resultData[0].courseId, learnerId: userId }),
      })
    }


    let chapterContentLink
    const chapterType = chapterId && resultData[0].chapters[chapterId] && resultData[0].chapters[chapterId].iType

    if (chapterId && (chapterType === 'IMAGE' || chapterType === 'PDF' ||
      chapterType === 'SHORTVIDEO' || chapterType === 'FILE')) {
      const resultData2 = await Promise.all([ApiHelper.callAwait({
        method: 'POST',
        jsonBody: {
          orgId: (resultData && resultData[0].orgId) ? resultData[0].orgId : orgId,
          slug: (resultData && resultData[0].slug) ? resultData[0].slug : courseSlug,
          chapterId: chapterId
        },
        endPoint: ApiHelper.makeUrlPath(['miscs', 'contentaccess']),
      })])
      chapterContentLink = resultData2[0].link
    } else if (chapterId && chapterType === 'YOUTUBE') {
      chapterContentLink = resultData[0].chapters[chapterId].contentYt
    } else if (chapterId && chapterType === 'LINK') {
      chapterContentLink = resultData[0].chapters[chapterId].contentLink
    }

    const result = resultData[0]
    const content = []
    const sectionwiseChapters = {}
    const status = this.checkChapterStatus(learnerCourseContext, chapterId)

    let sortedChapters = Object.values(result.chapters).length === 1 ? result.chapters : []
    sortedChapters = (result.chapters && Object.values(result.chapters).length > 0) && Object.values(result.chapters).sort((a, b) => (result.sections[a.sectionId].order + a.order) - (result.sections[b.sectionId].order + b.order))
    {
      sortedChapters.length > 0 &&
        sortedChapters.map((chapter, chapterIndex) => {
          if (chapterIndex !== 0) {
            result.chapters[chapter.id].previousChapterId = sortedChapters[chapterIndex - 1].id
            result.chapters[chapter.id].showPreviousButton = true
          }
          if (chapterIndex !== (sortedChapters.length - 1)) {
            result.chapters[chapter.id].nextChapterId = sortedChapters[chapterIndex + 1].id
            result.chapters[chapter.id].showNextButton = (status === CourseMgr.Status.COMPLETED) ? true : false
          }

          result.chapters[chapter.id].status = status
          result.chapters[chapter.id].buttonLabel = status === CourseMgr.Status.COMPLETED ? Helper.getString('markAsIncomplete') : Helper.getString('markAsComplete')
          result.chapters[chapter.id].buttonCompleted = status === CourseMgr.Status.COMPLETED ? true : false
          result.chapters[chapter.id].showDownloadButton = (chapter.iType === 'PDF' && chapter.isDownloadable) && chapter.isDownloadable === 'YES' ? true : false
          if (chapterId) {
            if (result.chapters[chapterId]) {
              result.chapters[chapterId] = {
                ...result.chapters[chapterId],
                contentLink: chapterContentLink
              }
            }
          }
          if (sectionwiseChapters[result.chapters[chapter.id].sectionId] && sectionwiseChapters[result.chapters[chapter.id].sectionId].chapters) {
            sectionwiseChapters[result.chapters[chapter.id].sectionId].chapters.push(result.chapters[chapter.id])
          }
          else {
            sectionwiseChapters[result.chapters[chapter.id].sectionId] = {
              chapters: [result.chapters[chapter.id]]
            }
          }
        }
        )
    }

    const sections = Object.values(result.sections)
    const sortedSections = sections.length > 0 && sections.sort((a, b) => a.order - b.order)
    if (Object.keys(sectionwiseChapters).length !== 0) {
      sortedSections.map((section, sectionIndex) => {
        if (sectionwiseChapters[section.id]) {
          const chapterDetail = {
            chapters: []
          }
          sectionwiseChapters[section.id].chapters.map((chapter, index) => {
            chapterDetail.chapters.push({
              ...chapter,
              status: this.checkChapterStatus(learnerCourseContext, chapter.id)
            })
          })
          const sectionwiseCompletedChapter = chapterDetail.chapters.filter(i => i.status === CourseMgr.Status.COMPLETED)
          const sectionwiseProgressChapter = chapterDetail.chapters.filter(i => i.status === CourseMgr.Status.PROGRESS)
          if (sectionwiseCompletedChapter.length > 0) {
            chapterDetail.status = CourseMgr.Status.COMPLETED
          }
          chapterDetail.sectionwiseCompletedChapter = sectionwiseCompletedChapter.length > 0 ? sectionwiseCompletedChapter.length : 0
          chapterDetail.sectionwiseProgressChapter = sectionwiseProgressChapter.length > 0 ? true : false
          const data = Object.assign(chapterDetail, section)
          content.push(data)
        }
      })
    }

    result.assignedCourseStatus = (learnerCourseContext && learnerCourseContext.assignment && learnerCourseContext.assignment.id) ? true : false
    result.content = content
    result.courseprogs = (learnerCourseContext && learnerCourseContext.progress) && learnerCourseContext.progress
    console.log(result, 'result')

    return result
  }

  getCourseConsumptionDetails2 = async (options) => {
    const { courseDataResp, chapterId, orgId, courseSlug } = options
    const { learnerCourseContext, course } = courseDataResp

    let chapterContentLink = null

    if (chapterId) {
      const chapterType = chapterId && course.chapters[chapterId] && course.chapters[chapterId].iType
      if (chapterId && (chapterType === 'IMAGE' || chapterType === 'PDF' ||
        chapterType === 'SHORTVIDEO' || chapterType === 'FILE')) {
        const chapterContent = await ApiHelper.callAwait({
          method: 'POST',
          jsonBody: {
            orgId: (course && course.orgId) ? course.orgId : orgId,
            slug: (course && course.slug) ? course.slug : courseSlug,
            chapterId: chapterId
          },
          endPoint: ApiHelper.makeUrlPath(['miscs', 'contentaccess']),
        })
        chapterContentLink = chapterContent.link
      } else if (chapterId && chapterType === 'YOUTUBE') {
        chapterContentLink = course.chapters[chapterId].contentYt
      } else if (chapterId && chapterType === 'LINK') {
        chapterContentLink = course.chapters[chapterId].contentLink
      }
    }

    const content = []
    const sectionwiseChapters = {}
    const status = this.checkChapterStatus(learnerCourseContext, chapterId)

    let sortedChapters = Object.values(course.chapters).length === 1 ? course.chapters : []

    const showAssessment = course.assessment === 'YES' ? true : false
    const showFeedback = course.feedback === 'YES' ? true : false
    const showConsent = (course.consents && Object.keys(course.consents).length > 0) ? true : false

    sortedChapters = (course.chapters && Object.values(course.chapters).length > 0) && Object.values(course.chapters).filter(chapter => course.sections[chapter.sectionId]).sort((a, b) => {
      // NOTE: the order should be uniform in length
      // if (a.order.length === 2) a.order = '0' + a.order
      // if (b.order.length === 2) b.order = '0' + b.order
      a.order = parseInt(a.order) || a.order
      b.order = parseInt(b.order) || b.order
      return (course.sections[a.sectionId].order + a.order) - (course.sections[b.sectionId].order + b.order)
    })
    // sortedChapters.forEach(element => {
    //   console.log(element.order);
    // });

    {
      sortedChapters.length > 0 &&
        sortedChapters.map((chapter, chapterIndex) => {


          if (chapterIndex !== 0) {
            // console.log(chapterIndex, sortedChapters[chapterIndex - 1].id, 'CHAPTER_DETAILS');
            course.chapters[chapter.id].previousChapterId = sortedChapters[chapterIndex - 1].id
            course.chapters[chapter.id].showPreviousButton = true
          }
          if (chapterIndex !== (sortedChapters.length - 1)) {
            course.chapters[chapter.id].nextChapterId = sortedChapters[chapterIndex + 1].id
            course.chapters[chapter.id].showNextButton = (status === CourseMgr.Status.COMPLETED) ? true : false
          } else if(status === 'COMPLETEMARKED' && chapterIndex === (sortedChapters.length - 1)) {
            if (showAssessment && !courseDataResp.courseCompletionInfo.assessmentCompletion) {
              course.chapters[chapter.id].showNextButton = (status === CourseMgr.Status.COMPLETED) ? true : false
            } else if (showConsent && !courseDataResp.courseCompletionInfo.completedConsents) {
              course.chapters[chapter.id].showNextButton = (status === CourseMgr.Status.COMPLETED) ? true : false
            } else if (showFeedback && !courseDataResp.courseCompletionInfo.completedFeedback) {
              course.chapters[chapter.id].showNextButton = (status === CourseMgr.Status.COMPLETED) ? true : false
            }
          }

          course.chapters[chapter.id].status = status
          course.chapters[chapter.id].buttonLabel = status === CourseMgr.Status.COMPLETED ? Helper.getString('markAsIncomplete') : Helper.getString('markAsComplete')
          course.chapters[chapter.id].buttonCompleted = status === CourseMgr.Status.COMPLETED ? true : false
          course.chapters[chapter.id].showDownloadButton = (chapter.iType === 'PDF' && chapter.isDownloadable) && chapter.isDownloadable === 'YES' ? true : false
          if (chapterId) {
            if (course.chapters[chapterId]) {
              course.chapters[chapterId] = {
                ...course.chapters[chapterId],
                contentLink: chapterContentLink
              }
            }
          }
          if (sectionwiseChapters[course.chapters[chapter.id].sectionId] && sectionwiseChapters[course.chapters[chapter.id].sectionId].chapters) {
            sectionwiseChapters[course.chapters[chapter.id].sectionId].chapters.push(course.chapters[chapter.id])
          }
          else {
            sectionwiseChapters[course.chapters[chapter.id].sectionId] = {
              chapters: [course.chapters[chapter.id]]
            }
          }
        }
        )
    }
    const sections = Object.values(course.sections)
    const sortedSections = sections.length > 0 && sections.sort((a, b) => a.order - b.order)
    if (Object.keys(sectionwiseChapters).length !== 0) {
      sortedSections.map((section, sectionIndex) => {
        if (sectionwiseChapters[section.id]) {
          const chapterDetail = {
            chapters: []
          }
          sectionwiseChapters[section.id].chapters.map((chapter, index) => {
            chapterDetail.chapters.push({
              ...chapter,
              status: this.checkChapterStatus(learnerCourseContext, chapter.id)
            })
          })
          const sectionwiseCompletedChapter = chapterDetail.chapters.filter(i => i.status === CourseMgr.Status.COMPLETED)
          const sectionwiseProgressChapter = chapterDetail.chapters.filter(i => i.status === CourseMgr.Status.PROGRESS)
          if (sectionwiseCompletedChapter.length > 0) {
            chapterDetail.status = CourseMgr.Status.COMPLETED
          }
          chapterDetail.sectionwiseCompletedChapter = sectionwiseCompletedChapter.length > 0 ? sectionwiseCompletedChapter.length : 0
          chapterDetail.sectionwiseProgressChapter = sectionwiseProgressChapter.length > 0 ? true : false
          const data = Object.assign(chapterDetail, section)
          content.push(data)
        }
      })
    }

    course.assignedCourseStatus = (learnerCourseContext && learnerCourseContext.assignment && learnerCourseContext.assignment.id) ? true : false
    course.content = content
    course.courseprogs = (learnerCourseContext && learnerCourseContext.progress) && learnerCourseContext.progress
    return course
  }

  getCourseDetails2 = async (options) => {
    const courseDataResp = await this._callCourseApis(options)
    const data = await this.getCourseConsumptionDetails2({ courseDataResp, ...options })
    console.log('data', data)
    let consumptionDetails = {}
    let eligibilityCourse = this._findEligibility(data.priorCourses, courseDataResp.learnerCourseHistory)

    for (const item of data.content) {
      if (!consumptionDetails.chapterId) {
        for (const chapter of item.chapters) {
          if (chapter.status === '' || chapter.status === 'PROGRESS') {
            consumptionDetails.chapterId = chapter.id
            break;
          }
        }
      }
    }

    const newStartCourse = (data.content.length > 0) && data.content[0].chapters[0].status === '' ? true : false
    const completedCourseStatus = !consumptionDetails.chapterId ? true : false
    const isCourseCompleted = courseDataResp.learnerCourseContext && courseDataResp.learnerCourseContext.progress && courseDataResp.learnerCourseContext.progress.state === 'COMPLETED' ? true : false
    if (!consumptionDetails.chapterId) {
      consumptionDetails.chapterId = (data.content.length > 0) && data.content[0].chapters[0].id
    }

    const eligibilityCourseList = {
      items: []
    }
    Object.keys(data.__resolves.priorCourseById).map(item => {
      eligibilityCourseList.items.push(data.__resolves.priorCourseById[item])
    })

    let certificateDetails = {}
    if (data.__resolves && data.__resolves.certificateTemplatesById) {
      Object.keys(data.__resolves.certificateTemplatesById).map(item => {
        if (item === data.certificateTemplate) {
          certificateDetails = data.__resolves.certificateTemplatesById[item]
        }
      })
    }

    const doneAssessmentQues = []
    let totalAssesmentAnsCount = 0
    if (data.courseprogs && data.courseprogs.items) {
      for (const item in data.courseprogs.items) {
        if (item.startsWith('assessmentquestion-') && data.courseprogs.items[item].response.value.length > 0) {
          doneAssessmentQues.push(data.courseprogs.items[item])
        }
      }
    }

    let correctAssessmentQuesCount = 0
    if (doneAssessmentQues.length > 0) {
      for (const key in doneAssessmentQues) {
        const itemId = doneAssessmentQues[key].id.split('-')

        let correctAnswer = true
        const assessmentQues = data.assessments.find(i => i.id === itemId[1])
        if (assessmentQues) {
          for (const assessmentValue in doneAssessmentQues[key].response.value) {
            for (const assessmentQuesOptions in assessmentQues.options) {
              if (doneAssessmentQues[key].response.value[assessmentValue] === assessmentQuesOptions &&
                assessmentQues.options[assessmentQuesOptions].correctOption === 'NO') {
                correctAnswer = false
              }
            }
          }
          if (correctAnswer) {
            correctAssessmentQuesCount = correctAssessmentQuesCount + 1
          }
          totalAssesmentAnsCount = totalAssesmentAnsCount + 1
        }
      }
    }

    let percentageValue = 0
    percentageValue = (data.assessment === 'YES' && data.assessments && data.assessments.length > 0) && Math.round((100 / data.assessments.length) * correctAssessmentQuesCount)
    const coursePassed = percentageValue >= data.assessmentPassPercent ? true : false

    const assessmentCompleted = (data.assessment === 'YES' && (data.assessments && data.assessments.length > 0) && (totalAssesmentAnsCount === data.assessments.length)) ? true : false
    let enableConsent = false
    const showConsent = (data.consents && Object.keys(data.consents).length > 0) ? true : false
    if (completedCourseStatus) {
      if (data.assessment === 'YES' && (data.assessments && data.assessments.length > 0)) {
        if (assessmentCompleted && coursePassed) {
          enableConsent = true
        } else {
          enableConsent = false
        }
      } else {
        enableConsent = true
      }
    }

    const celebration = (data.celebration === 'YES' && coursePassed) ? true : false
    const totalRating = 5
    const consents = {
      iType: 'OPTIONS-MUL',
      options: data.consents
    }

    const doneConsentsQues = []
    if (data.courseprogs && data.courseprogs.items) {
      for (const item in data.courseprogs.items) {
        if (item.startsWith('consent-') && data.courseprogs.items[item].state === 'SUBMITTED') {
          doneConsentsQues.push(data.courseprogs.items[item])
        }
      }
    }

    let completedConsents = false
    if (showConsent && (Object.keys(data.consents).length === doneConsentsQues.length)) {
      completedConsents = true
    }

    let showFeedback = false
    if ((data.feedback === 'YES') && data.feedbackQuestions && Object.keys(data.feedbackQuestions).length > 0) {
      showFeedback = true
    }

    let enablefeedback = false
    if (completedCourseStatus) {
      if (data.assessment === 'YES' && (data.assessments && data.assessments.length > 0)) {
        if (assessmentCompleted && coursePassed) {
          enablefeedback = true
          if (data.consents && Object.keys(data.consents).length > 0) {
            if (completedConsents) {
              enablefeedback = true
            } else {
              enablefeedback = false
            }
          }
        } else {
          enablefeedback = false
        }
      } else if (data.consents && Object.keys(data.consents).length > 0) {
        if (completedConsents) {
          enablefeedback = true
        } else {
          enablefeedback = false
        }
      } else {
        enablefeedback = true
      }
    }
    const doneFeedbackQues = []
    if (data.courseprogs && data.courseprogs.items) {
      for (const item in data.courseprogs.items) {
        if (item.startsWith('feedbackquestion-') && data.courseprogs.items[item].state === 'SUBMITTED') {
          doneFeedbackQues.push(data.courseprogs.items[item])
        }
      }
    }

    let completedFeedback = false
    // if (showFeedback && (Object.keys(data.feedbackQuestions).length === doneFeedbackQues.length)) {
    //   completedFeedback = true
    // }
    if (showFeedback && doneFeedbackQues.length > 0) {
      completedFeedback = true
    }

    let showCertification = false
    if (data.certification === 'YES') {
      showCertification = true
    }

    let enableCertification = false
    if (completedCourseStatus) {
      if (data.assessment === 'YES' && (data.assessments && data.assessments.length > 0)) {
        if (assessmentCompleted && coursePassed) {
          enableCertification = true
          if (data.consents && Object.keys(data.consents).length > 0) {
            if (completedConsents) {
              enableCertification = true
              if (data.feedback && data.feedback === 'YES' && Object.keys(data.feedbackQuestions).length > 0) {
                if (completedFeedback) {
                  enableCertification = true
                } else {
                  enableCertification = false
                }
              }
            } else {
              enableCertification = false
            }
          } else if (data.feedback && data.feedback === 'YES' && Object.keys(data.feedbackQuestions).length > 0) {
            if (completedFeedback) {
              enableCertification = true
            } else {
              enableCertification = false
            }
          }
        } else {
          enableCertification = false
        }
      } else if (data.consents && Object.keys(data.consents).length > 0) {
        if (completedConsents) {
          enableCertification = true
          if (data.feedback && data.feedback === 'YES' && Object.keys(data.feedbackQuestions).length > 0) {
            if (completedFeedback) {
              enableCertification = true
            } else {
              enableCertification = false
            }
          }
        } else {
          enableCertification = false
        }
      } else if (data.feedback && data.feedback === 'YES' && Object.keys(data.feedbackQuestions).length > 0) {
        if (completedFeedback) {
          enableCertification = true
        } else {
          enableCertification = false
        }
      } else {
        enableCertification = true
      }
    }


    let invalidCourseConfiguration = null
    if (data.feedback && data.feedback === 'YES') {
      if (data.feedbackQuestions && Object.keys(data.feedbackQuestions).length === 0) {
        invalidCourseConfiguration = Helper.getString('feedbackConfigurationError')
      }
    }

    if (data.assessment && data.assessment === 'YES') {
      if (data.assessments && data.assessments.length === 0) {
        invalidCourseConfiguration = Helper.getString('assessmentConfigurationError')
      } else if (data.assessments) {
        for (const a of data.assessments) {
          if (a.options) {
            if (Object.keys(a.options).length === 0) {
              invalidCourseConfiguration = Helper.getString('assessmentConfigurationError')
              break
            }
          }
        }
      }
    }

    if (!data.content || data.content.length === 0) {
      invalidCourseConfiguration = Helper.getString('sectionConfigurationError')
    }

    if (data.content && data.content.length > 0) {
      for (const c of data.content) {
        if (!c.chapters || c.chapters.length === 0) {
          invalidCourseConfiguration = Helper.getString('chapterConfigurationError')
        }
      }
    }

    if (data.chapterquestions && data.chapterquestions.length > 0) {
      for (const q of data.chapterquestions) {
        if (q.options) {
          if (Object.keys(q.options).length === 0) {
            invalidCourseConfiguration = Helper.getString('quizConfigurationError')
          }
        }
      }
    }

    consumptionDetails = {
      ...consumptionDetails,
      title: data.title,
      content: data.content,
      assessment: data.assessment,
      courseId: data.courseId,
      descr: data.descr,
      duration: data.duration,
      feedback: data.feedback,
      showFeedback: showFeedback,
      feedbackQuestions: data.feedbackQuestions,
      id: data.id,
      image: data.image,
      imageName: data.imageName,
      slug: data.slug,
      showAssessment: data.assessment === 'YES' ? true : false,
      faqs: data.faqs,
      resources: data.resources,
      eligibility: eligibilityCourseList,
      assessments: data.assessments,
      courseprogs: data.courseprogs,
      stats: data.stats,
      certification: data.certification,
      reward: data.reward,
      assignedCourses: data.assignedCourses,
      assignedCourseStatus: data.assignedCourseStatus,
      discoveryMode: data.discoveryMode,
      doneAssessmentQues: doneAssessmentQues,
      completedCourseStatus: completedCourseStatus,
      isCourseCompleted: isCourseCompleted,
      newStartCourse: newStartCourse,
      percentageValue: percentageValue,
      coursePassed: coursePassed,
      celebration: celebration,
      totalRating: totalRating,
      assessmentCompleted: assessmentCompleted,
      eligibilityCourse: eligibilityCourse,
      showConsent: showConsent,
      enableConsent: enableConsent,
      consents: consents,
      completedConsents: completedConsents,
      enablefeedback: enablefeedback,
      completedFeedback: completedFeedback,
      invalidCourseConfiguration: invalidCourseConfiguration,
      showCertification: showCertification,
      enableCertification: enableCertification,
      totalAssesmentAnsCount: totalAssesmentAnsCount,
      correctAssessmentQuesCount: correctAssessmentQuesCount,
      certificateTitle: certificateDetails.title ? certificateDetails.title : '',
      // dueDate: MockData.course.dueDate,
      // rating: MockData.course.rating,
      // totalRating: MockData.course.totalRating
    }
    return consumptionDetails
  }

  getChapterDetails = async (params) => {
    const { orgId, courseSlug, chapterId } = params
    const courseDataResp = await this._callCourseApis(params)
    const data = await this.getCourseConsumptionDetails2({ courseDataResp, ...params })
    const chapterQuizArr = []
    if (data.chapterquestions && data.chapterquestions.length > 0) {
      for (const chapterQuiz of data.chapterquestions) {
        if (chapterQuiz.chapterId === chapterId) {
          chapterQuizArr.push(chapterQuiz)
        }
      }
    }

    let options = {}
    const chapterkcsObj = {}
    if (data.chapterkcs && data.chapterkcs.length > 0) {
      for (const chapterkcs of data.chapterkcs) {
        if (chapterkcs.chapterId === chapterId) {
          options = {
            ...options,
            [chapterkcs.id]: chapterkcs
          }
        }
      }
      chapterkcsObj.id = chapterId
      chapterkcsObj.options = options
      chapterkcsObj.iType = 'OPTIONS-MUL'
    }

    let buttonAction = ''
    if (chapterkcsObj.options && Object.keys(chapterkcsObj.options).length > 0) {
      buttonAction = 'checklistDialogOpen'
    } else if (chapterQuizArr.length > 0) {
      buttonAction = 'quizDialogOpen'
    }

    // let buttonAction = ''
    // if (Object.keys(chapterkcsObj).length > 0) {
    //   if (chapterkcsObj.options && Object.keys(chapterkcsObj.options).length > 0) {
    //     buttonAction = 'checklistDialogOpen'
    //   } else if (chapterkcsObj.options && Object.keys(chapterkcsObj.options).length === 0 && chapterQuizArr.length > 0) {
    //     buttonAction = 'quizDialogOpen'
    //   }
    // }


    let lastChapterId = null
    if (data.content.length > 0) {
      const chaptersOfLastSection = data.content[data.content.length - 1].chapters
      if (chaptersOfLastSection.length > 0) {
        lastChapterId = chaptersOfLastSection[chaptersOfLastSection.length - 1].id
      }
    }

    if (data.content.length > 0) {
      for (let section of data.content) {
        for (const chapter of section.chapters) {
          if (chapter.id === chapterId) {
            const progressValue = (100 / section.chapters.length) * section.sectionwiseCompletedChapter
            section = {
              ...section,
              progressValue: progressValue,
              chapterquestions: chapterQuizArr,
              chapterkcsObj: chapterkcsObj,
              buttonAction: buttonAction,
              courseprogs: data.courseprogs,
              courseComplete: ((data && data.courseprogs && data.courseprogs.state) && (data.courseprogs.state === CourseMgr.CourseStatus.COMPLETED)) ? true : false,
              showAssessment: (data.assessment && data.assessment === 'YES' && data.assessments.length > 0) ? true : false,
              showConsent: (data.consents && Object.keys(data.consents).length > 0) ? true : false,
              showFeedback: (data.feedback && data.feedback === 'YES' && data.feedbackQuestions && Object.keys(data.feedbackQuestions).length > 0) ? true : false
            }
            if (chapterId === lastChapterId) {
              data.chapters[chapterId].isLastChapter = true
            }
            return [section, data.chapters[chapterId], courseDataResp.courseCompletionInfo]
          }
        }
      }
    }
    return null
  }

  getCourseBySlug = async (courseSlug) => {
    return await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], { globalcourses: true, slug: courseSlug }),
    })
  }

  // getGlobalCourseBySlug = async (courseSlug) => {
  //   return this.getCourseDetails2({ orgId: 'PRODUCT', courseSlug })
  // }

  // getGlobalChapterBySlug = async (orgId, courseSlug, chapterId) => {
  //   const qParams = { globalcourses: true, slug: courseSlug }
  //   if (!Controller.get().roleHelper().isAllVisibleToBeAdded(orgId)) {
  //     qParams.allvisible = true
  //   }
  //   const response = await ApiHelper.callAwait({
  //     method: 'GET',
  //     endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], qParams),
  //   })
  //   return this.getChapterDetails('', '', chapterId, response)
  // }

  getCategories = async (orgId) => {
    return await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'ccats'], { orgId: orgId }),
    })
  }

  navToChapter = (slug, chapterId, preview, orgId, state) => {
    if (preview) {
      Controller.get().pushRoute(UiHelper.baseRoute(orgId) + '/courses/preview/' + slug + '/' + chapterId)
    } else {
      Controller.get().pushRouteWithParams('/learner/orgs/' + orgId + '/course/' + slug + '/' + chapterId, { ...state })
    }
  }

  navToResourceDetail = (id, backButton) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRouteWithParams('/learner/orgs/' + orgId + '/resources/' + id, backButton)
  }

  navToCourse = (courseSlug, backButton) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRouteWithParams('/learner/orgs/' + orgId + '/course/' + courseSlug, backButton)
  }

  getCertificate = async () => {
    return await MockData.certificate

  }

  getResultDetails = async () => {
    // return await ApiHelper.callAwait({
    //   authWithKey: true,
    //   method: 'PUT',
    //   endPoint: ApiHelper.makeUrlPath(['miscs', 'result']),
    // })
    return await MockData.result
  }

  getFeedback = async (options) => {
    const courseDataResp = await this._callCourseApis(options)
    const data = await this.getCourseConsumptionDetails2({ ...options, courseDataResp })
    return data
  }

  linkItemClick = (item) => {
    if (item.key === 'logout') {
      Controller.get().logout()
    } else {
      Controller.get().pushRoute(item.link)
    }
  }

  getCourses = async (orgId) => {
    const response = await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], { orgId: orgId })
    })

    const filteredCourses = []
    response.items.map(item => {
      if (item.discoveryMode === 'OPEN' && item.state === 'LIVE') {
        filteredCourses.push(item)
      }
    })
    response.items = filteredCourses

    return response
  }

  navToAssessment = (slug) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRoute('/learner/orgs/' + orgId + '/course/' + slug + '/assessment')
  }

  navToResult = (slug) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRoute('/learner/orgs/' + orgId + '/course/' + slug + '/result')
  }

  navToConsent = (slug) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRoute('/learner/orgs/' + orgId + '/course/' + slug + '/consent')
  }

  navToFeedback = (slug) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRoute('/learner/orgs/' + orgId + '/course/' + slug + '/feedback')
  }

  navToCertificate = (slug, backButton, aid) => {
    const orgId = Controller.get().roleHelper().getOrgId()
    Controller.get().pushRouteWithParams('/learner/orgs/' + orgId + '/' + slug + '/' + aid + '/certificate', backButton)
  }

  navToBack = (link) => {
    Controller.get().pushRoute(link)
  }

  markChapterComplete = async (chapterId, courseprogs) => {
    return await ApiHelper.callAwait({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['courseprogs', courseprogs.id]),
      jsonBody: { updateAction: 'markComplete', itemId: 'chapter-' + chapterId }
    })
  }

  getCourseSlug = async (courseId) => {
    return await ApiHelper.callAwait({
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['courses', courseId]),
    })
  }

  markChapterInComplete = async (chapterId, courseprogs) => {
    return await ApiHelper.callAwait({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['courseprogs', courseprogs.id]),
      jsonBody: { updateAction: 'markInComplete', itemId: 'chapter-' + chapterId }
    })
  }

  submitChecklist = async (courseChapterKcId, courseprogs) => {
    return await ApiHelper.callAwait({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['courseprogs', courseprogs.id]),
      jsonBody: { updateAction: 'submitResponse', itemId: 'kc-' + courseChapterKcId }
    })
  }

  submitConsent = async (courseConsentId, courseprogs) => {
    return await ApiHelper.callAwait({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['courseprogs', courseprogs.id]),
      jsonBody: { updateAction: 'submitResponse', itemId: 'consent-' + courseConsentId }
    })
  }

  submitQuiz = async (chapterQuestionId, selectedValues, courseprogs) => {
    return await ApiHelper.callAwait({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['courseprogs', courseprogs.id]),
      jsonBody: {
        updateAction: 'submitResponse',
        itemId: 'chapterquestion-' + chapterQuestionId,
        response: {
          value: selectedValues
        }
      }
    })
  }

  submitAssessment = async (assessmentQuestionId, selectedValues, courseprogs) => {
    return await ApiHelper.callAwait({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['courseprogs', courseprogs.id]),
      jsonBody: {
        updateAction: 'submitResponse',
        itemId: 'assessmentquestion-' + assessmentQuestionId,
        response: {
          value: selectedValues
        }
      }
    })
  }

  submitFeedback = async (feedbackQuestionId, selectedValues, courseprogs) => {
    return await ApiHelper.callAwait({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['courseprogs', courseprogs.id]),
      jsonBody: {
        updateAction: 'submitResponse',
        itemId: 'feedbackquestion-' + feedbackQuestionId,
        response: {
          value: selectedValues
        }
      }
    })
  }

  submitRating = async (rating, comment, courseprogs) => {
    return await ApiHelper.callAwait({
      method: 'PUT',
      endPoint: ApiHelper.makeUrlPath(['courseprogs', courseprogs.id]),
      jsonBody: {
        updateAction: 'submitRating',
        rating: rating,
        comment: comment
      }
    })
  }

  selfenroll = async (orgId, courseId) => {
    return await ApiHelper.callAwait({
      method: 'POST',
      endPoint: ApiHelper.makeUrlPath(['miscs', 'selfenroll']),
      jsonBody: {
        orgId: orgId,
        courseId: courseId
      }
    })
  }

  getHtmlConvs = async (htmlString) => {
    return await ApiHelper.callAwait({
      method: 'POST',
      endPoint: ApiHelper.makeUrlPath(['htmlconvs']),
      jsonBody: {
        title: 'Certificate',
        html: htmlString
      }
    })
  }

  _findEligibility = (priorCourses, learnerHistory) => {
    let eligibility = true
    if (priorCourses && priorCourses.length > 0) {
      if (learnerHistory && learnerHistory.items && learnerHistory.items.length > 0) {
        priorCourses.map(courseId => {
          const value = learnerHistory.items.filter(i => i.courseId === courseId)
          if (value && value.length === 0) {
            eligibility = false
          }
        })
      } else {
        eligibility = false
      }
    }
    return eligibility
  }

  _callCourseApis = async (options) => {
    const { orgId, courseSlug, aid } = options
    if (orgId === 'PRODUCT') {
      const qParams = { orgId: orgId, slug: courseSlug, allvisible: true }
      const funcs = [
        ApiHelper.callAwait({
          method: 'GET',
          endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], qParams),
        })
      ]

      const results = await Promise.all(funcs)

      const response = {
        course: results[0]
      }
      return response
    }

    const userId = Controller.get().userMgr().getUserId()
    const qParams = { orgId: orgId, slug: courseSlug }
    if (!Controller.get().roleHelper().isAllVisibleToBeAdded(orgId)) {
      qParams.allvisible = true
    }
    const funcs = [
      ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'courses'], qParams),
      }),
      ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'learnercoursecontext'],
          // { orgId: orgId, courseSlug: courseSlug, learnerId: userId, aid })
          { orgId: orgId, courseSlug: courseSlug, learnerId: userId, aid: aid || '' })
      }),
      ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'learnercoursehistory'], { orgId: orgId, learnerId: userId }),
      })
    ]

    const results = await Promise.all(funcs)
    const courseCompletionInfo = AppHelper.courseCompletionInfo({
      course: results[0] || {},
      progress: results[1].progress || {},
      chapterCount: Object.keys(results[0].chapters).length,
      questions: { items: [] },
    })
    const response = {
      course: results[0],
      learnerCourseContext: results[1],
      learnerCourseHistory: results[2],
      courseCompletionInfo
    }
    return response
  }
}