
import { StyleSheet } from 'aphrodite';
import { AppTheme, AppStyles } from './AppTheme';
import { AppHelper } from '../helpers/AppHelper';
import { CommonTheme, CommonStyles } from './Styles';
const IMG_RENDER_SIZE = 135
const FIELD_HEIGHT = 55
const ICON_SIZE = 35

export const CustomProductsStyles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  stepFormTitle: {
    fontWeight: 600
  }
})

export const CustomNoPermissionStyles = StyleSheet.create({
  nfMiddle: {
    maxWidth: 500,
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  button: {
    textAlign: 'center',
    backgroundColor: 'whitesmoke',
    fontSize: 16,
    borderRadius: 0,
    cursor: 'pointer',
    padding: 10,
    color: AppTheme.primaryColor
  },
  buttonContainer: {
    display: 'flex',
    gap: 20
  }
})

export const CustomMessageDialogStyles = StyleSheet.create({
  modalHeader: {
    padding: 40,
    paddingBottom: 0
  },
  modalTitle: {
    color: '#121212',
    fontSize: 22,
    fontWeight: 500
  },
  modalContent: {
    padding: 40,
  },
  modalCloseButton: {
    position: 'absolute',
    top: 42,
    right: 40,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    width: 18,
    height: 18
  },

  customRoot: {
    backgroundColor: 'rgba(18, 18, 18, 0.2)',
    // backdropFilter: 'blur(10px)',
    zIndex: 13000
  },
  customPaper: {
    borderRadius: 12,
    // minWidth: 600,
    backgroundColor: AppTheme.secondaryBg,
    '::-webkit-scrollbar': {
      display: 'none'
    },
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
  }
})

export const CustomSearchBarStyles = StyleSheet.create({
})

export const CustomBreadCrumbsStyles = StyleSheet.create({
})

export const CustomImageUploaderStyles = StyleSheet.create({
  container: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
  },
  fitContainer: {
    height: FIELD_HEIGHT,
    width: 'auto'
  },
  fileInput: {
    width: '100%'
  },
  textButton: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10
  },
  text: {
    marginTop: 10,
    marginBottom: 3,
    fontSize: 12
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  editor: {
    width: 65,
    height: 65,
  },
  imgContainer: {
    display: 'none'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContent: {
    borderRadius: 6,
    maxWidth: '100%',
    maxHeight: '100%'
  },
  // closeIconContainer: {
  //   position: 'absolute',
  //   top: 8,
  //   right: 8,
  //   backdropFilter: 'blur(6px)',
  //   backgroundColor: 'white',
  //   display: 'flex',
  //   cursor: 'pointer',
  //   border: '1px solid #DCDBE0',
  //   padding: 2,
  //   borderRadius: 4,
  // },
  // closeIconDisableContainer: {
  //   backgroundColor: '#f5f5f5',
  //   cursor: 'default',
  // },
  closeIconContainer: {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'white',
    display: 'flex',
    cursor: 'pointer',
    border: '1px solid #DCDBE0',
    padding: 2,
    borderRadius: 4,
    marginLeft: 10
  },
  closeIconDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  reuploadIconContainer: {
    right: 34,
    alignSelf: 'center'
  },
  attachFileText: {
    margin: 0,
    fontSize: 13,
    color: 'black',
    //marginTop: 13
  },
  attachFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10
  },
  labelContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    padding: 0,
    borderRadius: 0,
    alignItems: 'center'
  },
  labelDisableContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: 0,
    backgroundColor: '#f5f5f5',
    cursor: 'unset',
    borderRadius: 0,
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  outerMainContainer: {
    display: 'flex'
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    border: '1px dashed #DCDBE0',
    borderRadius: AppTheme.borderRadius,
    //minHeight: 100,
    position: 'relative',
    overflow: 'hidden',
    height: FIELD_HEIGHT
  },
  imageOuterContainer: {
    flex: 'unset',
    border: '1px solid #DCDBE0'
  },
  imageIconContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageIconOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  fileDrop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    position: 'relative'
  },
  targetFileDrop: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reuploadContainer: {
    fontSize: 16,
    color: AppTheme.primaryColor,
    margin: 20,
    display: 'flex',
    alignItems: 'center'
  },
  reuploadLabel: {
    cursor: 'pointer'
  },
  deleteIconContainer: {
    margin: '0px 10px'
  },
  documentNameContainer: {
    lineHeight: 'unset',
    margin: 0,
    display: 'grid'
  },
  docName: {
    fontSize: 13,
    color: '#222222',
    margin: 0,
    alignItems: 'center',
    marginLeft: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

export const CustomTableComponentStyles = StyleSheet.create({
  noBorder: {
    border: '0px solid #DCDBE0',
    padding: 0
  },
  content: {
    border: '1px solid #DCDBE0',
    padding: '0px 24px',
    paddingBottom: 4,
    borderRadius: 12
  },
  divTable: {
    display: 'block',
    overflowX: 'auto',
    width: '100%',
    '@media (max-width: 320px)': {
      maxWidth: 310,
    },
    '@media (max-width: 767px) and (min-width: 321px)': {
      maxWidth: 350,
    },
    '@media (min-width: 768px) and (max-width: 1021px)': {
      maxWidth: 750,
    },
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 20px',
  },
  tableBorderSpacing: {
    borderSpacing: 0,
  },
  entityAddIcon: {
    fontSize: 36,
    color: 'black',
    cursor: 'pointer',
  },
  cellHead: {
    backgroundColor: 'white',
    color: '#121212',
    height: 38,
    fontSize: 16,
    fontWeight: 500,
    padding: '8px 8px 8px 8px',
    borderBottom: 0
  },
  cellBodyIcons: {
    // display: 'flex',
    // justifyContent: 'flex-end',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderRight: '1px solid #DCDBE0',
  },
  cellBody: {
    backgroundColor: 'white',
    color: '#121212',
    height: 54,
    borderTop: '1px solid #DCDBE0',
    borderBottom: '1px solid #DCDBE0',
    fontSize: 18,
    fontWeight: 500,
    padding: '8px 8px 8px 8px',
  },
  noBorderCell: {
    border: '0px solid #DCDBE0',
  },
  cellBodyRow: {
    border: '1px solid #DCDBE0'
  },
  firstcellBody: {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    paddingLeft: 20,
  },
  firstcellBodyStyle: {
    borderLeft: '1px solid #DCDBE0',
    fontWeight: 600
  },
  divIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
})

export const CustomSEStyles = StyleSheet.create({
  container: {
    background: 'white',
    height: '100%',
    borderRadius: 12,
    border: '1px solid #DCDBE0'
  },
  content: {
    padding: '20px 24px',
    paddingTop: 4,
    paddingBottom: 20
  },
  heading: {
    // borderBottom: '1px solid lightgray',
  },
  headingContent: {
    padding: '10px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  editButton: {
    color: AppTheme.primaryColor,
    backgroundColor: 'none',
    fontWeight: 600,
    padding: 0,
    fontSize: 16,
    textTransform: 'none',
    minWidth: 'auto',
    ':hover': {
      background: 'none',
      backgroundColor: 'none',
    }
  },
  stepFormTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#121212',
    margin: 0,
    textTransform: 'capitalize'
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
    color: '#121212',
    marginBottom: 4,
    marginTop: 0
  },
  value: {
    margin: 0,
    fontSize: 18,
    fontWeight: 600,
    color: '#121212',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  valueDisplayMultiline: {
    whiteSpace: 'normal'
  },
  actionIcon: {
    color: AppTheme.primaryColor
  },
  imageContainer: {
    width: 'fit-content',
    height: 50,
    border: '1px solid #E2EAFC',
    borderRadius: 8,
    overflow: 'hidden'
  },
  imageContent: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  link: {
    color: AppTheme.primaryColor,
    textDecoration: 'none',
    borderBottom: '0.5px solid ' + AppTheme.primaryColor,
    width: 'fit-content'
  },
  buttonText: {
    color: AppTheme.primaryColor,
    fontWeight: 600
  },
  labelContainer: {
    paddingBottom: 24
  },
})

export const CustomNextPageStyles = StyleSheet.create({
})

export const CustomHeaderbarStyles = StyleSheet.create({
  container: {
    position: 'fixed',
    top: 0,
    paddingTop: AppTheme.toolbarHeight ? 0 : 20,
    paddingBottom: AppTheme.toolbarHeight ? 0 : 20,
    minHeight: AppTheme.toolbarHeight, //TODO: creates issue in mobile view
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: AppTheme.secondaryBg,
    zIndex: 1000,
    maxWidth: AppTheme.viewPort,
    marginLeft: AppTheme.adminPagePadding,
    marginRight: AppTheme.adminPagePadding,
    '@media (max-width: 1024px)': {
      width: '-webkit-fill-available',
    },
    '@media (max-width: 767px)': {
      marginLeft: 10,
      marginRight: 10,
    }
  },
  containerNarrow: {
    maxWidth: AppTheme.viewPort,
    display: 'flex',
    flex: 1
  },
  title: {
    color: '#121212',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 38,
    fontWeight: 600,
    marginBottom: 10
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      marginBottom: 5,
      marginTop: 5
    }
  },
  actionComponent: {
    marginLeft: 10
  },
  button: {
    minWidth: 136,
    marginLeft: 10
  },
  buttonLabel: {
    marginLeft: 4
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    marginRight: 20
  },
  containerTitleContent: {
    display: 'grid'
  },
  imageContainer: {
    width: 48,
    height: 48,
    backgroundColor: AppTheme.secondaryBg,
    borderRadius: '50%',
    display: 'flex',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    marginLeft: 20,
    '@media (max-width: 767px)': {
      marginLeft: 5,
    }
  },
})

export const CustomOrgSwitcherStyles = StyleSheet.create({
  customRoot: {
    zIndex: 13000
  },
  customPaper: {
    borderRadius: AppTheme.borderRadius,
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  modalHeader: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalTitle: {
    color: '#222222',
    fontSize: 26,
    fontWeight: 600
  },
  modalContent: {
    paddingLeft: 30,
    paddingRight: 30,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalCloseButton: {
    position: 'absolute',
    top: 23,
    right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none'
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
  label: {
    fontSize: 14,
    color: '#7D7D7D',
    margin: 0,
    marginBottom: 8
  },
  menuProps: {
    zIndex: 130000,
    marginTop: 5
  },
  paper: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
    borderRadius: AppTheme.borderRadius,
    padding: '0px 10px'
  },
  formControl: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    margin: 0,
    borderRadius: AppTheme.borderRadius,
    ':focus': {
      backgroundColor: '#ffffff'
    }
  },
  selectMenuItemContainer: {
    padding: '5px 10xp',
    borderRadius: AppTheme.borderRadius,
  },
  menuItem: {
    fontSize: 16,
    color: '#222222',
    margin: '6px 0px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30
  },
  saveButton: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    height: 54,
    minWidth: 150,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg
  },
  marginLeft: {
    color: 'white'
  },
})

export const CustomSettingsMenuStyles = StyleSheet.create({
  customRoot: {
    zIndex: 13000
  },
  popoverContainer: {
    borderRadius: 12,
    boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
    border: '1px solid #DCDBE0',
    marginTop: 10,
    minWidth: 199
  },
  popoverContent: {
    padding: 5,
    paddingBottom: 10
  },
  popoverTitle: {
    color: '#222222',
    fontSize: 20,
    margin: 0,
    fontWeight: 500,
    padding: 10
  },
  menuItemContainer: {
    borderRadius: AppTheme.borderRadius,
    padding: '6px 10px'
  },
  menuItemContent: {
    margin: 0,
    color: '#222222',
    fontSize: 16,
    fontWeight: 500,
  },
  version: {
    color: '#7D7D7D',
    marginLeft: 12,
    fontSize: 12
  },
})

export const CustomDynamicFormDialogStyles = StyleSheet.create({
  modalHeader: {
    padding: 40,
    paddingBottom: 24
  },
  modalTitle: {
    color: '#121212',
    fontSize: 22,
    fontWeight: 500
  },
  modalContent: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  modalCloseButton: {
    position: 'absolute',
    top: 42,
    right: 40,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    width: 18,
    height: 18
  },

  customRoot: {
    backgroundColor: 'rgba(18, 18, 18, 0.2)',
    // backdropFilter: 'blur(10px)',
    zIndex: 13000
  },
  customPaper: {
    borderRadius: 12,
    // minWidth: 600,
    backgroundColor: AppTheme.secondaryBg,
    '::-webkit-scrollbar': {
      display: 'none'
    },
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
  },
  dialogActions: {
    paddingBottom: 40
  }
})

export const CustomDynamicFormStyles = StyleSheet.create({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // padding: AppTheme.pagePadding,
    '@media (max-width: 320px)': {
      maxWidth: 300,
    }
  },
  leftGridPadding: {
    paddingRight: 10,
    '@media (max-width: 767px)': {
      paddingRight: 0,
    }
  },
  rightGridPadding: {
    paddingLeft: 10,
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    }
  },
  errorMsg: {
    paddingRight: 20,
    color: '#f44336'
  },
  successMsg: {
    paddingRight: 20,
    color: 'green'
  },
  marginLeft: {
    color: 'white'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 32,
    marginBottom: 40
  },
  pendingApprovalLabel: {
    marginTop: 0,
    marginBottom: 0,
    color: 'orange',
    fontSize: 10
  },
  openDoc: {
    marginTop: 0,
    marginBottom: 0,
    color: 'black',
    fontSize: 10,
    cursor: 'pointer',
  },
  uploadFieldLabel: {
    marginTop: 10,
  },
  buttonDelete: {
    backgroundColor: '#FF0D0D'
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: '#121212',
    margin: 0,
    marginBottom: 4
  },
  formControl: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #DCDBE0',
    overflow: 'hidden'
  },
  textInputStyles: {
    padding: '14px 12px',
    flex: 1,
    textAlign: 'left',
    color: '#121212',
    fontSize: 12,
    fontWeight: 500
  },
  textInputStylesDisabled: {
    backgroundColor: '#f5f5f5',
  },
  labelPicker: {
    marginTop: 16
  },
  pickerStyle: {
    marginTop: 0
  },
  pickerInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #DCDBE0',
    overflow: 'hidden'
  },
  formInputStyle: {
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  tag: {
    backgroundColor: '#F9F9F9',
    color: '#121212',
    fontSize: 13,
    borderRadius: 6,
    height: 26,
    margin: 0,
    marginRight: 6
  },
  popupIndicator: {
    marginRight: 5,
  },
  dialog: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
  },
  paper: {
    borderRadius: AppTheme.borderRadius,
  },

  modalCloseIcon: {
    width: 12,
    height: 12,
    padding: 1,
    borderRadius: 6
  },

  fieldOuterContainer: {
    display: 'flex'
  },
  fieldLeftInnerContainer: {
    flex: 1,
    paddingRight: 10,
    '@media (max-width: 767px)': {
      paddingRight: 0,
    }
  },
  fieldRightInnerContainer: {
    flex: 1,
    paddingLeft: 10,
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    }
  },
  delFieldContainer: {
    width: '25%',
    marginRight: 20
  },
  formControlContainer: {
    marginTop: 0,
    marginBottom: 8
  },
  formControlMain: {
    marginTop: 0,
    marginBottom: 0
  },
  optionImageContainer: {
    width: 20,
    height: 20,
  },
  optionImage: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  optionImageContainerSpec: {
    marginRight: 9,
    marginLeft: 5
  },
  // requiredIndicator: {
  //   display: 'flex',
  //   color: 'grey',
  //   lineHeight: 0,
  //   alignItems: 'center',
  //   fontSize: 13,
  //   justifyContent: 'flex-end',
  //   marginLeft: 4
  // },
  requiredIndicator: {
    opacity: 0.5,
    height: 7,
    width: 7
  },
  labelContainer: {
    display: 'flex',
    marginBottom: 8
  },
  tooltip: {
    zIndex: 9999999999
  },
  autoCompleteInputRoot: {
    paddingRight: 56
  },
})

export const CustomDocumentUploaderStyles = StyleSheet.create({
  container: {
    // minHeight: 52,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
  },
  buttonContainer: {
    // height: 90
    height: FIELD_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #DCDBE0',
    borderRadius: AppTheme.borderRadius,
    position: 'relative'
  },
  fileInput: {
    width: '100%'
  },
  button: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    // minWidth: 150,
    padding: '5px 15px',
    fontSize: 10,
    color: AppTheme.secondaryBg
  },
  text: {
    fontSize: 10,
    margin: 0,
    marginRight: 10,
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer',
    borderRadius: AppTheme.borderRadius
  },
  labelDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'unset',
  },
  attachFile: {
    marginRight: 10,
    color: AppTheme.primaryColor,
    minWidth: 160
  },
  closeIconContainer: {
    color: AppTheme.primaryColor,
    marginRight: 10
  },
  openDoc: {
    marginTop: 0,
    marginBottom: 0,
    color: AppTheme.primaryColor,
    fontSize: 10,
    textDecoration: 'none',
    borderBottom: '0.5px solid ' + AppTheme.primaryColor
  },
  msgContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5
  },

  imageIconContainer: {
    width: 38,
    height: 38,
    display: 'flex',
    marginRight: 10
  },
  imageContent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: '7px 14px'
  },
  targetContainer: {
    // flex: 1,
    // display: 'flex',
    // alignItems: 'center'
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  documentContainer: {
    // display: 'flex',
    // flex: 1,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  },
  documentImageContainer: {
    width: 40,
    height: 40
  },
  closeImgContainer: {
    // display: 'flex',
    // cursor: 'pointer',
    // position: 'absolute',
    // top: 12,
    // right: 12,
    // zIndex: 1000,
    // color: '#222222',
    // border: '1px solid' + '#222222',
    // borderRadius: 4,
    // padding: 2
    display: 'flex',
    cursor: 'pointer',
    color: '#222222',
    border: '1px solid' + '#222222',
    borderRadius: 4,
    padding: 2
  },
  closeImgDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  documentNameContainer: {
    // fontSize: 16,
    // color: '#222222',
    // margin: 0,
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: 10
    lineHeight: 'unset',
    margin: 0,
    display: 'grid'
  },
  attachFileText: {
    fontSize: 13,
    color: '#222222',
    //fontWeight: 500,
    margin: 0
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  reuploadIconContainer: {
    right: 36,
    alignSelf: 'center'
  },
  delImgContainer: {
    margin: '0px 10px'
  },
  docName: {
    fontSize: 13,
    color: '#222222',
    margin: 0,
    alignItems: 'center',
    marginLeft: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  imageIconOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
})

export const CustomBaseEnityStyles = StyleSheet.create({
  entityContainer: {
    marginTop: AppTheme.headerbarHeight,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: AppTheme.secondaryBg,
    marginLeft: 60,
    marginRight: 60,
    maxWidth: AppTheme.viewPort,
    '@media (max-width: 1025px)': {
      marginBottom: 100
    },
    '@media (max-width: 767px)': {
      marginLeft: 10,
      marginRight: 10,
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  breadCrumbsContainer: {
    alignItems: 'flex-start',
    paddingBottom: 0
  },
  header: {
    marginBottom: 30
  }
})

export const CustomPreviewDialogStyles = StyleSheet.create({
})

export const CustomAppButtonStyles = StyleSheet.create({
  button: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    height: 44,
    minWidth: 136,
    padding: 13,
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg
  },
  buttonText: {
    fontSize: 16,
    margin: 0,
    textTransform: 'none',
    color: AppTheme.tintContrastColour,
    fontWeight: 600,
    '@media (max-width: 767px)': {
      fontSize: 12,
    },
  },
  progressIcon: {
    color: '#FFFFFF',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  }
})

export const CustomMenuItemsDropdownStyles = StyleSheet.create({
  customRoot: {
    zIndex: 13000
  },
  titleContainer: {
    marginLeft: 5,
    fontSize: 14,
    color: '#6E84A3',
  },
  menuItem: {
    color: '#6E84A3',
    padding: '0px 5px',
    opacity: 1,
    fontWeight: 400,
    ':hover': {
      color: 'black',
      backgroundColor: 'white'
    }
  },
  entityTableLinkIcon: {
    padding: 3,
    fontSize: 18,
    color: AppTheme.primaryColor,
    cursor: 'pointer'
  },
  entityTableLinkIconMenu: {
    height: 26,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 5
  },
  icon: {
    padding: 0
  },
  popover: {
    padding: '3px 24px',
    border: '1px solid #DCDBE0',
    borderRadius: 12,
    boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
    minWidth: 107
  },
  divider: {
    color: '#edf2f9',
    margin: '3px 0px'
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center'
  },
})

export const CustomCardsStyles = StyleSheet.create({
})

export const CustomColourPickerStyles = StyleSheet.create({
})

export const CustomUsersOfOrgStyles = StyleSheet.create({
  statusContainer: {
    display: 'flex',
    gap: 5,
    flexDirection: 'column'
  },
  statusContainerContent: {
    margin: 0
  }
})
