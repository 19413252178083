import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Grid } from '@material-ui/core';

import { UiHelper } from '../helpers/UiHelper';
import { NextPage } from './NextPage';
import { MenuItemsDropdown } from './MenuItemsDropdown';
import { AppConfig } from '../../AppConfig';
import { CustomCardsStyles } from '../../styles/CustomStyles';

export const Cards = (props) => {

  const setUpActions = (current, index) => {
    // headerActions - if any action is multi: actions.push('checkbox')
    let isMulti = false
    if (props.headerActions) {
      for (const action of props.headerActions) {
        if (action.multi) {
          isMulti = true;
          break;
        }
      }
    }

    let actions = []
    if (props.onActions) {
      actions = Object.assign([], props.onActions(current))
    }
    // if (isMulti) {
    //   actions.push(addButtonCheckbox(current, index))
    // }
    return actions;
  }

  const setUpMenuItems = (current, index) => {
    if (props.onMenuItems) {
      return <MenuItemsDropdown onMenuItems={props.onMenuItems} current={current} customPopover={Styles.popover} />
    }
  }

  return (
    props.items.length > 0 ?
      <div className={css(Styles.container)}>
        <Grid container spacing={6}>
          {props.items.map((item, index) => {
            return (
              <Grid key={item.id} lg={2} xs={12} sm={2} md={2} item >
                <div className={css(Styles.productTileContainer)}>
                  {props.onCardValue(item, 'top')}
                  <div className={css(Styles.itemDetails)}>
                    <div className={css(Styles.titleAndIcon)}>
                      {props.onCardValue(item, 'bottom')}
                      {setUpActions(item, index)}
                      {setUpMenuItems(item, index)}
                    </div>
                  </div>
                </div>
              </Grid>
            )
          })}
          {props.nextPage &&
            (<NextPage
              itemCount={props.items.length}
              nextPageState={props.nextPageState}
              onNext={props.onNext}></NextPage>)}
        </Grid>
      </div>
      :
      UiHelper.emptyView(props.emptyText)
  )
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCardsStyles : StyleSheet.create({
  container: {
    paddingBottom: 50,
    overflow: 'hidden',
    flex: 1
  },
  productTileContainer: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  itemDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  titleAndIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  popover: {
    minWidth: 50,
    gap: 8
  }
})

