import React, { Fragment, Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CoursesByCat } from '../common/components/course/CoursesByCat';
import { Controller } from '../common/Controller';

export class CoursesByTeamsWrapper extends Component {
  constructor(props) {
    super(props)
    this.orgId = (Controller.get().userMgr().isProductSU() ? (this.props.match.params.orgId ? this.props.match.params.orgId : 'PRODUCT') : Controller.get().roleHelper().getOrgId())
    this.catId = this.props.match.params.catId
    this.courseDetails = props.location.state ? props.location.state : ''
  }
  render() {
    return (
      <CoursesByCat teams={true} orgId={this.orgId} catId={this.catId} courseDetails={this.courseDetails} />
    )
  }
}

const Styles = StyleSheet.create({
})