import React, { Fragment, useEffect, useState, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { ScreenWrapper } from '../components/ScreenWrapper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { Courses } from '../components/Courses/Courses';
import { Trophies } from '../components/Trophies';
import { AppContext } from '../common/components/contexts/AppContext';
import { EmptyView } from '../components/EmptyView';

export function Accolades(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    result: {},
  })
  const orgId = Controller.get().roleHelper().getOrgId()
  const userId = Controller.get().userMgr().getUserId()

  async function fetchData() {
    try {
      setState({ fetchState: ApiHelper.State.LOADING })
      const response = await Controller.get().courseMgr().getAccoladesDetails(orgId, userId)
      const courseList = {
        items: []
      }
      if (response && response.items && response.items.length > 0) {
        for(const item of response.items) {
          if(!item._course || !item._course.certification || item._course.certification === 'NO') continue
          courseList.items.push({ cpId: item.id, ...item, ...item._course, courseState: item.state, showCompletionTs: true, showMedal: true }) //TODO: additionally passing course progress id - since it was over written here
        }
      }

      setState({
        ...state,
        fetchState: ApiHelper.State.READY,
        result: response,
        courseList: courseList,
      })
    }
    catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR, errMsg: Helper.getErrorMsg(e) })
    }
  }

  useEffect(() => {
    appContextStateHandler({ pageKey: 'accolades' })
    fetchData()
  }, [])

  return (
    <ScreenWrapper
      state={state}
      title={Helper.getString('accolades')}
      logo={true}>

      {((state.courseList && state.courseList.items && state.courseList.items.length === 0) && (state.result.stats && state.result.stats.rewards && Object.keys(state.result.stats.rewards).length === 0)) ?
        <EmptyView
          title={Helper.getString('noItemsToShow')} />
        :
        state.result &&
        <Fragment>
          {(state.result.stats && state.result.stats.rewards && Object.keys(state.result.stats.rewards).length > 0 && state.result.stats.points) &&
            <div className={css(Styles.trophieContainer)}>
              <Trophies
                data={state.result.stats.rewards}
                points={state.result.stats.points} />
            </div>
          }

          {state.courseList && state.courseList.items && state.courseList.items.length > 0 &&
            <Courses
              showTitle={true}
              title={Helper.getString('myCertificates')}
              borderContainer={[CommonStyles.borderContainer, Styles.courseContainer]}
              data={state.courseList}
            />
          }
        </Fragment>
      }


    </ScreenWrapper>
  )
}

const Styles = StyleSheet.create({
  trophieContainer: {
    flex: .1,
    display: 'flex',
  },
  courseContainer: {
    marginTop: 20,
    '@media(max-width: 767px)': {
      marginTop: 25,
    },
  },
})