import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppCommonTheme } from '../styles/Styles';

export function EmptyView(props) {

  return (
    <div className={css(Styles.outerContainer)}>
      <div className={css(Styles.imgContainer)}>
        <img src={require('../assets/images/empty.svg')}
          className={css(Styles.imgContent)}
          width='450px'
          height='316px'
          alt={Helper.getString('emptyImgAlt')} />
      </div>
      <p className={css(Styles.title)}>{props.title}</p>
    </div>
  )
}

const Styles = StyleSheet.create({
  outerContainer: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  imgContainer: {
    width: 450,
    height: 316,
    display: 'flex',
    '@media(max-width: 767px)': {
      width: 268,
      height: 188
    },
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: AppCommonTheme.borderRadius
  },
  title: {
    margin: AppCommonTheme.pMargin,
    color: AppCommonTheme.appFontColor,
    fontSize: 48,
    marginTop: 42,
    '@media(max-width: 767px)': {
      fontSize: 28,
      marginTop: 24,
    },
  }
})