import React, { useState, useEffect } from 'react'
import { StyleSheet, css } from 'aphrodite';
import { SearchRounded } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppTheme } from '../styles/AppTheme';
import { Helper } from '../common/helpers/Helper';
import TextField from '@material-ui/core/TextField';
import { AppCommonTheme } from '../styles/Styles';
import { Link, useParams } from 'react-router-dom';
import { Controller } from '../common/Controller';

export const SearchBar = (props) => {

  const [state, setState] = useState({
    searchText: props.searchText || ''
  })

  const searchInputRef = React.useRef(null);

  const handleKeyPress = (e) => {
    const key = e.keyCode || e.which;
    if (key === 13) {
      onEndEditing()
    }
  }

  const onEndEditing = () => {
    if (props.onEndEditing) {
      props.onEndEditing(state.searchText.toLowerCase())
    } else if(!props.onInputChange) {
      const path = {
        pathname: 'search',
        search: '?query=' + state.searchText.toLowerCase()
      };
      Controller.get().pushRouteWithQueryParams(path)
    }
  }

  const onInputChange = (e) => {
    e.preventDefault()
    setState({
      ...state,
      // searchText: e.target.value.toLowerCase()
      searchText: e.target.value
    })
    props.onInputChange && props.onInputChange(e.target.value.toLowerCase())
  }


  const searchContainerStyle = props.header ? css(Styles.searchContainer) : css(Styles.searchContainer, Styles.searchContainerPage)
  const inputStyles = props.header ? css(Styles.inputStyles) : css(Styles.inputStyles, Styles.inputStylesPage)
  const searchIconContentStyle = props.header ? css(Styles.searchIconContent) : css(Styles.searchIconContent, Styles.searchIconContentPage)
  const iconSize = props.header ? '18px' : '32px'
  const searchBtnsContainerStyle = props.header ? css(Styles.searchBtnsContainer) : css(Styles.searchBtnsContainer, Styles.searchBtnsContainerPage)
  // const placeholder = props.header ? Helper.getString('searchPlaceholder') : Helper.getString('searchPlaceholderPage')
  return (
    <div className={searchContainerStyle}>
      <div
        className={searchBtnsContainerStyle}
        onClick={onEndEditing}>
        <img
          alt={Helper.getString('searchImageAlt')}
          src={require('../assets/images/search_icon.svg')}
          width={iconSize} height={iconSize}
          className={searchIconContentStyle} />
        {/* <div className={css(Styles.searchIconContainer)}>
          {!props.inProcess &&
            <SearchRounded className={css(Styles.searchIcon)} />
          }
          {props.inProcess &&
            <CircularProgress size={24} className={css(Styles.progressVariant)} />
          }
        </div> */}
      </div>
      <TextField
        type={'text'}
        ref={searchInputRef}
        onKeyPress={handleKeyPress}
        className={css(Styles.input)}
        placeholder={props.placeholder}
        value={state.searchText}
        onChange={onInputChange}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: inputStyles
          }
        }} />
    </div>
  )
}

const Styles = StyleSheet.create({
  searchContainer: {
    display: 'flex',
    // alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    border: AppCommonTheme.border,
    borderRadius: AppCommonTheme.borderRadius,
    padding: 12,
    overflow: 'hidden',
    minWidth: 265,
    '@media (max-width: 767px)': {
      width: '100%',
    }
  },
  searchContainerPage: {
    padding: '15px 18px',
    '@media (max-width: 767px)': {
      padding: '18px 12px',
    }
  },
  input: {
    border: 'none',
    outline: 'none',
    flex: 1,
    color: AppCommonTheme.appFontColor,
    '::placeHolder': {
      color: '#767676',
    }
  },
  inputStyles: {
    fontSize: 12,
    padding: 0,
    textOverflow: 'ellipsis'
  },
  inputStylesPage: {
    fontSize: 16,
  },
  searchBtnsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 8
  },
  searchBtnsContainerPage: {
    paddingRight: 16,
    '@media (max-width: 767px)': {
      paddingRight: 12
    }
  },
  searchIcon: {
    color: '#FFFFFF',
  },

  searchIconContainer: {
    backgroundColor: AppTheme.primaryColor,
    padding: 10,
    display: 'flex',
  },
  progressVariant: {
    color: AppCommonTheme.appFontColor,
  },
  searchIconContent: {
    width: 18,
    height: 18,
  },
  searchIconContentPage: {
    width: 32,
    height: 32,
    '@media (max-width: 767px)': {
      width: 26,
      height: 26,
    }
  }
})
